/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-unused-vars */
import { Inject, TreeMapComponent, TreeMapTooltip } from '@syncfusion/ej2-react-treemap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../../redux/app/app.actions';
import { setSelectedSampleAttributeSet } from '../../../../redux/collections/collections.actions';
import { setIsSampleBlockClicked, setModalData, setNewCohortDimension } from '../../../../redux/modal/modal.actions';
import AppService from '../../../services/app-service';
import './single-block.styles.scss';

const SingleSampleBlock = (props) => {
    let {
        modalData
    } = props;
    let dispatch = useDispatch();
    let [attribute, setAttribute] = useState(modalData);
    let [dimensionVal, setDimensionVal] = useState(attribute?.dimension || 750);
    let [cohortId, setCohortId] = useState(AppService.getRandomId(5));
    let [attributeCopy, setAttributeCopy] = useState({ ...modalData });
    const toolTipFormat = ' ${displayName} (n=${count}) ';
    const weightValuePath = 'count';
    const equalColorValuePath = 'name';

    useEffect(() => {
        // setAttributeCopy({ ...attribute });
        if (attribute?.dimension) {
            setTimeout(() => {
                delete attribute['dimension'];
                setAttribute(attribute);
                dispatch(setLoader(false));
            }, 10);
        }
        return () => {
            setAttribute(null);
        }
    }, [attribute]);

    const zoomInAndOut = (type = 'in') => {
        let axesValue = dimensionVal;
        if (type === 'in') {
            axesValue *= 5;
        } else if (type === 'out') {
            axesValue /= 5;
            if (axesValue < 750) {
                axesValue = 750;
            }
        }
        // setDimensionVal(axesValue);
        dispatch(setLoader(true));
        dispatch(setNewCohortDimension({ dimension: axesValue, ...attribute }));
        dispatch(setModalData(null));
        // setTimeout(() => {
        // }, 250);
    }

    const eventSelected = (event) => {
        if (event && event.attribute && event.groupName) {
            const gpName = Number(event.groupName) ? Number(event.groupName) : event.groupName;
            dispatch(setIsSampleBlockClicked(true));
            dispatch(setSelectedSampleAttributeSet([event.attribute, gpName]));
            dispatch(setModalData(null));
        }
    }

    return (
        (attribute) ?
            <div className='block-container'>
                <div className='zoom'>
                    <div className='in' onClick={() => zoomInAndOut('in')}></div>
                    <div className='out' onClick={() => zoomInAndOut('out')}></div>
                </div>
                <div className='block' style={{ maxHeight: '455px', maxWidth: '455px' }}>
                    <TreeMapComponent
                        id={AppService.getRandomId(5)}
                        dataSource={attribute.dataSource}
                        weightValuePath={weightValuePath}
                        equalColorValuePath={equalColorValuePath}
                        leafItemSettings={attribute.leafItemSettings}
                        className='treemap'
                        itemClick={(event) => eventSelected({
                            ...event,
                            attribute: attribute.attributeName ? attribute.attributeName : attribute.name
                        })}
                        tooltipSettings={{
                            visible: true,
                            format: toolTipFormat.trim(),
                            template: '<div class="ttip">${displayName} (n=${count})</div>'
                        }}
                        width={`${dimensionVal}px`}
                        height={`${dimensionVal}px`}
                    >
                        <Inject services={[TreeMapTooltip]} />
                    </TreeMapComponent>
                </div>
            </div> :
            null
    );
};

export default SingleSampleBlock;