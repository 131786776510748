const geneSetAddForm = {
    id: {
        value: null,
        valid: true
    },
    setName: {
        label: 'Set Name',
        description: 'set name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    setSize: {
        label: 'Set Size',
        description: 'set size',
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: false
        },
        valid: false,
        touched: false
    },
    pubMedId: {
        label: 'PubMed',
        description: 'pub med',
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    category: {
        label: 'Category',
        description: 'category',
        elementType: 'input', // 'select',
        elementConfig: {
            type: 'text', // 'select',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: true,
        touched: false
    },
    species: {
        label: 'Species',
        description: 'species',
        elementType: 'input', // 'select',
        elementConfig: {
            type: 'text', // 'select',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: true,
        touched: false
    },
    description: {
        label: 'Description',
        description: 'description',
        elementType: 'textArea',
        elementConfig: {
            type: 'text',
            placeholder: null,
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
};

export default geneSetAddForm;