import { modalActionTypes } from "./modal.types";

const INITIAL_STATE = {
    userErrorData: null,
    userErrorModalData: null,
    newSampleAttributesModalData: null,
    viewSampleAttributesModalData: null,
    modalData: null,
    modalPostData: null,
    cohortDimension: null,
    isSampleBlockClicked: false
};

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case modalActionTypes.SET_MODAL:
            return {
                ...state,
                modalData: action.payload
            };
        case modalActionTypes.SET_SUBMIT_MODAL:
            return {
                ...state,
                modalPostData: action.payload
            };
        case modalActionTypes.SET_USER_ERROR_MODAL:
            return {
                ...state,
                userErrorModalData: action.payload
            };
        case modalActionTypes.SET_ADD_SAMPLE_ATTRIBUTES_MODAL:
            return {
                ...state,
                newSampleAttributesModalData: action.payload
            };
        case modalActionTypes.SET_VIEW_SAMPLE_ATTRIBUTES_MODAL:
            return {
                ...state,
                viewSampleAttributesModalData: action.payload
            };
        case modalActionTypes.SET_NEW_COHORT_DIMENSION:
            return {
                ...state,
                cohortDimension: action.payload
            };
        case modalActionTypes.SET_SAMPLE_BLOCK_CLICK_EVENT:
            return {
                ...state,
                isSampleBlockClicked: action.payload
            };
        default:
            return state;
    }
};

export default modalReducer;