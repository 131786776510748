const advancedSearchActionTypes = {
    SET_ADVANCED_SEARCH_STATE: 'SET_ADVANCED_SEARCH_STATE',
    SET_ADVANCED_SEARCH_NOTIFICATION: 'SET_ADVANCED_SEARCH_NOTIFICATION',
    SET_ADVANCED_SEARCH_RESULT_DATA: 'SET_ADVANCED_SEARCH_RESULT_DATA',
    SET_NOTIF_QUERY_DATA: 'SET_NOTIF_QUERY_DATA',
    SET_API_CALL_INIT_FOR_ADV_SEARCH: 'SET_API_CALL_INIT_FOR_ADV_SEARCH',
    SET_GLOBAL_SEARCH_VAL_FOR_GENESET: 'SET_GLOBAL_SEARCH_VAL_FOR_GENESET',
    SET_SCREEN_TO_ADVANCED_SEARCH: 'SET_SCREEN_TO_ADVANCED_SEARCH'
};

export default advancedSearchActionTypes;