import { notificationActionTypes } from "./notification.types";

const INITIAL_STATE = {
    searchNotificationsData: {},
    downloadNotificationsData: {},
    commentNotificationsData: {}
};

const notificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case notificationActionTypes.SET_SEARCH_NOTIFICATION_DATA: {
            return {
                ...state,
                searchNotificationsData: action.payload
            };
        };
        case notificationActionTypes.SET_DOWNLOAD_NOTIFICATION_DATA: {
            return {
                ...state,
                downloadNotificationsData: action.payload
            }
        };
        case notificationActionTypes.SET_COMMENT_NOTIFICATION_DATA: {
            return {
                ...state,
                commentNotificationsData: action.payload
            }
        }
        default:
            return state;
    }
};

export default notificationsReducer;