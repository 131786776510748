/* eslint-disable no-unused-vars */
import { collectionsActionTypes } from "./collections.types";
import {
    addItemToAttributeAddedList,
    addItemToAttributeDeletedList,
    addItemToAttributeList
} from "./collections.utils";

const INITIAL_STATE = {
    isDataSet: false,
    files: [],
    collectionSearchVal: '',
    collectionListData: [],
    sampleAttributeValues: null,
    selectedSampleAttributeSet: [],
    addedSampleAttributeSet: [],
    sampleAttributeState: [],
    selectedDataSet: null,
    dsRoutePathData: null,
    dcRoutePathData: null,
    possibleValues: null,
    dsRouteTitleObject: null,
    dcRouteTitleObject: null,
    mainRouteTitleObject: null,
    selectedCollectionItem: null,
    dataMenuTriggered: null,
    collectionData: null
};

const collectionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case collectionsActionTypes.SET_SELECTED_COLLECTION_ITEM:
            return {
                ...state,
                selectedCollectionItem: action.payload
            };
        case collectionsActionTypes.SET_ATTRIBUTE_FILES:
            return {
                ...state,
                files: action.payload
            };
        case collectionsActionTypes.SET_COLLECTION_LIST_DATA:
            return {
                ...state,
                collectionListData: action.payload
            };
        case collectionsActionTypes.SET_COLLECTION_SEARCH_VAL:
            return {
                ...state,
                collectionSearchVal: action.payload
            };
        case collectionsActionTypes.SET_SAMPLE_ATTRIBUTE_VALUES:
            return {
                ...state,
                sampleAttributeValues: action.payload
            };
        case collectionsActionTypes.SET_SELECTED_SAMPLE_ATTRIBUTE_SET:
            return {
                ...state,
                selectedSampleAttributeSet: addItemToAttributeList(state.selectedSampleAttributeSet, action.payload)
            };
        case collectionsActionTypes.SET_MAIN_ROUTE_TITLES:
            return {
                ...state,
                mainRouteTitleObject: action.payload
                // deletedSampleAttributeSet: addItemToAttributeDeletedList(state.deletedSampleAttributeSet, action.payload)
            };
        case collectionsActionTypes.SET_ADDED_SAMPLE_ATTRIBUTE_SET:
            return {
                ...state,
                addedSampleAttributeSet: addItemToAttributeAddedList(state.addedSampleAttributeSet, action.payload)
            };
        case collectionsActionTypes.SET_SAMPLE_ATTRIBUTE_STATE:
            return {
                ...state,
                sampleAttributeState: action.payload
            };
        case collectionsActionTypes.SET_DS_SELECTION:
            return {
                ...state,
                selectedDataSet: action.payload
            };
        case collectionsActionTypes.SET_IS_DATASET:
            return {
                ...state,
                isDataSet: action.payload
            };
        case collectionsActionTypes.SET_DS_ROUTE_PATH:
            return {
                ...state,
                dsRoutePathData: action.payload
            };
        case collectionsActionTypes.SET_DC_ROUTE_PATH:
            return {
                ...state,
                dcRoutePathData: action.payload
            };
        case collectionsActionTypes.SET_POSSIBLE_VALUES:
            return {
                ...state,
                possibleValues: action.payload
            };
        case collectionsActionTypes.SET_DS_ROUTE_TITLES:
            return {
                ...state,
                dsRouteTitleObject: action.payload
            };
        case collectionsActionTypes.SET_DC_ROUTE_TITLES:
            return {
                ...state,
                dcRouteTitleObject: action.payload
            };
        case collectionsActionTypes.SET_DATA_MENU_TRIGGER:
            return {
                ...state,
                dataMenuTriggered: action.payload
            };
        case collectionsActionTypes.SET_COLLECTION_DATA:
            return {
                ...state,
                collectionData: action.payload
            };
        default:
            return state;
    }
};

export default collectionsReducer;