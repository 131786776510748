import AuthService from "../services/authentication-service";

export const PERSMISSION_SET = [];

export const PERMISSION_EXT = [
    "_LIST_VIEW",
    "_MODIFY_OWNER_SELF",
    "_MODIFY_ALL_ATTRIBUTES",
    "_DELETE_SELF",
    "_DOWNLOAD",
    "_COMMENT",
    "_CREATE",
    "_DELETE",
    "_EDIT",
    "_VIEW",
    "_SEARCH",
    "_PREVIEW",
    "_DOWNLOAD",
    "_FROM_DC",
    "_PERMISSIONS",
    ""
];

export const convertPermType = (type) => {
    switch (type) {
        case 'data-collections':
        case 'collections':
            return 'DATA_COLLECTION';
        case 'data-set':
            return 'DATA_SET';
        case 'dataset-list':
            return 'DATASET_LIST';
        case 'collection':
            return 'COLLECTION';
        case 'analyses':
        case 'analysis':
            return 'PROJECT';
        case 'resources':
            return 'RESOURCE_GENESET';
        case 'user-management':
            return 'USER_MANAGEMENT';
        case 'admin':
        case 'tables':
            return 'ATTRIBUTES_VERSION';
        case 'system-tables':
        case 'system-tables-list':
        case 'system-tables-create':
            return 'SYSTEM_TABLES_SAMPLE_ATTRIBUTES';
        case 'system-tables-dc':
            return 'SYSTEM_TABLES_DATA_COLLECTION_ATTRIBUTES';
        case 'system-tables-ds':
            return 'SYSTEM_TABLES_DATA_SET_ATTRIBUTES';
        case 'system-tables-pj':
            return 'SYSTEM_TABLES_PROJECT_ATTRIBUTES';
        case 'attributes-version':
            return 'ATTRIBUTES_VERSION';
        default:
            return type;
    }
}

export const UP_ACCESS = (permissionSet = [], operator = 'OR') => {
    const userPermissions = AuthService._getUserPermissions();
    if (permissionSet.length && userPermissions && userPermissions.length) {
        if (operator === 'OR') {
            for (let permission of permissionSet) {
                if (userPermissions.indexOf(permission) >= 0) {
                    return true;
                }
            }
        } else if (operator === 'AND') {
            let availablePermissions = [];
            for (let permission of permissionSet) {
                if (userPermissions.indexOf(permission) >= 0) {
                    availablePermissions.push(permission);
                }
            }
            if (availablePermissions.length === permissionSet.length) {
                return true;
            }
        }
    }
    return false;
}