/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setAdvancedSearchResultData, setAdvancedSearchState, setNotifAPICallInitiationForAdvancedSearch, setScreenToAdvancedSearch, setSearchQueryDataForNotifications } from '../../../redux/advanced-search/advanced-search.actions';
import { getAdvancedSearchNotifData, getNotifAPICallInitiationForAdvancedSearch } from '../../../redux/advanced-search/advanced-search.selectors';
import { setHomePageClick, setLoader, setRefreshAppId } from '../../../redux/app/app.actions';
import { getAppId, getHomePageClick, getNotifData } from '../../../redux/app/app.selectors';
import { setCurrentUser, setDownloadNotifs } from '../../../redux/user/user.actions';
import { setSearchDataState } from '../../../redux/data-saver/data-saver.actions';
import { getCurrentUser, getDownloadNotifs } from '../../../redux/user/user.selectors';
import AppService from '../../services/app-service';
import SecurityService from '../../services/security-service';
import { UP_ACCESS } from '../../utils/permissions';
import GlobalSearch from '../global-search/global-search.component';
import PopOver from '../pop-over/pop-over.component';
import './header.styles.scss';
import { ucsfInstance, ucsfLogoutInstance } from '../../axios/ucsf';
import { COMPONENT_APIS } from '../../utils/omi-api';
import { CONST } from '../../utils/omi-constants';
import AuthService from '../../services/authentication-service';
import ReportBug from '../report-bug/report-bug.component';

const Header = ({
    history,
    setLoader,
    setCurrentUser,
    downloadNotifications = [],
    clearAllDownloadNotifs,
    clearDownloadNotif,
    isDownloadOptionClose = false,
    setHomePageClick,
    appId,
    setDownloadNotifs,
    setAdvancedSearchDownloadNotifs,
    advancedSearchNotifData = null,
    setAdvancedSearchState,
    location,
    notificationData,
    setAdvancedSearchResultData,
    setSearchDataState,
    setSearchQueryDataForNotifications,
    notifAPICallInitiationForAdvancedSearch,
    setNotifAPICallInitiationForAdvancedSearch,
    currentUser,
    setRefreshAppId
}) => {
    const d = useDispatch();
    const [showUserOptions, setShowUserOptions] = useState(false);
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);
    const [notifSwing, setNotifSwing] = useState(false);
    const [downloadNotifList, setDownloadNotifList] = useState([]);
    const [notifLoader, setNotifLoader] = useState(false);
    const userOptionRef = createRef();
    const downloadNotifRef = createRef();
    const wsEndPoint = AppService.wsDowloadZip(appId);
    const wsDockerEndPoint = AppService.wsDockerEndPoint(appId);
    const wsKmeEndPoint = AppService.wsKmeEndPoint(appId);
    const wsSearchEndPoint = AppService.wsSearchEndPoint(appId);
    const wsCommentNotifEndPoint = AppService.wsCommentNotifEndPoint(currentUser?.user?.id);
    const [isUnreadExist, setIsUnreadExist] = useState(false);
    let queryParams = null, notifId = null, viewMode = false;
    if (location) {
        queryParams = AppService.fetchQueryParams(location.search);
        notifId = queryParams['notificationID'];
        viewMode = notifId ? true : false;
    }
    useEffect(() => {
        setDownloadNotifList(downloadNotifications);
    }, [downloadNotifications]);
    useEffect(() => {
        setHomePageClick(false);
        if (isDownloadOptionClose) { setShowDownloadOptions(false); }
    }, [isDownloadOptionClose]);

    useEffect(() => {
        if (notificationData) {
            let { type, covariation_file_id, project_id, key, co_variation_network_id, size, name, downloadType } = notificationData;
            let endPoint = wsEndPoint, socketData = null;
            if (type === 'docker') {
                endPoint = wsKmeEndPoint;
                socketData = JSON.stringify({ 'co_variation_network_id': covariation_file_id });
            } else if (type === 'kme') {
                endPoint = wsKmeEndPoint;
                socketData = JSON.stringify({ co_variation_network_id });
            } else {
                socketData = JSON.stringify({ key });
            }
            const ws = new WebSocket(endPoint);
            ws.onopen = () => ws.send(socketData);
            AppService.listenWSFC(ws).then(response => {
                ucsfInstance.post(COMPONENT_APIS.CREATE_DOWNLOAD_NOTIFICATION, {name: name || response.fileName, file_size: size || null, key: response.key, type: downloadType || null});
                const notifData = {
                    title: 'Your download is ready!',
                    message: 'Please check the notifications to view the results',
                    duration: 2500,
                    type: 'success'
                };
                AppService.setNotification(notifData);
                setIsUnreadExist(true);
                setDownloadNotifs(response);
            }).catch(err => { console.log(err); });
        }
    }, [notificationData]);

    useEffect(() => {
        if (appId) {
            const ws = new WebSocket(wsSearchEndPoint);
            AppService.listenWSFAS(ws).then(response => {
                processResultData(response);
            }).catch(err => { console.log(err); });
            setNotifAPICallInitiationForAdvancedSearch(null);
        }
    }, [appId]);

    useEffect(() => {
        let subscription = AppService.getMessage().subscribe(response => {
            processResultData(response);
            if (response) AppService.clearMessages();
        });
        return () => {
            subscription.unsubscribe();
        }
    }, []);

    useEffect(() => {
        const ws = new WebSocket(wsCommentNotifEndPoint);
        AppService.listenWSFMC(ws).then(response => {
            processCommentNotifData(response);
        }).catch(err => { console.log(err); });
    }, [])

    const processCommentNotifData = (propData) => {
        let { commentNotifData } = propData;
        if (commentNotifData) {
            const notifData = commentNotifData.is_owner ? {
                title: 'Message',
                message: `${commentNotifData.mentioned_by} added a comment in your ${commentNotifData.entity_type}`,
                duration: 2500,
                type: 'info'
            } : {
                title: 'Message',
                message: 'You are mentioned in a comment',
                duration: 2500,
                type: 'info'
            };
            setIsUnreadExist(true);
            AppService.setNotification(notifData);
        }
    }

    const processResultData = (propData) => {
        if (propData) {
            let { advancedSearchNotifData } = propData;
            if (advancedSearchNotifData) {
                const responseData = advancedSearchNotifData.resultData ? advancedSearchNotifData.resultData : advancedSearchNotifData;
                if (responseData?.processing) {
                    setLoader(false);
                    const notifData = {
                        title: 'Fetching Results!',
                        message: responseData.message,
                        duration: 3000
                    };
                    AppService.setNotification(notifData);
                } else if (responseData?.isNotification) {
                    const notifData = {
                        title: 'Results Ready!',
                        message: 'Please check the notifications to view the results',
                        duration: 2500,
                        type: 'success'
                    };
                    setIsUnreadExist(true);
                    AppService.setNotification(notifData);
                   // setAdvancedSearchDownloadNotifs(propData);
                } else {
                    setAdvancedSearchResultData(propData);
                }
            }
        }
    }

    const handleUserOptions = (event) => {
        if (event.img === 'logout') {
            setLoader(true);
            ucsfInstance.post(COMPONENT_APIS.APP_LOGOUT, {}).then(res => {
                setShowUserOptions(!showUserOptions);
                SecurityService.removeSecurityToken();
                setTimeout(() => {
                    setRefreshAppId(true);
                    setCurrentUser();
                    setTimeout(() => {
                        setLoader(false);
                        history.push('/');
                    }, 750);
                }, 250);
            }).catch(err => {
                console.log(err);
                setLoader(false);
                const notifData = {
                    title: 'Logout Error!',
                    message: 'Please check the logout URL',
                    duration: 2500,
                    type: 'danger'
                };
                AppService.setNotification(notifData);
            });
        }
    }

    // LOGOUT EVEN ON ERROR -------------------------
    const handleUserOptionsOnError = (event) => {
        const clearCacheData = () => {
            caches?.keys()?.then((names) => {
                names?.forEach((name) => {
                    caches?.delete(name);
                });
            });
        };
        if (event.img === 'logout') {
            setLoader(true, 'Logging you out');
            clearCacheData();
            setShowUserOptions(!showUserOptions);
            SecurityService.removeSecurityToken();
            SecurityService.setLogoutId(AppService.getRandomId(6));
            if (currentUser?.role === 'RU' || currentUser?.role === 'GU') {
                SecurityService.setLogoutId('');
            }
            setTimeout(() => {
                setRefreshAppId(true);
                setTimeout(() => {
                    setLoader(false);
                    setCurrentUser();
                    history.push('/');
                }, 1500);
            }, 250);
        }
    }

    const handleDownloadOptions = (event) => {
        setShowDownloadOptions(true);
        setAdvancedSearchState(null);
        if (event.name === 'clear all') {
            ucsfInstance.put(COMPONENT_APIS.MARK_ALL_NOTIFICATION_AS_READ, {}).then(() => {
                clearAllDownloadNotifs(event);
            });
            //clearAllDownloadNotifs(event);
            setSearchDataState({ actionType: 'clear-all' });
            setShowDownloadOptions(false);
            setHomePageClick(false);
        } else if (event.listType && event.listType === "advancedSearch") {
            setLoader(true);
            let { type, notifId } = event || {};
            //let notifData = AuthService._getStorageData(notifId, true) || null;
            let notifData = event.notifData;
            // ALERT NOTIFICATION -------------------------------------------
            let resultsAlreadyFetched = () => {
                setTimeout(() => {
                    setLoader(false);
                    AppService.setNotification({
                        title: 'Alert',
                        message: `You must have already fetched the results for this ${type} query in a different tab/window.`,
                        duration: 4500,
                        type: 'info'
                    });
                }, 500);
            }
            // RESET AND CLEAR FILTERS ON PROJECT RELOAD --------------------
            let resetAndclearFilters = () => {
                if (type) { resultsAlreadyFetched(); }
                else { setLoader(false); }
            }
            // ---------------------------------------
            if (notifData && event) {
               // clearDownloadNotif(event);
                let baseUrl = '/home/advanced-search';
                let searchParams = `notificationID=${notifId}`;
                setSearchQueryDataForNotifications(event);
                if (location) {
                    history.push(`${baseUrl}?${searchParams}`);
                }
               ucsfInstance.put(`${COMPONENT_APIS.MARK_NOTIFICATION_AS_READ}${notifId}`, {}).then(() => {
                clearDownloadNotif(event);
               });
            } else if (!notifData && event) {
               // clearDownloadNotif(event);
                resetAndclearFilters();
            }
        } else if (event.listType === 'download') {
            ucsfInstance.post(`${COMPONENT_APIS.MARK_DOWNLOAD_NOTIFICATION_AS_READ}`, { key: event.key }).then(() => {
                clearDownloadNotif(event);
            });
            AppService.getS3URLAndDownloadFile(event);
        } else if (event.listType === 'mention') {
            localStorage.setItem('parent', event.parent_id);
            window.location.href = event.url;
            ucsfInstance.put(`${COMPONENT_APIS.MARK_MENTION_NOTIFICATION_AS_READ}${event.notifId}`, {}).then(() => {
                clearDownloadNotif(event);
            });
        }
        setShowDownloadOptions(false);
    }

    const routeToAdvancedSearch = () => {
        d(setScreenToAdvancedSearch({
            viewSampleQueries: false,
            viewSavedQueries: false,
            viewRecentQueries: false
        }));
        history.push('/home/advanced-search');
    }

    const adminPermission = () => {
        return (
            AppService.hasAccess('attributes-version', '_LIST_VIEW') ||
            AppService.hasAccess('system-tables', '_LIST_VIEW')
        );
    }
    return (
        <div className="hdr-container">
            <div className="icon-container side-width">
                <div className="logo" onClick={() => { history.push('/home'); }}></div>
            </div>
            <div className="info-container full-width">
                <div className="part-l">
                    <GlobalSearch history={history} />
                    <div className="adv" onClick={() => routeToAdvancedSearch()}>
                        Advanced Search
                    </div>
                </div>
                <div className="controls">
                    {/**REPORT BUG/FEATURE */}
                    <ReportBug className="rb-btn-link" isLink history={history}/>
                    {/**NOTIFICATIONS */}
                    <div
                        className={`notifs animate__animated${notifSwing ? ' animate__tada' : ''}`}
                        ref={downloadNotifRef}
                        onClick={() => {
                            !showDownloadOptions && setNotifLoader(true);
                            !showDownloadOptions && ucsfInstance.get(COMPONENT_APIS.UNREAD_NOTIFICATION).then(res => { // Manual call to fetch unread notification
                                setAdvancedSearchDownloadNotifs(res.data);
                                setNotifLoader(false);
                                setIsUnreadExist(false);
                            }).catch((err)=> {console.log(err);setNotifLoader(false)});
                            setShowDownloadOptions(!showDownloadOptions);
                        }}
                        tabIndex={1}>
                        {isUnreadExist || downloadNotifications.length ? <div className="bubble"></div> : null}
                        {
                            showDownloadOptions ? (
                                <PopOver
                                    dataList={downloadNotifList}
                                    showToolTip={true}
                                    listType="downloadNotifs"
                                    className={`notif-options${notifSwing ? ' animate__swing' : ''}`}
                                    divRef={downloadNotifRef}
                                    showLink={true}
                                    click={(event) => handleDownloadOptions(event)}
                                    loader={notifLoader}
                                    viewNotificationLink={() => history.push('/home/notifications')} />
                                    
                            ) : null
                        }
                    </div>
                    {/**USER-MENU */}
                    <div
                        className="avatar"
                        ref={userOptionRef}
                        onClick={() => setShowUserOptions(!showUserOptions)}
                        onBlur={() => setShowUserOptions(false)}
                        tabIndex={2}>
                        <span className="img"></span>
                        <span className="drop"><i className="arrow down"></i></span>
                        {
                            showUserOptions ? (
                                <PopOver
                                    userData={currentUser}
                                    listType="userOptions"
                                    className={`user-options${UP_ACCESS(['ATTRIBUTES_VERSION_LIST_VIEW']) ? '' : ' no-gear'}`}
                                    divRef={userOptionRef}
                                    click={(event) => handleUserOptionsOnError(event)} />
                            ) : null
                        }
                    </div>
                    {/**ADMIN-GEAR-ICON */}
                    {
                        UP_ACCESS(['ATTRIBUTES_VERSION_LIST_VIEW']) ?
                            <div className="settings" onClick={() => history.push('/home/admin')}></div> :
                            null
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    appId: getAppId,
    downloadNotifications: getDownloadNotifs,
    isDownloadOptionClose: getHomePageClick,
    advancedSearchNotifData: getAdvancedSearchNotifData,
    notificationData: getNotifData,
    notifAPICallInitiationForAdvancedSearch: getNotifAPICallInitiationForAdvancedSearch,
    currentUser: getCurrentUser
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val, text = '') => dispatch(setLoader(val, text)),
    setCurrentUser: () => dispatch(setCurrentUser(null)),
    setDownloadNotifs: (data) => dispatch(setDownloadNotifs(data)),
    setAdvancedSearchDownloadNotifs: (data) => dispatch(setDownloadNotifs(data, 'all')),
    clearAllDownloadNotifs: (data) => dispatch(setDownloadNotifs(data, 'clear all')),
    clearDownloadNotif: (data) => dispatch(setDownloadNotifs(data, 'clear-one')),
    setHomePageClick: (data) => dispatch(setHomePageClick(false)),
    setAdvancedSearchState: (data) => dispatch(setAdvancedSearchState(data)),
    setAdvancedSearchResultData: (data) => dispatch(setAdvancedSearchResultData(data)),
    setSearchDataState: (data) => dispatch(setSearchDataState(data)),
    setSearchQueryDataForNotifications: (data) => dispatch(setSearchQueryDataForNotifications(data)),
    setNotifAPICallInitiationForAdvancedSearch: (data) => dispatch(setNotifAPICallInitiationForAdvancedSearch(data)),
    setRefreshAppId: (val) => dispatch(setRefreshAppId(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Header));