const newGeneSetData = {
    'category': [{
        name: 'Tissue Type'
    }, {
        name: 'Organism Type'
    }],
    'species': [{
        name: 'Mus musculus 20'
    }, {
        name: 'Mus musculus 21'
    }],
};

export default newGeneSetData;