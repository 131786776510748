import { createSelector } from "reselect";

const notificationsState = state => state.notifications;

export const getSearchNotificationData = createSelector(
    [notificationsState],
    (notifications) => notifications?.searchNotificationsData
);

export const getDownloadNotificationData = createSelector(
    [notificationsState],
    (notifications) => notifications?.downloadNotificationsData
);

export const getCommentNotificationData = createSelector(
    [notificationsState],
    (notifications) => notifications?.commentNotificationsData
);
