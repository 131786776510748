import React, { useState } from 'react';
import CustomButton from '../../../../../core/components/custom-button/custom-button.component';
import FormInput from '../../../../../core/components/form-input/form-input.component';
import { PreAuthCommonValidation } from '../../../../../core/components/pre-auth-layout/pre-auth-common-validation.component';
import PreAuthLayoutComponent from '../../../../../core/components/pre-auth-layout/pre-auth-layout.component';
import { preAuthBtnStyle } from '../../../../../core/style-config/elements.component';
import formValidityCheck from '../../../../../core/utils/validator';
import codeForm from '../../forms/code.forms';

const GetCode = ({ history }) => {
    const [formData, setFormData] = useState({
        codeFormData: { ...codeForm },
        formIsValid: false,
        loading: false
    });
    const { codeFormData } = formData;

    const inputChangedHandler = (event, indentifier) => {
        let formIsValid = true;
        const updatedCodeForm = { ...codeFormData };
        const updatedFormElement = { ...updatedCodeForm[indentifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedCodeForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation.message = errorMessage;
        updatedFormElement.touched = true;
        updatedCodeForm[indentifier] = updatedFormElement;
        for (let identifier in updatedCodeForm) {
            formIsValid = updatedCodeForm[identifier].valid && formIsValid;
        }
        setFormData({ codeFormData: updatedCodeForm, formIsValid: formIsValid });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push(`/register/verify-code`);
    }

    const form = (
        <form onSubmit={handleSubmit}>
            <FormInput
                elementType={codeFormData.phone.elementType}
                elementConfig={codeFormData.phone.elementConfig}
                value={codeFormData.phone.value}
                invalid={!codeFormData.phone.valid}
                shouldValidate={codeFormData.phone.validation}
                touched={codeFormData.phone.touched}
                errorMessage={codeFormData.phone.validation.message}
                changed={(event) => inputChangedHandler(event, 'phone')}
                blurred={(event) => inputChangedHandler(event, 'phone')} />
            <CustomButton
                type="submit"
                inverted="inverted"
                disabled={!formData.formIsValid}
                style={preAuthBtnStyle}>
                GET CODE
            </CustomButton>
        </form>
    );

    return (
        <PreAuthLayoutComponent>
            <PreAuthCommonValidation>
                Enter your phone number to receive a one-time verification code.
            </PreAuthCommonValidation>
            {form}
        </PreAuthLayoutComponent>
    );
};

export default GetCode;