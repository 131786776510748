const newSampleAttributeData = {
    'required': [{
        name: 'Yes'
    }, {
        name: 'No'
    }],
    'indexed': [{
        name: 'Yes'
    }, {
        name: 'No'
    }],
    'attributeType': [
        'Date',
        'Enumeration',
        'Integer',
        'Numeric',
        'String', // 'Text',
        'URL / file path',
        'Mixed'
    ]
};

export default newSampleAttributeData;