import { createSelector } from "reselect";

const appState = state => state.app;

export const isLoading = createSelector(
    [appState],
    (app) => app?.loading
);

export const isInLoading = createSelector(
    [appState],
    (app) => app?.inLoading
);

export const getAppId = createSelector(
    [appState],
    (app) => app.appId
);

export const getUcsfWindow = createSelector(
    [appState],
    (app) => app.window
);

export const getUploadStatus = createSelector(
    [appState],
    (app) => app.uploadSuccess
);

export const getUserErrorData = createSelector(
    [appState],
    (app) => app?.userErrorData
);

export const getMainContentScrollData = createSelector(
    [appState],
    (app) => app?.scrollData
);

export const getNetworkSearchVal = createSelector(
    [appState],
    (app) => app?.networkSearchVal
);

export const getFileUploadReset = createSelector(
    [appState],
    (app) => app?.fileUploadReset
);

export const getRouteTitleObject = createSelector(
    [appState],
    (app) => app?.routeTitleObject
);

export const getNetworkMode = createSelector(
    [appState],
    (app) => app?.networkMode
);

export const getHomePageClick = createSelector(
    [appState],
    (app) => app?.homePageClick
);

export const getCommentsCount = createSelector(
    [appState],
    (app) => app?.commentsCount
);

export const getNotifData = createSelector(
    [appState],
    (app) => app?.notificationData
);

export const getLandingPageSamples = createSelector(
    [appState],
    (app) => app?.landingPageSamples
);

export const getSystemTablesData = createSelector(
    [appState],
    (app) => app?.systemTablesData
);

export const getRefreshAppId = createSelector(
    [appState],
    (app) => app?.refreshAppId
);

export const isRefreshWindow = (state) => {
    return state.app.refreshWindow;
}