import React, { useState } from 'react';
import CustomButton from '../../../../../core/components/custom-button/custom-button.component';
import FormInput from '../../../../../core/components/form-input/form-input.component';
import { PreAuthCommonValidation } from '../../../../../core/components/pre-auth-layout/pre-auth-common-validation.component';
import PreAuthLayoutComponent from '../../../../../core/components/pre-auth-layout/pre-auth-layout.component';
import { preAuthBtnStyle, preAuthBtnStyleNormal } from '../../../../../core/style-config/elements.component';
import formValidityCheck from '../../../../../core/utils/validator';
import codeVerificationForm from '../../forms/verify.forms';

const VerifyCode = ({ history }) => {
    const [verificationError, setVerificationError] = useState(false);
    const [verifyCodeData, setCodeVerificationData] = useState({
        codeVerifyData: { ...codeVerificationForm },
        formIsValid: false,
        loading: false
    });
    const { codeVerifyData } = verifyCodeData;
    let verificationText = 'Please enter the code sent to your phone number.';
    let resendElement = <div className="pre-auth-link left"> Didn’t get it? Resend.</div>;
    if (verificationError) {
        verificationText = 'You’ve entered an invalid code, it has expired, or it has already been used.';
        resendElement = null;
    }

    const inputChangedHandler = (event, indentifier) => {
        let formIsValid = true;
        const updatedCodeVerificationForm = { ...codeVerifyData };
        const updatedFormElement = { ...updatedCodeVerificationForm[indentifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedCodeVerificationForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation.message = errorMessage;
        updatedFormElement.touched = true;
        updatedCodeVerificationForm[indentifier] = updatedFormElement;
        for (let identifier in updatedCodeVerificationForm) {
            formIsValid = updatedCodeVerificationForm[identifier].valid && formIsValid;
        }
        setCodeVerificationData({ codeVerifyData: updatedCodeVerificationForm, formIsValid: formIsValid });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (codeVerifyData.verifyCode.value === null || codeVerifyData.verifyCode.value === '') {
            setVerificationError(true);
        }
        if (verificationError) {
            history.push('/register/get-code');
        }
    }

    let form = (
        <form onSubmit={handleSubmit}> {/**onSubmit={handleSubmit} */}
            {
                verificationError ?
                    null :
                    <FormInput
                        elementType={codeVerifyData.verifyCode.elementType}
                        elementConfig={codeVerifyData.verifyCode.elementConfig}
                        value={codeVerifyData.verifyCode.value}
                        invalid={!codeVerifyData.verifyCode.valid}
                        shouldValidate={codeVerifyData.verifyCode.validation}
                        touched={codeVerifyData.verifyCode.touched}
                        errorMessage={codeVerifyData.verifyCode.validation.message}
                        changed={(event) => inputChangedHandler(event, 'verifyCode')}
                        blurred={(event) => inputChangedHandler(event, 'verifyCode')} />
            }
            <CustomButton
                type="submit"
                inverted="inverted"
                disabled={!verifyCodeData.formIsValid}
                style={verificationError ? preAuthBtnStyleNormal : preAuthBtnStyle}>
                {verificationError ? 'GET A NEW CODE' : 'VERIFY'}
            </CustomButton>
            {resendElement}
        </form>
    );

    return (
        <PreAuthLayoutComponent>
            <PreAuthCommonValidation>
                {verificationText}
            </PreAuthCommonValidation>
            {form}
        </PreAuthLayoutComponent>
    );
};

export default VerifyCode;