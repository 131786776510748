import { createSelector } from "reselect";

const collectionsState = state => state.collections;

export const getCollectionListDataValue = createSelector(
    [collectionsState],
    (collections) => collections?.collectionListData
);

export const getCollectionSearchVal = createSelector(
    [collectionsState],
    (collections) => collections?.collectionSearchVal
);

export const getSelectedCollectionDataItem = createSelector(
    [collectionsState],
    (collections) => collections?.selectedCollectionItem
);

export const getSampleAttributeValues = createSelector(
    [collectionsState],
    (collections) => collections.sampleAttributeValues
);

export const getSelectedSampleAttributeValues = createSelector(
    [collectionsState],
    (collections) => collections.selectedSampleAttributeSet
);

export const getMainRouteTitleObject = createSelector(
    [collectionsState],
    (collections) => collections.mainRouteTitleObject
);

export const getSampleAttributeState = createSelector(
    [collectionsState],
    (collections) => collections.sampleAttributeState
);

export const fetchSampleAttributeState = (state) => {
    let { collections } = state || {};
    return collections?.sampleAttributeState;
};

export const getSelectedDataset = createSelector(
    [collectionsState],
    (collections) => collections.selectedDataSet
);

export const getPossibleValues = createSelector(
    [collectionsState],
    (app) => app?.possibleValues
);

export const getIsDataset = createSelector(
    [collectionsState],
    (collections) => collections.isDataSet
);

export const getAddedSampleAttributeValues = createSelector(
    [collectionsState],
    (collections) => collections.addedSampleAttributeSet
);

export const getAttributeFiles = createSelector(
    [collectionsState],
    (collections) => collections.files
);

export const getCollectionDataInfo = createSelector(
    [collectionsState],
    (collections) => collections.collectionData
);

export const getIsDataMenuTriggered = createSelector(
    [collectionsState],
    (collections) => collections.dataMenuTriggered
);

/* export const getSavedDSRoutePath = createSelector(
    [collectionsState],
    (app) => app?.dsRoutePathData
);

export const getSavedDCRoutePath = createSelector(
    [collectionsState],
    (app) => app?.dcRoutePathData
);

export const getDSRouteTitleObject = createSelector(
    [collectionsState],
    (app) => app?.dsRouteTitleObject
);

export const getDCRouteTitleObject = createSelector(
    [collectionsState],
    (app) => app?.dcRouteTitleObject
); */
