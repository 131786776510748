/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './docker.styles.scss';
import AppService from "../../../../core/services/app-service";
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { createStructuredSelector } from 'reselect';
import { getAppId } from '../../../../redux/app/app.selectors';
import { connect } from 'react-redux';
import { setLoader, setNetworkModeOn } from '../../../../redux/app/app.actions';

const DockerComponent = (props) => {
    const {
        location,
        history,
        appId,
        setLoader,
        setNetworkModeOn
    } = props;
    let queryParams = null, key = null;
    const wsEndPoint = AppService.wsDowloadZip(appId);
    const wsDockerEndPoint = AppService.wsDockerEndPoint(appId);
    if (location) {
        queryParams = AppService.fetchQueryParams(location.search);
        key = queryParams['key'];
    }

    useEffect(() => {
        if (key) {
            AppService.getAwsCredentials().then(async res => {
                const s3Configuration = await {
                    credentials: {
                        accessKeyId: res['AccessKeyId'],
                        secretAccessKey: res['SecretAccessKey'],
                        sessionToken: res['SessionToken']
                    },
                    region: res['region']
                };
                const s3 = await new S3Client(s3Configuration);
                const url = await getSignedUrl(s3, new GetObjectCommand({
                    Bucket: res['bucket'],
                    Key: key
                }), {
                    expiresIn: 30 * 60
                });
                AppService.setNotification({
                    title: 'Downloading',
                    message: 'Docker image file is getting downloaded'
                });
                setTimeout(() => {
                    window.open(url, key);
                    history.push('/home');
                }, 500);
            });
        }
        setTimeout(() => {
            setNetworkModeOn('expand');
        }, 500);
        return () => {
            setNetworkModeOn(null);
        }
    }, [key]);

    return (
        <div className='docker-container'>
            <span>Your Docker file will be downloaded shortly...</span>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    appId: getAppId
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val) => dispatch(setLoader(val)),
    setNetworkModeOn: (val) => dispatch(setNetworkModeOn(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(DockerComponent);
