import { CONST } from "../../../utils/omi-constants";
import { browserVersion, getTimeZone } from "./utils";

export const featureRequestTemplateForm = {
    firstName: {
        label: 'First Name',
        postKey: 'first_name',
        description: 'first name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'First Name',
            disabled: false
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false
    },
    lastName: {
        label: 'Last Name',
        postKey: 'last_name',
        description: 'last name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Last Name',
            disabled: false
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false
    },
    email: {
        label: 'Email',
        postKey: 'email',
        description: 'email',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email',
            disabled: false
        },
        value: '',
        validation: { required: true, isEmail: true },
        valid: false,
        touched: false
    },
    omiconVersion: {
        label: 'Omicon Version',
        postKey: 'omicon_version',
        description: 'browser version',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: true
        },
        value: CONST.OMICON_VERSION, // 'Chrome v1.0.10.9',
        validation: { required: false },
        valid: true,
        touched: false
    },
    comment: {
        label: 'Comment',
        postKey: 'comment',
        description: 'comment',
        elementType: 'textArea',
        elementConfig: {
            type: 'text',
            placeholder: 'Enter you comments for feedback',
            disabled: false
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false
    }
};

export const bugReportTemplateForm = {
    firstName: {
        label: 'First Name',
        postKey: 'first_name',
        description: 'first name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'First Name',
            disabled: false
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false
    },
    lastName: {
        label: 'Last Name',
        postKey: 'last_name',
        description: 'last name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Last Name',
            disabled: false
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false
    },
    email: {
        label: 'Email',
        postKey: 'reporter_email',
        description: 'email',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email',
            disabled: false
        },
        value: '',
        validation: { required: true, isEmail: true },
        valid: false,
        touched: false
    },
    phone: {
        label: 'Phone',
        postKey: 'reporter_phone',
        description: 'phone',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Phone',
            disabled: false
        },
        value: '',
        validation: {
            required: false,
            isNumeric: true,
            minLength: 6,
            maxLength: 13
        },
        valid: false,
        touched: false
    },
    omiconVersion: {
        label: 'Omicon Version',
        postKey: 'omicon_version',
        description: 'browser version',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: true
        },
        value: CONST.OMICON_VERSION, // 'Chrome v1.0.10.9',
        validation: { required: false },
        valid: true,
        touched: false
    },
    browserVersion: {
        label: 'Browser Version',
        postKey: 'browser_version',
        description: 'browser version',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: true
        },
        value: browserVersion, // 'Chrome v1.0.10.9',
        validation: { required: false },
        valid: true,
        touched: false
    },
    osVersion: {
        label: 'OS Version',
        postKey: 'os_version',
        description: 'os version',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: true
        },
        value: window.navigator.platform, // 'Linux v22.4',
        validation: { required: false },
        valid: true,
        touched: false
    },
    timeZone: {
        label: 'Time Zone',
        postKey: 'timezone',
        description: 'time zone',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: true
        },
        value: getTimeZone(),
        validation: { required: false },
        valid: true,
        touched: false
    },
    environmentalVariables: {
        label: 'Environmental Variables',
        postKey: 'environmental_variables',
        description: 'environmental variables',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: true
        },
        value: CONST.environmentalVariables,
        validation: { required: false },
        valid: true,
        touched: false
    },
    bugDescription: {
        label: 'Bug Description',
        postKey: 'bug_description',
        description: 'bug description',
        elementType: 'textArea',
        elementConfig: {
            type: 'text',
            placeholder: 'Please provide your bug description here.',
            disabled: false
        },
        value: '',
        validation: { required: true },
        valid: false,
        touched: false
    },
    stepsToReproduce: {
        label: 'Steps to reproduce',
        postKey: 'steps_to_produce_bug',
        description: 'steps to reproduce',
        elementType: 'textArea',
        elementConfig: {
            type: 'text',
            placeholder: 'Enter the steps needed to reproduce the issue.',
            disabled: false
        },
        value: '',
        validation: { required: false },
        valid: true,
        touched: false
    }
};