/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import './form-input.styles.scss';
import InLoader from '../loaders/component-loader/component-loader.component';
import { createStructuredSelector } from 'reselect';
import { isInLoading } from '../../../redux/app/app.selectors';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
// import SearchIcon from "../../../assets/search-ico.svg";
// import { AsyncTypeahead } from 'react-bootstrap-typeahead';
// import { Fragment } from 'react';

const FormInput = (props) => {
    // DECLARATIONS : STATE, VARS
    const {
        label = null,
        globalSearchLocations,
        multiList,
        multiSelectValues,
        style,
        list,
        inLoading,
        isMultiSelectSearchDisabled = false,
        fullWidth = false,
        postKey,
        isDynamic = false,
        placeholder = null,
        className = '',
        showInputValue = true
    } = props;
    const multiSelectRef = useRef(null);
    const [toggleLocationOptions, setToggleLocations] = useState(false);
    const [toggleSelectionOptions, setToggleSelection] = useState(false);
    const [toggleMultiSelectionOptions, setToggleMultiSelection] = useState(false);
    let errorMessage = null, inputClasses = ['group'];
    let labelVal = (val) => (label?.toLowerCase() === 'enumerated values') ? '' : (val === 'blank') ? '' : val;
    const [selectAll, setSelectAll] = useState({
        id: 'all',
        name: 'Select All',
        selected: false
    });

    // DEFINITIONS/CONDITIONS
    if (props.invalid && props.shouldValidate && props.touched) {
        errorMessage = props.errorMessage;
        inputClasses.push('error');
    } else {
        inputClasses = ['group'];
    }
    if (className) {
        inputClasses.push(className);
    }

    const toggleLocations = (type = null) => {
        if (type) {
            setToggleLocations(false);
        } else {
            setToggleLocations(!toggleLocationOptions);
        }
    };

    const toggleSelection = (event, type = null) => {
        if (type) {
            const currentTarget = event.currentTarget;
            setTimeout(() => {
                if (!currentTarget.contains(document.activeElement)) {
                    setToggleSelection(false);
                }
            }, 10);
        } else {
            setToggleSelection(!toggleSelectionOptions);
        }
    };

    const toggleMultiSelection = (event, type = null) => {
        if (type === 'blur') {
            const currentTarget = event.currentTarget;
            setTimeout(() => {
                if (!currentTarget.contains(document.activeElement)) {
                    setToggleMultiSelection(false);
                }
            }, 10);
        } else {
            setToggleMultiSelection(true);
        }
    };

    const options = (
        <div className="options">
            {
                globalSearchLocations?.map((location, index) => {
                    return (
                        <div
                            key={(location?.type || index)}
                            className="g-option"
                            onClick={() => {
                                setToggleLocations(false);
                                return props.onGlobalSelectValue(location);
                            }}>
                            {location.display}
                        </div>
                    );
                })
            }
        </div>
    );

    const multiCheckElement = (data, index) => (
        <div key={(data?.id || index)} className="multi-check-space">
            <div className="multi-check">
                <input
                    type="checkbox"
                    name={data.name}
                    value={(props?.selected) ? props?.selected : false}
                    checked={data.selected}
                    onChange={(event) => {
                        if (data.id === 'all') {
                            data['selected'] = !data['selected'];
                            setSelectAll(data);
                        } else {
                            const selectObj = { ...selectAll };
                            selectObj['selected'] = false;
                            setSelectAll(selectObj);
                        }
                        return props.checked(data, event.target.checked);
                    }} />
            </div>
            <div className="multi-option">
                {data.name}
            </div>
        </div>
    );

    const multiSelectOptions = () => {
        return (
            <div className={`multi-select-options ${fullWidth ? 'full-width' : ''}`}>
                <div className="all">
                    {multiCheckElement({ ...selectAll })}
                </div>
                <div className="multi-search">
                    <div className="list-search-container">
                        <div className="search-icon"></div>
                        <input
                            className={`form-input${isMultiSelectSearchDisabled ? ' disabled' : ''}`}
                            placeholder={`${props?.searchLabel}`}
                            value={(props?.value) ? props?.value : ''}
                            onChange={props?.changed}
                            {...props.elementConfig} />
                        {/* onBlur={props?.blurred} */}
                    </div>
                </div>
                <div className="multi-scroll">
                    {
                        inLoading ?
                            <InLoader /> :
                            multiList?.map((object, i) => multiCheckElement(object, i))
                    }
                </div>
            </div>
        );
    };

    const linkAction = (props, val = null) => {
        let linkUrl = val ? val : props.value;
        if (linkUrl && typeof linkUrl === 'string' && linkUrl.toLowerCase() !== 'na' && linkUrl.toLowerCase() !== '') {
            if (props.elementConfig.baseUrl) {
                const value = (val ? val : props.value);
                linkUrl = props.elementConfig.baseUrl + value;
                if (value.toLowerCase().includes('-')) {
                    linkUrl = `https://www.ebi.ac.uk/arrayexpress/experiments/${value}`;
                }
                window.open(linkUrl);
            } else {
                window.open(linkUrl);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && props.searchClick) {
            props.searchClick();
        }
    };

    const splitValues = (values, forToolTip = false) => {
        let defaultLabel = <label style={{ color: '#444444' }}>NA</label>;
        let valArray = [];
        if (values && !forToolTip) {
            if (values && typeof values === 'string' && values.trim() === 'NA') {
                return defaultLabel;
            }
            if (Array.isArray(values)) {
                valArray = values;
                if (values && values.length === 1 && values[0] === 'NA') {
                    return defaultLabel;
                }
            } else {
                if (values.includes('|')) { valArray = values.split('|'); }
                else { valArray = values.split(' '); }
            }
            let labels = (valArray.length) ? valArray.map((val, i) => {
                return (<label key={`${val}_${i}`} onClick={() => linkAction(props, val)}>{val}</label>);
            }) : defaultLabel;
            return labels;
        }
        return defaultLabel;
    };

    const spltValuesForToolTip = (values) => {
        let valArray = [];
        if (values) {
            if (Array.isArray(values)) {
                valArray = values;
            } else {
                if (values.includes('|')) { valArray = values.split('|'); }
                else { valArray = values.split(' '); }
            }
            return valArray.map((val) => ` ${val}`);
        }
        return valArray;
    }

    const spanWrapper = (template, value) => {
        if (props.elementConfig && props.elementConfig.disabled) {
            return (
                <span title={value}> {template} </span>
            );
        }
        return template;
    }

    const inputElem = () => {
        switch (props.elementType) {
            case ('input'):
                let inputVal = (props.value) ? props.value : '';
                if (props.elementConfig && props.elementConfig.disabled && !inputVal) {
                    inputVal = placeholder ? labelVal(placeholder) : labelVal('NA');
                }
                return spanWrapper(
                    showInputValue ?
                        <input
                            style={style}
                            tabIndex={props?.tabIndex}
                            className="form-input"
                            value={inputVal}
                            onChange={props.changed}
                            onBlur={props.blurred}
                            {...props.elementConfig} /> :
                        <input
                            style={style}
                            tabIndex={props?.tabIndex}
                            className="form-input"
                            onChange={props.changed}
                            onBlur={props.blurred}
                            {...props.elementConfig} />,
                    inputVal
                );
            case ('globalSearch'):
                // const selectedLocation = globalSearchLocations.find(location => (props.selectedValue === location.type));
                return (
                    <div className="search-input">
                        <input
                            className="form-input g-search"
                            value={(props?.value) ? props?.value : ''}
                            onChange={props.changed}
                            onBlur={props.blurred}
                            onKeyDown={handleKeyDown}
                            {...props.elementConfig} />
                        {/*
                        <div className="line">|</div>
                        <div
                            className="form-input g-select"
                            onClick={() => toggleLocations()}
                            onBlur={() => toggleLocations('blur')}
                            tabIndex={0}>
                            <label>{selectedLocation?.display}</label>
                            <i className="arrow down"></i>
                            {toggleLocationOptions ? options : null}
                        </div>
                        */}
                        <div className="g-button" onClick={() => props.searchClick ? props.searchClick() : {}}>
                            <div className="sr-img"></div>
                            {/* <img className='sr-img' src={require("../../../assets/search-ico.svg").default} alt={''} /> */}
                        </div>
                    </div>
                );
            case ('listSearch'):
                return (
                    <div className={`list-search-container${(props.showResetIcon || props.showClearIcon) ? ' reset' : ''}`}>
                        <div className="search-icon" onClick={() => props.searchClick ? props.searchClick() : {}}></div>
                        {
                            props.showResetIcon ?
                                <div className="reset-icon" title="reset" onClick={props.resetClick}></div> :
                                null
                        }
                        {
                            props.showClearIcon ?
                                <div className="clear-icon" title="reset" onClick={props.resetClick}></div> :
                                null
                        }
                        <input
                            tabIndex={props?.tabIndex}
                            className="form-input"
                            value={(props?.value) ? props?.value : ''}
                            onChange={props.changed}
                            onBlur={props.blurred}
                            onKeyDown={handleKeyDown}
                            {...props.elementConfig} />
                    </div>
                );
            case ('multiSelect'):
                return spanWrapper(
                    <div
                        ref={multiSelectRef}
                        style={style}
                        className={`multi-select${fullWidth ? ' full-width' : ''}${props?.elementConfig?.disabled ? ' disabled' : ''}`}
                        onClick={(event) => toggleMultiSelection(event)} // props.toggle()
                        onBlur={(event) => toggleMultiSelection(event, 'blur')} // props.toggle
                        tabIndex={props?.tabIndex}>
                        <div className="selected-values">
                            {
                                (multiSelectValues && multiSelectValues.length) ?
                                    multiSelectValues.map((value, index) =>
                                        !(props?.elementConfig?.disabled) ?
                                            <div key={(value?.id || index)} className="select-block">
                                                <div className="selected-name">{value?.name}</div>
                                                {
                                                    <div className="cancel" onClick={() => {
                                                        props.cancelCheck(value);
                                                        setTimeout(() => {
                                                            const selectObj = { ...selectAll };
                                                            selectObj['selected'] = false;
                                                            setSelectAll(selectObj);
                                                        }, 20);
                                                    }}></div>
                                                }
                                            </div> :
                                            `${value?.name}${(multiSelectValues.length && index < multiSelectValues.length - 1) ? ', ' : ''}`
                                    ) :
                                    placeholder ? labelVal(placeholder) : labelVal('NA')
                            }
                        </div>
                        {/* toggleSelection(event, 'blur') */}
                        {!props?.elementConfig?.disabled ? <div className="down-arrow"></div> : null}
                        {/* props?.isToggled */}
                        {toggleMultiSelectionOptions ? multiSelectOptions() : null}
                    </div>,
                    multiSelectValues.map(({ name }) => ` ${name}`)
                );
            case ('select'):
                const selectOptions = (
                    <div className="options">
                        {
                            list?.map((data, index) => {
                                return (
                                    <div
                                        key={(data?.id || index)}
                                        className={`select-option`}
                                        title={data.name ? data.name : data}
                                        onClick={(event) => {
                                            toggleSelection(event, 'blur');
                                            return props.selectVal(data);
                                        }}>
                                        {
                                            isDynamic ?
                                                <div className='dynamic'>{(data.name) ? data.name : data}</div> :
                                                (data.name) ? data.name : data
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                );
                let selectedValue = null, selectVal = null;
                if (props.idSelected && Number(props.idSelected)) {
                    selectedValue = props?.list?.find(data => (props.idSelected === data.id));
                } else {
                    if (props.idSelected === 0) {
                        selectedValue = props?.list?.find(data => (props.idSelected === data.id));
                    } else {
                        selectedValue = props.idSelected;
                    }
                }
                selectVal = (selectedValue?.name) ? selectedValue?.name : selectedValue;
                return spanWrapper(
                    <div
                        className={`select ${props.elementConfig.disabled ? 'disabled' : ''}`}
                        style={style}
                        onClick={() => toggleSelection()}
                        onBlur={(event) => {
                            toggleSelection(event, 'blur');
                            if (props.blurred) { props.blurred(); }
                        }}
                        tabIndex={props?.tabIndex}>
                        <label>{(selectedValue?.name) ? selectedValue?.name : selectedValue ? selectedValue : placeholder ? labelVal(placeholder) : labelVal('NA')}</label>
                        {!props.elementConfig.disabled ? <div className="down-arrow"></div> : null}
                        {/* <div className="down-arrow"></div> */}
                        {toggleSelectionOptions ? selectOptions : null}
                    </div>,
                    selectVal
                );
            case ('textArea'):
                return (
                    (!props.elementConfig?.disabled) ?
                        (
                            showInputValue ?
                                <textarea
                                    tabIndex={props?.tabIndex}
                                    style={style}
                                    className="text-area"
                                    value={props.value}
                                    onChange={props.changed}
                                    onBlur={props.blurred}
                                    {...props.elementConfig}
                                    maxLength={props.maxLen}>
                                </textarea> :
                                <textarea
                                    tabIndex={props?.tabIndex}
                                    style={style}
                                    className="text-area"
                                    onChange={props.changed}
                                    onBlur={props.blurred}
                                    {...props.elementConfig}>
                                </textarea>
                        ) :
                        <div className="text-area disabled">
                            {props.value}
                        </div>
                );
            case ('link'):
                let inputValue = (props.value) ? props.value : '';
                if (props?.elementConfig && props.elementConfig.disabled && !inputValue) {
                    inputValue = placeholder ? labelVal(placeholder) : labelVal('NA');
                }
                return (postKey && !props?.elementConfig?.disabled) ?
                    <input
                        className="form-input"
                        value={inputValue}
                        onChange={props.changed}
                        onBlur={props.blurred} /> :
                    spanWrapper(
                        <div
                            className="select link"
                            style={style}
                            title={props?.value}
                            onClick={() => linkAction(props)}>
                            <label>{(props?.value) ? props?.value : (placeholder) ? labelVal(placeholder) : labelVal('NA')}</label>
                        </div>,
                        props?.value
                    );
            case ('multiLink'):
                return spanWrapper(
                    <div
                        className="select multi-link"
                        style={style}
                        title={props?.value}>
                        {splitValues(props?.value)}
                    </div>,
                    spltValuesForToolTip(props?.value)
                );
            case ('innerRoute'):
                return (
                    <div
                        className="select multi-link"
                        style={style}
                        onClick={props?.click}
                        title={props?.value}>
                        <label>{props?.value}</label>
                    </div>
                );
            case ('ej2MultiSelect'):
                return (
                    <MultiSelectComponent // ref={e => { if (e) { this.multiSelDwnObj = e; } }}
                        dataSource={props?.possibleValues ? props?.possibleValues : []}
                        showSelectAll={true}
                        selectAllText="Select All"
                        unSelectAllText="Unselect All"
                        placeholder="Select values"
                        value={props?.listValue}
                        mode="CheckBox"
                        title={props?.listTitle}>
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                );
            default:
                return spanWrapper(
                    <input
                        className="form-input"
                        value={(props?.value) ? props?.value : ''}
                        onChange={props.changed}
                        onBlur={props.blurred}
                        {...props.elementConfig} />,
                    (props?.value) ? props?.value : ''
                );
        }
    };

    return (
        <div className={`${inputClasses.join(' ')}`} style={props?.groupStyle}>
            {
                label ?
                    <div className="label">
                        {label}
                        {
                            props?.elementConfig?.hint ?
                                <Fragment>
                                    <span className="hint">
                                        <div className="tooltip">{props.elementConfig.hint}</div>
                                    </span>
                                </Fragment> :
                                null
                        }
                        {props?.shouldValidate?.required ? <span className="required-field">*</span> : null}
                    </div> : null
            }
            {inputElem()}
            {
                (errorMessage) ? (
                    <div className="error-bubble"> {errorMessage} </div>
                ) : null
            }
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    inLoading: isInLoading
});

export default connect(mapStateToProps)(React.memo(FormInput));