import { appActionTypes } from "./app.types";

const INITIAL_STATE = {
    loading: { val: false, text: '' },
    inLoading: false,
    triggerApiCall: false,
    appId: null,
    window: null,
    uploadSuccess: false,
    userErrorData: null,
    scrollData: null,
    networkSearchVal: '',
    routePath: '',
    routeTitleObject: {},
    fileUploadReset: false,
    networkMode: null,
    homePageClick: false,
    commentsCount: 0,
    notificationData: null,
    landingPageSamples: null,
    systemTablesData: null,
    refreshWindow: null
};

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case appActionTypes.SET_LOADER_STATE:
            return {
                ...state,
                loading: action.payload
            };
        case appActionTypes.SET_IN_LOADER_STATE:
            return {
                ...state,
                inLoading: action.payload
            };
        case appActionTypes.SET_LOADER_ON:
            return {
                ...state,
                loading: true
            };
        case appActionTypes.SET_LOADER_OFF:
            return {
                ...state,
                loading: false
            };
        case appActionTypes.SET_APP_ID:
            return {
                ...state,
                appId: action.payload
            };
        case appActionTypes.SET_WINDOW:
            return {
                ...state,
                window: action.payload
            };
        case appActionTypes.SET_COLLECTION_UPLOAD_SUCCESS_VAL:
            return {
                ...state,
                uploadSuccess: action.payload
            };
        case appActionTypes.SET_USER_ERROR:
            return {
                ...state,
                userErrorData: action.payload
            };
        case appActionTypes.SET_MAIN_CONTENT_SCROLL:
            return {
                ...state,
                scrollData: action.payload
            };
        case appActionTypes.SET_NETWORK_SEARCH_VAL:
            return {
                ...state,
                networkSearchVal: action.payload
            }
        case appActionTypes.SET_FILE_UPLOAD_RESET:
            return {
                ...state,
                fileUploadReset: action.payload
            }
        case appActionTypes.SET_ROUTE_TITLES:
            return {
                ...state,
                routeTitleObject: action.payload
            }
        case appActionTypes.SET_NETWORK_MODE:
            return {
                ...state,
                networkMode: action.payload
            }
        case appActionTypes.SET_HOME_PAGE_CLICK:
            return {
                ...state,
                homePageClick: action.payload
            }
        case appActionTypes.SET_COMMENTS_COUNT:
            return {
                ...state,
                commentsCount: action.payload
            }
        case appActionTypes.SET_NOTIF_DATA:
            return {
                ...state,
                notificationData: action.payload
            }
        case appActionTypes.SET_LANDING_PAGE_SAMPLES:
            return {
                ...state,
                landingPageSamples: action.payload
            }
        case appActionTypes.SET_SYSTEM_TABLES_DATA:
            return {
                ...state,
                systemTablesData: action.payload
            }
        case appActionTypes.SET_REFRESH_APP_ID:
            return {
                ...state,
                refreshAppId: action.payload
            }
        case appActionTypes.SET_REFRESH_WINDOW:
            return {
                ...state,
                refreshWindow: action.payload
            }
        default:
            return state;
    }
};

export default appReducer;