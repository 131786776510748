import { omiconUnlinkSet } from "../../../pages/post-auth/home/advanced-search/data/advanced-search.data";

const Grid = {
    checkForLinks: (params) => {
        let { val, keyVal, type, resultType } = params;
        const GRID_TYPES = ['geneSet'];
        let showOmiconIdLink = true, UNLINK_SET = omiconUnlinkSet;
        if (type && (GRID_TYPES.indexOf(type) >= 0 || (resultType && UNLINK_SET.indexOf(resultType?.toLowerCase()) >= 0))) { showOmiconIdLink = false; }
        if (val) {
            if (typeof val === 'string') {
                if (val.includes('://')) {
                    let urlStrArr = val.split('://');
                    if (urlStrArr.length <= 2 && urlStrArr[0]?.trim()?.length <= 5) { return true; }
                } else {
                    if (keyVal && typeof keyVal === 'string') {
                        switch (keyVal.toLowerCase()) {
                            case 'input dataset omicon id':
                            case 'input dataset':
                            case 'covariation network omicon id':
                            case 'covariation network':
                            case 'covariation module omicon id':
                            case 'geneset omicon id':
                                return true;
                            case 'omicon id':
                                if (val && typeof val === 'string' && val !== 'NA' && showOmiconIdLink) {
                                    if (val.split(' ').length > 1) { return false; }
                                    return true;
                                }
                                return false;
                            case 'pubmed id':
                            case 'accession id':
                            case 'bioproject id':
                                if (val && typeof val === 'string' && val !== 'NA') {
                                    if (val.split(' ').length > 1) { return false; }
                                    if (Number.isNaN(Number(val))) { return false; }
                                    return true;
                                }
                                return false;
                            default:
                                return false;
                        }
                    }
                }
            } else if (Array.isArray(val)) {
                if (keyVal && typeof keyVal === 'string') {
                    switch (keyVal.toLowerCase()) {
                        case 'pubmed id':
                        case 'omicon id':
                        case 'accession id':
                        case 'bioproject id':
                            if (val.length > 1) { return true; }
                            else if (val.length === 1 && val[0].trim() !== 'NA') { return true; }
                            return false;
                        default:
                            return false;
                    }
                }
            } else if (typeof val === 'number') {
                if (keyVal && typeof keyVal === 'string') {
                    switch (keyVal.toLowerCase()) {
                        case 'omicon id':
                        case 'pubmed id':
                        case 'accession id':
                        case 'bioproject id':
                            if (val && typeof val === 'number' && val !== 'NA') {
                                return true;
                            }
                            return false;
                        default:
                            return false;
                    }
                }
            }
        }
        return false;
    },
    linkAction: (props, val = null) => {
        let linkUrl = `https://pubmed.ncbi.nlm.nih.gov/${val}`;
        window.open(linkUrl);
    },
    splitValues: (values, props = null) => {
        const spanElem = (val, i) => {
            if (val === 'NA') { return (<span key={`${val}_${i}`} className="normal">{val}</span>); }
            return (<span key={`${val}_${i}`} onClick={() => Grid.linkAction(props, val)}>{val}</span>);
        }
        if (values) {
            let valArray = [];
            if (typeof values === 'string') {
                if (values.includes('|')) {
                    valArray = values.split('|');
                } else {
                    valArray = values.split(' ');
                }
            } else if (Array.isArray(values)) {
                valArray = values.filter(val => val.trim() !== '|');
                let contentVal = valArray.map((val, i) => spanElem(val, i));
                return contentVal;
            }
            if (valArray.length > 1) {
                let contentVal = valArray.map((val, i) => spanElem(val, i));
                return contentVal;
            }
        }
        return values;
    },
    pubMedIdListCheck: (val, key) => {
        if (val && (typeof val === 'string' || Array.isArray(val))) {
            if (key && typeof key === 'string') {
                switch (key.toLowerCase()) {
                    case 'pubmed id':
                    case 'omicon id':
                    case 'accession id':
                    case 'bioproject id':
                        if (val && typeof val === 'string' && val !== 'NA') {
                            if (val.split(' ').length > 1) { return true; }
                            return false;
                        } else if (Array.isArray(val)) {
                            if (val.length > 1) { return true; }
                            return false;
                        }
                        return false;
                    default:
                        return false;
                }
            }
        }
        return false;
    },
    getBtnText: (gridType) => {
        switch (gridType) {
            case 'sampleAttributes':
                return 'Add New Sample Attribute';
            case 'geneSet':
                return 'Add New Gene Set';
            default:
                break;
        }
    },
    getGridTypeAccess: (gridType) => {
        switch (gridType) {
            case 'sampleAttributes':
                return 'SYSTEM_TABLES_SAMPLE_ATTRIBUTES_CREATE';
            case 'geneSet':
                return 'RESOURCE_GENESET_ATTRIBUTES_MODIFY';
            default:
                break;
        }
    },
    sortTime: (tableData = [], order, key) => {
        // VARS -------------------------------------------
        let timeAscOrder = ['second', 'minute', 'hour'],
            timeDescOrder = [...timeAscOrder].reverse(),
            newDataArray = [], secArray = [], minArray = [], hourArray = [];
        // METHOD DEC -------------------------------------------
        let timeInNum = (val) => val.split(' '),
            numConv = (item, pos = 0) => Number(timeInNum(item[key])[pos]),
            ascCall = (a, b) => (numConv(a) - numConv(b)),
            descCall = (a, b) => (numConv(b) - numConv(a));
        // MINUTE-DATA-SORT ---------------------------------------------------
        const sortMinuteData = () => {
            if (order && order === 'asc' && minArray.length) {
                minArray.sort((a, b) => {
                    if (timeInNum(a[key])[2] && timeInNum(b[key])[2]) {
                        return (numConv(a, 2) - numConv(b, 2));
                    }
                    return (numConv(a) - numConv(b));
                });
            } else if (order && order === 'desc' && minArray.length) {
                minArray.sort((a, b) => {
                    if (timeInNum(a[key])[2] && timeInNum(b[key])[2]) {
                        return (numConv(b, 2) - numConv(a, 2));
                    }
                    return (numConv(b) - numConv(a));
                });
            }
        }
        // ---------------------------------------------------------------------
        // SORTING ALGORITH FOR EXECUTION TIME -------------------------------
        const sortArrays = () => {
            (order === 'asc') ? secArray.sort(ascCall) : secArray.sort(descCall);
            (order === 'asc') ? minArray.sort(ascCall) : minArray.sort(descCall);
            (order === 'asc') ? hourArray.sort(ascCall) : hourArray.sort(descCall);
        };
        const sortData = (time) => {
            tableData.forEach(data => {
                if (timeInNum(data[key])[1] && timeInNum(data[key])[1].includes(time)) {
                    if (time === 'second') { secArray.push(data); }
                    else if (time === 'minute') { minArray.push(data); }
                    else if (time === 'hour') { hourArray.push(data); }
                }
            });
        }
        // --------------------------------------------------------------------
        if (order && order === 'asc') {
            timeAscOrder.forEach(time => sortData(time));
            sortMinuteData();
            sortArrays();
            newDataArray = [...secArray, ...minArray, ...hourArray];
        } else if (order && order === 'desc') {
            timeDescOrder.forEach(time => sortData(time));
            sortMinuteData();
            sortArrays();
            newDataArray = [...hourArray, ...minArray, ...secArray];
        }
        // ADDING NA & OTHER VALUES TO THE NEW DATA ARRAY ----------------------
        tableData.forEach(data => {
            if (data[key] === 'NA' || !(timeInNum(data[key])[1])) {
                newDataArray.push(data);
            }
        });
        // ---------------------------------------------------------------------
        return newDataArray;
    },
    sortStringVal: (a, b, key, order) => {
        if ((a[key] && typeof a[key] === 'string') && (b[key] && typeof b[key] === 'string')) {
            if (!isNaN(Number(a[key])) && !isNaN(Number(b[key]))) {
                a[key] = Number(a[key]); b[key] = Number(b[key]);
                if (order && order === 'asc') {
                    return (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
                } else {
                    return (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0);
                }
            } else {
                if (order && order === 'asc') {
                    return (a[key].toLowerCase() > b[key].toLowerCase()) ? 1 : ((b[key].toLowerCase() > a[key].toLowerCase()) ? -1 : 0);
                } else {
                    return (a[key].toLowerCase() < b[key].toLowerCase()) ? 1 : ((b[key].toLowerCase() < a[key].toLowerCase()) ? -1 : 0);
                }
            }
        } else {
            if (Array.isArray(a[key]) && Array.isArray(b[key])) {
                let isNumber = (arr = []) => {
                    arr.forEach(val => { if (isNaN(Number(val))) return false; });
                    return true;
                };
                let sortNumArr = (arr = []) => {
                    if (order && order === 'asc' && isNumber(arr)) return arr.sort((x, y) => (Number(x) - Number(y)));
                    else if (order && order === 'desc' && isNumber(arr)) return arr.sort((x, y) => (Number(y) - Number(x)));
                    return arr;
                };
                a[key] = sortNumArr(a[key]);
                b[key] = sortNumArr(b[key]);
            }
            if (order && order === 'asc') {
                return (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
            } else {
                return (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0);
            }
        }
    },
    extractURL: (url, fileType) => {
        if (url.includes('.txt') || url.includes('.pdf') || url.includes('.csv')) {
            const type = url.includes('.csv') ? '.csv' : fileType;
            const urlArray = url.split(type);
            const baseUrl = urlArray[0];
            const encodedURL = encodeURIComponent(urlArray[1]);
            return `${baseUrl}${type}${encodedURL}`;
        } else if (url.toLowerCase().includes('.r')) {
            const type = url.includes('.R') ? '.R' : '.r';
            const urlArray = url.split(type);
            const baseUrl = urlArray[0];
            const encodedURL = encodeURIComponent(urlArray[1]);
            return `${baseUrl}${type}${encodedURL}`;
        }
    },
    processColumnVersion: (version) => {
        let versionString = '';
        const versionArray = version.split('-') || [];
        versionArray.forEach(val => {
            versionString += val;
        });
        return Number(versionString.trim());
    },
    fileTypeIdentifier: (fileName) => {
        if (fileName) {
            if (fileName.includes('.txt')) {
                return 'txt';
            } else if (fileName.includes('.csv') || fileName.includes('.xls') || fileName.includes('.xlsx')) {
                return 'xls';
            } else if (fileName.includes('.pdf')) {
                return 'pdf';
            } else if (fileName.toLowerCase().includes('.r')) {
                return 'r-code';
            } else {
                return 'folder';
            }
        } else {
            return null;
        }
    },
    sortVersion: (tableData, headerData, order, sortItem = 'versionVal') => {
        tableData.map((row) => {
            row['enableHistory'] = false; // COMMENT TO MAINTAIN EXPANDED PROPERTY
            if (headerData.sortSubKey) {
                order === 'asc' ?
                    row[headerData.sortSubKey].sort((a, b) => (a[sortItem] - b[sortItem])) :
                    row[headerData.sortSubKey].sort((a, b) => (b[sortItem] - a[sortItem]));
                row['versionAtFirstPos'] = row[headerData.sortSubKey][0][sortItem];
                if (row[headerData.sortSubKey] && row[headerData.sortSubKey].length > 0) {
                    row[headerData.sortSubKey].map((history, i) => {
                        // UNCOMMENT TO MAINTAIN EXPANDED PROPERTY ------------------------------------
                        // if (row['enableHistory']) { history['showHistory'] = row['enableHistory']; }
                        // else { history['showHistory'] = i === 0 ? true : false; }
                        // ----------------------------------------------------------------------------
                        history['showHistory'] = i === 0 ? true : false; // COMMENT TO MAINTAIN EXPANDED PROPERTY
                        if (sortItem === 'versionVal') {
                            history[sortItem] = Grid.processColumnVersion(history['version']);
                        }
                        return history;
                    });
                }
            }
            return row;
        });
        order === 'asc' ?
            tableData.sort((a, b) => (a['versionAtFirstPos'] - b['versionAtFirstPos'])) :
            tableData.sort((a, b) => (b['versionAtFirstPos'] - a['versionAtFirstPos']));
        return tableData;
    },
    filterSpecialChars: (dataVal, key) => {
        let valArray = [];
        if (Array.isArray(dataVal)) {
            valArray = dataVal.filter(val => (typeof val === 'string' && val.trim() !== '|'));
            if (['Accession ID', 'BioProject ID', 'PubMed ID'].indexOf(key) < 0) {
                let val = '';
                valArray.forEach((value, i) => {
                    val += value + ((dataVal.length > 1 && i < dataVal.length - 1) ? `, ` : '');
                });
                return val;
            }
            return valArray;
        }
        return dataVal;
    },
    createGridDataKey: (
        gridType,
        folderList,
        keyListToDelete = [],
        responseKeys
    ) => {
        let tableData = [], gridKeyDataList = [];
        if (gridType === 'advancedResults' && folderList && folderList.length) {
            folderList.forEach((row) => {
                if (row) {
                    keyListToDelete.forEach(key => delete row[key]);
                    tableData.push({ ...row });
                }
            });
        }
        if (responseKeys && responseKeys.length) {
            responseKeys.forEach(key => {
                if ((keyListToDelete && !keyListToDelete.length) || keyListToDelete.indexOf(key) < 0) {
                    gridKeyDataList.push({ key });
                }
            });
        } else {
            if (tableData && tableData.length && tableData[0]) {
                Object.keys(tableData[0]).forEach(key => gridKeyDataList.push({ key: key }));
            }
        }
        return gridKeyDataList;
    }
};

export default Grid;