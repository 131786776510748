export const dropdown = {
    defaultOperators: [
        { key: 'Equals', value: 'equal' },
        { key: 'Not Equals', value: 'notequal' }
        // { key: 'In', value: 'in' },
        // { key: 'Not In', value: 'notin' }
    ],
    multicheckOperators: [
        { key: 'Equals', value: 'equal' },
        { key: 'Includes', value: 'in' },
        { key: 'Excludes', value: 'notin' }
    ],
    numericOperators: [
        { key: 'Equals', value: 'equal' },
        { key: 'Greater Than', value: 'greaterthan' },
        { key: 'Less Than', value: 'lessthan' }
    ],
    dateOperators: [
        { key: 'Equals', value: 'equal' },
        { key: 'Greater Than', value: 'greaterthan' },
        { key: 'Greater Than or Equals', value: 'greaterthanorequal' },
        { key: 'Less Than', value: 'lessthan' },
        { key: 'Less Than or Equals', value: 'lessthanorequal' }
    ],
    stringOperators: [
        { key: 'Equals', value: 'equal' },
        { key: 'Contains', value: 'contains' }
    ],
    operators_EQ_IN: [
        { key: 'Includes', value: 'in' }
    ],
    operators_EQ_IN_EX: [
        { key: 'Includes', value: 'in' },
        { key: 'Excludes', value: 'notin' }
    ],
    OPERATOR_DATA: {
        'equals': { key: 'Equals', value: 'equal' },
        'not equals': { key: 'Does not equal', value: 'notequal' },
        'not equal': { key: 'Does not equal', value: 'notequal' },
        'includes': { key: 'Includes', value: 'in' },
        'excludes': { key: 'Excludes', value: 'notin' },
        'greater than': { key: 'Greater Than', value: 'greaterthan' },
        'greater than orequal to': { key: 'Greater Than or Equals', value: 'greaterthanorequal' },
        'greater than or equal to': { key: 'Greater Than or Equals', value: 'greaterthanorequal' },
        'greater than or equals': { key: 'Greater Than or Equals', value: 'greaterthanorequal' },
        'less than': { key: 'Less Than', value: 'lessthan' },
        'less than orequal to': { key: 'Less Than or Equals', value: 'lessthanorequal' },
        'less than or equal to': { key: 'Less Than or Equals', value: 'lessthanorequal' },
        'less than or equals': { key: 'Less Than or Equals', value: 'lessthanorequal' },
        'contains': { key: 'Contains', value: 'contains' },
        generateOperator: (val) => ({ 'key': val, 'value': val.toLowerCase() })
    },
    convertToQueryFields: (e, op) => {
        return {
            [e.split(' ').join('_')]: '',
            field: e.split(' ').join('_'),
            key: e,
            title: e,
            operators: op,
            label: e,
            type: 'string',
            isAutoComplete: true
        }
    }
};