import { userActionTypes } from "./user.types";

export const setCurrentUser = userData => ({
    type: userActionTypes.SET_CURRENT_USER,
    payload: userData
});

export const setRouteData = routeData => ({
    type: userActionTypes.SET_CURRENT_ROUTE,
    payload: routeData
});

export const setAwsCredentials = data => ({
    type: userActionTypes.SET_AWS_CREDENTIALS,
    payload: data
});

export const setDownloadNotifs = (data, type = null) => ({
    type: userActionTypes.SET_DOWNLOAD_NOTIFS,
    payload: { data, type }
});

export const setUserGlobalSearchData = (data) => ({
    type: userActionTypes.SET_GLOBAL_SEARCH_DATA,
    payload: data
});

export const setExactMatchGlobalSearchData = (data) => ({
    type: userActionTypes.SET_EXACT_MATCH_GLOBAL_SEARCH_DATA,
    payload: data
});