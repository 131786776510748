import { userActionTypes } from "./user.types";
import { setDownloadNotifList } from "./user.utils";

const INITIAL_STATE = {
    currentUser: null,
    loginError: false,
    isLoading: false,
    forgotPassword: false,
    passwordSent: false,
    routeData: null,
    awsCredentials: null,
    downloadNotifs: [],
    globalSearchData: null,
    exactMatchGlobalSearchData: null
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            };
        case userActionTypes.SET_CURRENT_ROUTE:
            return {
                ...state,
                routeData: action.payload
            };
        case userActionTypes.SET_AWS_CREDENTIALS:
            return {
                ...state,
                awsCredentials: action.payload
            };
        case userActionTypes.SET_DOWNLOAD_NOTIFS:
            return {
                ...state,
                downloadNotifs: setDownloadNotifList(state.downloadNotifs, action.payload)
            };
        case userActionTypes.SET_GLOBAL_SEARCH_DATA:
            return {
                ...state,
                globalSearchData: action.payload
            };
        case userActionTypes.SET_EXACT_MATCH_GLOBAL_SEARCH_DATA:
            return {
                ...state,
                exactMatchGlobalSearchData: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;