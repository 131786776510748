const SECURITY_KEY = '__context__';
const REFRESH_KEY = '__refresh__';
const EXPIRES_AT = { key: 'expires_at', value: 600 };
const APP_ID = 'appId';
const LOGOUT_ID = '__logout__';

const SecurityService = {
    setLogoutId: (logoutId) => {
        window.localStorage.setItem(LOGOUT_ID, logoutId);
    },
    getLogoutId: () => {
        let logoutId = window.localStorage.getItem(LOGOUT_ID);
        return logoutId;
    },
    removeLogoutId: () => {
        window.localStorage.removeItem(LOGOUT_ID);
    },
    setAppId: (appID) => {
        window.localStorage.setItem(APP_ID, appID);
    },
    // get application id
    getAppId: () => {
        return window.localStorage.getItem(APP_ID);
    },
    // get security token from local storage
    getSecurityToken: () => {
        return window.localStorage.getItem(SECURITY_KEY);
    },
    // set security token to local storaage
    setSecurityToken: (token) => {
        window.localStorage.setItem(SECURITY_KEY, token);
    },
    // get refresh token from local storage
    getRefreshToken: () => {
        return window.localStorage.getItem(REFRESH_KEY);
    },
    // set refresh token to local storaage
    setRefreshToken: (token) => {
        window.localStorage.setItem(REFRESH_KEY, token);
    },
    // remove security token from local storage
    removeSecurityToken: () => {
        window.localStorage.removeItem(SECURITY_KEY);
        window.localStorage.removeItem(EXPIRES_AT.key);
        window.localStorage.removeItem('user');
        window.sessionStorage.clear();
        window.localStorage.clear();
        localStorage.clear();
    },
    // remove all data from local storage
    logout: () => {
        window.localStorage.clear();
    },
    // save username local storage
    remeberMe: (username) => {
        window.localStorage.setItem('userName', username);
    },
    // get username from local storage
    didRememberMe: () => {
        return window.localStorage.getItem('userName');
    },
    // remove username from local storage
    forget: () => {
        window.localStorage.removeItem('userName');
    }
}

export default SecurityService;