/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Aux from '../../../auxiliary/auxiliary';
import subContentSearchFieldForm from './forms/sub-content.forms';
import FormInput from '../../../../components/form-input/form-input.component';

import './content-list.styles.scss';
// import collectionList from './data/collection-list.data';
import ListElement from '../../../list-element/list-element.component';
import SideMenuItem from '../../../side-menu-item/side-menu-item.component';
import contentListDataObject from './data/content-list.data';
import { ucsfInstance } from '../../../../axios/ucsf';
import { connect } from 'react-redux';
import { setLoader, setNetworkModeOn, setNetworkSearchVal } from '../../../../../redux/app/app.actions';
import { createStructuredSelector } from 'reselect';
import { getRouteTitleObject, isLoading } from '../../../../../redux/app/app.selectors';
import {
    setCollectionListDataValue,
    setCollectionSearchVal,
    setMainRouteTitles,
    setSelectedCollectionItem
} from '../../../../../redux/collections/collections.actions';
import { getCollectionListDataValue, getCollectionSearchVal, getIsDataMenuTriggered } from '../../../../../redux/collections/collections.selectors';
import InLoader from '../../../loaders/component-loader/component-loader.component';
import AppService from '../../../../services/app-service';
import { setNewlyCreatedAnalysisId, setAnalysisListDataValue, setSelectedAnalysisItem } from '../../../../../redux/analyses/analyses.actions';
import { getNewlyCreatedAnalysisId, getAnalysisListDataValue, getIsAnalysisMenuTriggered } from '../../../../../redux/analyses/analyses.selectors';
import { COMPONENT_APIS } from '../../../../utils/omi-api';
import { getCurrentUser } from '../../../../../redux/user/user.selectors';
import AuthService from '../../../../services/authentication-service';
import { setCurrentUser } from '../../../../../redux/user/user.actions';
import SecurityService from '../../../../services/security-service';

// const dataSetSpace = 16;

const ContentList = (props) => {
    let {
        loading,
        history,
        location,
        setLoader,
        setSelectedCollectionItem,
        setCollectionListDataValue,
        collectionListDataArray,
        analysisListDataArray,
        setCollectionSearchVal,
        collectionSearchVal,
        newlyCreatedAnalysisId,
        setNewlyCreatedAnalysisId,
        setAnalysisListDataValue,
        setSelectedAnalysisItem,
        setNetworkSearchVal,
        routeTitleObject,
        setMainRouteTitles,
        currentUser,
        setCurrentUser,
        setNetworkModeOn,
        isDataMenuTriggered = false,
        isAnalysisMenuTriggered = false
    } = props;
    const [scrolled, setScrolled] = useState(false);
    const [inLoader, setInLoader] = useState(false);
    const [showNetworkElements, setShowNetworkElements] = useState(false);
    const [contentData, setContentData] = useState([]);
    const [scrollObject, setScrollObject] = useState({ top: 0, left: 0 });
    const [collectionListData, setCollectionListData] = useState([...collectionListDataArray]); // ...collectionListDataArray
    const [analysisListData, setAnalysisListData] = useState([...analysisListDataArray]); // ...analysisListDataArray
    const [subContentSearchField, setSubContentSearchFieldData] = useState({ ...subContentSearchFieldForm });
    const queryParams = AppService.fetchQueryParams(location.search);
    const { contentType } = queryParams;
    let versions = (queryParams.versions) ? '&versions=true' : '';
    let prevDcId = (queryParams.prevDcId) ? `&prevDcId=${queryParams.prevDcId}` : '';
    let contentDataList = [];

    // DEFINITIONS : HOOKS
    useEffect(() => {
        SecurityService.getSecurityToken() && listContentGenertion();
    }, []);

    useEffect(() => {
        setShowNetworkElements(false);
        if (collectionListData) { resetPopOver(collectionListData, 'collections'); }
        if (analysisListData) { resetPopOver(analysisListData, 'analyses'); }
        if (history?.location?.pathname.includes('network')) {
            setShowNetworkElements(true);
            setNetworkSearchVal('');
        }
        menuRenderBasedOnPermissions();
    }, [history?.location?.pathname]);

    useEffect(() => {
        if (newlyCreatedAnalysisId) {
            setLoader(true);
            setTimeout(() => {
                ucsfInstance.get(COMPONENT_APIS.RECENT_ANALYSES).then(analyses => {
                    processAnalysisList(analyses.data, newlyCreatedAnalysisId);
                }).catch((error) => error);
            }, 1000);
        }
    }, [newlyCreatedAnalysisId]);

    useEffect(() => {
        const pathname = history?.location?.pathname;
        if (contentType === 'collections' && collectionListData) {
            analysisListData?.map(content => content['focused'] = false);
            if (pathname.includes('data-listing') || pathname.includes('collections')) {
                collectionListData.map(content => {
                    if (content['id']) {
                        const dcId = queryParams.prevDcId ? queryParams.prevDcId : queryParams.dcId;
                        content['focused'] = (content['id'] === dcId) ? true : false;
                        return content;
                    } else {
                        content['focused'] = (content['title'] === routeTitleObject.dcTitle) ? true : false;
                        return content;
                    }
                });
            }
        } else if (contentType === 'analyses' && analysisListData) {
            collectionListData?.map(content => content['focused'] = false);
            if (pathname.includes('data-listing') || pathname.includes('analyses')) {
                analysisListData.map(content => {
                    if (content['id']) {
                        const analysisId = queryParams.prevAnalysisId ? queryParams.prevAnalysisId : queryParams.analysisId;
                        content['focused'] = (content['id'] === analysisId) ? true : false;
                        return content;
                    } else {
                        content['focused'] = (content['title'] === routeTitleObject.analysisTitle) ? true : false;
                        return content;
                    }
                });
            }
        } else {
            analysisListData?.map(content => content['focused'] = false);
            collectionListData?.map(content => content['focused'] = false);
        }
    }, [
        collectionListData,
        analysisListData,
        location.search,
        history?.location?.pathname
    ]);

    useEffect(() => {
        if (routeTitleObject?.dcUpdated) {
            ucsfInstance.get(COMPONENT_APIS.RECENT_COLLECTIONS).then(collections => {
                processCollectionList(collections.data);
            });
        } else if (routeTitleObject?.analysisUpdated) {
            ucsfInstance.get(COMPONENT_APIS.RECENT_ANALYSES).then(analyses => {
                processAnalysisList(analyses.data);
            });
        }
    }, [routeTitleObject]);

    useEffect(() => {
        if (isDataMenuTriggered || isAnalysisMenuTriggered) {
            let contentList = contentDataList.length ? [...contentDataList] : [...contentData];
            let content = null;
            if (history?.location?.pathname.includes('network') && contentList && contentList.length) {
                if (history?.location?.pathname.includes('analyses')) {
                    content = contentList.find(item => item.type === 'analyses');
                    content.selected = false;
                    handleContentSelection(content);
                } else if (
                    history?.location?.pathname.includes('collections') ||
                    history?.location?.pathname.includes('data-sets')
                ) {
                    content = contentList.find(item => item.type === 'collections');
                    content.selected = false;
                    handleContentSelection(content);
                }
            }
        }
    }, [
        isDataMenuTriggered,
        isAnalysisMenuTriggered
    ]);

    const menuRenderBasedOnPermissions = () => {
        const activeRoute = fetchRoute();
        const contentObject = JSON.parse(JSON.stringify(contentListDataObject));
        const contentListArray = [...contentObject[activeRoute]];
        if (contentListArray && contentListArray.length) {
            setNetworkModeOn();
            contentObject[activeRoute] = AppService.returnSideMenuListWithPermissions(contentListArray, currentUser);
            contentDataList = contentObject[activeRoute];
            setContentData(contentObject[activeRoute]);
            if (!contentObject[activeRoute].length) { setNetworkModeOn(true); }
        }
    }

    const listContentGenertion = async () => {
        await ucsfInstance.get(COMPONENT_APIS.RECENT_COLLECTIONS).then(collections => {
            processCollectionList(collections.data);
        }).catch((error) => {
            processCollectionList([]);
        });
        await ucsfInstance.get(COMPONENT_APIS.RECENT_ANALYSES).then(analyses => {
            processAnalysisList(analyses.data);
        }).catch((error) => {
            processAnalysisList([]);
        });
    };

    const processCollectionList = (collectionList) => {
        const collectionListArray = [...collectionList];
        collectionListArray.map(collection => {
            collection['selected'] = false;
            if (collection['data_sets'] && collection['data_sets'].length > 0) {
                delete collection['data_sets'];
            }
            return collection;
        });
        setCollectionListData(collectionListArray);
        setCollectionListDataValue(collectionListArray);
    };

    const processAnalysisList = (analysisList, newlyCreatedAnalysisId = null) => {
        const analysisListArray = [...analysisList];
        let selectedAnalysis = {};
        analysisListArray.map(analysis => {
            analysis['selected'] = false;
            if (analysis['data_sets'] && analysis['data_sets'].length > 0) {
                delete analysis['data_sets'];
            }
            if (newlyCreatedAnalysisId) {
                if (analysis['id'] === newlyCreatedAnalysisId) {
                    analysis['selected'] = true;
                    selectedAnalysis['analysisData'] = analysis;
                }
            }
            return analysis;
        });
        setNewlyCreatedAnalysisId(null);
        setAnalysisListData(analysisListArray);
        setAnalysisListDataValue(analysisListArray);
        if (selectedAnalysis && selectedAnalysis['analysisData']) {
            listElementClick(selectedAnalysis, null, { type: 'analyses' }, analysisListArray);
            handleContentSelection({ type: 'analyses' });
        }
    };

    const resetPopOver = (dataList, type = null) => {
        const contentListArray = [...dataList];
        contentListArray.map(content => {
            content['moreSelected'] = false;
            return content;
        });
        if (type === 'analyses') {
            setAnalysisListData(contentListArray);
        } else {
            setCollectionListData(contentListArray);
        }
    };

    const fetchRoute = () => {
        const { location: { pathname } } = props;
        const routes = pathname ? pathname.split('/') : [];
        let currentRoute = routes[1]; // routes[1];
        if (routes.includes('admin')) {
            currentRoute = 'admin'; // routes[3];
        }
        if (routes.includes('notifications') || routes.includes('downloads') || routes.includes('comment-notifications')) {
            if(AuthService._getStorageData('user', true).role === 'GU') {
                currentRoute = 'restricted-notification-logs';
            } else {
                currentRoute = 'notification-logs';
            }
        }
        return currentRoute?.trim();
    };

    const debouncedSearch = useCallback(
        AppService.debounce((params) => {
            ucsfInstance.get(`${params.api}?search=${params.text}`).then(response => {
                if (params.type === 'collectionsText') {
                    processCollectionList(response.data);
                } else if (params.type === 'analysesText') {
                    processAnalysisList(response.data);
                }
                setInLoader(false);
            }).catch((error) => {
                console.log('ERROR -> ', error);
                setInLoader(false);
            });
        }, 500), []
    );

    const inputChangedHandler = (event, identifier,) => {
        setInLoader(true);
        let api = '';
        const updatedSubContentSearchFormData = { ...subContentSearchField };
        const updatedForm = { ...updatedSubContentSearchFormData[identifier] };
        updatedForm.value = event ? event.target.value : '';
        updatedSubContentSearchFormData[identifier] = updatedForm;
        setSubContentSearchFieldData(updatedSubContentSearchFormData);
        switch (identifier) {
            case 'collectionsText':
                api = event.target.value.trim() ? COMPONENT_APIS.DATA_COLLECTIONS : COMPONENT_APIS.DATA_COLLECTIONS; // '/data-collections', '/data-sets-groups', 'data-sets';
                setCollectionSearchVal(event.target.value);
                break;
            case 'analysesText':
                api = event.target.value.trim() ? COMPONENT_APIS.SEARCH_ANALYSES : COMPONENT_APIS.SEARCH_ANALYSES;
                setCollectionSearchVal(event.target.value);
                break;
            case 'networkSearchText': setInLoader(false);
                break;
            default:
                break;
        }
        if (api !== '') {
            debouncedSearch({ text: event.target.value, api: api, type: identifier });
        }
    };

    const handleContentSelection = (content = null) => {
        if (content) {
            if (content.type === 'tables') {
                history.push('/home/admin');
            } else if (content.type === 'resources') {
                history.push('/home/resources');
            } else if (content.type === 'user-management') {
                history.push('/home/admin/user-management');
            } else if (content.type === 'downloadNotifications') {
                history.push('/home/downloads');
            } 
            else if (content.type === 'searchNotifications') {
                history.push('/home/notifications');
            } else if (content.type === 'commentNotifications') {
                history.push('/home/comment-notifications');
            } 
            else {
                const contentList = [...contentData];
                let updatedContentList = contentList.map(item => {
                    item.selected = (content.type === item.type) ? !item.selected : false;
                    return item;
                });
                setContentData(updatedContentList);
            }
        }
    };

    const resetMenuElements = ({ type }) => {
        switch (type) {
            case 'collections':
                const analysisList = resetList([...analysisListData]);
                setAnalysisListData(analysisList);
                setAnalysisListDataValue(analysisList);
                break;
            case 'analyses':
                const collectionList = resetList([...collectionListData]);
                setCollectionListData(collectionList);
                setCollectionListDataValue(collectionList);
                break;
            default:
                break;
        }
    };

    const resetList = (listData) => {
        listData.map(item => {
            item['selected'] = false;
            item['focused'] = false;
            item['moreSelected'] = false;
            return item;
        });
        return listData;
    };

    const listElementClick = (
        item,
        event = null,
        elementContent = null,
        listDataArray = []
    ) => {
        resetMenuElements(elementContent);
        let updatedContentList = [];
        let baseUrl = '';
        let mainRoute = '';
        let dataSetRoute = 'dataset';
        let listData = [...collectionListData];
        let dataType = 'dataCollection';
        if (elementContent.type === 'analyses') {
            listData = [...analysisListData];
            if (listDataArray.length) { listData = [...listDataArray]; }
            dataType = 'analysisData';
        }
        updatedContentList = [...listData];
        if (!item['isDataSet']) {
            updatedContentList.map(content => {
                content['moreSelected'] = false;
                if (item[dataType]['id']) {
                    content['focused'] = (content['id'] === item[dataType]['id']) ? true : false;
                    content.selected = (
                        (content['id'] === item[dataType]['id']) &&
                        item[dataType]['dataSets'] && item[dataType]['dataSets'].length
                    ) ? !content.selected : false;
                } else {
                    content['focused'] = (content['title'] === item[dataType]['title']) ? true : false;
                    content.selected = (
                        (content['title'] === item[dataType]['title']) &&
                        item[dataType]['dataSets'] && item[dataType]['dataSets'].length
                    ) ? !content.selected : false;
                }
                if (content.selected && content?.data_sets && content?.data_sets?.length) {
                    content?.dataSets.map(dataSet => dataSet['focused'] = false);
                }
                return content;
            });
        } else if (item['isDataSet']) {
            updatedContentList.map(content => {
                content['focused'] = false;
                if (content.selected && content?.data_sets && content?.data_sets?.length) {
                    content?.dataSets.map(dataSet => {
                        dataSet['focused'] = (dataSet.id === item?.dataSet?.id) ? true : false;
                        return dataSet;
                    });
                }
                return content;
            });
        }
        if (elementContent.type === 'collections' && updatedContentList.length) {
            setCollectionListData(updatedContentList);
            setCollectionListDataValue(updatedContentList);
        } else if (elementContent.type === 'analyses' && updatedContentList.length) {
            setAnalysisListData(updatedContentList);
            setAnalysisListDataValue(updatedContentList);
        }
        if (event !== 'arrowClick') {
            if (elementContent.type === 'collections') {
                item['isDataSet'] ? setSelectedCollectionItem(item?.dataSet) : setSelectedCollectionItem(item[dataType]);
            } else if (elementContent.type === 'analyses') {
                item['isDataSet'] ? setSelectedAnalysisItem(item?.dataSet) : setSelectedAnalysisItem(item[dataType]);
            }
            mainRoute = item[dataType]['title'];
            dataSetRoute = (item?.dataSet?.title) ? item.dataSet.title : dataSetRoute;
            baseUrl = `/home/${elementContent.type}/${mainRoute}/${dataSetRoute}`;
            if (elementContent.type === 'collections' && AppService.hasAccess('dataset-list', '_FROM_DC')) {
                history.push({
                    pathname: '/home/data-listing',
                    search: `dcId=${item[dataType]['id']}&contentType=${elementContent.type}&dcTitle=${item[dataType]['title']}`
                }); // search: `dcTitle=${item[dataType]['title']}&dcId=${item[dataType]['id']}`
            } else if (elementContent.type === 'analyses') {
                let searchUrl = `analysisId=${item[dataType]['id']}`;
                let searchParams = `${searchUrl}&mainAnalysisId=${item[dataType]['id']}`;
                baseUrl = `/home/analyses/analysis-data`;
                setSelectedAnalysisItem(item[dataType]);
                setMainRouteTitles({ searchParam: searchParams });
                history.push({
                    pathname: `${baseUrl}/attributes`,
                    search: `${searchParams}${versions}${prevDcId}&contentType=${elementContent.type}`
                }); /* history.push({ pathname: '/home/data-listing', search: `analysisId=${item[dataType]['id']}&contentType=${elementContent.type}` }); */
            }
            if (!AppService.hasAccess('dataset-list', '_FROM_DC') && elementContent.type === 'collections') {
                updatedContentList.map(content => {
                    content.focused = false;
                    content.selected = false;
                    return content;
                });
            }
        }
    };

    const handleMoreItems = (event, item) => {
        let dataList = [];
        if (event.type === 'collections') {
            dataList = [...collectionListData];
        } else if (event.type === 'analyses') {
            dataList = [...analysisListData];
        }
        if (!item['isDataSet']) {
            dataList.map(content => {
                content['moreSelected'] = false;
                if (content.id === item.id) {
                    content['moreSelected'] = !item['moreSelected'];
                }
                return content;
            });
        }
        if (event.type === 'collections' && dataList.length) {
            setCollectionListData(dataList);
        } else if (event.type === 'analyses' && dataList.length) {
            setAnalysisListData(dataList);
        }
    };

    const listDataElementContent = (listData, contentData = null, elementContent = null) => {
        return listData.map((content, index) => {
            return (
                <Fragment key={content.id ? content.id : index}>
                    <ListElement
                        key={content.id ? content.id : index}
                        {...content}
                        draggable={elementContent?.isDraggable}
                        listType="folderOptions"
                        elementType={elementContent.type}
                        tabIndex={index}
                        scrolled={scrolled}
                        scrollObject={scrollObject}
                        itemClicked={() => setScrolled(false)}
                        moreItemsClick={(event, props) => handleMoreItems(elementContent, props)}
                        arrowClick={(event, props, targetEvent) => {
                            const itemData = { isDataSet: false, dataSet: props };
                            switch (elementContent.type) {
                                case 'collections':
                                    itemData['dataCollection'] = contentData ? contentData : content;
                                    break;
                                case 'analyses':
                                    itemData['analysisData'] = contentData ? contentData : content;
                                    break;
                                default:
                                    break;
                            }
                            if (event === 'isDataSet') { itemData['isDataSet'] = true; }
                            return listElementClick(itemData, targetEvent, elementContent);
                        }} /> {/* style={{ paddingLeft: `${dataSetSpace}px` }}*/}
                    {
                        (content.dataSets && content.selected) ?
                            <div> {listDataElementContent(content.dataSets, { ...content }, elementContent)} </div> :
                            null
                    }
                </Fragment>
            );
        });
    };

    const handleNewItemFunction = (content) => {
        if (content.type === 'collections') {
            history.push('/home/add-new-collection');
        } else if (content.type === 'analyses') {
            history.push('/home/add-new-analysis');
        }
    };

    const handleScroll = (val) => {
        setScrolled(val);
        const folderContent = document.querySelector('.folder-content');
        setScrollObject({ top: folderContent.scrollTop, left: folderContent.scrollLeft });
    };

    return (
        <div className="list-container">
            {
                contentData?.map(content => {
                    return (
                        <Fragment key={content.type}>
                            <SideMenuItem
                                id={content.type}
                                key={content.type}
                                content={content}
                                click={() => handleContentSelection(content)}
                                folderClick={() => handleNewItemFunction(content)} />
                            {
                                (content.type === 'collections' && content.selected) ?
                                    <div className={`sub-content ${content['selected'] ? 'scale-in-ver-top' : 'scale-out-ver-top'}`}>
                                        {
                                            AppService.hasAccess('collections', '_SEARCH') ?
                                                <div className="search-sub-content">
                                                    <FormInput
                                                        elementType={subContentSearchField.collectionsText.elementType}
                                                        elementConfig={subContentSearchField.collectionsText.elementConfig}
                                                        value={subContentSearchField.collectionsText.value}
                                                        changed={(event) => inputChangedHandler(event, 'collectionsText')} />
                                                </div> :
                                                null
                                        }
                                        <div className="folder-content" onScroll={() => handleScroll(true)}>
                                            {inLoader ? <InLoader /> : listDataElementContent(collectionListData, null, content)}
                                        </div>
                                    </div> : null
                            }
                            {
                                (content.type === 'analyses' && content.selected) ?
                                    <div className={`sub-content ${content['selected'] ? 'scale-in-ver-top' : 'scale-out-ver-top'}`}>
                                        {
                                            AppService.hasAccess('analyses', '_SEARCH') ?
                                                <div className="search-sub-content">
                                                    <FormInput
                                                        elementType={subContentSearchField.analysesText.elementType}
                                                        elementConfig={subContentSearchField.analysesText.elementConfig}
                                                        value={subContentSearchField.analysesText.value}
                                                        changed={(event) => inputChangedHandler(event, 'analysesText')} />
                                                </div> :
                                                null
                                        }
                                        <div className="folder-content" onScroll={() => handleScroll(true)}>
                                            {inLoader ? <InLoader /> : listDataElementContent(analysisListData, null, content)}
                                        </div>
                                    </div> : null
                            }
                        </Fragment>
                    );
                })
            }
            {
                showNetworkElements ?
                    <div className="sub-content" style={{
                        marginTop: '15px'
                    }}>
                        <div className="search-sub-content">
                            <FormInput
                                showResetIcon={true}
                                elementType={subContentSearchField.networkSearchText.elementType}
                                elementConfig={subContentSearchField.networkSearchText.elementConfig}
                                value={subContentSearchField.networkSearchText.value}
                                resetClick={() => {
                                    inputChangedHandler(null, 'networkSearchText');
                                    setNetworkSearchVal('reset');
                                }}
                                searchClick={() => setNetworkSearchVal(subContentSearchField.networkSearchText.value)}
                                changed={(event) => inputChangedHandler(event, 'networkSearchText')} />
                        </div>
                    </div> : null
            }
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    loading: isLoading,
    collectionListDataArray: getCollectionListDataValue,
    analysisListDataArray: getAnalysisListDataValue,
    collectionSearchVal: getCollectionSearchVal,
    newlyCreatedAnalysisId: getNewlyCreatedAnalysisId,
    routeTitleObject: getRouteTitleObject,
    currentUser: getCurrentUser,
    isDataMenuTriggered: getIsDataMenuTriggered,
    isAnalysisMenuTriggered: getIsAnalysisMenuTriggered
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val) => dispatch(setLoader(val)),
    setSelectedCollectionItem: (item) => dispatch(setSelectedCollectionItem(item)),
    setSelectedAnalysisItem: (item) => dispatch(setSelectedAnalysisItem(item)),
    setCollectionSearchVal: (item) => dispatch(setCollectionSearchVal(item)),
    setCollectionListDataValue: (collectionListData) => dispatch(setCollectionListDataValue(collectionListData)),
    setAnalysisListDataValue: (analysisListData) => dispatch(setAnalysisListDataValue(analysisListData)),
    setNewlyCreatedAnalysisId: (val) => dispatch(setNewlyCreatedAnalysisId(val)),
    setNetworkSearchVal: (val) => dispatch(setNetworkSearchVal(val)),
    setMainRouteTitles: (val) => dispatch(setMainRouteTitles(val)),
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    setNetworkModeOn: (val = false) => dispatch(setNetworkModeOn(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ContentList));