export const SAMPLE_ORDER_DATA = [
    {
        hasType: false,
        ORDER_LIST: [
            'Organism',
            'NCBITAXON ID',
            'Individual',
            'Tissue',
            'UBERON ID',
            'Subsample_1',
            'Subsample_2',
            'Sample Type',
            'Sampling Strategy',
            'Laterality',
            'Sex',
            'Age',
            'Age Unit',
            'Developmental Epoch',
            'Sample Preservation',
            'Tissue Bank',
            'Tissue Bank Accession ID',
            'Cell Line',
            'Cellosaurus ID',
            'Disease',
            'MONDO ID',
            'Age Dx',
            'Age Dx Unit',
            'Developmental Epoch Dx',
            'Tumor Occurrence',
            'Tumor Recurrence Number',
            'Tumor Grade',
            'Tumor Subregion',
            'Tumor Purity',
            'Mitotic Index',
            'Necrosis Status',
            'Percent Necrosis',
            'Vascular Proliferation',
            'CIMP Status',
            'MGMT Status'
        ]
    },
    {
        hasType: true,
        ORDER_LIST: [
            'Mut',
            'Mut Source',
            'Pheno',
        ]
    },
    {
        hasType: false,
        ORDER_LIST: [
            'KPSI',
            'ECOG',
            'Performance Status Scale Timing',
            'Smoker Status',
        ]
    },
    {
        hasType: true,
        ORDER_LIST: [
            'Neoadj',
        ]
    },
    {
        hasType: false,
        ORDER_LIST: [
            'Neoadjuvant Treatment Status',
            'Worksite',
            'Batch',
            'Array ID',
            'Array Position',
            'Hyb Date',
            'RIN Score',
            '260/280 Ratio',
            '260/230 Ratio'
        ]
    }
];

export const RECENT_FILES_ORDER = [
    "Organism", "Tissue", "Sample Type", "Sex", "Developmental Epoch", "Disease", "Tumor Grade",
    "Tumor Occurrence", "KPSI", "Neoadjuvant Treatment", "MGMT Status", "CIMP Status",
    "IDH1 Mut", "IDH2 Mut", "TP53 Mut", "ATRX Mut", "TERT Mut", "EGFR Mut", "chr1p Mut", "chr19q Mut"
];