import analysesActionTypes from "./analyses.types";

export const setSelectedAnalysisItem = (item) => ({
    type: analysesActionTypes.SET_SELECTED_PROJECT_ITEM,
    payload: item
});

export const setNewlyCreatedAnalysisId = (item) => ({
    type: analysesActionTypes.SET_NEW_PROJECT_ID,
    payload: item
});

export const setAnalysisListDataValue = (data) => ({
    type: analysesActionTypes.SET_ANALYSES_LIST_DATA,
    payload: data
});

export const triggerAnalysisMenu = (data) => ({
    type: analysesActionTypes.SET_ANALYSIS_TRIGGER_MENU,
    payload: data
});

export const setAnalysisDataInfo = (data) => ({
    type: analysesActionTypes.SET_ANALYSIS_DATA_INFO,
    payload: data
});