import { createSelector } from "reselect";

const dataSaverState = (state) => state.dataSaver;

export const getSearchDataState = createSelector(
    [dataSaverState],
    dataSaver => dataSaver.searchDataState
);

export const getOwnersList = createSelector(
    [dataSaverState],
    dataSaver => dataSaver.owners
);

export const getAnalystsList = createSelector(
    [dataSaverState],
    dataSaver => dataSaver.analysts
);

export const getUsersList = createSelector(
    [dataSaverState],
    dataSaver => dataSaver.users
);

export const getNetworkDataState = createSelector(
    [dataSaverState],
    dataSaver => dataSaver.networkDataState
);

export const getNotificationSearchQueryResultData_BACKUP = createSelector(
    [dataSaverState],
    dataSaver => dataSaver.notificationSearchQueryResultData
);