import dataSaverActionTypes from "./data-saver.types";
import { searchDataList } from "./data-saver.utils";

const INITIAL_STATE = {
    searchDataState: [],
    owners: [],
    analysts: [],
    users: [],
    networkDataState: null,
    notificationSearchQueryResultData: null
};

const dataSaverReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case dataSaverActionTypes.SET_SEARCH_STATE_FOR_ADVANCED_SEARCH:
            return {
                ...state,
                searchDataState: searchDataList(state.searchDataState, action.payload)
            };
        case dataSaverActionTypes.SET_OWNERS_LIST:
            return {
                ...state,
                owners: action.payload
            };
        case dataSaverActionTypes.SET_ANALYSTS_LIST:
            return {
                ...state,
                analysts: action.payload
            };
        case dataSaverActionTypes.SET_USERS_LIST:
            return {
                ...state,
                users: action.payload
            };
        case dataSaverActionTypes.SET_NETWORK_DATA:
            return {
                ...state,
                networkDataState: action.payload
            };
        case dataSaverActionTypes.SET_NOTIF_QUERY_DATA:
            return {
                ...state,
                notificationSearchQueryResultData: action.payload
            };
        default:
            return state;
    }
};

export default dataSaverReducer;