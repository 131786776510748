import analysesActionTypes from "./analyses.types";

const INITIAL_STATE = {
    isDataSet: false,
    analysesSearchVal: '',
    analysesListData: [],
    newAnalysisIdCreated: null,
    selectedAnalysisItem: null,
    analysisMenuTriggered: false,
    analysisDataInfo: null
};

const analysesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case analysesActionTypes.SET_SELECTED_PROJECT_ITEM:
            return {
                ...state, // isDataSet: action.payload['isDataSet'] ? true : false
                selectedAnalysisItem: action.payload
            };
        case analysesActionTypes.SET_ANALYSES_LIST_DATA:
            return {
                ...state,
                analysesListData: action.payload
            };
        case analysesActionTypes.SET_NEW_PROJECT_ID:
            return {
                ...state,
                newAnalysisIdCreated: action.payload
            };
        case analysesActionTypes.SET_ANALYSIS_TRIGGER_MENU:
            return {
                ...state,
                analysisMenuTriggered: action.payload
            };
        case analysesActionTypes.SET_ANALYSIS_DATA_INFO:
            return {
                ...state,
                analysisDataInfo: action.payload
            };
        default:
            return state;
    }
};

export default analysesReducer;