export const addItemToAttributeList = (attributeList = [], attribute) => {
    let arrayList = [...attributeList];
    if (attribute === null || typeof attribute === 'undefined') {
        arrayList = [];
    } else {
        arrayList.push(attribute);
    }
    return arrayList;
};

export const addItemToAttributeDeletedList = (attributeList = [], attribute) => {
    let arrayList = [...attributeList];
    if (attribute === null || typeof attribute === 'undefined') {
        arrayList = [];
    } else {
        arrayList.push(attribute);
    }
    return arrayList;
};

export const addItemToAttributeAddedList = (attributeList = [], attribute) => {
    let arrayList = [...attributeList];
    if (attribute === null || typeof attribute === 'undefined') {
        arrayList = [];
    } else {
        arrayList.push(attribute);
    }
    return arrayList;
};