const resetPasswordDataForm = {
    password: {
        description: 'password',
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Password'
        },
        value: '',
        validation: {
            required: true,
            isPassword: true,
            minLength: 8,
            message: 'Enter password'
        },
        valid: false,
        touched: false
    },
    confirmPassword: {
        description: null,
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Confirm Password'
        },
        value: '',
        validation: {
            required: true,
            isConfirmPassword: true,
            minLength: 8,
            message: 'Passwords don\'t match'
        },
        valid: false,
        touched: false
    }
};

export default resetPasswordDataForm;