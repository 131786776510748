const codeVerificationForm = {
    verifyCode: {
        description: 'code received',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Code'
        },
        value: '',
        validation: {
            required: true,
            isCode: true,
            message: 'Enter Code'
        },
        valid: false,
        touched: false
    }
};

export default codeVerificationForm;