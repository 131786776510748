import React, { Fragment, useEffect, useState } from 'react';
import CustomButton from '../../../core/components/custom-button/custom-button.component';
import FormInput from '../../../core/components/form-input/form-input.component';
import { PreAuthCommonValidation } from '../../../core/components/pre-auth-layout/pre-auth-common-validation.component';
import PreAuthLayoutComponent from '../../../core/components/pre-auth-layout/pre-auth-layout.component';
import { preAuthBtnStyle } from '../../../core/style-config/elements.component';
import formValidityCheck from '../../../core/utils/validator';
import resetPasswordDataForm from './forms/reset-password.forms';
import { ucsfLoginInstance } from '../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import AppService from '../../../core/services/app-service';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../redux/app/app.actions';

const ResetPassword = ({ location }) => {
    const formArrayElememts = [];
    const d = useDispatch();
    const [isPasswordReset, setNewPassword] = useState(false);
    const [isUsedLink, setUsedLink] = useState(false);
    const [resetPasswordData, setResetPasswordData] = useState({
        resetPasswordObject: { ...resetPasswordDataForm },
        formIsValid: false,
        loading: false
    });
    const { resetPasswordObject } = resetPasswordData;
    let queryParams = '', passwordToken = '';
    if (location) {
        queryParams = AppService.fetchQueryParams(location.search);
        passwordToken = queryParams?.token;
    }

    useEffect(() => {
        d(setLoader(true, 'Please wait'));
        ucsfLoginInstance.post(COMPONENT_APIS.CHECK_EXPIRY_RESET_PWD, {
            "token": passwordToken
        }).then(res => {
            d(setLoader(false));
            setNewPassword(false);
            setUsedLink(false);
        }).catch(err => {
            d(setLoader(false));
            setUsedLink(true);
            let linkError = err?.response?.data?.error;
            AppService.setNotification({
                title: 'Invalid Link!',
                message: linkError,
                type: 'danger',
                duration: 2500
            });
        })
    }, []);

    const inputChangedHandler = (event, indentifier) => {
        let formIsValid = true;
        const updatedResetPasswordDataForm = { ...resetPasswordData.resetPasswordObject };
        const updatedFormElement = { ...updatedResetPasswordDataForm[indentifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedResetPasswordDataForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation.message = errorMessage;
        updatedFormElement.touched = true;
        updatedResetPasswordDataForm[indentifier] = updatedFormElement;
        for (let identifier in updatedResetPasswordDataForm) {
            formIsValid = updatedResetPasswordDataForm[identifier].valid && formIsValid;
        }
        setResetPasswordData({ resetPasswordObject: updatedResetPasswordDataForm, formIsValid: formIsValid });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        d(setLoader(true, 'Please wait. Your password is getting updated'));
        setNewPassword(false);
        const { password } = resetPasswordObject;
        const passwordValue = AppService.encryptData(password.value);
        ucsfLoginInstance.post(COMPONENT_APIS.RESET_PASSWORD_API, {
            "token": passwordToken,
            "password": passwordValue,
            "confirm_password": passwordValue
        }).then(res => {
            d(setLoader(false));
            setNewPassword(true);
            AppService.setNotification({
                title: 'Successful!',
                message: `You've successfully reset your password. Please use the new password to login`,
                type: 'success',
                duration: 3000
            });
        }).catch(err => {
            d(setLoader(false));
            setNewPassword(false);
            let resetError = err?.response?.data?.error;
            AppService.setNotification({
                title: 'Reset link sent!',
                message: resetError,
                type: 'danger',
                duration: 2500
            });
        });
    };

    for (let key in resetPasswordData.resetPasswordObject) {
        formArrayElememts.push({
            id: key,
            config: resetPasswordData.resetPasswordObject[key]
        });
    }

    const form = (
        <form className="sign-up-form" onSubmit={handleSubmit}>
            {
                formArrayElememts.map(formElement => (
                    <FormInput
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        errorMessage={formElement.config.validation.message}
                        changed={(event) => inputChangedHandler(event, formElement.id)}
                        blurred={(event) => inputChangedHandler(event, formElement.id)} />
                ))
            }
            <CustomButton
                type="submit"
                value="Submit"
                disabled={!resetPasswordData.formIsValid}
                inverted="inverted"
                style={preAuthBtnStyle}>
                SAVE
            </CustomButton>
        </form>
    );

    return (
        <PreAuthLayoutComponent className='reset-password'>
            {
                (isUsedLink && passwordToken) ?
                    <PreAuthCommonValidation>
                        The link that you are trying to access has already been used, please try to reset your password again.
                    </PreAuthCommonValidation> :
                    <Fragment>
                        <PreAuthCommonValidation>
                            {
                                isPasswordReset ?
                                    `You've successfully reset your password. Please use the new credentials to login.` :
                                    `Please reset your password.`
                            }
                        </PreAuthCommonValidation>
                        {!isPasswordReset && form}
                    </Fragment>
            }
        </PreAuthLayoutComponent>
    );
};

export default ResetPassword;