import formValidityCheck from "../../../utils/validator";

export const handleContentBugReportFormValueChange = ({
    event,
    identifier,
    bugReportFormData,
    setBugReportForm,
    changeTrigger,
    formType,
    filesAccepted,
    isFilesRequired
}) => {
    let formIsValid = true;
    const updatedBugReportForm = { ...bugReportFormData };
    const updatedFormElement = { ...updatedBugReportForm[identifier] };
    updatedFormElement.value = event.target.value;
    const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedBugReportForm);
    updatedFormElement.valid = validity;
    updatedFormElement.validation['message'] = errorMessage;
    updatedFormElement.touched = true;
    updatedBugReportForm[identifier] = updatedFormElement;
    for (let identifier in updatedBugReportForm) {
        if (updatedBugReportForm[identifier].validation?.required) {
            formIsValid = updatedBugReportForm[identifier].valid && formIsValid;
        }
    }
    let formUpdates = {
        formData: updatedBugReportForm,
        isFormValid: formIsValid,
        formType,
        filesAccepted,
        isFilesRequired
    };
    setBugReportForm(formUpdates);
    changeTrigger(formUpdates);
};

export const handleContentFeatureFormValueChange = ({
    event,
    identifier,
    featureFormData,
    setFeatureForm, changeTrigger,
    formType
}) => {
    let formIsValid = true;
    const updatedFeatureForm = { ...featureFormData };
    const updatedFormElement = { ...updatedFeatureForm[identifier] };
    updatedFormElement.value = event.target.value;
    const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedFeatureForm);
    updatedFormElement.valid = validity;
    updatedFormElement.validation['message'] = errorMessage;
    updatedFormElement.touched = true;
    updatedFeatureForm[identifier] = updatedFormElement;
    for (let identifier in updatedFeatureForm) {
        if (updatedFeatureForm[identifier].validation?.required) {
            formIsValid = updatedFeatureForm[identifier].valid && formIsValid;
        }
    }
    setFeatureForm({ formData: updatedFeatureForm, isFormValid: formIsValid, formType });
    changeTrigger({ formData: updatedFeatureForm, isFormValid: formIsValid, formType });
};

navigator.sayswho = (function () {
    var ua = navigator.userAgent;
    var tem;
    var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
})();

export const browserVersion = navigator.sayswho;

export const formDataPorstHeaders = { "Content-Type": "multipart/form-data" };

export const getTimeZone = () => {
    let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    let areaString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return `GMT${(offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2)}, ${areaString}`;
}