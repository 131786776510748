let contentListDataObject = {
    'home': [{
        type: 'collections',
        displayName: 'Data',
        isDraggable: true,
        hasFolder: false, // UNCOMMENT FOR PHASE 2
        selected: false
    }, {
        type: 'analyses',
        displayName: 'Analyses',
        hasFolder: false, // UNCOMMENT FOR PHASE 2
        selected: false
    }, {
        type: 'resources',
        displayName: 'Resources',
        hasFolder: false,
        selected: false
    }, {
        type: 'starred',
        displayName: 'Starred',
        hasFolder: false,
        selected: false
    }],
    'system-tables': [{
        type: 'tables',
        displayName: 'Tables',
        hasFolder: false,
        selected: false
    }, {
        type: 'roles',
        displayName: 'Roles and Permissions',
        hasFolder: false,
        selected: false
    }, {
        type: 'preferences',
        displayName: 'Preferences',
        hasFolder: false,
        selected: false
    }],
    'admin': [{
        type: 'tables',
        displayName: 'Tables',
        hasFolder: false,
        selected: false
    }, {
        type: 'roles',
        displayName: 'Roles and Permissions',
        hasFolder: false,
        selected: false
    }, {
        type: 'preferences',
        displayName: 'Preferences',
        hasFolder: false,
        selected: false
    }, {
        type: 'user-management',
        displayName: 'User Management',
        hasFolder: false,
        selected: false
    }],
    'notification-logs': [{
        type: 'searchNotifications',
        displayName: 'Search Notifications',
        hasFolder: false,
        selected: false
    },
    {
        type: 'downloadNotifications',
        displayName: 'Downloads',
        hasFolder: false,
        selected: false
    },
    {
        type: 'commentNotifications',
        displayName: 'Comments',
        hasFolder: false,
        selected: false
    }],
    'restricted-notification-logs': [{
        type: 'searchNotifications',
        displayName: 'Search Notifications',
        hasFolder: false,
        selected: false
    }]
};

export default contentListDataObject;