import React from 'react';
import ContentList from './content-list/content-list.component';
import './side-content.styles.scss';

const SideContent = (props) => {
    const {
        hideSideContent,
        ...otherProps
    } = props;
    return (
        <div className={`side-content side-width${hideSideContent ? ' hidden' : ''}`}> {/*side-width*/}
            <ContentList {...otherProps} />
        </div>
    );
};

export default React.memo(SideContent);