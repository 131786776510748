const codeForm = {
    phone: {
        description: 'phone number',
        elementType: 'input',
        elementConfig: {
            type: 'number',
            placeholder: 'Phone Number'
        },
        value: '',
        validation: {
            required: true,
            isPhone: true,
            message: 'Enter phone number'
        },
        valid: false,
        touched: false
    }
};

export default codeForm;