// import logger from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import rootReducer from './root-reducer.redux';

let middlewares = []; // thunk // logger

/* if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
} else {
    middlewares = [];
} */

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);
const exportContent = { store, persistor };

// eslint-disable-next-line import/no-anonymous-default-export
export default exportContent;