import React, { Fragment, createRef, useEffect, useState } from 'react';
import FormInputComponent from '../../form-input/form-input.component';
import { bugReportTemplateForm, featureRequestTemplateForm } from './form';
import { formDataPorstHeaders, handleContentBugReportFormValueChange, handleContentFeatureFormValueChange } from './utils';
import Dropzone from 'react-dropzone';
import '../report-bug.styles.scss';
import AppService from '../../../services/app-service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubmitModalData } from '../../../../redux/modal/modal.selectors';
import { setModalData, setModalSubmitData } from '../../../../redux/modal/modal.actions';
import { ucsfInstance, ucsfLoginInstance } from '../../../axios/ucsf';
import { COMPONENT_APIS } from '../../../utils/omi-api';
import { setLoader } from '../../../../redux/app/app.actions';
import { fetchCurrentUser } from '../../../../redux/user/user.selectors';
import { CONST } from '../../../utils/omi-constants';

const RBM_FORM_DATA_LIST_OBJECT = {
    REPORT_BUG: 'Bug Report',
    FEATURE_REQUEST: 'Comment/Feature Request'
};

const RBM_FORM_DATA = {
    label: 'Report Type',
    description: 'report type',
    elementType: 'select',
    elementConfig: {
        type: 'select',
        placeholder: 'Select report type',
        disabled: false
    },
    idSelected: RBM_FORM_DATA_LIST_OBJECT.REPORT_BUG,
    list: [
        RBM_FORM_DATA_LIST_OBJECT.REPORT_BUG,
        RBM_FORM_DATA_LIST_OBJECT.FEATURE_REQUEST
    ],
    validation: { required: false },
    valid: true,
    touched: false
};

const ReportBugModal = ({ changeTrigger, modalData }) => {
    const dropzoneRef = createRef();
    const dispatch = useDispatch();
    const currentUser = useSelector(fetchCurrentUser) || null;
    const submittedModalData = useSelector(fetchSubmitModalData) || null;
    const [acceptedFileData, setAcceptedFileData] = useState([]);
    const [formData, setFormData] = useState(RBM_FORM_DATA);
    const [reportType, setReportType] = useState(formData.idSelected);
    const [featureForm, setFeatureForm] = useState({
        formData: { ...featureRequestTemplateForm },
        isFormValid: false,
        formType: 'featureReport'
    });
    const [bugReportForm, setBugReportForm] = useState({
        formData: { ...bugReportTemplateForm },
        isFormValid: false,
        isFilesRequired: false,
        formType: 'bugReport',
        filesAccepted: []
    });
    const featureFormData = featureForm.formData;
    const bugReportFormData = bugReportForm.formData;

    useEffect(() => {
        if (submittedModalData) {
            let { formData, formType, filesAccepted } = submittedModalData || {};
            let modalDataPayload = {}, API = '', apiInstance;
            const formInputData = new FormData();
            if (formType === 'bugReport') {
                for (let file of filesAccepted) { formInputData.append('screenshot', file); }
                dispatch(setLoader(true, 'Your bug report is getting created.'));
                API = COMPONENT_APIS.CREATE_NEW_BUG_REPORT;
            } else if (formType === 'featureReport') {
                dispatch(setLoader(true, 'Your feature/comment report is getting created.'));
                API = COMPONENT_APIS.CREATE_NEW_FEEDBACK_REPORT;
            }
            Object.keys(formData).forEach(key => {
                modalDataPayload[formData[key]['postKey']] = formData[key]['value'];
            });
            formInputData.append("metadata", JSON.stringify(modalDataPayload));
            if (currentUser) { apiInstance = ucsfInstance; }
            else if (!currentUser) { apiInstance = ucsfLoginInstance; }
            const apiInstanceCall = () => {
                if (formType === 'bugReport') {
                    return [API, formInputData, { headers: formDataPorstHeaders }];
                }
                return [API, modalDataPayload];
            }
            apiInstance.post(...apiInstanceCall()).then((response) => {
                dispatch(setLoader(false));
                AppService.setNotification({
                    message: 'Your response is recorded!',
                    title: 'Successful',
                    type: 'success'
                });
            }).catch(err => {
                dispatch(setLoader(false));
                AppService.setNotification({
                    message: 'Something went wrong!',
                    title: 'Error',
                    type: 'danger'
                });
            });
        }
        return () => {
            dispatch(setModalData(null));
            dispatch(setModalSubmitData(null));
        };
        // eslint-disable-next-line
    }, [submittedModalData]);

    const handleFormSelectionVal = (selectedData) => {
        const formInputData = { ...formData };
        formInputData['value'] = selectedData || selectedData.value;
        formInputData['idSelected'] = selectedData || selectedData.value;
        setReportType(selectedData);
        setFormData(formInputData);
    };

    const handleContentFormValueChange = (event, identifier, reportType) => {
        let formType = '', formContent = { event, identifier, reportType, changeTrigger };
        if (reportType === 'bugReport') {
            formType = 'bugReport';
            handleContentBugReportFormValueChange({
                ...formContent,
                bugReportFormData,
                formType,
                setBugReportForm,
                isFilesRequired: bugReportForm.isFilesRequired,
                filesAccepted: acceptedFileData
            });
        } else {
            formType = 'featureReport';
            handleContentFeatureFormValueChange({ ...formContent, featureFormData, formType, setFeatureForm });
        }
    };

    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const onDelete = (item) => {
        const newItemList = [];
        const itemList = [...acceptedFileData];
        itemList.forEach(itemData => {
            if (itemData.name !== item.name) {
                newItemList.push(itemData);
            }
        });
        setAcceptedFileData(newItemList);
        changeTrigger({
            ...bugReportForm,
            filesAccepted: newItemList
        });
    };

    const FEEDBACK_TEMPLATE = (templateContent) => {
        return (
            <div className='report-wrapper'>
                <div className='report-wrapper-title'>
                    <div className='report-wrapper-title-name'>[UCSF] Omicon Platform Feedback</div>
                    <div className='report-wrapper-title-version'>(Version: v{CONST.OMICON_VERSION})</div>
                </div>
                <div className='report-wrapper-body'>
                    {templateContent}
                </div>
            </div>
        );
    };

    const FeatureTemplate = FEEDBACK_TEMPLATE(
        <Fragment>
            {Object.entries(featureFormData).map((data, i) => {
                let key = data[0], formData = data[1];
                return (
                    <FormInputComponent
                        tabIndex={1}
                        key={key}
                        className={(key === 'comment') ? 'comment' : ''}
                        label={formData.label}
                        elementType={formData.elementType}
                        elementConfig={formData.elementConfig}
                        value={formData.value}
                        showInputValue={true}
                        invalid={!formData.valid}
                        shouldValidate={formData.validation}
                        touched={formData.touched}
                        errorMessage={formData.validation.message}
                        changed={(event) => handleContentFormValueChange(event, key)}
                        blurred={(event) => handleContentFormValueChange(event, key)}
                    />
                );
            })}
        </Fragment>
    );

    const BugReportTemplate = FEEDBACK_TEMPLATE(
        <Fragment>
            {Object.entries(bugReportFormData).map((data, i) => {
                let key = data[0], formData = data[1];
                return (
                    <FormInputComponent
                        tabIndex={1}
                        key={key}
                        className={(formData.elementType === 'textArea') ? 'comment' : ''}
                        label={formData.label}
                        elementType={formData.elementType}
                        elementConfig={formData.elementConfig}
                        value={formData.value}
                        showInputValue={true}
                        invalid={!formData.valid}
                        shouldValidate={formData.validation}
                        touched={formData.touched}
                        errorMessage={formData.validation.message}
                        changed={(event) => handleContentFormValueChange(event, key, 'bugReport')}
                        blurred={(event) => handleContentFormValueChange(event, key, 'bugReport')}
                    />
                );
            })}
            <div style={{ height: 'auto' }} className='group comment'>
                <div className="rbm-container-block-drop">
                    <div className="block-drop-label">Attach screen-shots if any</div>
                    <div className="rbm-container-block-drop-container">
                        <div className="rbm-container-block-drop-container-img"></div>
                        <div className="rbm-container-block-drop-container-desc">
                            <Dropzone
                                ref={dropzoneRef}
                                noClick
                                noKeyboard
                                multiple={true}
                                accept="image/*"
                                onDrop={(acceptedFiles) => {
                                    setAcceptedFileData(acceptedFiles);
                                    changeTrigger({
                                        ...bugReportForm,
                                        filesAccepted: acceptedFiles
                                    });
                                }}>
                                {({ getRootProps, getInputProps, isDragActive }) => {

                                    return (
                                        <div className="container">
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input type='file' {...getInputProps()} />
                                                {/* <>{isDragActive ? <p>Release to drop the files here</p>:<p> */}
                                                    {/* Drag 'n' drop or&nbsp; */}
                                                    <span onClick={openDialog}>Browse screenshots</span>
                                                {/* </p>}</> */}
                                            </div>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                        </div>
                    </div>
                    {
                        acceptedFileData && acceptedFileData.length ? (
                            <div className="rbm-container-block-drop-container files">
                                <div className="selected-file">Selected File(s)</div>
                                <ul>
                                    {acceptedFileData.map(file => (
                                        <li key={file.path}>
                                            &#9632;
                                            <span className="title">{file.path} - {AppService.formatBytes(file.size)}</span>
                                            <span className="delete-item" onClick={() => onDelete(file)}></span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </Fragment>
    );

    return (
        <div className='rbm-container'>
            <div className='rbm-options'>
                <FormInputComponent {...formData} selectVal={handleFormSelectionVal} />
                {
                    (reportType === RBM_FORM_DATA_LIST_OBJECT.FEATURE_REQUEST) &&
                    FeatureTemplate
                }
                {
                    (reportType === RBM_FORM_DATA_LIST_OBJECT.REPORT_BUG) &&
                    BugReportTemplate
                }
            </div>
        </div>
    );
};

export default ReportBugModal;