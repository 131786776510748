import dataSaverActionTypes from "./data-saver.types";

export const setSearchDataState = (searchState) => ({
    type: dataSaverActionTypes.SET_SEARCH_STATE_FOR_ADVANCED_SEARCH,
    payload: searchState
});

export const setOwnersList = (data) => ({
    type: dataSaverActionTypes.SET_OWNERS_LIST,
    payload: data
});

export const setAnalystsList = (data) => ({
    type: dataSaverActionTypes.SET_ANALYSTS_LIST,
    payload: data
});

export const setUsersList = (data) => ({
    type: dataSaverActionTypes.SET_USERS_LIST,
    payload: data
});

export const setNetworkDataState = (data) => ({
    type: dataSaverActionTypes.SET_NETWORK_DATA,
    payload: data
});

export const setSearchQueryDataForNotifications_BACKUP = (data) => ({
    type: dataSaverActionTypes.SET_NOTIF_QUERY_DATA,
    payload: data
});