import advancedSearchActionTypes from "./advanced-search.types";

const INITIAL_STATE = {
    searchTabState: null,
    notification: null,
    advancedSearchResultData: null,
    notificationSearchQueryResultData: null,
    notifAPICallInitiationForAdvancedSearch: null,
    geneSetSearchVal: '',
    advancedSearchScreenState: null
};

const advancedSearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case advancedSearchActionTypes.SET_ADVANCED_SEARCH_STATE:
            return {
                ...state,
                searchTabState: action.payload
            };
        case advancedSearchActionTypes.SET_ADVANCED_SEARCH_RESULT_DATA:
            return {
                ...state,
                advancedSearchResultData: action.payload
            };
        case advancedSearchActionTypes.SET_ADVANCED_SEARCH_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        case advancedSearchActionTypes.SET_NOTIF_QUERY_DATA:
            return {
                ...state,
                notificationSearchQueryResultData: action.payload
            };
        case advancedSearchActionTypes.SET_API_CALL_INIT_FOR_ADV_SEARCH:
            return {
                ...state,
                notifAPICallInitiationForAdvancedSearch: action.payload
            };
        case advancedSearchActionTypes.SET_GLOBAL_SEARCH_VAL_FOR_GENESET:
            return {
                ...state,
                geneSetSearchVal: action.payload
            };
        case advancedSearchActionTypes.SET_SCREEN_TO_ADVANCED_SEARCH:
            return {
                ...state,
                advancedSearchScreenState: action.payload
            };
        default:
            return state;
    }
};

export default advancedSearchReducer;