import advancedSearchActionTypes from "./advanced-search.types";

export const setAdvancedSearchState = (searchState) => ({
    type: advancedSearchActionTypes.SET_ADVANCED_SEARCH_STATE,
    payload: searchState
});

export const setAdvancedSearchResultData = (data) => ({
    type: advancedSearchActionTypes.SET_ADVANCED_SEARCH_RESULT_DATA,
    payload: data
});

export const setAdvancedSearchNotification = (payload) => ({
    type: advancedSearchActionTypes.SET_ADVANCED_SEARCH_NOTIFICATION,
    payload
});

export const setSearchQueryDataForNotifications = (data) => ({
    type: advancedSearchActionTypes.SET_NOTIF_QUERY_DATA,
    payload: data
});

export const setNotifAPICallInitiationForAdvancedSearch = (data) => ({
    type: advancedSearchActionTypes.SET_API_CALL_INIT_FOR_ADV_SEARCH,
    payload: data
});

export const setGlobalSearchValForGeneSets = (data) => ({
    type: advancedSearchActionTypes.SET_GLOBAL_SEARCH_VAL_FOR_GENESET,
    payload: data
});

export const setScreenToAdvancedSearch = (data) => ({
    type: advancedSearchActionTypes.SET_SCREEN_TO_ADVANCED_SEARCH,
    payload: data
});