import { createSelector } from "reselect";

const advancedSearchState = state => state.advancedSearch;

export const getSavedAdvancedSearchData = createSelector(
    [advancedSearchState],
    (advancedSearch) => advancedSearch.searchTabState
);

export const getAdvancedSearchNotifData = createSelector(
    [advancedSearchState],
    (advancedSearch) => advancedSearch.notification
);

export const getAdvancedSearchResultData = createSelector(
    [advancedSearchState],
    (advancedSearch) => advancedSearch.advancedSearchResultData
);

export const getNotificationSearchQueryResultData = createSelector(
    [advancedSearchState],
    advancedSearch => advancedSearch.notificationSearchQueryResultData
);

export const getNotifAPICallInitiationForAdvancedSearch = createSelector(
    [advancedSearchState],
    advancedSearch => advancedSearch.notifAPICallInitiationForAdvancedSearch
);

export const getGeneSetSearchVal = ({ advancedSearch }) => {
    return advancedSearch?.geneSetSearchVal;
};

export const getAdvancedSearchScreenState = ({ advancedSearch }) => {
    return advancedSearch?.advancedSearchScreenState;
};
