const args = (val) => val ? val + '/' : '';

export const OMI_API = {
    sampleAttributes: {
        CREATE: '/api/sample-attributes/',
        RETRIEVE: '/api/sample-attributes/',
        UPDATE: '/api/update-sample-attributes/',
        DELETE: '/api/delete-sample-attributes/'
    },
    dataCollectionAttributes: {
        RETRIEVE: '/api/dc-attributes/'
    },
    dataSetAttributes: {
        RETRIEVE: '/api/ds-attributes/'
    },
    analysisAttributes: {
        // RETRIEVE: '/api/analysis-attributes/'
        RETRIEVE: '/api/project-attributes/'
    },
    geneSetAttributes: {
        // RETRIEVE: '/api/sample-attributes/',
        CREATE: '/api/add-gene-sets',
        UPDATE: '/api/update-gene-set/',
        DELETE: '/api/delete-gene-set/'
    }
};

export const COMPONENT_APIS = {
    RECENT_COLLECTIONS: '/api/recent-collections/',
    RECENT_ANALYSES: '/api/recent-projects',
    DATA_COLLECTIONS: '/api/data-collections',
    SEARCH_ANALYSES: '/api/projects',
    DATA_SETS: '/api/data-sets',
    DATA_SETS_SAMPLE_ATTRIBUTES: '/api/data-sets/sample-attributes',
    CSV_FILE_PREVIEW: '/api/csv-file-preview',
    AWS_TEMP_CREDENTIALS: (params) => `/api/aws-temp-credentials${params}`,
    POSSIBLE_VALUES_DS: `/api/possible-values/ds-attributes`,
    POSSIBLE_VALUES_DC: `/api/possible-values/dc-attributes`,
    POSSIBLE_VALUES_PJCT: `/api/possible-values/project-attributes`,
    ONTOLOGIES: '/api/ontologies/',
    ATTRIBUTES: '/api/attributes/',
    GET_DC_DETAILS: (id = '') => `/api/get-dc-details/${args(id)}`,
    USERS: '/api/users/',
    OWNERS: '/api/owners/',
    ANALYSTS: '/api/analysts/',
    DC_UPDATE: '/api/dc-attributes/update',
    DS_UPDATE: '/api/ds-attributes/update',
    PROJECT_UPDATE: '/api/project-attributes/update',
    SAVE_UPLOAD: '/api/save-upload',
    NETWORK: (id) => `/api/network/${args(id)}`,
    ANALYSES: (id) => `/api/projects/${args(id)}`,
    CREATE_ANALYSES: `/api/create-project/`,
    GENE_SETS: (num) => `/api/gene-sets/${args(num)}`,
    GENE_SET_SEARCH: '/search/gene-sets/',
    GENE_SET_SEARCH_ADV: '/search/gene-sets-advanced/',
    ONTOLOGY: '/api/autocomplete-ontology/',
    USER_PERMISSION: '/api/user-permissions/',
    NON_MICROARRAY_MAPPING: '/api/list-mapping-tables/non-microarray/',
    MICROARRAY_MAPPING: '/api/list-mapping-tables/microarray/',
    MAPPING: (val) => `/api/mapping-table/${args(val)}`,
    MUTATED_GENE_SETS_OR_REGIONS: '/api/mutated-gene-sets-or-regions',
    MUTATED_GENE_VALUES: '/api/mutated-gene-values',
    DOCKER_DOWNLOAD: (covariation_file_id, project_id) => `api/download-docker-module/?covariation_file_id=${covariation_file_id}&project_id=${project_id}`,
    ADVANCED_SEARCH_BK: '/search/sample-attributes-tab',
    ADVANCED_SEARCH: '/search/advanced-search/',
    CONFIG: '/search/configurations/',
    SPECIES: '/api/species/',
    CATEGORIES: '/api/categories/',
    COMMENTS_COUNT: (id) => `/api/comments-count/${id}/`,
    WHITELIST_USERS: `/api/whitelist-users/`,
    S3_ADV_SEARCH_RESULTS: `/api/s3-lambda-view/`,
    APP_SHIBBOLETH_LOGOUT: `/Shibboleth.sso/Logout?return=https://idp-stage.ucsf.edu/idp/profile/Logout`,
    APP_SHIBBOLETH_REDIRECT_LOGOUT: `https://idp-stage.ucsf.edu/idp/profile/Logout`,
    APP_LOGOUT: `/Shibboleth.sso/Logout`,
    UCSF_LOGOUT_URL: `https://idp-stage.ucsf.edu/idp/profile/Logout`,
    REVIEWERS_LOGIN: `/api/login`,
    ADV_SEARCH_QUERY: (queryId) => `/api/advance-search-query/${queryId}`,
    ADV_SEARCH_RECENT_QUERY: (queryId) => `/api/log-advance-search-query/${queryId}`,
    COVARIATION_MODULES_REDIRECT: `/api/omicon-id-redirect/`,
    SAVE_SEARCH_QUERY_RESULTS: `/api/save-search-query-result/`,
    FETCH_SEARCH_QUERY_LIST: `/api/search-query-list/`,
    FETCH_RECENT_SEARCH_QUERY_LIST: `/api/log-search-query-list/`,
    SAVE_SEARCH_QUERY_LIST: `/api/log-search-query-result/`,
    DELETE_ADV_SEARCH: (queryId) => `/api/advance-search-query/${queryId}/`,
    RESULTS_DRILL_DOWN: '/api/drill-down-samples/',
    OMICONID_SEARCH: (searchVal) => `api/omiconid-search?search=${searchVal}`,
    FILE_SEARCH: (searchVal) => `api/files-search?search=${searchVal}`,
    LIST_OMICON_IDS: `api/module-omiconid/`,
    USER_REG: 'api/user-register',
    USER_LOGIN: 'api/user-login',
    USER_MANAGEMENT_LIST: 'api/user-management/',
    EMAIL_VERIFICATION: 'api/email-verification/',
    EMAIL_VERIFICATION_FOR_FORGOT_PWD: 'api/user-account-forget-password/',
    RESET_PASSWORD_API: 'api/user-reset-password/',
    CREATE_NEW_BUG_REPORT: 'api/create-bug-report/',
    CREATE_NEW_FEEDBACK_REPORT: 'api/create-feedback-comment/',
    CHECK_EXPIRY_RESET_PWD: 'api/check-expiry-reset-password/',
    VERSION_CHECK: 'api/version-check',
    CREATE_NOTIFICATION: 'api/create-notification/',
    UNREAD_NOTIFICATION: 'api/unread-notifications/',
    LIST_SEARCH_NOTIFICATIONS: 'api/all-search-notifications/',
    LIST_COMMENT_NOTIFICATIONS: 'api/all-mention-notifications/',
    MARK_NOTIFICATION_AS_READ: 'api/mark-notification-as-read/',
    MARK_ALL_NOTIFICATION_AS_READ: 'api/mark-all-notifications-as-read/',
    LIST_DOWNLOAD_NOTIFICATIONS: 'api/all-download-notifications/',
    CREATE_DOWNLOAD_NOTIFICATION: 'api/create-download-notification/',
    MARK_DOWNLOAD_NOTIFICATION_AS_READ: 'api/mark-download-notification-as-read/',
    MARK_MENTION_NOTIFICATION_AS_READ: 'api/mark-mention-notification-as-read/',
    ACCEPT_TOS: 'api/accept-tos/'
};