/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import FormInput from '../../../../../../core/components/form-input/form-input.component';
import AppService from '../../../../../../core/services/app-service';
import formValidityCheck from '../../../../../../core/utils/validator';
import newGeneSetData from './data/gs-modal-body.data';
import geneSetAddForm from './forms/gs-modal-body.forms';
import './gs-modal-body.styles.scss';
import { Progress } from 'react-sweet-progress';
import { useDispatch } from 'react-redux';
import { Fragment } from 'react';

const style = {
    width: '260px',
    height: '36px'
};

const GeneSetModalBody = ({ changeTrigger, ...otherProps }) => {
    const { modalData, filesTrigger, filesAccepted } = otherProps;
    const dropzoneRef = createRef();
    const [acceptedFileData, setAcceptedFileData] = useState([]);
    const [uploadStatus, setUploadStatus] = useState('active');
    const [progress, setProgress] = useState(0);
    const [geneSetForm, setGeneSetForm] = useState({
        formData: { ...geneSetAddForm },
        isFormValid: false
    });
    const { formData } = geneSetForm;
    // eslint-disable-next-line no-unused-vars
    const [geneSetData, setGeneSetData] = useState({
        ...newGeneSetData
    });
    const d = useDispatch();

    useEffect(() => {
        filesTrigger(acceptedFileData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFileData]);

    useEffect(() => {
        resetFormValues();
        if (modalData) {
            populateEditValues(modalData);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData]);

    const resetFormValues = () => {
        const updatedGeneSetForm = { ...formData };
        for (let identifier in updatedGeneSetForm) {
            updatedGeneSetForm[identifier].value = '';
            updatedGeneSetForm[identifier].valid = false;
            if (identifier === 'required' || identifier === 'indexed') {
                updatedGeneSetForm[identifier].value = 'No';
                updatedGeneSetForm[identifier].valid = true;
            }
        }
        setGeneSetForm({ formData: updatedGeneSetForm });
        changeTrigger({ formData: updatedGeneSetForm, isFormValid: false, filesAccepted: acceptedFileData });
    };

    const populateEditValues = (modalData) => {
        let formIsValid = true;
        const editData = {
            id: modalData['id'],
            sampleAttributeSource: modalData['name'],
            type: modalData['type'],
            examples: modalData['examples'],
            required: modalData['is_required'] ? 'Yes' : 'No',
            indexed: modalData['is_indexed'] ? 'Yes' : 'No',
            possibleValues: convertPossibleValues(modalData['possible_values']),
            category: modalData['category'],
            description: modalData['description']
        };
        const updatedSampleAttributesForm = { ...formData };
        updatedSampleAttributesForm['id'].value = null;
        for (let identifier in updatedSampleAttributesForm) {
            updatedSampleAttributesForm[identifier].value = editData[identifier];
            updatedSampleAttributesForm[identifier].valid = editData[identifier] ? true : false;
            if (updatedSampleAttributesForm[identifier].validation?.required) {
                formIsValid = updatedSampleAttributesForm[identifier].valid && formIsValid;
            }
        }
        setGeneSetForm({ formData: updatedSampleAttributesForm });
        changeTrigger({ formData: updatedSampleAttributesForm, isFormValid: formIsValid, filesAccepted: acceptedFileData });
    };

    const convertPossibleValues = (possibleValues = []) => {
        let valueArray = '';
        if (possibleValues && possibleValues.length) {
            possibleValues.forEach((value, index) => {
                if (index !== (possibleValues.length - 1)) {
                    valueArray += value + ', ';
                } else {
                    valueArray += value;
                }
            });
        }
        return valueArray;
    };

    const inputChangedHandler = (event, identifier) => {
        // FORM-DATA-UPDATE-HANDLER
        let formIsValid = true;
        const updatedGeneSetForm = { ...formData };
        const updatedFormElement = { ...updatedGeneSetForm[identifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedGeneSetForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation['message'] = errorMessage;
        updatedFormElement.touched = true;
        updatedGeneSetForm[identifier] = updatedFormElement;
        for (let identifier in updatedGeneSetForm) {
            if (updatedGeneSetForm[identifier].validation?.required) {
                formIsValid = updatedGeneSetForm[identifier].valid && formIsValid;
            }
        }
        setGeneSetForm({ formData: updatedGeneSetForm, isFormValid: formIsValid });
        changeTrigger({ formData: updatedGeneSetForm, isFormValid: formIsValid, filesAccepted: acceptedFileData });
    };

    const selectValue = (event, identifier) => {
        let formIsValid = true;
        const updatedGeneSetForm = { ...formData };
        const updatedFormElement = { ...updatedGeneSetForm[identifier] };
        updatedFormElement.value = (event && event?.id ? event.id : (event?.name) ? event.name : updatedFormElement?.value ? updatedFormElement.value : '');
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedGeneSetForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation['message'] = errorMessage;
        updatedFormElement.touched = true;
        updatedGeneSetForm[identifier] = updatedFormElement;
        for (let identifier in updatedGeneSetForm) {
            if (updatedGeneSetForm[identifier].validation?.required) {
                formIsValid = updatedGeneSetForm[identifier].valid && formIsValid;
            }
        }
        setGeneSetForm({ formData: updatedGeneSetForm, isFormValid: formIsValid });
        changeTrigger({ formData: updatedGeneSetForm, isFormValid: formIsValid, filesAccepted: acceptedFileData });
    };

    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const onDelete = (item) => {
        const newItemList = [];
        const itemList = [...acceptedFileData];
        itemList.forEach(itemData => {
            if (itemData.name !== item.name) {
                newItemList.push(itemData);
            }
        });
        setAcceptedFileData(newItemList);
        changeTrigger({ formData, isFormValid: geneSetForm.isFormValid, filesAccepted: newItemList });
        setProgress(0);
        setUploadStatus('active');
    }

    return (
        <Fragment>
            <div className="gs-form-elements req">
                <span className="req">*</span> Required fields
            </div>
            <div className="gs-form-elements">
                {/* <div style={{ width: '100%', height: 'auto' }}> */}
                <div>
                    <FormInput
                        tabIndex={1}
                        style={style}
                        label={formData.setName.label}
                        elementType={formData.setName.elementType}
                        elementConfig={formData.setName.elementConfig}
                        invalid={!formData.setName.valid}
                        shouldValidate={formData.setName.validation}
                        touched={formData.setName.touched}
                        errorMessage={formData.setName.validation.message}
                        value={formData.setName.value}
                        changed={(event) => inputChangedHandler(event, 'setName')}
                        blurred={(event) => inputChangedHandler(event, 'setName')} />
                </div>
                <div>
                    <FormInput
                        // idSelected={formData.category.value}
                        // isToggled={formData.category.toggle}
                        tabIndex={2}
                        style={style}
                        label={formData.category.label}
                        elementType={formData.category.elementType}
                        elementConfig={formData.category.elementConfig}
                        invalid={!formData.category.valid}
                        shouldValidate={formData.category.validation}
                        touched={formData.category.touched}
                        errorMessage={formData.category.validation.message}
                        value={formData.category.value}
                        changed={(event) => inputChangedHandler(event, 'category')}
                        blurred={(event) => inputChangedHandler(event, 'category')} />
                    {/* list={geneSetData['category']}
                        selectVal={(event) => selectValue(event, 'category')}
                        blurred={(event) => selectValue(event, 'category')} /> */}
                </div>
            </div>
            <div className="gs-form-elements">
                <div>
                    <FormInput
                        // idSelected={formData.species.value}
                        // isToggled={formData.species.toggle}
                        tabIndex={3}
                        style={style}
                        label={formData.species.label}
                        elementType={formData.species.elementType}
                        elementConfig={formData.species.elementConfig}
                        invalid={!formData.species.valid}
                        shouldValidate={formData.species.validation}
                        touched={formData.species.touched}
                        errorMessage={formData.species.validation.message}
                        value={formData.species.value}
                        changed={(event) => inputChangedHandler(event, 'species')}
                        blurred={(event) => inputChangedHandler(event, 'species')} />
                    {/* // list={geneSetData['species']}
                        // selectVal={(event) => selectValue(event, 'species')}
                        // blurred={(event) => selectValue(event, 'species')} /> */}
                </div>
                <div>
                    <FormInput
                        tabIndex={4}
                        style={style}
                        label={formData.pubMedId.label}
                        elementType={formData.pubMedId.elementType}
                        elementConfig={formData.pubMedId.elementConfig}
                        invalid={!formData.pubMedId.valid}
                        shouldValidate={formData.pubMedId.validation}
                        touched={formData.pubMedId.touched}
                        errorMessage={formData.pubMedId.validation.message}
                        value={formData.pubMedId.value}
                        changed={(event) => inputChangedHandler(event, 'pubMedId')}
                        blurred={(event) => inputChangedHandler(event, 'pubMedId')} />
                </div>
            </div>
            <div className="gs-form-elements">
                <div style={{ width: '100%', height: 'auto' }}>
                    <FormInput
                        tabIndex={5}
                        label={formData.description.label}
                        elementType={formData.description.elementType}
                        elementConfig={formData.description.elementConfig}
                        invalid={!formData.description.valid}
                        shouldValidate={formData.description.validation}
                        touched={formData.description.touched}
                        errorMessage={formData.description.validation.message}
                        value={formData.description.value}
                        changed={(event) => inputChangedHandler(event, 'description')}
                        blurred={(event) => inputChangedHandler(event, 'description')} />
                </div>
            </div>
            <div className="gs-form-elements">
                <div style={{ width: '100%', height: 'auto' }}>
                    <div className="gene-set-container-block-drop">
                        <div className="block-drop-label">Please upload the new gene set file <span className="req">*</span></div>
                        <div className="gene-set-container-block-drop-container">
                            <div className="gene-set-container-block-drop-container-img"></div>
                            <div className="gene-set-container-block-drop-container-desc">
                                <Dropzone
                                    ref={dropzoneRef}
                                    noClick
                                    noKeyboard
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                        setAcceptedFileData(acceptedFiles);
                                        changeTrigger({ formData, isFormValid: geneSetForm.isFormValid, filesAccepted: acceptedFiles });
                                    }}>
                                    {({ getRootProps, getInputProps }) => {
                                        return (
                                            <div className="container">
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>
                                                        {/* Drag 'n' drop or&nbsp; */}
                                                        <span onClick={openDialog}>Browse file</span>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Dropzone>
                            </div>
                        </div>
                        {
                            acceptedFileData && acceptedFileData.length ? (
                                <div className="gene-set-container-block-drop-container files">
                                    <div className="selected-file">Selected File(s)</div>
                                    <ul>
                                        {acceptedFileData.map(file => (
                                            <li key={file.path}>
                                                {/* <span>{file.path} - {file.size} bytes</span> */}
                                                &#9632;
                                                <span className="title">{file.path} - {AppService.formatBytes(file.size)}</span>
                                                <span className="delete-item" onClick={() => onDelete(file)}></span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null
                        }
                        {
                            progress ? (
                                <div className="upload-process">
                                    <Progress percent={progress} status={uploadStatus} />
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default GeneSetModalBody;