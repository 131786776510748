export const colorCodes = [
    '#14CFB7', '#17A5D8', '#EA1717', '#CABFD9', '#CACA1D', '#EA6717', '#EAAA17', '#A6A296', '#7F3D1C', '#96A82F', '#53880A', '#2DE316', '#16E397', '#1C9B6C',
    '#46D9DA', '#A117D8', '#D296D5', '#C83181', '#5E2DA8', '#F7F703', "#568E47", "#38DD7A", "#BC3B4F", "#BB52EC", "#7215D0", "#447858", "#837BC5", "#364A2D",
    "#1613C6", "#FDE926", "#719CB1", "#5D28DD", "#10D6B4", "#E0C64E", "#C25585", "#519CAC", "#C1E123", "#6594E9", "#5546A8", "#483E47", "#56B686", "#489306",
    "#4FDA3E", "#652AF1", "#067799", "#5786A3", "#75763A", "#3FEE5A", "#2CC90C", "#CD64CC", "#5EA46E", "#EE4227", "#9E26BC", "#82A188", "#A5A36A", "#0EE2F9",
    "#7DB448", "#FD19CC", "#EA961F", "#2C5924", "#881573", "#5BF93D", "#B13522", "#8AFE9C", "#F705F8", "#018C92", "#7C579D", "#C62B88", "#3527AC", "#FFEEA1",
    "#8B9673", "#E291CE", "#976AA1", "#46B5C9", "#DACE7A", "#24D2C6", "#C71B8C", "#963E20", "#82B4DA", "#714E74", "#BDC237", "#B9112A", "#6EBA43", "#57652F",
    "#9A35A7", "#D563F7", "#D45899", "#72C4DE", "#8314AC", "#79EB61", "#63E898", "#7C0B06", "#36DB35", "#C39E57", "#BABB4C", "#15B142", "#2286FD", "#BDDF19",
    "#99B32C", "#EDA109", "#A49A59", "#7A8D44", "#6B85F6", "#E04F57", "#D85E41", "#E36BED", "#1A65AE", "#212DB3", "#916D24", "#5AE21A", "#4E2185", "#48E5CA",
    "#79E386", "#6EDC14", "#D5A623", "#E466DF", "#A83B88", "#565D2B", "#A9B529", "#CFDB86", "#9D4C91", "#1CB4BC", "#10555B", "#B2BE0E", "#E24E1B", "#70DC82",
    "#328A6E", "#D92E21", "#A7D860", "#96D0BB", "#E14B77", "#831D4E", "#C1C238", "#03DE67", "#919474", "#ECA891", "#70315A", "#ADC182", "#71575D", "#452388",
    "#922C82", "#59E836", "#BE4EF7", "#B483E1", "#EB983D", "#1B69DB", "#14CABB", "#EE8DE8", "#AAC104", "#F973B4", "#02377D", "#772EA9", "#B1DAA5", "#55E881",
    "#00EA33", "#64D586", "#42C3F9", "#B52296", "#7CA8C4", "#B5B8EC", "#E15B19", "#639687", "#1C7EBB", "#79D38E", "#D0B3DA", "#89B813", "#87D51B", "#A44FDD",
    "#E29CD3", "#255F6D", "#51B2C7", "#BC54D0", "#F87B32", "#BEC17E", "#546432", "#95C2C7", "#34E2C2", "#7AA481", "#72D3DE", "#BC8A4B", "#2F615D", "#58689D",
    "#CC48D1", "#0EC476", "#413ECF", "#D2142D", "#1C4287", "#E7A4E0", "#DAAEC6", "#EB20AB", "#4364B1", "#15AD3C", "#45AE68", "#669807", "#E68524", "#4B0E96",
    "#02F18B", "#4A9594", "#6AE72C", "#831DDA", "#733ABA", "#E1A364", "#D8E4B4", "#2678CA", "#A3CD6A", "#C373A6", "#B01D79", "#AFA4E7", "#B23585", "#4ECCB3",
    "#323214", "#A6E504", "#29EC85", "#C9226B", "#101746", "#71AC8A", "#C94BE7", "#ADE707", "#4936CA", "#1788E5"
];