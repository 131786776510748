/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import FormInputComponent from '../../../../../../core/components/form-input/form-input.component';
import formValidityCheck from '../../../../../../core/utils/validator';
import newUserForm from './forms/new-user-modal.forms';
import './new-user-modal.styles.scss';

const NewUserModal = ({
    changeTrigger,
    modalData
}) => {
    const [userForm, setUserForm] = useState({
        formData: { ...newUserForm },
        isFormValid: false
    });
    const { formData } = userForm;
    const style = { width: '260px', height: '36px' };
    const TYPES = ['LAB']; // ['LAB', 'UCSF USER']; // ['UCSF user', 'Admin', 'Lab'];

    useEffect(() => {
        resetFormValues();
        if (modalData) {
            populateEditValues(modalData);
        }
    }, [modalData]);

    const resetFormValues = () => {
        const updatedUserForm = { ...formData };
        for (let identifier in updatedUserForm) {
            updatedUserForm[identifier].value = '';
            updatedUserForm[identifier].valid = false;
            if (identifier === 'availability') {
                updatedUserForm[identifier].value = 'LAB';
                updatedUserForm[identifier].valid = true;
            }
        }
        setUserForm({ formData: updatedUserForm });
        changeTrigger({ formData: updatedUserForm, isFormValid: false });
    };

    const populateEditValues = (modalData) => {
        let formIsValid = true;
        const editData = {
            id: modalData['id'],
            userName: modalData['name'],
            email: modalData['email'],
            availability: modalData['visibility']
        };
        const updatedUserForm = { ...formData };
        for (let identifier in updatedUserForm) {
            updatedUserForm[identifier].value = editData[identifier];
            updatedUserForm[identifier].valid = editData[identifier] ? true : false;
            if (updatedUserForm[identifier].validation?.required) {
                formIsValid = updatedUserForm[identifier].valid && formIsValid;
            }
        }
        if (editData.type === 'availability') {
            updatedUserForm['possibleValues'].elementConfig.disabled = false;
        }
        setUserForm({ formData: updatedUserForm });
        changeTrigger({ formData: updatedUserForm, isFormValid: formIsValid });
    };

    const inputChangedHandler = (event, identifier) => {
        let formIsValid = true;
        const updatedUserForm = { ...formData };
        const updatedFormElement = { ...updatedUserForm[identifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedUserForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation['message'] = errorMessage;
        updatedFormElement.touched = true;
        updatedUserForm[identifier] = updatedFormElement;
        for (let identifier in updatedUserForm) {
            if (updatedUserForm[identifier].validation?.required) {
                formIsValid = updatedUserForm[identifier].valid && formIsValid;
            }
        }
        setUserForm({ formData: updatedUserForm, isFormValid: formIsValid });
        changeTrigger({ formData: updatedUserForm, isFormValid: formIsValid });
    };

    const selectValue = (event, identifier) => {
        if (event) {
            let formIsValid = true;
            const updatedUserForm = { ...formData };
            const updatedFormElement = { ...updatedUserForm[identifier] };
            updatedFormElement.value = (event && event?.id ? event.id : (event?.name) ? event.name : event ? event : updatedFormElement?.value ? updatedFormElement.value : '');
            const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedUserForm);
            updatedFormElement.valid = validity;
            updatedFormElement.validation['message'] = errorMessage;
            updatedFormElement.touched = true;
            updatedUserForm[identifier] = updatedFormElement;
            setUserForm({ formData: updatedUserForm, isFormValid: formIsValid });
            changeTrigger({ formData: updatedUserForm, isFormValid: formIsValid });
            for (let identifier in updatedUserForm) {
                if (updatedUserForm[identifier].validation?.required) {
                    formIsValid = updatedUserForm[identifier].valid && formIsValid;
                }
            }
            setUserForm({ formData: updatedUserForm, isFormValid: formIsValid });
            changeTrigger({ formData: updatedUserForm, isFormValid: formIsValid });
        }
    };

    return (
        <div className="user-form-container">
            <FormInputComponent
                tabIndex={1}
                style={style}
                label={formData.userName.label}
                elementType={formData.userName.elementType}
                elementConfig={formData.userName.elementConfig}
                invalid={!formData.userName.valid}
                shouldValidate={formData.userName.validation}
                touched={formData.userName.touched}
                errorMessage={formData.userName.validation.message}
                value={formData.userName.value}
                changed={(event) => inputChangedHandler(event, 'userName')}
                blurred={(event) => inputChangedHandler(event, 'userName')} />
            <FormInputComponent
                tabIndex={2}
                style={style}
                label={formData.email.label}
                elementType={formData.email.elementType}
                elementConfig={formData.email.elementConfig}
                invalid={!formData.email.valid}
                shouldValidate={formData.email.validation}
                touched={formData.email.touched}
                errorMessage={formData.email.validation.message}
                value={formData.email.value}
                changed={(event) => inputChangedHandler(event, 'email')}
                blurred={(event) => inputChangedHandler(event, 'email')} />
            <FormInputComponent
                tabIndex={3}
                style={style}
                idSelected={formData.availability.value}
                label={formData.availability.label}
                elementType={formData.availability.elementType}
                elementConfig={formData.availability.elementConfig}
                isToggled={formData.availability.toggle}
                invalid={!formData.availability.valid}
                shouldValidate={formData.availability.validation}
                touched={formData.availability.touched}
                errorMessage={formData.availability.validation.message}
                list={TYPES}
                selectVal={(event) => selectValue(event, 'availability')}
                blurred={(event) => selectValue(event, 'availability')} />
        </div>
    );
};

export default NewUserModal;