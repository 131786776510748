import React from 'react';
import './pre-auth-layout.styles.scss';

const PreAuthLayoutComponent = ({
    isNormal = false,
    routeType = '',
    children,
    className = '',
    errorClass = ''
}) => {
    return (
        <div className="pre-auth-background">
            <div className="title">
                OMICON
            </div>
            {
                isNormal ?
                    <div className="normal-container">
                        {children}
                    </div> :
                    <div className={`input-container${routeType ? ` ${routeType}` : ''}${className ? (' ' + className) : ''}`}>
                        {
                            isNormal ?
                                null :
                                <div className={`within-container${(errorClass) ? ' error' : ''}`}> {children} </div>
                        }
                    </div>
            }
        </div>
    );
};

export default PreAuthLayoutComponent;