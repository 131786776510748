/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react'
import PreAuthLayoutComponent from '../../../core/components/pre-auth-layout/pre-auth-layout.component'
import AppService from '../../../core/services/app-service';
import { ucsfLoginInstance } from '../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import { useState } from 'react';
import InLoader from '../../../core/components/loaders/component-loader/component-loader.component';

const UserVerificationComponent = (props) => {
    const { location } = props;

    // REACT STATE HOOKS
    const [inPageLoader, setInPageLoader] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [invalidToken, setInvalidToken] = useState(false);

    let queryParams = null, userToken = '';
    if (location) {
        queryParams = AppService.fetchQueryParams(location.search);
        userToken = queryParams?.token;
    }

    useEffect(() => {
        setInvalidToken(false);
        if (userToken) {
            setInPageLoader(true);
            ucsfLoginInstance.post(COMPONENT_APIS.EMAIL_VERIFICATION, { token: userToken }).then(res => {
                setInPageLoader(false);
                setVerificationStatus(res.data);
            }).catch(err => {
                setInPageLoader(false);
                setVerificationStatus(null);
            });
        } else {
            setInvalidToken(true);
        }
    }, [userToken]);

    return (
        <PreAuthLayoutComponent isNormal={false} className='user-verification' errorClass={(verificationStatus) ? '' : (inPageLoader) ? '' : 'error'}>
            {
                (inPageLoader) ?
                    <InLoader loaderText="Verifying User"></InLoader> :
                    (verificationStatus) ?
                        "User verified successfully!" :
                        (invalidToken) ?
                            "Invalid or no token available!" :
                            "User verification unsuccessful!"
            }
        </PreAuthLayoutComponent>
    )
};

export default UserVerificationComponent;
