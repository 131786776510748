/* eslint-disable no-unused-vars */
import _ from 'lodash';

export const searchDataList = (searchDataStateList = [], payload) => {
    if (payload && Array.isArray(payload)) {
        return payload;
    } else {
        let { actionType = null } = payload;
        if (searchDataStateList && searchDataStateList.length && actionType === 'clear-one') {
            let filteredSearchDataList = searchDataStateList.filter(searchData => (searchData.notifId !== payload.notifId)) || [];
            return JSON.parse(JSON.stringify(filteredSearchDataList));
        } else if (searchDataStateList && searchDataStateList.length && (actionType === 'clear-all' || actionType === 'clear-others')) {
            searchDataStateList = [];
        } else {
            searchDataStateList = [...searchDataStateList, _.cloneDeep(payload)];
        }
    }
    return searchDataStateList;
}