import React, { useEffect } from 'react';
import TermsOfService from '../../../../pages/post-auth/home/tos/tos.component';
import { ucsfInstance } from '../../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../../core/utils/omi-api';
import AppService from '../../../../core/services/app-service';

const TOSModal = ({ isTosAccepted }) => {
    useEffect(() => {
        isTosAccepted && ucsfInstance.put(COMPONENT_APIS.ACCEPT_TOS, {}).then((response) => {
            const userObject = JSON.parse(AppService.getStorageValue('user'));
            userObject.user.is_accepted_TOS = true;
            AppService.setStorageValue('user', userObject)
        });
    }, [isTosAccepted])
    return <div className='tos-wrapper'><TermsOfService /></div>
}

export default TOSModal;