import React from 'react';
import Aux from '../auxiliary/auxiliary';
import './custom-button.styles.scss';

const CustomButton = ({
    children,
    click,
    className = "cloud",
    size = 'small',
    inverted = '',
    btnType = '',
    ...otherProps
}) => {
    return (
        <button
            className={`omi-btn ${size} ${inverted} ${btnType}`}
            onClick={click}
            {...otherProps}>
            {
                btnType === 'upload' ? (
                    <Aux>
                        <span className={className}></span>
                        <span className="omi-btn-txt">{children}</span>
                    </Aux>
                ) : children
            }
        </button>
    );
};

export default React.memo(CustomButton);