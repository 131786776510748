export const collectionsActionTypes = {
    SET_SELECTED_COLLECTION_ITEM: 'SET_SELECTED_COLLECTION_ITEM',
    SET_COLLECTION_LIST_DATA: 'SET_COLLECTION_LIST_DATA',
    SET_COLLECTION_SEARCH_VAL: 'SET_COLLECTION_SEARCH_VAL',
    SET_SAMPLE_ATTRIBUTE_VALUES: 'SET_SAMPLE_ATTRIBUTE_VALUES',
    SET_SELECTED_SAMPLE_ATTRIBUTE_SET: 'SET_SELECTED_SAMPLE_ATTRIBUTE_SET',
    SET_MAIN_ROUTE_TITLES: 'SET_MAIN_ROUTE_TITLES',
    SET_ADDED_SAMPLE_ATTRIBUTE_SET: 'SET_ADDED_SAMPLE_ATTRIBUTE_SET',
    SET_SAMPLE_ATTRIBUTE_STATE: 'SET_SAMPLE_ATTRIBUTE_STATE',
    SET_DS_SELECTION: 'SET_DS_SELECTION',
    SET_IS_DATASET: 'SET_IS_DATASET',
    SET_DS_ROUTE_PATH: 'SET_DS_ROUTE_PATH',
    SET_POSSIBLE_VALUES: 'SET_POSSIBLE_VALUES',
    SET_DS_ROUTE_TITLES: 'SET_DS_ROUTE_TITLES',
    SET_DC_ROUTE_TITLES: 'SET_DC_ROUTE_TITLES',
    SET_DC_ROUTE_PATH: 'SET_DC_ROUTE_PATH',
    SET_ATTRIBUTE_FILES: 'SET_ATTRIBUTE_FILES',
    SET_DATA_MENU_TRIGGER: 'SET_DATA_MENU_TRIGGER',
    SET_COLLECTION_DATA: 'SET_COLLECTION_DATA'
};
