import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ucsfInstance } from '../../../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../../../core/utils/omi-api';
import { setCommentNotificationData } from '../../../../../redux/notifications/notifications.action';
import { setDownloadNotifs } from '../../../../../redux/user/user.actions';
import { getCommentNotificationData } from '../../../../../redux/notifications/notification.selector';
import { setLoader } from '../../../../../redux/app/app.actions';
import OmiGrid from '../../../../../core/components/omi-grid/omi-grid.component';
import './comment-notifications.scss';

const CommentNotifications = (props) => {
    let {
        setCommentNotificationData,
        commentNotificationData,
        setLoader,
    } = props;
   const [apiResponseData, setApiResponseData] = useState(null);
   const [pageIndex, setPageIndex] = useState(0);
    useEffect(() => {
        listCommentNotifications();
    }, []);
    useEffect(() => {
        commentNotificationData?.mention_notifications?.length > 0 && commentNotificationData?.mention_notifications?.map(notif => {
               notif['comment_details'] = notif.comment_details.replaceAll('<span class=\"mention-link\">', ' ').replaceAll('</span>', ' ');
               return notif;
            });
            setApiResponseData({
                ...commentNotificationData,
                selectedPage: (commentNotificationData?.total < commentNotificationData?.items_per_page) ? 0 : pageIndex
            });
    }, [commentNotificationData]);
    const listCommentNotifications = async (page = 0) => {
        setLoader(true);
        setPageIndex(page);
        await ucsfInstance.post(COMPONENT_APIS.LIST_COMMENT_NOTIFICATIONS, {
            page: typeof(page) === 'object' ? page.selectedPage : page
        }).then(res => {
            setCommentNotificationData(res.data);
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            setCommentNotificationData({});
        });
    };
    const markAsRead = async (row) => {
        localStorage.setItem('parent', row.parent_id || row.comment);
        window.location.href = row.comment_page_url;
        ucsfInstance.put(`${COMPONENT_APIS.MARK_MENTION_NOTIFICATION_AS_READ}${row.notification_id}`, {});
    }
    return <div className="search-notification-container">
        <div className="title">Comment Notification</div>
            <OmiGrid
                hasCheckBox={false}
                apiResponseData={{ ...commentNotificationData, selectedPage: pageIndex }}
                borderedGridDataLimit={commentNotificationData?.items_per_page ? commentNotificationData.items_per_page : 50}
                totalPageCount={apiResponseData?.total}
                type="commentNotifications"
                hasServerPagination={true}
                sortPageRecords={(val) => listCommentNotifications(val)}
                linkClicked={row => markAsRead(row)}
                {...props} />

    </div>
    
};

const mapStateToProps = createStructuredSelector({
    commentNotificationData: getCommentNotificationData,
});

const mapDispatchToProps = (dispatch) => ({
    setCommentNotificationData: (collectionListData) => dispatch(setCommentNotificationData(collectionListData)),
    setLoader: (val) => dispatch(setLoader(val)),
    clearDownloadNotif: (data) => dispatch(setDownloadNotifs(data, 'clear-one')),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CommentNotifications));