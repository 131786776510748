import React from 'react';
import './report-bug.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setModalData } from '../../../redux/modal/modal.actions';
import { fetchCurrentUser } from '../../../redux/user/user.selectors';

const ReportBug = ({ className, isLink, history }) => {
    let classNameValue = className ? className = ` ${className}` : className;
    const d = useDispatch();
    const currentUser = useSelector(fetchCurrentUser);

    const handleReportugClick = () => {
        const reportBugModalData = {
            type: 'report-bug',
            modalData: isLink ? currentUser : null,
            data: {
                title: 'REPORT BUG/FEATURE',
                btnText: 'SUBMIT'
            }
        };
        d(setModalData(reportBugModalData));
    }

    const handleAboutClick = () => {
        history.push('/home/about')
    }

    const handleHelpClick = () => {
        history.push('/home/help')
    }

    const handleTOSClick = () => {
        history.push('/home/termsofservice')
    }

    return (
        <div className={`about-help-feedback-wrapper report-bug-container${classNameValue}`}>
            {
                !isLink &&
                <div className='pre-auth-feedback' onClick={handleReportugClick}>
                    Feedback
                </div>
            }
            {
                isLink &&
                <><div className={classNameValue} onClick={handleAboutClick}>About</div>
                    <div className={classNameValue} onClick={handleHelpClick}>Help</div>
                    <div className={classNameValue} onClick={handleTOSClick}>TOS</div>
                    <div className={classNameValue} onClick={handleReportugClick}>
                        Feedback
                    </div></>

            }
        </div>
    );
}

export default ReportBug;