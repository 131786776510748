import React from 'react';
// import { useDispatch } from 'react-redux';
// import { setModalData } from '../../../../redux/modal/modal.actions';
// import { preAuthBtnStyle } from '../../../style-config/elements.component';
// import CustomButton from '../../custom-button/custom-button.component';
import './permission.styles.scss';

const PermissionModal = ({ history }) => {

    // let d = useDispatch();

    return (
        <div className='perm-modal-container'>
            <div className='perm-modal-container-desc'>You need to login to download/modify contents of the system. Please login to using you login credentials or register as public user to further access the content.</div>
            {/* <div>
                <CustomButton
                    type="button"
                    inverted="inverted"
                    style={preAuthBtnStyle}
                    click={(e) => history.push('/')}>
                    LOGIN
                </CustomButton>
                <CustomButton
                    type="button"
                    inverted="inverted"
                    style={preAuthBtnStyle}
                    click={(e) => d(setModalData(null))}>
                    CANCEL
                </CustomButton>
            </div> */}
        </div>
    );
}

export default PermissionModal;