const registerForm = {
    userName: {
        label: 'User Name',
        description: 'user name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'User Name'
        },
        value: '',
        validation: {
            required: true,
            isEmail: false,
            message: 'Enter user name'
        },
        valid: false,
        touched: false
    },
    firstName: {
        label: 'First Name',
        description: 'first name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'First Name'
        },
        value: '',
        validation: {
            required: true,
            isEmail: false,
            message: 'Enter first name'
        },
        valid: false,
        touched: false
    },
    lastName: {
        label: 'Last Name',
        description: 'last name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Last Name'
        },
        value: '',
        validation: {
            required: true,
            isEmail: false,
            message: 'Enter last name'
        },
        valid: false,
        touched: false
    },
    email: {
        label: 'Email',
        description: 'email address',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email Address'
        },
        value: '',
        validation: {
            required: true,
            isEmail: true,
            message: 'Enter email address'
        },
        valid: false,
        touched: false
    },
    password: {
        label: 'Password',
        description: 'password',
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Password'
        },
        value: '',
        validation: {
            required: true,
            isPassword: true,
            minLength: 8,
            message: 'Enter password'
        },
        valid: false,
        touched: false
    },
    confirmPassword: {
        label: 'Confirm Password',
        description: null,
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Confirm Password'
        },
        value: '',
        validation: {
            required: true,
            isConfirmPassword: true,
            minLength: 8,
            message: 'Passwords don\'t match'
        },
        valid: false,
        touched: false
    },
    institution: {
        label: 'Institution',
        description: 'instituion',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Institution'
        },
        value: '',
        validation: {
            required: false,
            isEmail: false,
            message: 'Enter institution'
        },
        valid: true,
        touched: true
    },
    orcidID: {
        label: 'ORCID ID',
        description: 'ORCID ID',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'ORCID ID'
        },
        value: '',
        validation: {
            required: false,
            isEmail: false,
            message: 'Enter orcid id'
        },
        valid: true,
        touched: true
    },
};

export default registerForm;