/* eslint-disable react/style-prop-object */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import useStateRef from 'react-usestateref';
import { useDispatch } from 'react-redux';
import './file-content.styles.scss';

import jspreadsheet from "jspreadsheet-ce";
import "../../../../../node_modules/jspreadsheet-ce/dist/jexcel.css";
import CustomButton from '../../custom-button/custom-button.component';
import { setLoader } from '../../../../redux/app/app.actions';
import AppService from '../../../services/app-service';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { setModalData } from '../../../../redux/modal/modal.actions';
import { Fragment } from 'react';
import InLoader from '../../loaders/component-loader/component-loader.component';
import PDFViewer from 'mgr-pdf-viewer-react';
import { useState } from 'react';

const FilePreviewContent = (props) => {
    const {
        data: { title },
        modalData
    } = props;
    const [contentLoaded, setContentLoaded, contentLoadedRef] = useStateRef(false);
    const [isCSV, setIsCSV, isCSVRef] = useStateRef(false);
    const [timeInterVal, setTimeInterval, timeInterValRef] = useStateRef(null);
    const [linkUrl, setLinkUrl, linkUrlRef] = useStateRef('');
    const [docBody, setDocBody] = useStateRef({
        uri: modalData.url,
        fileType: 'txt'
    });
    const [isAboveLimit, setIsAboveLimit] = useStateRef(false);
    const jRef = useRef(null);
    const [jssOptions, setJssOptions, jssOptionsRef] = useStateRef({
        // csv: modalData.url,
        // csvHeaders: true,
        tableOverflow: true,
        lazyLoading: true,
        loadingSpin: true,
        minDimensions: [20, 25],
        rowResize: true,
        updateTable: function (instance, cell, col, row, val, label, cellName) {
            cell.className = 'sheet-cell';
        }
    });
    const dispatch = useDispatch();
    const gotoPage = useRef(null);
    const [pageNumber, setPageNumber] = useState(modalData?.page || 1);
    const params = {
        "Parameters": [{
            "Name": "File",
            "FileValue": { "Url": "" }
        }, {
            "Name": "StoreFile",
            "Value": true
        }]
    };

    const getBaseURl = () => {
        if (modalData.fileType === 'csv') {
            return 'https://v2.convertapi.com/convert/csv/to/xls?Secret=M5mlvSCix67Oy7C6&StoreFile=true'
        } else if (modalData.fileType === 'txt') {
            return 'https://v2.convertapi.com/convert/txt/to/pdf?Secret=M5mlvSCix67Oy7C6&StoreFile=true'
        }
    };

    const urlInstance = axios.create({
        baseURL: modalData.url,
        withCredentials: false
    });

    const toArrayOfArrays = (res) => {
        const csvArray = [];
        res.data.forEach(valArray => {
            const objArray = [];
            valArray.forEach(val => {
                const obj = { value: val };
                objArray.push(obj);
                objArray.push(val);

            });
            if (valArray.length > 0 && valArray.length !== 7) {
                const len = 7 - valArray.length;
                for (let i = 0; i < len; i++) {
                    objArray.push({ value: '' });
                }
            }
            csvArray.push(objArray);
        });
        return csvArray;
    };

    const fileSizeChecker = () => {
        let exceedsLimit = false;
        setIsAboveLimit(false);
        if (
            modalData.sizeDisplayName &&
            (
                modalData.sizeDisplayName.includes('MB') ||
                modalData.sizeDisplayName.includes('GB') ||
                modalData.sizeDisplayName.includes('TB') ||
                modalData.sizeDisplayName.includes('PB')
            )
        ) {
            const valArray = modalData.sizeDisplayName.split(' ');
            const val = Number(valArray[0]);
            if (val > 1) {
                setIsAboveLimit(true);
                exceedsLimit = true;
            }
        }
        return exceedsLimit;
    };

    const setLinkForIframe = (dataLink) => {
        setLinkUrl(null);
        setTimeInterval(setTimeout(() => {
            setLinkUrl('');
            setLinkUrl(dataLink);
            window.open(dataLink, '_blank');
            dispatch(setLoader(false));
            dispatch(setModalData(null));
        }, 2500));
    };

    const extractURL = (url, fileType) => {
        if (url.includes('.txt') || url.includes('.pdf')) {
            const urlArray = url.split(fileType);
            const baseUrl = urlArray[0];
            const encodedURL = encodeURIComponent(urlArray[1]);
            return `${baseUrl}${fileType}${encodedURL}`;
        }
    };

    const onIframeLoaded = () => {
        dispatch(setLoader(false));
        clearInterval(timeInterVal);
        clearInterval(timeInterValRef.current);
        setTimeInterval(null);
    };

    const fetchDataForCSV = () => {
        if (modalData.csvGridData) {
            const csvGridData = modalData.csvGridData; // CSV_GRID_DATA;
            const columnArray = [], dataArray = [], arrayLen = [];
            modalData.columns.forEach((gridKey, index) => {
                if (index < 25) {
                    const valPixels = [];
                    let maxVal = 150;
                    Object.keys(csvGridData[gridKey]).forEach((valKey) => {
                        const valString = csvGridData[gridKey][valKey] ? csvGridData[gridKey][valKey].toString() : "";
                        valPixels.push(valString.length);
                    });
                    valPixels.sort((a, b) => (b - a));
                    maxVal += valPixels[0];
                    columnArray.push({ title: gridKey, width: `${gridKey.length + maxVal}px` }); // width: `${gridKey.length + 150}px`
                }
                arrayLen.push(Object.keys(csvGridData[gridKey]).length);
            });
            arrayLen.sort((a, b) => (b - a));
            for (let i = 0; i < arrayLen[0]; i++) {
                dataArray.push([]);
                modalData.columns.forEach((key, index) => {
                    if (index < 25) {
                        const keyVal = csvGridData[key][i] ? csvGridData[key][i] : null;
                        dataArray[i].push(keyVal);
                    }
                });
            }
            const preview = {
                columns: columnArray,
                data: dataArray
            };
            setJssOptions({ ...preview, ...jssOptions });
        }
    };

    const fileDownload = (rowData) => {
        dispatch(setLoader(true));
        AppService.getAwsCredentials().then(async res => {
            const s3Configuration = await {
                credentials: {
                    accessKeyId: res['AccessKeyId'],
                    secretAccessKey: res['SecretAccessKey'],
                    sessionToken: res['SessionToken']
                },
                region: res['region']
            };
            const s3 = await new S3Client(s3Configuration);
            const url = await getSignedUrl(s3, new GetObjectCommand({
                Bucket: res['bucket'],
                Key: rowData['key']
            }), {
                expiresIn: 15 * 60
            });
            AppService.downloadURL(url);
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        let dataLink = '';
        setIsCSV(false);
        setLinkUrl(dataLink);
        setDocBody({ uri: modalData.url });
        const encodedURL = encodeURIComponent(modalData.url);
        setTimeout(() => {
            switch (modalData.fileType) {
                case 'xls':
                case 'doc':
                case 'docx':
                    if (!modalData.name.includes('.csv')) {
                        dataLink = `https://view.officeapps.live.com/op/embed.aspx?src=${modalData.url}`;
                        setLinkUrl(dataLink);
                    }
                    break;
                case 'pdf':
                    // case 'text':
                    // case 'txt':
                    // DATALINK SETTER--------------------------------------------------------------
                    dataLink = `https://docs.google.com/viewer?url=${encodedURL}&embedded=true`;
                    // dataLink = `https://docs.google.com/gview?url=${encodedURL}&embedded=true`;
                    // dataLink = `https://drive.google.com/viewerng/viewer?url=${encodedURL}&embedded=true`;
                    // GOOGLE DRIVE LINK SETTER-----------------------------------------------------
                    // const driveUrl = extractURL(modalData.url, modalData.fileType);
                    // dataLink = `https://drive.google.com/viewerng/viewer?url=${driveUrl}&embedded=true`;
                    // -----------------------------------------------------------------------------
                    setTimeout(() => { setLinkUrl(dataLink); }, 500);
                    break;
                default:
                    break;
            }
            fetchDataForCSV();
            if (modalData.name && modalData.name.includes('.csv')) {
                if (!modalData.csvGridData) {
                    const jssOptionsData = { ...jssOptions };
                    jssOptionsData['csv'] = modalData.url;
                    jssOptionsData['csvHeaders'] = false;
                    jssOptionsData['colWidths'] = [150];
                    setJssOptions(jssOptionsData);
                }
                setIsCSV(true); // if (!fileSizeChecker()) {
                params.Parameters[0].FileValue.Url = modalData.url;
                if (!jRef.current.jspreadsheet) {
                    jspreadsheet(jRef.current, jssOptionsRef.current);
                } // }
                dispatch(setLoader(false));
            } else {
                // setLinkForIframe(dataLink);
                dispatch(setLoader(false));
            }
        }, 250);
        return () => {
            setLinkUrl('');
            clearInterval(timeInterVal);
            clearInterval(timeInterValRef.current);
            setContentLoaded(false);
        }
    }, [modalData]);

    const navigationElement = (e) => {
        let { page, pages, handleNextClick, handlePrevClick } = e;
        return (
            (Number(pages) > 0) && !(modalData['Omicon ID']) &&
            <div className='nav-elem'>
                <div className='lft-svg' onClick={() => {
                    if (handlePrevClick && ((e.page - 1) > 0)) { setPageNumber(e.page - 1); handlePrevClick(); }
                }}></div>
                <div><b>Page</b> {page} <b>of</b> {pages}</div>
                <div className='rgt-svg' onClick={() => {
                    if (handleNextClick && ((e.page + 1) <= pages)) { setPageNumber(e.page + 1); handleNextClick(); }
                }}></div>
                &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                <div>
                    <b>Goto page</b>
                    &nbsp;&nbsp;
                    <input id="page-num" ref={gotoPage} type='number' name='pagin' />
                    &nbsp;&nbsp;
                    <CustomButton click={() => {
                        if (gotoPage.current.value && gotoPage.current.value <= pages) setPageNumber(Number(gotoPage.current.value))
                    }}>GO</CustomButton>
                </div>
            </div>
        );
    };

    const iFrame = (object = false) => {
        const contentStyle = !contentLoaded ? { display: "none" } : {};
        let timeOut = setTimeout(() => {
            if (!contentLoadedRef.current) {
                AppService.renderFrame();
                clearTimeout(timeOut);
            }
        }, 30000);
        return (
            (!object) ?
                <Fragment>
                    {/* -------------------------------------IFRAME-PDF-RENDER------------------------------------- */}
                    {/* {!contentLoaded && <InLoader loaderText="Loading file content..." />} */}
                    {/* <iframe
                        id='file_content_viewer'
                        className="file-preview"
                        style={contentStyle}
                        src={linkUrlRef.current} // linkUrl
                        title={title}
                        onLoad={() => {
                            setContentLoaded(true);
                            onIframeLoaded();
                        }}>
                    </iframe> */}
                    {/* -------------------------------------REACT-PDF------------------------------------- */}
                    {/* <div className="file-preview">
                        <Document file={modalData.url} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>
                    </div> */}
                    {/* -------------------------------------PDF-VIEWER------------------------------------- */}
                    {/* -------------------UNCOMMENT FOR OMICON ID DISPLAY ---------------------------- */}
                    {
                        (modalData['Omicon ID'] || (modalData.omiconIDs && modalData.omiconIDs.length && modalData?.omiconIDs[pageNumber - 1])) &&
                        <div className='content-title'>
                            {modalData['Omicon ID'] || (modalData.omiconIDs[pageNumber - 1] || modalData.omiconIDs[0])}
                        </div>
                    }
                    <PDFViewer
                        loader={<InLoader loaderText="Loading file content..." />}
                        // css={`pdf-viewer-wrapper${((modalData['Omicon ID'] || modalData.omiconIDs) && Number(modalData?.page) === 1) ? ' full-height' : ''}`}
                        css='pdf-viewer-wrapper'
                        page={pageNumber}
                        document={{ url: modalData.url }}
                        navigation={navigationElement}
                    />
                </Fragment> :
                <div className="txt-view" dangerouslySetInnerHTML={{ __html: modalData.textData }}>
                </div>
        );
    }

    return (
        (!isCSVRef.current && (linkUrl !== '' && linkUrlRef.current)) ? (
            iFrame(false)
        ) : isAboveLimit ? (
            <div className="size-info-container">
                <div> Preview is not supported for files of more than size of 1MB. Please download to view the file.</div>
                <div>
                    <CustomButton
                        inverted='inverted'
                        style={{ height: '30px', width: '100px' }}
                        click={() => fileDownload(modalData)}>
                        Download
                    </CustomButton>
                </div>
            </div>
        ) : (isCSVRef.current) ?
            <div ref={jRef}></div> :
            iFrame(true)
    );
}

export default FilePreviewContent;