const subContentSearchFieldForm = {
    collectionsText: {
        description: '',
        elementType: 'listSearch',
        elementConfig: {
            type: 'text',
            placeholder: 'Search by accession ID, PubMed ID, or last name of publication first author'
        },
        value: '',
        selectedValue: 'all',
        validation: {
            required: false,
            isListSearch: true
        },
        valid: false,
        touched: false
    },
    analysesText: {
        description: '',
        elementType: 'listSearch',
        elementConfig: {
            type: 'text',
            placeholder: 'Search by accession ID, PubMed ID, or last name of publication first author'
        },
        value: '',
        selectedValue: 'all',
        validation: {
            required: false,
            isListSearch: true
        },
        valid: false,
        touched: false
    },
    networkSearchText: {
        description: '',
        elementType: 'listSearch',
        elementConfig: {
            type: 'text',
            placeholder: 'find container or transformation'
        },
        value: '',
        selectedValue: '',
        validation: {
            required: false,
            isListSearch: true
        },
        valid: false,
        touched: false
    }
};

export default subContentSearchFieldForm;