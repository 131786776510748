import { collectionsActionTypes } from "./collections.types";

export const setSelectedCollectionItem = (item) => ({
    type: collectionsActionTypes.SET_SELECTED_COLLECTION_ITEM,
    payload: item
});

export const setCollectionSearchVal = (item) => ({
    type: collectionsActionTypes.SET_COLLECTION_SEARCH_VAL,
    payload: item
});

export const setCollectionListDataValue = (data) => ({
    type: collectionsActionTypes.SET_COLLECTION_LIST_DATA,
    payload: data
});

export const setSampleAttributeValues = (data) => ({
    type: collectionsActionTypes.SET_SAMPLE_ATTRIBUTE_VALUES,
    payload: data
});

export const setSampleAttributeState = (data) => ({
    type: collectionsActionTypes.SET_SAMPLE_ATTRIBUTE_STATE,
    payload: data
});

export const setSelectedSampleAttributeSet = (data) => ({
    type: collectionsActionTypes.SET_SELECTED_SAMPLE_ATTRIBUTE_SET,
    payload: data
});

export const setMainRouteTitles = (data) => ({
    type: collectionsActionTypes.SET_MAIN_ROUTE_TITLES,
    payload: data
});

export const setIsDataset = (data) => ({
    type: collectionsActionTypes.SET_IS_DATASET,
    payload: data
});

export const setPossibleValues = (data) => ({
    type: collectionsActionTypes.SET_POSSIBLE_VALUES,
    payload: data
});

export const setAddedSampleAttributeSet = (data) => ({
    type: collectionsActionTypes.SET_ADDED_SAMPLE_ATTRIBUTE_SET,
    payload: data
});

export const setAttributeFiles = (data) => ({
    type: collectionsActionTypes.SET_ATTRIBUTE_FILES,
    payload: data
});

export const triggerDataMenu = (data) => ({
    type: collectionsActionTypes.SET_DATA_MENU_TRIGGER,
    payload: data
});

export const setCollectionDataInfo = (data) => ({
    type: collectionsActionTypes.SET_COLLECTION_DATA,
    payload: data
});

/* export const setDatasetSelection = (data) => ({
    type: collectionsActionTypes.SET_DS_SELECTION,
    payload: data
});

export const setCurrentDSRoute = pathData => ({
    type: collectionsActionTypes.SET_DS_ROUTE_PATH,
    payload: pathData
});

export const setCurrentDCRoute = pathData => ({
    type: collectionsActionTypes.SET_DC_ROUTE_PATH,
    payload: pathData
});

export const setDSRouteTitles = (data) => ({
    type: collectionsActionTypes.SET_DS_ROUTE_TITLES,
    payload: data
});

export const setDCRouteTitles = (data) => ({
    type: collectionsActionTypes.SET_DC_ROUTE_TITLES,
    payload: data
}); */