import { createSelector } from "reselect";

const analysesState = state => state.analyses;

export const getSelectedAnalysisDataItem = createSelector(
    [analysesState],
    (analyses) => analyses
);

export const getAnalysisListDataValue = createSelector(
    [analysesState],
    (analyses) => analyses?.analysesListData
);

export const getNewlyCreatedAnalysisId = createSelector(
    [analysesState],
    (analyses) => analyses?.newAnalysisIdCreated
);

export const getIsAnalysisMenuTriggered = createSelector(
    [analysesState],
    (analyses) => analyses?.analysisMenuTriggered
);

export const getAnalysisDataInfo = createSelector(
    [analysesState],
    (analyses) => analyses?.analysisDataInfo
);
