const loginFormData = {
    userName: {
        description: 'user name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            autoComplete: 'off',
            placeholder: 'User Name'
        },
        value: '',
        validation: {
            required: true,
            isEmail: false,
            message: 'Enter user name'
        },
        valid: true,
        touched: false
    },
    email: {
        description: 'email address',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            autoComplete: 'off',
            placeholder: 'Enter email address'
        },
        value: '',
        validation: {
            required: true,
            isEmail: true,
            message: 'Enter email address'
        },
        valid: true,
        touched: false
    },
    password: {
        description: 'password',
        elementType: 'input',
        elementConfig: {
            type: 'password',
            autoComplete: 'off',
            placeholder: 'Password'
        },
        value: '',
        validation: {
            required: true,
            isPassword: true,
            // minLength: 8,
            message: 'Enter password'
        },
        valid: false,
        touched: false
    }
};

export default loginFormData;