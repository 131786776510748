/* eslint-disable no-unused-vars */
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalSearchValForGeneSets } from '../../../redux/advanced-search/advanced-search.actions';
import { setSelectedAnalysisItem } from '../../../redux/analyses/analyses.actions';
import { setLoader } from '../../../redux/app/app.actions';
import { setModalData } from '../../../redux/modal/modal.actions';
import { setExactMatchGlobalSearchData, setUserGlobalSearchData } from '../../../redux/user/user.actions';
import { ucsfInstance } from '../../axios/ucsf';
import AppService from '../../services/app-service';
import { COMPONENT_APIS } from '../../utils/omi-api';
import FormInput from '../form-input/form-input.component';
import Grid from '../omi-grid/omi-grid.methods';
import globalSearchFormData from './forms/global-search.forms';
import './global-search.styles.scss';

const globalSearchLocations = [
    { type: 'all', display: 'All' },
    { type: 'files', display: 'Files' },
    { type: 'folders', display: 'Folders' }
];

const GlobalSearch = ({
    history
}) => {
    const d = useDispatch();
    const [globalSearch, setGlobalSearchData] = useState({
        formData: { ...globalSearchFormData }
    });
    const { formData } = globalSearch;
    const [omiconIdGlobalSearchResults, setOmiconIdGlobalSearchResults] = useState(null);
    const [globalSearchResults, setGlobalSearchResults] = useState({
        dataCollections: [],
        analyses: [],
        omiconIdSearchResults: null,
        fileTypeResults: null
    });

    const inputChangedHandler = (event, identifier) => {
        const updatedGlobalSearchFormData = { ...formData };
        const updatedForm = { ...globalSearchFormData[identifier] };
        updatedForm.value = event.target.value;
        updatedGlobalSearchFormData[identifier] = updatedForm;
        setGlobalSearchData({ formData: updatedGlobalSearchFormData });
    };

    const inputSelectChangedHandler = (event, identifier) => {
        const updatedGlobalSearchFormData = { ...formData };
        const updatedForm = { ...globalSearchFormData[identifier] };
        updatedForm.selectedValue = event.target.value;
        updatedGlobalSearchFormData[identifier] = updatedForm;
        setGlobalSearchData({ formData: updatedGlobalSearchFormData });
    };

    const globalSearchLocationSelection = (event, identifier = 'text') => {
        const updatedGlobalSearchFormData = { ...formData };
        const updatedForm = { ...globalSearchFormData[identifier] };
        updatedForm.selectedValue = event.type;
        updatedGlobalSearchFormData[identifier] = updatedForm;
        setGlobalSearchData({ formData: updatedGlobalSearchFormData });
    };

    const openAddModal = (modalData = null) => {
        const name = modalData.name ? modalData.name : modalData.key.split('/')[1];
        modalData['name'] = name;
        d(setModalData({
            type: 'file-preview',
            modalData,
            data: { title: name }
        }));
    };

    const awsFolderDownload = (type, rowData) => {
        AppService.getAwsCredentials().then(async res => {
            const s3Configuration = await AppService.credentialObj(res);
            if (rowData['fileType'] && s3Configuration.credentials && s3Configuration.credentials.accessKeyId) {
                const s3 = await new S3Client(s3Configuration);
                const url = await getSignedUrl(s3, new GetObjectCommand({
                    Bucket: res['bucket'],
                    Key: rowData['key']
                }), {
                    expiresIn: 30 * 60
                });
                if (type === 'download') {
                    d(setLoader(false));
                    history.push(url, rowData['key']);
                } else {
                    if (url.toLowerCase().includes('.r') || url.includes('.txt')) {
                        fetch(url).then(res => res.text()).then(rcode => {
                            openAddModal({ ...rowData, url, fileType: rowData['fileType'], textData: rcode.replace(/\n/g, "<br />") });
                        });
                    } else {
                        openAddModal({
                            ...rowData,
                            url,
                            type: 'filePreview',
                            fileType: rowData['fileType']
                        });
                    }
                }
            } else {
                d(setLoader(false));
            }
        }).catch(err => {
            d(setLoader(false));
        });
    };

    const redirectToExactMatchPage = (responseData) => {
        let { type, results } = responseData;
        let resultType = type?.toLowerCase(),
            rowObject = { ...results[0], ...(results[0]['attributes'] || {}) },
            resultTypes = ['covariation modules'];
        if (resultType && (resultTypes.indexOf(resultType.toLowerCase()) >= 0)) {
            if (rowObject['Covariation Network'] || rowObject['Covariation Network Omicon ID']) {
                d(setLoader(true));
                let payload = {
                    project_id: rowObject['project_id'],
                    covariation_network: rowObject['Covariation Network'] || rowObject['Covariation Network Omicon ID'],
                    module: rowObject['Module']
                };
                ucsfInstance.post(COMPONENT_APIS.COVARIATION_MODULES_REDIRECT, payload).then(({ data }) => {
                    data['fileType'] = 'pdf';
                    data['name'] = data['key'];
                    awsFolderDownload('preview', data);
                }).catch(err => {
                    AppService.setNotification({ title: 'Module not found. Please try later.', type: 'danger' });
                    d(setLoader(false));
                });
            }
        } else {
            d(setExactMatchGlobalSearchData(responseData));
            history.push(`/home/global-search-results`);
        }
    };

    const searchClick = () => {
        let { text } = formData;
        let globalSearchResultsData = { ...globalSearchResults };
        let searchFormatStringList = ['.gs.', '.ds.', '.dc.', '.p.', '.cm.', '.cn.', '.a.'];
        let omiconIdTypeIndex = searchFormatStringList.findIndex(val => text['value'].trim().toLowerCase().includes(val.toLowerCase()));
        if (text['value'] && text['value'].trim() !== '' && omiconIdTypeIndex < 0) {
            d(setLoader(true));
            text['value'] = text['value'].trim();
            const getDCnAList = () => {
                ucsfInstance.get(`${COMPONENT_APIS.DATA_COLLECTIONS}?search=${text['value']}`).then(dcRes => {
                    globalSearchResultsData = ({ ...globalSearchResultsData, dataCollections: dcRes['data'] });
                    ucsfInstance.get(`${COMPONENT_APIS.SEARCH_ANALYSES}?search=${text['value']}`).then(analysesRes => {
                        globalSearchResultsData = ({ ...globalSearchResultsData, analyses: analysesRes['data'] });
                        setGlobalSearchResults(globalSearchResultsData);
                        d(setUserGlobalSearchData({ ...globalSearchResultsData, omiconIdSearchResults: { omiconId: text['value'] } }));
                        history.push(`/home/global-search-results`);
                        d(setLoader(false));
                    }).catch((error) => {
                        setGlobalSearchResults({ ...globalSearchResultsData, analyses: [] });
                        d(setLoader(false));
                    });
                }).catch((error) => {
                    setGlobalSearchResults({ ...globalSearchResultsData, dataCollections: [] });
                    d(setLoader(false));
                });
            }
            ucsfInstance.get(COMPONENT_APIS.FILE_SEARCH(text['value'])).then(({ data }) => {
                if (Array.isArray(data) && data.length && data[0] === 'Invalid File Name') {
                    getDCnAList();
                } else {
                    d(setLoader(false));
                    let res = { fileSet: data, fileName: text['value'] };
                    d(setUserGlobalSearchData({ ...globalSearchResultsData, omiconIdSearchResults: null, fileTypeResults: res }));
                    history.push(`/home/global-search-results`);
                }
            });
        } else if (text['value'] && text['value'].trim() !== '' && omiconIdTypeIndex >= 0) {
            d(setLoader(true));
            text['value'] = text['value'].trim();
            ucsfInstance.get(COMPONENT_APIS.OMICONID_SEARCH(text['value'].toUpperCase())).then(({ data }) => {
                let responseData = data.results || data;
                d(setLoader(false));
                // ERROR RESPONSE METHOD ----------------------------------------------
                const searchResponseError = () => {
                    AppService.setNotification({
                        title: 'Error',
                        message: responseData[0] || 'Invalid Omicon ID!',
                        type: 'danger'
                    });
                    d(setUserGlobalSearchData({
                        dataCollections: [],
                        analyses: [],
                        omiconIdSearchResults: { omiconId: text['value'] }
                    }));
                    if (!history?.location?.pathname.includes('global-search-results')) {
                        history.push(`/home/global-search-results`);
                    }
                }
                // --------------------------------------------------------------------
                d(setExactMatchGlobalSearchData(null));
                if (searchFormatStringList[omiconIdTypeIndex] === '.gs.') {
                    if (data && data.type) {
                        d(setGlobalSearchValForGeneSets(text['value']));
                        if (!history?.location?.pathname.includes('resources/gene-set')) {
                            history.push('/home/resources/gene-set');
                        }
                    } else {
                        searchResponseError();
                    }
                } else if (responseData && responseData.length > 1) {
                    let res = { ...data, omiconId: text['value'] };
                    d(setUserGlobalSearchData({ ...globalSearchResultsData, omiconIdSearchResults: res }));
                    history.push(`/home/global-search-results`);
                } else if (responseData && responseData.length === 1) {
                    if (data && data.type) {
                        redirectToExactMatchPage(data);
                    } else {
                        searchResponseError();
                    }
                }
            }).catch(err => {
                d(setLoader(false));
            });
        } else {
            AppService.setNotification({
                title: 'Field required',
                message: 'Please enter a value to be searched for',
                type: 'warning'
            });
        }
    };

    return (
        <div className="search">
            <FormInput
                elementType={formData.text.elementType}
                elementConfig={formData.text.elementConfig}
                value={formData.text.value}
                selectedValue={formData.text.selectedValue}
                invalid={!formData.text.valid}
                shouldValidate={formData.text.validation}
                touched={formData.text.touched}
                errorMessage={formData.text.validation.message}
                globalSearchLocations={globalSearchLocations}
                searchClick={searchClick}
                onGlobalSelectValue={(event) => globalSearchLocationSelection(event)}
                changed={(event) => inputChangedHandler(event, 'text')}
                selectedChanged={(event) => inputSelectChangedHandler(event, 'text')} />
        </div>
    );
};

export default GlobalSearch;