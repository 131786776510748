import React from 'react';
import './side-menu-item.styles.scss';

const SideMenuItem = (props) => {
    const { content, click, folderClick, id } = props
    return (
        <div
            id={`content-${id}`}
            className={`content-item ${content.selected ? 'selected' : ''}`}>
            <div className={`img-${id}`} onClick={click}></div>
            <div className={`content-label`} onClick={click}>
                <label> {content.displayName} </label>
            </div>
            {
                content.hasFolder ?
                    <div className={`folder`} onClick={folderClick}></div> :
                    null
            }
        </div>
    );
};

export default React.memo(SideMenuItem);