import React from 'react';
import './message.styles.scss';

const MessageModal = (props) => {
    return (
        <div className='message-modal-container'>
            {props.data.message}
        </div>
    );
}

export default MessageModal;