/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import './App.scss';
import HomePage from './pages/post-auth/home/home.component';
import LoginPage from './pages/pre-auth/login/login.component';
import GetCode from './pages/pre-auth/register/components/get-code/get-code.component';
import VerifyCode from './pages/pre-auth/register/components/verifiy-code/verify-code.component';
import RegisterPage from './pages/pre-auth/register/register.component';
import ResetPassword from './pages/pre-auth/reset-password/reset-password.component';
import WelcomePage from './pages/pre-auth/welcome/welcome.component';
import { connect, useSelector } from 'react-redux';
import { getCurrentUser } from './redux/user/user.selectors';
import GuardedRoute from './core/components/guarded-route/guarded-route.component';
import PageLoader from './core/components/loaders/page-loader/page-loader.component';
import LoadingPage from './pages/pre-auth/loading/loading.component';
import { setAppId, setRefreshAppId } from './redux/app/app.actions';
import SecurityService from './core/services/security-service';
import OmiModal from './core/components/modals/omi-modal/omi-modal.modal';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import '@progress/kendo-theme-default/dist/all.css';
import { getRefreshAppId, isRefreshWindow } from './redux/app/app.selectors';
import UserVerificationComponent from './pages/pre-auth/user-verification/user-verification.component';
import ReportBug from './core/components/report-bug/report-bug.component';

const App = ({ currentUser, setAppId, refreshAppId = false, setRefreshAppId, history }) => {
  const [uuid, setUUID] = useState(null);
  const refreshWindow = useSelector(isRefreshWindow) || null;

  const getUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0;
      let v = (c === 'x') ? r : (r && 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const setApplicationSession = () => {
    const uUID = getUUID();
    let sessionId = window.localStorage.getItem('appId');
    if (!sessionId) {
      SecurityService.setAppId(uUID);
      sessionId = SecurityService.getAppId() || uUID;
    }
    setUUID(sessionId);
    setAppId(uuid);
  }

  useEffect(() => {
    if (refreshAppId) {
      setApplicationSession();
      setRefreshAppId(false);
    }
  }, [refreshAppId]);

  useEffect(() => {
    if (refreshWindow) {
      window.location.reload();
    }
  }, [refreshWindow])

  useEffect(() => {
    setApplicationSession();
  }, [setAppId, uuid]);

  return (
    <Fragment>
      <div className="app-container">
        <ReportBug className="rb-btn" />
        <ReactNotification />
        <Switch>
          <GuardedRoute exact path="/" component={WelcomePage} currentUser={currentUser} />
          <GuardedRoute exact path="/external" component={WelcomePage} currentUser={currentUser} />
          <GuardedRoute path="/login" component={LoginPage} currentUser={currentUser} />
          <GuardedRoute exact path="/register/get-code" component={GetCode} currentUser={currentUser} />
          <GuardedRoute exact path="/register/verify-code" component={VerifyCode} currentUser={currentUser} />
          <GuardedRoute path="/register" component={RegisterPage} currentUser={currentUser} />
          <GuardedRoute path="/reset-password" component={ResetPassword} currentUser={currentUser} />
          <GuardedRoute path="/loading" component={LoadingPage} currentUser={currentUser} />
          <GuardedRoute path="/user-verification" component={UserVerificationComponent} currentUser={currentUser} />
          <Route path="/home" render={(props) => {
            if (currentUser) {
              return (<HomePage {...props} />);
            } else {
              return (<Redirect to='/' />);
            }
          }} />
        </Switch>
      </div>
      <PageLoader />
      <OmiModal />
    </Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: getCurrentUser,
  refreshAppId: getRefreshAppId
});

const mapDispatchToProps = (dispatch) => ({
  setAppId: (uuid) => dispatch(setAppId(uuid)),
  setRefreshAppId: (val) => dispatch(setRefreshAppId(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
