import { modalActionTypes } from "./modal.types";

export const setModalData = modalData => ({
    type: modalActionTypes.SET_MODAL,
    payload: modalData
});

export const setModalSubmitData = modalData => ({
    type: modalActionTypes.SET_SUBMIT_MODAL,
    payload: modalData
});

export const setUserErrorModalData = errorModalData => ({
    type: modalActionTypes.SET_USER_ERROR_MODAL,
    payload: errorModalData
});

export const setAddSampleAttributesModalData = modalData => ({
    type: modalActionTypes.SET_ADD_SAMPLE_ATTRIBUTES_MODAL,
    payload: modalData
});

export const setViewSampleAttributeModal = modalData => ({
    type: modalActionTypes.SET_VIEW_SAMPLE_ATTRIBUTES_MODAL,
    payload: modalData
});

export const setNewCohortDimension = modalData => ({
    type: modalActionTypes.SET_NEW_COHORT_DIMENSION,
    payload: modalData
});

export const setIsSampleBlockClicked = modalData => ({
    type: modalActionTypes.SET_SAMPLE_BLOCK_CLICK_EVENT,
    payload: modalData
});