/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from 'react';
import { GRID_DATA, GRID_HEADERS, GRID_KEY_DATA } from '../../utils/omi-grid-data';
import './omi-grid.styles.scss';
import PopOver from '../pop-over/pop-over.component';
import { setLoader, setNotifData } from '../../../redux/app/app.actions';
import { connect, useDispatch } from 'react-redux';
import { ucsfInstance } from '../../axios/ucsf';
import CustomButton from '../custom-button/custom-button.component';
import { setModalData, setModalSubmitData } from '../../../redux/modal/modal.actions';
import ReactPaginate from 'react-paginate';
import { COMPONENT_APIS, OMI_API } from '../../utils/omi-api';
import { setDownloadNotifs, setRouteData } from '../../../redux/user/user.actions';
import AppService from '../../services/app-service';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { createStructuredSelector } from 'reselect';
import { getAppId } from '../../../redux/app/app.selectors';
import { Fragment } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import Grid from './omi-grid.methods';
import { UP_ACCESS } from '../../utils/permissions';
import { setIsDataset } from '../../../redux/collections/collections.actions';
import { setSelectedAnalysisItem } from '../../../redux/analyses/analyses.actions';
import AuthService from '../../services/authentication-service';
import { getCurrentUser } from '../../../redux/user/user.selectors';
import { CONST } from '../../utils/omi-constants';
import { setGlobalSearchValForGeneSets } from '../../../redux/advanced-search/advanced-search.actions';

const ADV_SEARCH_UNSORTED = ['accession id', 'bioproject id', 'pubmed id'];
const VISIBILTY = {
    'private': 'Lab'
};

const OmiGrid = (props) => {
    // COMPONENT PROPS ------------------------------------------------------
    const {
        bordered = false,
        history,
        hasCheckBox = false,
        rowClickable = false,
        collectionData = null,
        folderList = null,
        analysisData = null,
        setLoader,
        addNewSampleAttributeModal,
        apiResponseData,
        setModalSubmitData,
        setRouteData,
        systemTableData,
        parentRoute = null,
        clearCheckVals = false,
        checked,
        openFilePreview,
        addNewModalButton = false,
        totalPageCount = null,
        keyListToDelete = [],
        sortPageRecords,
        responseKeys = [],
        showToolTip = false,
        hasPagination = false,
        horizontalScroll = false,
        location,
        resultType,
        setIsDataset,
        setSelectedAnalysisItem,
        setNotifData,
        gridRef,
        editUserModal,
        currentUser,
        splitActionView = false,
        showAutoColumnSplitPercentage = true,
        gridDataType,
        deleteSelectedItem,
        queryType = null,
        hasServerPagination = false,
        handleSearchQueryResult,
        linkClicked
    } = props;
    // ----------------------------------------------------------------------

    // GLOBAL VAR DECLARATIONS -----------------------------------------------------
    const borderedGridDataLimit = props.borderedGridDataLimit ? props.borderedGridDataLimit : 20;
    const gridType = props?.type;
    // ----------------------------------------------------------------------

    // REACT STATE VARS -----------------------------------------------------
    const [style, setStyle] = useState({ width: '230px' });
    const [listType, setListType] = useState('gridOptions');
    const [selectedPage, setSelectedPage] = useState(0);
    const [activeClass, setActiveClass] = useState(null);
    const [hasCheckBoxInput, setHasCheckBoxInput] = useState(false);
    const [gridHeaders, setGridHeaders] = useState(GRID_HEADERS[gridType] || []);
    const [gridData, setGridData] = useState(GRID_DATA[gridType]);
    const [gridDataCopy, setGridDataCopy] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [scrollObject, setScrollObject] = useState({ top: 0, left: 0 });
    const [divReference, setDivReference] = useState(null);
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
    const [colWidth, setColWidth] = useState({ width: null });
    const [sortData, setSortOrder] = useState({ key: null, order: 'asc', headerData: null, selectedPage: 0, searchType: 'simple' });
    const divRef = createRef();
    const d = useDispatch();
    // ----------------------------------------------------------------------

    // CONDITIONAL VAR DECLARATIONS FOR OMI GRID RENDER ------------------------------------------
    let gridKeyData = (
        GRID_KEY_DATA[gridType] ||
        Grid.createGridDataKey(gridType, folderList, keyListToDelete, responseKeys) ||
        []
    );
    const mainContentWindow = document.getElementsByClassName('main-content')[0];
    let queryParams = null, contentType = null, covariationNetworkId = null;
    if (location) {
        queryParams = AppService.fetchQueryParams(location.search);
        contentType = queryParams['contentType'];
        covariationNetworkId = queryParams['analysisFileFolderId'];
    }
    // ----------------------------------------------------------------------

    // REACT USE EFFECT HOOKS -----------------------------------------------
    useEffect(() => {
        if (apiResponseData && apiResponseData.api) {
            fetchListApi(apiResponseData.api);
        }
        if (clearCheckVals) {
            handleCheckBox('all', 'true');
            checked();
        }
    }, [apiResponseData, clearCheckVals]);

    useEffect(() => {
        if (hasCheckBox) { setHasCheckBoxInput(hasCheckBox); }
        let tableData = [];
        const headerDataArray = [...gridHeaders];
        headerDataArray.map(header => {
            header['asc'] = undefined;
            if (header['sortKey'] === 'size') { header['isSize'] = true; }
            return header;
        });
        setGridHeaders(headerDataArray);
        setListType(gridType);
        if (gridType === 'recentFiles') {
            tableData = [...gridData];
            tableData.map(row => {
                row['showMore'] = true;
                row['checked'] = false;
                row['more'] = false;
                row['sizeByteVal'] = AppService.formatBytes(row?.size, 0, true);
                return row;
            });
            setGridData(tableData);
        } else if (gridType === 'dsFiles') {
            tableData = []; // [...gridData];
            if (folderList && folderList.length) {
                folderList.forEach((row) => {
                    const obj = {
                        id: row['_id'],
                        title: row['Title'],
                        platform: row['Platform'],
                        transformations: row['Transformations'],
                        quantification_scale: row['Quantification Scale'],
                        notes: row['Notes'],
                        upload_date: row['Upload Date'],
                        ds_version: row['Dataset Version'],
                        number_of_samples: Number(row['Number of Samples'])
                    };
                    tableData.push(obj);
                });
            }
            setGridData(tableData);
        } else if (gridType === 'advancedResults') {
            tableData = []; // [...gridData];
            // SETTING GRID HEADER LIST BASED ON GRID INPUT PROPS ----------------------------------------
            let gridHeaderList = [], col = 0;
            if (folderList && folderList.length) {
                folderList.forEach((row) => {
                    if (row) {
                        row['showMore'] = (splitActionView) ? true : false;
                        row['more'] = false;
                        keyListToDelete.forEach(key => delete row[key]);
                        Object.keys(row).forEach(key => {
                            if ((typeof row[key] === 'string' && row[key]?.trim() === '') || row[key] === null) {
                                row[key] = 'NA';
                            } else if (Array.isArray(row[key]) && !row[key].length) {
                                row[key] = ['NA'];
                            }
                        });
                        tableData.push({ ...row });
                    }
                });
                const setResponseKeyHeaders = () => {
                    responseKeys.forEach(key => {
                        if (keyListToDelete.indexOf(key) < 0) {
                            let convertedName = AppService.convertName(key);
                            gridHeaderList.push({
                                name: convertedName,
                                sortKey: key,
                                isSortable: true // (ADV_SEARCH_UNSORTED.indexOf(convertedName.toLowerCase()) >= 0) ? false : true
                            });
                        }
                    });
                }
                if (tableData && tableData.length && tableData[0]) {
                    let advancedSearchQueryLinks = ['sampleSearchQueries', 'savedSearchQueries', 'recentSearchQueries'];
                    if (advancedSearchQueryLinks.indexOf(resultType) >= 0) {
                        gridHeaderList = GRID_HEADERS[resultType] || [];
                    } else if (responseKeys && responseKeys.length) {
                        setResponseKeyHeaders();
                    } else {
                        Object.keys(tableData[0]).forEach(key => {
                            gridHeaderList.push({
                                name: AppService.convertName(key),
                                sortKey: key,
                                isSortable: true
                            });
                        });
                    }
                } else if (responseKeys && responseKeys.length) {
                    setResponseKeyHeaders();
                }
                col = 100 / (gridHeaderList.length);
                if (gridHeaderList.length > CONST.advancedSearchResultsMaxLen) {
                    col = '15%';
                    setColWidth({ minWidth: col });
                } else {
                    if (showAutoColumnSplitPercentage) {
                        col = `${col}%`;
                        setColWidth({ minWidth: col });
                    }
                }
                setGridHeaders(gridHeaderList);
            }
            // -------------------------------------------------------------------------------------------
            setGridData(tableData);
            setGridDataCopy(tableData);
            trimTableDataCount(selectedPage, tableData);
        } else if (gridType === 'ontologies') {
            tableData = systemTableData?.ontologies && systemTableData?.ontologies.length ? [...systemTableData[gridType]] : [...gridData];
            tableData.map(row => {
                row['enableHistory'] = false;
                if (row['history'] && row['history'].length > 0) {
                    row['history'].map((history, i) => {
                        history['showHistory'] = i === 0 ? true : false;
                        history['versionVal'] = Grid.processColumnVersion(history['version']);
                        history['sizeDisplayName'] = AppService.formatBytes(history.size);
                        history['sizeByteVal'] = AppService.formatBytes(history.size, 0, true);
                        return history;
                    });
                }
                return row;
            });
            setGridData(tableData);
        } else if (gridType === 'attributeTables') {
            let tableData = systemTableData?.attributeTables ? [...systemTableData[gridType]] : [];
            tableData.map(row => {
                row['enableHistory'] = false;
                if (row['history'] && row['history'].length > 0) {
                    row['history'].map((history, i) => {
                        history['name'] = history['key']?.trim()?.split('/')[1];
                        history['showHistory'] = i === 0 ? true : false;
                        history['versionVal'] = Grid.processColumnVersion(history['version']);
                        history['sizeDisplayName'] = AppService.formatBytes(history.size);
                        history['sizeByteVal'] = AppService.formatBytes(history.size, 0, true);
                        history['type'] = row.name;
                        return history;
                    });
                }
                return row;
            });
            setGridData(tableData);
            if (window.localStorage.getItem('AWS_ROW_DATA')) {
                let rowData = JSON.parse(window.localStorage.getItem('AWS_ROW_DATA'));
                window.localStorage.removeItem('AWS_ROW_DATA');
                setTimeout(() => {
                    handleHistoryToggle(rowData, 'history', tableData);
                }, 50);
            }
        } else if (gridType === 'files') {
            let dataContent = null;
            let permissionList = [], userRoleList = ['REVIEWER', 'GU'];
            if (collectionData) {
                dataContent = collectionData;
                permissionList = ['DS_FOLDER_FILE_PREVIEW', 'DS_FOLDER_FILE_DOWNLOAD', 'FILE_PREVIEW'];
            } else if (analysisData) {
                dataContent = analysisData;
                permissionList = ['PROJECT_FOLDER_FILE_PREVIEW', 'PROJECT_FOLDER_FILE_DOWNLOAD', 'FILE_PREVIEW'];
            } else if (folderList) {
                dataContent = folderList;
            }
            tableData = dataContent?.files ? [...dataContent.files] : dataContent.length ? dataContent : [];
            tableData.map(row => {
                row['showMore'] = true;
                if (UP_ACCESS(permissionList)) { row['showMore'] = true; }
                else if (!UP_ACCESS(permissionList)) { row['showMore'] = false; }
                row['fileType'] = Grid.fileTypeIdentifier(row['name']); // 'folder';
                row['ownerImg'] = 'user';
                row['sizeDisplayName'] = AppService.formatBytes(row?.size);
                row['sizeByteVal'] = AppService.formatBytes(row?.size, 0, true);
                row['comments'] = 5;
                row['visibilityDisplayName'] = VISIBILTY[row?.visibility] ? VISIBILTY[row?.visibility] : row?.visibility;
                row['checked'] = row['more'] = false;
                if (
                    UP_ACCESS(permissionList) &&
                    userRoleList.indexOf(currentUser?.role?.trim()) >= 0 &&
                    ('children' in row && !(row?.name && row?.name.toLowerCase().includes('.r')))
                ) {
                    row['showMore'] = false;
                }
                return row;
            });
            setGridData(tableData);
        } else if (gridType === 'fileTypeResults' || gridType === 'globalSearchFileTypeResults') {
            let dataContent = null;
            if (folderList) { dataContent = folderList; }
            tableData = dataContent?.files ? [...dataContent.files] : dataContent.length ? dataContent : [];
            tableData.map(row => {
                if (gridType === 'globalSearchFileTypeResults') {
                    row['location'] = row['key'] || 'NA';
                }
                row['showMore'] = true;
                row['fileType'] = Grid.fileTypeIdentifier(row['name']); // 'folder';
                row['ownerImg'] = 'user';
                row['sizeDisplayName'] = AppService.formatBytes(row?.size);
                row['sizeByteVal'] = AppService.formatBytes(row?.size, 0, true);
                row['visibilityDisplayName'] = VISIBILTY[row?.visibility] ? VISIBILTY[row?.visibility] : row?.visibility;
                return row;
            });
            setGridData(tableData);
            setGridDataCopy(tableData);
            trimTableDataCount(selectedPage, tableData);
        } else if (gridType === 'sampleAttributes') {
            fetchListApi(OMI_API.sampleAttributes.RETRIEVE);
        } else if (gridType === 'dataCollectionAttributes') {
            fetchListApi(OMI_API.dataCollectionAttributes.RETRIEVE);
        } else if (gridType === 'dataSetAttributes') {
            fetchListApi(OMI_API.dataSetAttributes.RETRIEVE);
        } else if (gridType === 'analysisAttributes') {
            fetchListApi(OMI_API.analysisAttributes.RETRIEVE);
        } else if (gridType === 'geneSet') {
            setStyle({ width: '170px' });
            if (apiResponseData && typeof apiResponseData['selectedPage'] !== 'undefined') {
                setSelectedPage(apiResponseData['selectedPage']);
            }
            if (apiResponseData && apiResponseData['headerData']) {
                setColSortFromApiResponse(apiResponseData['headerData'], apiResponseData['order']);
            }
            let permissionList = ['RESOURCE_GENESET_PREVIEW', 'RESOURCE_GENESET_DOWNLOAD'];
            tableData = (apiResponseData && apiResponseData['gene_sets']) ? [...apiResponseData['gene_sets']] : [];
            tableData.map(rowData => {
                rowData['showMore'] = true;
                if (UP_ACCESS(permissionList)) { rowData['showMore'] = true; }
                else if (!UP_ACCESS(permissionList)) { rowData['showMore'] = false; }
                if (!rowData['deletable']) { rowData['showMore'] = false; }
                return rowData;
            });
            if (bordered) {
                setGridDataCopy(tableData);
                trimTableDataCount(selectedPage, tableData);
            }
            setActiveClass('active'); // mainContentWindow?.scrollTo(0, 0);
        } else if (gridType === 'analysisFiles') {
            tableData = []; // [...gridData];
            if (folderList && folderList.length) {
                folderList.forEach(row => {
                    row['upload_date'] = '08-03-2020';
                    row['owner'] = row['owner'] ? row['owner'] : 'Michael Oldham';
                    tableData.push(row);
                });
            }
            setGridData(tableData);
        } else if (gridType === 'microArray' || gridType === 'nonMicroArray') {
            tableData = [];
            if (folderList && folderList.length) {
                folderList.forEach((row) => {
                    const obj = {
                        id: row['id'],
                        name: row['name'] || 'NA',
                        fileType: Grid.fileTypeIdentifier(row['name']),
                        version: row['version'] || 'NA',
                        platform: row['platform'] || 'NA',
                        organism: row['organism'] || 'NA',
                        key: row['key'] || 'NA',
                        date_uploaded: row['date_uploaded'] || 'NA',
                        unique_identifier: row['unique_identifier'] || 'NA',
                        type: 'Mapping Tables'
                    };
                    tableData.push(obj);
                });
            }
            setGridData(tableData);
        } else if (gridType === 'mappingTables') {
            tableData = [];
            if (folderList && folderList.length) {
                folderList.forEach((row, i) => {
                    const obj = {
                        id: row['id'] ? row['id'] : (i + 1),
                        name: row['name'] || 'NA',
                        fileType: Grid.fileTypeIdentifier(row['name']),
                        version: row['version'] || 'NA',
                        value: row['mapped_to'] || 'NA',
                        key: row['key'] || 'NA',
                        date_uploaded: row['date_uploaded'] || 'NA',
                        unique_identifier: row['unique_identifier'] || 'NA',
                        type: 'Mapping Tables'
                    };
                    tableData.push(obj);
                });
            }
            setGridData(tableData);
        } else if (gridType === 'userManagement' || gridType === 'userManagementForLab') {
            let userRoleList = ['REVIEWER', 'RU', 'GU', 'LAB'];
            tableData = folderList ? [...folderList] : folderList?.length ? folderList : [];
            tableData.map(row => {
                row['showMore'] = (userRoleList.indexOf(currentUser?.role?.trim()) >= 0) ? false : true;
                row['ownerImg'] = 'user';
                row['visibilityDisplayName'] = VISIBILTY[row?.visibility] ? VISIBILTY[row?.visibility] : row?.visibility;
                row['checked'] = row['more'] = false;
                if (currentUser?.user?.email?.toLowerCase() === row?.email?.toLowerCase()) {
                    row['checked'] = true;
                    row['type'] = 'disabled';
                }
                row['tos_accepted'] = row?.is_accepted_TOS ? 'Yes' : 'No';
                return row;
            });
            setGridData(tableData);
        } else if (gridType === 'sampleSearchQueries') {
            tableData = folderList ? [...folderList] : folderList?.length ? folderList : [];
            tableData.map(row => {
                row['showMore'] = false;
                return row;
            });
            setGridData(tableData);
        } else if (gridType === 'searchNotifications') {
            tableData = (apiResponseData && apiResponseData['search_notification']) ? [...apiResponseData['search_notification']] : [];
            setGridData(tableData);
            setColWidth({width: '25%'})
        } else if (gridType === 'downloadNotifications') {
            tableData = (apiResponseData && apiResponseData['download_notifications']) ? [...apiResponseData['download_notifications']] : [];
            setGridData(tableData);
            setColWidth({width: '25%'})
        } else if (gridType === 'commentNotifications') {
            tableData = (apiResponseData && apiResponseData['mention_notifications']) ? [...apiResponseData['mention_notifications']] : [];
            setGridData(tableData);
            setColWidth({width: '30%'})
        }
        setTimeout(() => {
            let div = document.getElementById('omicon-grid-body');
            if (div) { setHasVerticalScroll(div.scrollHeight > div.clientHeight); }
        }, 50);
        return () => {
            openFilePreview(null);
        }
    }, [
        gridType,
        collectionData,
        analysisData,
        folderList,
        systemTableData,
        apiResponseData
    ]);
    // ----------------------------------------------------------------------

    // OMICON-GRID COMPONENT METHODS ----------------------------------------
    const handleCheckBox = (rowObject, event = null) => {
        const tableData = [...gridData];
        const rowObjectIndex = (event !== null) ? event : null;
        if (rowObject !== 'all') {
            setCheckAll(false);
            let rowData = tableData.find((data, i) => i === rowObjectIndex);
            rowData['checked'] = !rowData['checked'];
            setGridData(tableData);
        } else {
            let checkAllVal = (event === 'true') ? true : checkAll; // !checkAll;
            checkAllVal = !checkAllVal;
            setCheckAll(checkAllVal);
            tableData.forEach(row => row['checked'] = checkAllVal);
            setGridData(tableData);
        }
        const hasCheckedValArray = gridData.filter(data => (data['checked']));
        props.showCheckedVals(hasCheckedValArray);
        if (hasCheckedValArray.length) {
            let mainScreen = document.getElementById("main_content");
            if (!mainScreen) { return; }
            setTimeout(() => {
                mainScreen.scroll({ top: mainScreen.scrollHeight, behavior: 'smooth' });
            }, 25);
        }
    };

    const handleHistoryToggle = (rowData, identifier, gridTableData = []) => {
        let tableData = (gridData && gridData.length) ? [...gridData] : [...gridTableData];
        tableData.map(row => {
            if (rowData['id'] === row['id']) {
                row['enableHistory'] = !row['enableHistory'];
                if (row[identifier] && row[identifier].length) {
                    row[identifier].map((history, i) => {
                        if (row['enableHistory']) {
                            history['showHistory'] = row['enableHistory'];
                        } else {
                            history['showHistory'] = i === 0 ? true : false;
                        }
                        return history;
                    });
                }
            }
            return row;
        });
        setGridData(tableData);
    };

    const togglePopOver = (row, type = null, index = null, event = null) => {
        setScrolled(false);
        setDivReference(divRef);
        if (props && props.offSetVal) {
            props.offSetVal(event?.currentTarget?.offsetTop);
        }
        const tableData = [...gridData];
        index = (index !== null) ? index : null;
        tableData.map((rowData, i) => {
            if (row && (i === index)) { // (row.id === rowData.id)
                if (type) {
                    rowData['more'] = false;
                } else {
                    rowData['more'] = !rowData['more'];
                }
            }
            return rowData;
        });
        setGridData(tableData);
    };

    const filePreviewClick = (rowData, type, key = 'name') => {
        if(gridType === 'geneSet') {
            rowData['type'] = 'Gene Sets';
        }
        setLoader(true);
        const isCSV = rowData[key]?.includes('.csv');
        if (!isCSV || (isCSV && type === 'download')) {
            if (type === 'download') {
                if (rowData['fileType'] && rowData['fileType'] === 'folder') {
                    awsSocketFolderDownload(rowData);
                } else {
                    if (rowData['key']) {
                        if (rowData['sizeDisplayName'] && AppService.sizeChecker(rowData['sizeDisplayName'])) {
                            awsSocketFolderDownload(rowData);
                        } else if (rowData['key'] && rowData['key'].toLowerCase().includes('mapping_tables')) {
                            awsSocketFolderDownload(rowData);
                        } else {
                            awsSocketFolderDownload(rowData);
                        }
                    } else {
                        setLoader(false);
                        AppService.setNotification({
                            title: 'Warning',
                            message: 'Please provide a valid key for download',
                            type: 'warning',
                            width: 275
                        });
                    }
                }
            } else {
                awsFolderDownload(type, rowData);
            }
        } else {
            const key = { key: rowData['key'] };
            if (gridType === 'geneSet') {
                rowData['fileType'] = 'csv';
                awsFolderDownload(type, rowData);
            } else {
                ucsfInstance.post(COMPONENT_APIS.CSV_FILE_PREVIEW, key).then(res => {
                    const result = { ...res.data }; // setLoader(false);
                    const parsedRes = JSON.parse(result['results']);
                    let columns = result['columns'];
                    openAddModal({
                        ...rowData,
                        type: gridType === 'geneSet' ? 'filePreview' : null,
                        csvGridData: parsedRes,
                        fileType: 'csv',
                        columns: columns
                    });
                }).catch(err => {
                    setLoader(false);
                    AppService.setNotification({
                        title: 'Error!',
                        message: 'Error while previewing the file',
                        type: 'danger',
                        width: 275
                    });
                });
            }
        }
    };

    const awsSocketFolderDownload = (rowData, type = null) => {
        setLoader(true);
        AppService.getAwsCredentials().then(async res => {
            const notifData = {
                title: 'Download Initiated!',
                message: `${rowData.name ? rowData.name : rowData['Set ID'] ? rowData['Set ID'] : 'You\'ll be notified when your download is ready.'}`
            };
            setLoader(false);
            AppService.setNotification(notifData);
            if (type === 'docker') {
                setNotifData({
                    type,
                    covariation_file_id: window.location.pathname.includes('global-search-results') ? rowData['network_id'] : rowData['_id'],
                    project_id: queryParams ? queryParams['analysisId'] : rowData['entity_id'],
                    downloadType: rowData['type'], size: rowData['size'], name: rowData['name']
                });
            } else if (type === 'kme') {
                setNotifData({
                    type,
                    co_variation_network_id: rowData['_id'],
                    downloadType: 'Covariation Network Search KME Table'
                });
            } else {
                setNotifData({ key: rowData['key'], type, downloadType: rowData['type'], size: rowData['size'], name: rowData['name'] });
            }
        }).catch(err => {
            setLoader(false);
        });
    };

    const moreItemsClick = (event, props, rowData, key = 'name') => {
        const type = event['action'] ? event['action'] : event['name'].toLowerCase();
        let userRoleList = []; // ['REVIEWER', 'RU', 'GU'];
        if (type === 'download' || type === 'preview') {
            if ((userRoleList.indexOf(currentUser?.role?.trim()) >= 0) && type === 'download') {
                d(setModalData({
                    type: 'permission',
                    history,
                    data: {
                        title: 'Permission Denied!',
                        btnText: 'Go to Register',
                        cancelText: 'Cancel'
                    }
                }));
            } else {
                filePreviewClick(rowData, type, key);
            }
        } else if (type === 'edit') {
            openAddModal(rowData);
        } else if (type === 'delete') {
            setModalSubmitData({ formData: rowData, reqType: 'DELETE', gridType });
        } else if (type === 'delete_query') {
            deleteSelectedItem({ rowData });
        } else if (type === 'docker image download' || type === 'create local kme table') {
            setLoader(true);
            awsSocketFolderDownload(rowData, 'docker');
        } else if (type === 'download kme table') {
            setLoader(true);
            awsSocketFolderDownload(rowData, 'kme');
        }
    };

    const awsFolderDownload = (type, rowData) => {
        AppService.getAwsCredentials().then(async res => {
            const s3Configuration = await AppService.credentialObj(res);
            if (rowData['fileType'] && s3Configuration.credentials && s3Configuration.credentials.accessKeyId) {
                const s3 = await new S3Client(s3Configuration);
                const url = await getSignedUrl(s3, new GetObjectCommand({
                    Bucket: res['bucket'],
                    Key: rowData['key']
                }), {
                    expiresIn: 30 * 60
                });
                const driveUrl = Grid.extractURL(url, rowData['fileType']);
                if (type === 'download') {
                    setLoader(false);
                    window.open(url, rowData['key']);
                } else {
                    if (url.toLowerCase().includes('.r') || url.includes('.txt')) {
                        fetch(url).then(res => res.text()).then(rcode => {
                            openAddModal({ ...rowData, url, fileType: rowData['fileType'], textData: rcode.replace(/\n/g, "<br />") });
                        });
                    } else {
                        let modalData = {
                            ...rowData, url, fileType: rowData['fileType'],
                            type: gridType === 'geneSet' ? 'filePreview' : (rowData?.type || null),
                            downloadType: 'Gene Sets'
                        };
                        if (rowData?.type === 'Module Snapshots') {
                            setLoader(true);
                            ucsfInstance.post(
                                COMPONENT_APIS.LIST_OMICON_IDS,
                                { covariation_network: covariationNetworkId }
                            ).then(({ data }) => {
                                setLoader(false);
                                openAddModal({ ...modalData, omiconIDs: data.omicon_ids.filter(id => id) });
                            }).catch(err => {
                                setLoader(false);
                                openAddModal({ ...modalData, omiconIDs: null });
                            });
                        } else {
                            if (!modalData['Omicon ID'] && modalData['Covariation Module Omicon ID']) {
                                modalData['Omicon ID'] = modalData['Covariation Module Omicon ID'];
                            }
                            openAddModal(modalData);
                        }
                    }
                }
            } else {
                setLoader(false);
            }
        }).catch(err => {
            setLoader(false);
        });
    };

    const handleLinkClick = (row, keyVal, rowObject = null) => {
        if(gridType === 'searchNotifications' && keyVal === 'type') {
            handleSearchQueryResult(rowObject);
        }
        if(gridType === 'downloadNotifications' && keyVal === 'type') {
           AppService.getS3URLAndDownloadFile(rowObject);
           linkClicked(rowObject);
        }
        if(gridType === 'commentNotifications' && keyVal === 'comment_details') {
            linkClicked(rowObject);
         }
        if (parentRoute === 'analyte-mapping') {
            setRouteData({ routeTitle: row?.name, rowData: row, gridType: 'mappingTables' });
            history.push(`/home/resources/mapping-tables/mappingTables/${row?.name}`);
        } else if (row.redirectionUrl) {
            window.open(row.redirectionUrl, row.name, '', true);
        } else {
            if (row.name) {
                switch (row.name) {
                    case 'Sample Attributes':
                        history.push('/home/admin/sample-attributes');
                        break;
                    case 'Data Collection Attributes':
                        history.push('/home/admin/data-collection-attributes');
                        break;
                    case 'Dataset Attributes':
                        history.push('/home/admin/data-set-attributes');
                        break;
                    case 'Project Attributes':
                    case 'Analysis Attributes':
                        history.push('/home/admin/analysis-attributes');
                        break;
                    case 'Microarray Mapping Tables':
                        setRouteData({ routeTitle: 'Microarray', rowData: row, gridType: 'microArray', parentRoute: 'analyte-mapping' });
                        history.push('/home/resources/analyte-mapping/microArray/Microarray');
                        break;
                    case 'Organism Mapping Tables':
                    case 'Nonmicroarray Mapping Tables':
                        setRouteData({ routeTitle: 'Organism', rowData: row, gridType: 'nonMicroArray', parentRoute: 'analyte-mapping' });
                        history.push('/home/resources/analyte-mapping/nonMicroArray/Organism');
                        break;
                    case 'Gene Sets':
                        history.push('/home/resources/gene-set');
                        break;
                    default:
                        filePreviewClick(row, 'preview');
                        break;
                }
            } else {
                if (typeof row === 'string' && row?.includes('http')) {
                    window.open(row, '_blank').focus();
                } else {
                    if (keyVal && typeof keyVal === 'string') {
                        let link = '',
                            baseUrl = '',
                            searchParam = '',
                            urlAddress = '',
                            redirectionKeyList = [
                                'Input Dataset',
                                'Input Dataset Omicon ID',
                                'Covariation Network',
                                'Covariation Network Omicon ID',
                                'Covariation Module Omicon ID',
                                'Geneset Omicon ID',
                            ];
                        if (
                            (typeof row === 'string' && row.split(' ').length === 1) ||
                            (Array.isArray(row) && row.length === 1) ||
                            (redirectionKeyList.indexOf(keyVal) >= 0)
                        ) {
                            if (Array.isArray(row)) { row = row[0]; }
                            if (typeof row === 'string' && row !== 'NA') {
                                // REDIRECTING TO COVARIATION NETWORKS FILE TAB PAGE FROM ADVANCED SEARCH RESULTS ---------------------------------
                                let covariationNetworkRedirection = (redirectionId) => {
                                    let searchUrl = `analysisId=${rowObject['project_id']}`,
                                        searchParams = `${searchUrl}&mainAnalysisId=${redirectionId}`;
                                    searchParams = `${searchUrl}&mainAnalysisId=${rowObject['project_id']}&analysisFileFolderId=${redirectionId}`;
                                    baseUrl = `/home/analyses/analysis-data`;
                                    urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                                    window.open(`${urlAddress}/files?${searchParams}&contentType=analyses&from=advancedSearch`);
                                }
                                // ----------------------------------------------------------------------------------------------------------------
                                // REDIRECTING TO COVARIATION NETWORKS FILE TAB PAGE FROM ADVANCED SEARCH RESULTS ---------------------------------
                                let covariationModuleRedirection = () => {
                                    if (rowObject['Covariation Network'] || rowObject['Covariation Network Omicon ID']) {
                                        setLoader(true);
                                        let payload = {
                                            project_id: rowObject['project_id'],
                                            covariation_network: rowObject['Covariation Network'] || rowObject['Covariation Network Omicon ID'],
                                            module: rowObject['Module'] || rowObject['Covariation Module']
                                        };
                                        if (rowObject['project_id']) {
                                            ucsfInstance.post(COMPONENT_APIS.COVARIATION_MODULES_REDIRECT, payload).then(({ data }) => {
                                                data['fileType'] = 'pdf';
                                                data['name'] = data['key'];
                                                data[keyVal] = rowObject[keyVal];
                                                awsFolderDownload('preview', data);
                                            }).catch(err => {
                                                setLoader(false);
                                            });
                                        } else {
                                            setLoader(false);
                                            AppService.setNotification({
                                                title: 'No matching analysis found!',
                                                type: 'warning'
                                            });
                                        }
                                    } else {
                                        AppService.setNotification({
                                            title: 'Please select Covariation Network in result type options.',
                                            type: 'warning'
                                        });
                                    }
                                }
                                // -----------------------------------------------------------------------------------------------------------------
                                switch (keyVal.toLowerCase()) {
                                    case 'pubmed id':
                                        link = `https://pubmed.ncbi.nlm.nih.gov/${row}`;
                                        window.open(link, keyVal, '');
                                        break;
                                    case 'accession id':
                                        link = `https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${row}`;
                                        if (row.includes('-')) {
                                            link = `https://www.ebi.ac.uk/arrayexpress/experiments/${row}`;
                                        }
                                        window.open(link, keyVal, '');
                                        break;
                                    case 'bioproject id':
                                        link = `https://www.ncbi.nlm.nih.gov/bioproject/${row}`;
                                        window.open(link, keyVal, '');
                                        break;
                                    case 'omicon id':
                                        baseUrl = `/home/collections/data-collections`;
                                        searchParam = `dcId=${rowObject['_id']}`;
                                        urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                                        if (resultType && resultType.toLowerCase().trim() === 'datasets') {
                                            baseUrl = `/home/collections/data-sets`;
                                            urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                                            setIsDataset(true);
                                            setLoader(true);
                                            ucsfInstance.get(COMPONENT_APIS.GET_DC_DETAILS(rowObject['_id'])).then(async dcData => {
                                                await setLoader(false);
                                                searchParam = await `dcId=${dcData.data['_id']}&dsId=${rowObject['_id']}`;
                                                await window.open(`${urlAddress}/attributes?${searchParam}&contentType=collections&from=advancedSearch`);
                                            }).catch(err => setLoader(false));
                                        } else if (resultType && resultType.toLowerCase().trim() === 'data collections') {
                                            window.open(`${urlAddress}/attributes?${searchParam}&contentType=collections&from=advancedSearch`);
                                        } else {
                                            let searchUrl = `analysisId=${rowObject['_id']}`;
                                            let searchParams = `${searchUrl}&mainAnalysisId=${rowObject['_id']}`;
                                            switch (resultType.toLowerCase()) {
                                                case 'analysis instances':
                                                    baseUrl = `/home/analyses/analysis-data`;
                                                    urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                                                    setSelectedAnalysisItem(rowObject);
                                                    window.open(`${urlAddress}/attributes?${searchParams}&contentType=analyses&from=advancedSearch`);
                                                    break;
                                                case 'covariation networks':
                                                    covariationNetworkRedirection(rowObject['_id']);
                                                    break;
                                                case 'covariation modules':
                                                    covariationModuleRedirection();
                                                    break;
                                                case 'gene sets':
                                                    const endPoint = `${window.location.protocol}//${window.location.host}`;
                                                    window.open(`${endPoint}/home/resources/gene-set?geneSetOmiconID=${rowObject[keyVal]}`);
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        break;
                                    case 'input dataset omicon id':
                                    case 'input dataset':
                                        if (rowObject['parent_dataset_id'] || rowObject['dataset_id']) {
                                            let DS_ID = rowObject['parent_dataset_id'] || rowObject['dataset_id'];
                                            baseUrl = `/home/analyses/data-sets`;
                                            searchParam = `analysisId=${rowObject['project_id']}&dsId=${DS_ID}&mainAnalysisId=${rowObject['project_id']}`;
                                            urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                                            window.open(`${urlAddress}/attributes?${searchParam}&contentType=analyses&fromAnalysis=true`);
                                        }
                                        break;
                                    case 'covariation network omicon id':
                                    case 'covariation network':
                                        let networkID = rowObject['co_variation_network_id'] || rowObject['network_id'];
                                        covariationNetworkRedirection(networkID);
                                        break;
                                    case 'covariation module omicon id':
                                        covariationModuleRedirection();
                                        break;
                                    case 'geneset omicon id':
                                        const endPoint = `${window.location.protocol}//${window.location.host}`;
                                        window.open(`${endPoint}/home/resources/gene-set?geneSetOmiconID=${rowObject[keyVal]}`);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        } else if (typeof row === 'number' && row !== 'NA') {
                            switch (keyVal.toLowerCase()) {
                                case 'pubmed id':
                                    link = `https://pubmed.ncbi.nlm.nih.gov/${row}`;
                                    break;
                                case 'accession id':
                                    link = `https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${row}`;
                                    break;
                                case 'bioproject id':
                                    link = `https://www.ncbi.nlm.nih.gov/bioproject/${row}`;
                                    break;
                                default:
                                    break;
                            }
                            if (link) { window.open(link, keyVal, ''); }
                        }
                    }
                }
            }
        }
    };

    const handleScroll = (val) => {
        setScrolled(val);
        const folderContent = document.querySelector('.omi-grid-body');
        setScrollObject({ top: folderContent.scrollTop, left: folderContent.scrollLeft });
    };

    const handleMouseOver = (event, index, action) => {
        const tableData = [...gridData];
        index = (index !== null) ? index : null;
        tableData.map((rowData, i) => {
            rowData['imgHover'] = false;
            if (action === 'on') {
                if ((i === index)) { // (row.id === rowData.id)
                    rowData['offSetTop'] = {
                        bottom: `calc(100% - ${(event?.currentTarget?.offsetTop - scrollObject.top)}px)`
                    };
                    rowData['imgHover'] = true;
                }
            }
            return rowData;
        });
        setGridData(tableData);
    };

    const handleColSort = (headerData) => {
        let order = null;
        let tableData = [...gridData];
        if (gridType === 'advancedResults' || gridType === 'fileTypeResults') { tableData = [...gridDataCopy]; }
        const headerDataArray = [...gridHeaders];
        const key = headerData?.sortKey;
        headerDataArray.map(item => {
            if (item.sortKey === key) {
                if (typeof item['asc'] === 'undefined') { item['asc'] = false; }
                item['asc'] = !item['asc'];
                order = item['asc'] ? 'asc' : 'desc';
            } else {
                if (typeof item['asc'] !== 'undefined') { delete item['asc'] };
            }
            return item;
        });
        setGridHeaders(headerDataArray);
        if (!totalPageCount) {
            if (order && order === 'asc') {
                if (key === 'sizeByteVal' && (typeof (headerData.sortSubKey) === 'undefined' || headerData.sortSubKey !== 'history')) {
                    tableData.sort((a, b) => (a[key] - b[key]));
                } else if (
                    key === 'version' ||
                    key === 'date_modified' ||
                    key === 'sizeByteVal'
                ) {
                    let sortItem = 'versionVal';
                    if (key === 'sizeByteVal') { sortItem = key; }
                    tableData = [...Grid.sortVersion(tableData, headerData, order, sortItem)];
                } else if (key === 'executionTime') {
                    tableData = Grid.sortTime(tableData, order, key);
                } else {
                    tableData.sort((a, b) => Grid.sortStringVal(a, b, key, order));
                }
            } else if (order && order === 'desc') {
                if (key === 'sizeByteVal' && (typeof (headerData.sortSubKey) === 'undefined' || headerData.sortSubKey !== 'history')) {
                    tableData.sort((a, b) => (b[key] - a[key]));
                } else if (
                    key === 'version' ||
                    key === 'date_modified' ||
                    key === 'sizeByteVal'
                ) {
                    let sortItem = 'versionVal';
                    if (key === 'sizeByteVal') { sortItem = key; }
                    tableData = [...Grid.sortVersion(tableData, headerData, order, sortItem)];
                } else if (key === 'executionTime') {
                    tableData = Grid.sortTime(tableData, order, key);
                } else {
                    tableData.sort((a, b) => Grid.sortStringVal(a, b, key, order));
                }
            }
            setGridData(tableData);
            if (gridType === 'advancedResults' || gridType === 'fileTypeResults') {
                setGridDataCopy(tableData);
                trimTableDataCount(selectedPage, tableData);
            }
        } else {
            setActiveClass(null);
            let sortDataObject = {
                key,
                order,
                headerData,
                selectedPage: (typeof apiResponseData.selectedPage !== 'undefined') ? apiResponseData.selectedPage : selectedPage,
                searchType: apiResponseData.searchType
            };
            setSelectedPage(sortDataObject.selectedPage);
            setSortOrder({ ...sortDataObject });
            setActiveClass('active');
            sortPageRecords({ ...sortDataObject });
        }
    };

    const setColSortFromApiResponse = (headerData, order = 'asc') => {
        const headerDataArray = [...gridHeaders];
        const key = headerData?.sortKey;
        headerDataArray.map(item => {
            if (item.sortKey === key) {
                if (typeof item['asc'] === 'undefined') { item['asc'] = false; }
                item['asc'] = (order === 'asc') ? true : false;
            } else {
                if (typeof item['asc'] !== 'undefined') { delete item['asc'] };
            }
            return item;
        });
        setGridHeaders(headerDataArray);
    };

    const handleStringArray = (dataArray = []) => {
        let arrayVals = '';
        if (dataArray && dataArray.length) {
            dataArray?.map((data, index) => arrayVals += `${index + 1}) ${data}` + ((index === dataArray.length - 1) ? '' : ' '));
        }
        return arrayVals;
    };

    const openAddModal = (modalData = null) => {
        switch (gridType) {
            case 'sampleAttributes':
                addNewSampleAttributeModal({
                    type: 'new-sample-attribute',
                    modalData,
                    data: {
                        title: modalData ? 'Edit Sample Attribute' : 'Add New Sample Attribute',
                        btnText: modalData ? 'UPDATE SAMPLE ATTRIBUTE' : 'ADD SAMPLE ATTRIBUTE'
                    }
                });
                break;
            case 'geneSet':
                if (modalData && modalData.type === 'filePreview') {
                    const name = modalData.name ? modalData.name : modalData.key.split('/')[1];
                    modalData.name = name;
                    openFilePreview({
                        type: 'file-preview',
                        modalData,
                        data: { title: name }
                    });
                } else {
                    addNewSampleAttributeModal({
                        type: 'new-gene-set',
                        modalData,
                        data: {
                            title: modalData ? 'Edit Gene Set' : 'Add New Gene Set',
                            btnText: modalData ? 'UPDATE GENE SET' : 'ADD GENE SET'
                        }
                    });
                }
                break;
            case 'files':
                openFilePreview({
                    type: 'file-preview',
                    modalData,
                    data: { title: modalData.name }
                });
                break;
            case 'userManagement':
            case 'userManagementForLab':
                editUserModal({
                    type: 'new-user',
                    modalData,
                    data: {
                        title: 'Edit User Details',
                        btnText: modalData ? 'UPDATE USER' : 'ADD USER'
                    }
                });
                break;
            default:
                const name = modalData.name ? modalData.name : modalData.key.split('/')[1];
                modalData['name'] = name;
                openFilePreview({
                    type: 'file-preview',
                    modalData,
                    data: { title: name }
                });
                break;
        }
    };

    const handlePageClick = (event) => {
        setActiveClass('active');
        if (totalPageCount) {
            fetchSelectedPageData(event['selected']);
        } else {
            trimTableDataCount(event['selected']);
        }
    };

    const trimTableDataCount = (index = 0, dataArray = [], returnArray = false) => {
        setSelectedPage(index);
        if (totalPageCount === null) {
            const tableDataArray = [];
            let tableData = dataArray && dataArray.length ? [...dataArray] : [...gridDataCopy];
            setGridDataCopy(tableData);
            const dataCount = tableData.length; // totalCount ? totalCount : tableData.length;
            const len = (dataCount >= borderedGridDataLimit && ((dataCount - (borderedGridDataLimit * index)) > borderedGridDataLimit)) ? borderedGridDataLimit : dataCount - (borderedGridDataLimit * index);
            for (let i = borderedGridDataLimit * index; i < ((borderedGridDataLimit * index) + len); i++) {
                tableDataArray.push(tableData[i]);
            }
            if (returnArray) {
                return tableDataArray;
            } else {
                setGridData(tableDataArray);
            }
        } else {
            if (returnArray) {
                return dataArray;
            } else {
                setGridData(dataArray);
            }
        }
    };

    const fetchSelectedPageData = (index) => {
        setLoader(true);
        setSelectedPage(index);
        sortPageRecords({
            selectedPage: index,
            key: sortData.key,
            order: sortData.order,
            headerData: sortData.headerData,
            searchType: apiResponseData.searchType
        });
    };

    const fetchListApi = (api) => {
        setLoader(true); // setPageCount(0);
        ucsfInstance.get(api).then(async res => {
            const tableData = await (res.data ? [...res.data] : []);
            await tableData.map(rowData => {
                rowData['showMore'] = true;
                if (
                    gridType === 'sampleAttributes' ||
                    gridType === 'dataSetAttributes' ||
                    gridType === 'dataCollectionAttributes' ||
                    gridType === 'analysisAttributes'
                ) {
                    if (!rowData['deletable']) { rowData['showMore'] = false; }
                }
                if (gridType === 'sampleAttributes' && currentUser.role.toLowerCase() === 'reviewer') {
                    rowData['showMore'] = false;
                }
                return rowData;
            });
            if (bordered) {
                await setGridDataCopy(tableData);
                await trimTableDataCount(selectedPage, tableData);
            } else {
                await setGridData(tableData);
            } // await setInitialPage(0);
            await setActiveClass('active');
            await setLoader(false);
            await mainContentWindow.scrollTo(0, 0);
        }).catch(err => {
            setLoader(false);
        });
    };

    const conditionalWidth = (row, keyObj) => {
        if(gridType === 'searchNotifications' || gridType === 'downloadNotifications') {
            return '25%';
        }
        if (keyObj.key === 'description') {
            if (gridType === 'sampleAttributes') {
                if (!row['showMore']) {
                    return '23%';
                }
            }
        }
    };

    const ToolTip = (row, keyObjVal, isHeader = false, templateContent) => {
        let contentVal = row[keyObjVal] ? row[keyObjVal].toString() : '';
        let showContentVal = true;
        if (gridType === 'advancedResults') {
            if (['accession id', 'bioproject id', 'pubmed id'].indexOf(keyObjVal.toLowerCase()) >= 0) {
                showContentVal = false;
            }
        }
        const style = isHeader ? {
            width: 'calc(100% - 15px)',
            height: '100%',
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        } : {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            position: Grid.pubMedIdListCheck(contentVal, keyObjVal, true) ? 'unset' : 'relative'
        }
        return (
            (showToolTip && contentVal && showContentVal) ?
                <TooltipComponent
                    content={contentVal}
                    style={{ ...style }}>
                    {templateContent}
                </TooltipComponent> :
                (gridType === 'advancedResults') ?
                    <div style={style}>
                        {templateContent}
                    </div> :
                    templateContent

        );
    };

    const moreActionItems = (dataProps) => {
        let { keyIndex, row, keyObj, rowIndex, columnWidth } = dataProps;
        return (
            row['showMore'] &&
            <div key={keyIndex} className="body-content col" style={splitActionView ? columnWidth : colWidth}>
                <div
                    ref={divRef}
                    className={`col-val ${keyObj?.typeKey || ''}${(row.type && row['checked']) ? ' disabled' : ''} ${gridType}`}
                    onClick={(event) => togglePopOver(row, null, rowIndex, event)}
                    onBlur={() => togglePopOver(row, 'blur', rowIndex)}
                    tabIndex={rowIndex}>
                    {
                        row['more'] && !scrolled ? (
                            <PopOver
                                rowData={row}
                                className="grid"
                                gridLen={gridData.length}
                                listType={queryType || listType}
                                gridType={gridType}
                                gridDataType={gridDataType}
                                elementType={contentType}
                                tabIndex={rowIndex}
                                offSetOn={false}
                                scrollObject={scrollObject}
                                divRef={divReference}
                                click={(event) => moreItemsClick(event, props, row)} />
                        ) : null
                    }
                </div>
            </div>
        );
    };

    const OMICON_GRID = () => {
        let gridBodyType = '', advancedResultType = '';
        let advancedSearchQueryLinks = ['sampleSearchQueries', 'savedSearchQueries', 'recentSearchQueries'];
        if (gridHeaders.length > CONST.advancedSearchResultsMaxLen) { gridBodyType = ' scrollable'; }
        if (gridDataType) { advancedResultType = ` ${gridDataType.toLowerCase().replace(/\s/g, '-')}`; }
        if ((advancedSearchQueryLinks.indexOf(resultType) >= 0) && gridData && gridData.length) {
            gridKeyData = GRID_KEY_DATA[resultType] || gridKeyData;
        }
        const copyVal = () => AppService.setNotification({
            title: 'Copied to Clipboard',
            message: `Your link has been copied`,
            width: 245
        });
        const SECTION_MAIN = (
            <div
                id="omicon-tbl"
                ref={gridRef}
                className={`omi-grid-container${bordered ? ' bordered' : ''} ${gridType}${advancedResultType}`}
                style={horizontalScroll ? { overflowX: 'auto' } : null}>
                {/* ----------------------------------------- GRID HEADER ELEMENT ----------------------------------------- */}
                <div className={`omi-grid-header ${gridType}`}>
                    <div className="row">
                        {
                            hasCheckBoxInput ? (
                                <div className="header-content col" key={AppService.getRandomAlphaNumId(7)}>
                                   {UP_ACCESS(['FILE_DOWNLOAD', 'FILE_DOWNLOADS']) && <input type="checkbox" value="all" checked={checkAll} onChange={() => handleCheckBox('all')} />} {/* value={null} */}
                                </div>
                            ) : null
                        }
                        {
                            gridHeaders.map((header, index) => {
                                return (
                                    <div
                                        key={AppService.getRandomAlphaNumId(7)}
                                        className={`header-content col ${gridType === 'downloadNotifications' && header.name === 'Size' ? 'downloag-log-file-size-field ' : '' }`}
                                        style={colWidth}
                                        title={!showToolTip ? header.name : ''}>
                                        {ToolTip(header, 'name', true,
                                            <div
                                                className="col-title"
                                                onClick={() => { if (header.isSortable) { handleColSort(header); } }}>
                                                {header.name}
                                            </div>
                                        )}
                                        {
                                            header.isSortable ?
                                                <div
                                                    className={`col-sort${header['asc'] ? ' asc' : (typeof header['asc'] !== 'undefined' && !header['asc']) ? ' desc' : ''}`}
                                                    onClick={() => handleColSort(header)}></div> :
                                                null
                                        }
                                    </div>
                                );
                            })
                        }
                        {
                            hasVerticalScroll ?
                                <div className="header-content col" style={{ minWidth: '10px' }}></div> :
                                null
                        }
                    </div>
                </div>
                {/* ------------------------------------------ GRID BODY ELEMENT ------------------------------------------ */}
                <div id="omicon-grid-body" className={`omi-grid-body ${gridType}`} onScroll={() => handleScroll(true)}>
                    {
                        (gridData && gridData.length) ?
                            gridData?.map((row, rowIndex) => {
                                return (
                                    <div
                                        key={row.id ? `row_${row.id}` : (`index_${rowIndex + 1}`)}
                                        className={`row${row?.enableHistory ? ' expand' : ''}${gridType === 'searchNotifications' && !row.is_read ? ' unread-notification' : ''}`}
                                        onClick={() => {
                                            if (rowClickable) { return props.rowDoubleClick(row); }
                                        }}>
                                        {
                                            gridKeyData?.map((keyObj, keyIndex) => {
                                                switch (keyObj.typeKey) {
                                                    case 'fileType':
                                                        const fileType = (row.fileType && row.fileType === 'folder') ? ' can-click' : '';
                                                        return (
                                                            <div
                                                                key={keyIndex}
                                                                className={`body-content col${fileType}`}
                                                                style={colWidth}
                                                                title={row[keyObj.key]}
                                                                onClick={() => {
                                                                    if (row.fileType && row.fileType === 'folder' && props?.rowDataClick) {
                                                                        return props?.rowDataClick(row);
                                                                    }
                                                                }}>
                                                                {ToolTip(row, keyObj.key, false,
                                                                    <Fragment>
                                                                        <div className={`col-val ${row[keyObj.typeKey]}-icon`}></div>
                                                                        <div className="col-val">{row[keyObj.key]}</div>
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                        );
                                                    case 'img':
                                                        return (
                                                            <div
                                                                className="body-content col"
                                                                style={{ ...colWidth, position: gridType === 'fileTypeResults' ? 'relative' : '' }}
                                                                key={keyIndex}>
                                                                <div
                                                                    className={`col-val ${row[keyObj.key]}`}
                                                                    onMouseOver={(event) => handleMouseOver(event, rowIndex, 'on')}
                                                                    onMouseLeave={(event) => handleMouseOver(event, rowIndex, 'leave')}>
                                                                    {
                                                                        row[keyObj?.hoverVal] && row['imgHover'] ?
                                                                            <div
                                                                                className="img-tooltip"
                                                                                style={row['offSetTop']}>
                                                                                {row[keyObj?.hoverVal]}
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    case 'comments':
                                                        return (
                                                            <div className="body-content col" style={colWidth} key={keyIndex}>
                                                                <div className={`col-val ${keyObj.typeKey}`}></div>
                                                                <div className="col-val normal">({row[keyObj.key]})</div>
                                                            </div>
                                                        );
                                                    case 'checkbox':
                                                        return (
                                                            <div className="body-content col" style={colWidth} key={keyIndex}>
                                                               {UP_ACCESS(['FILE_DOWNLOAD', 'FILE_DOWNLOADS']) && <input type="checkbox" checked={row[keyObj.key]} onChange={() => handleCheckBox(row, rowIndex)} />}
                                                                {/* value={row[keyObj.key]} */}
                                                            </div>
                                                        );
                                                    case 'more':
                                                        return moreActionItems({ keyIndex, row, keyObj, rowIndex });
                                                    case 'link':
                                                        return (
                                                            <div className="body-content col" title={row[keyObj?.key]} style={colWidth} key={keyIndex}>
                                                                <div
                                                                    className={`col-val ${keyObj?.typeKey} ${gridType} ${row['redirectionUrl'] ? '' : 'no-link'}`}
                                                                    onClick={() => handleLinkClick(row)}>
                                                                    {row[keyObj?.key]}
                                                                </div>
                                                            </div>
                                                        );
                                                    case 'linkWithOnClick':
                                                        return (
                                                            <div className="body-content col" title={row[keyObj?.key]} style={colWidth} key={keyIndex}>
                                                                <div
                                                                    className={`col-val ${keyObj?.typeKey} ${gridType} ${(gridType == 'downloadNotifications' || gridType == 'commentNotifications') && !row.is_read ? 'unread-download-log':'' } ${row['redirectionUrl'] ? '' : 'no-link'}`}
                                                                    onClick={() => handleLinkClick(row[keyObj.key], keyObj.key, row)}>
                                                                    {row[keyObj?.key]}
                                                                </div>
                                                            </div>
                                                        );
                                                    case 'history_array':
                                                        return (
                                                            <div className="col-data col" key={keyIndex}>
                                                                {row[keyObj.subKey].map((data, indX) => {
                                                                    let item =
                                                                        data['showHistory'] ? (
                                                                            <div className="body-content" key={data['id']}>
                                                                                <div className="col-val">{data[keyObj.key]}</div>
                                                                                {
                                                                                    keyObj.key === 'version' ? (
                                                                                        <Fragment>
                                                                                            {/* <div className={`col-val url-download`} onClick={() => window.open(data?.downloadUrl, '_blank')}></div> */}
                                                                                            {/* <div className={`col-val url-download`} onClick={() => filePreviewClick(data, 'download', 'key')}></div> */}
                                                                                            <div className={`col-val url-download`} onClick={() => {
                                                                                                AuthService._setStorageData('AWS_ROW_DATA', JSON.stringify(row));
                                                                                                awsSocketFolderDownload(data);
                                                                                            }}></div>
                                                                                            {
                                                                                                (row[keyObj.subKey].length > 1 && indX === 0) ? (
                                                                                                    <div className={`col-val show-history`} onClick={() => handleHistoryToggle(row, keyObj.subKey)}>
                                                                                                        {
                                                                                                            !row['enableHistory'] ? (
                                                                                                                <Fragment>
                                                                                                                    <span>Show History</span>
                                                                                                                    <span className="right"><i className="app-arrow right version"></i></span>
                                                                                                                </Fragment>
                                                                                                            ) : (
                                                                                                                <Fragment>
                                                                                                                    <span>Hide History</span>
                                                                                                                    <span className="down"><i className="app-arrow down version"></i></span>
                                                                                                                </Fragment>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : null
                                                                                            }
                                                                                        </Fragment>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        ) : null;
                                                                    return item;
                                                                })}
                                                            </div>
                                                        );
                                                    case 'stringArray':
                                                        return (
                                                            <div className="body-content col" style={colWidth} title={handleStringArray(row[keyObj.key])} key={keyIndex}>
                                                                <div className={`col-val ${keyObj.typeKey}`}>
                                                                    {handleStringArray(row[keyObj.key])}
                                                                </div>
                                                            </div>
                                                        );
                                                    case 'answer':
                                                        return (
                                                            <div className="body-content col" style={colWidth} key={keyIndex}>
                                                                <div className={`col-val ${keyObj.typeKey}`}>
                                                                    {row[keyObj.key] ? 'Yes' : 'No'}
                                                                </div>
                                                            </div>
                                                        );
                                                    case 'download':
                                                        return (
                                                            <div
                                                                key={keyIndex}
                                                                className="body-content col" style={colWidth}>
                                                                <div className={`col-val ${keyObj.key}`} onClick={() => filePreviewClick(row, 'download')}></div>
                                                            </div>
                                                        );
                                                    case 'nameAndDownload':
                                                        return (
                                                            <div className="body-content col" style={colWidth} key={keyIndex}>
                                                                <div className={`col-val`} title={row[keyObj.key]}>{row[keyObj.key]}</div>
                                                                {/* <div className={`col-val download`} onClick={() => filePreviewClick(row, 'download', 'key')}></div> */}
                                                                <div
                                                                    ref={divRef}
                                                                    className={`col-val more ${gridType}`}
                                                                    onClick={(event) => togglePopOver(row, null, rowIndex, event)}
                                                                    onBlur={() => togglePopOver(row, 'blur', rowIndex)}
                                                                    tabIndex={rowIndex}>
                                                                    {
                                                                        row['more'] && !scrolled ? (
                                                                            <PopOver
                                                                                rowData={row}
                                                                                className="grid_name_download"
                                                                                listType={listType}
                                                                                tabIndex={rowIndex}
                                                                                offSetOn={false}
                                                                                scrollObject={scrollObject}
                                                                                divRef={divReference}
                                                                                click={(event) => moreItemsClick(event, props, row, 'key')} />
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    case 'linkAndCopy':
                                                        return (
                                                            <div className="body-content col" style={colWidth} key={`00_${keyIndex}`}>
                                                                {ToolTip(row, keyObj.key, false,
                                                                    <div
                                                                        className={`col-val link`}
                                                                        title={row[keyObj.key]}
                                                                        onClick={() => handleLinkClick(row[keyObj.key], keyObj.key, row)}>
                                                                        {row[keyObj.key]}
                                                                    </div>
                                                                )}
                                                                <div
                                                                    title='Copy Query Link'
                                                                    className={`col-val copy`}
                                                                    onClick={() => {
                                                                        copyVal();
                                                                        navigator.clipboard.writeText(row[keyObj.key]);
                                                                    }}
                                                                    tabIndex={rowIndex}>
                                                                </div>
                                                            </div>
                                                        );
                                                    default:
                                                        let widthStyle = { ...colWidth };
                                                        let params = { val: row[keyObj.key], keyVal: keyObj.key, type: gridType, resultType };
                                                        let isPubMedIdList = Grid.pubMedIdListCheck(row[keyObj.key], keyObj.key);
                                                        let isLink = Grid.checkForLinks(params);
                                                        if (isPubMedIdList) { widthStyle = { ...widthStyle, padding: '0 1%' }; }
                                                        return (
                                                            <div
                                                                key={keyIndex}
                                                                className={`body-content col${isPubMedIdList ? ' pub-med-list' : ''} ${gridType === 'commentNotifications' ? 'comment-table-field' : ''} ${gridType === 'downloadNotifications' && keyObj.key === 'file_size' ? 'downloag-log-file-size-field ' : '' }`}
                                                                title={!showToolTip ? row[keyObj.key] : ''}
                                                                style={gridType === 'advancedResults' ? widthStyle : { width: conditionalWidth(row, keyObj) }}>
                                                                {ToolTip(row, keyObj.key, false,
                                                                    <div
                                                                        className={`col-val${isLink ? ' link' : ''}${isPubMedIdList ? ' multi-val' : ''}`}
                                                                        onClick={() => handleLinkClick(row[keyObj.key], keyObj.key, row)}>
                                                                        {isPubMedIdList ? Grid.splitValues(row[keyObj.key]) : Grid.filterSpecialChars(row[keyObj.key], keyObj.key)}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                }
                                            })
                                        }
                                    </div>
                                );
                            }) :
                            <div className={`row`}>
                                <div
                                    className="body-content col empty" style={colWidth}>
                                    <div className="col-val">No records found</div>
                                </div>
                            </div>
                    }
                </div>
                {/* ------------------------------- GRID PAGINATION AND ACTION EVENT ELEMENTS ----------------------------- */}
                {
                    (bordered || hasServerPagination) ?
                        <Fragment>
                            {
                                addNewModalButton ?
                                    <div className={`omi-grid-footer ${gridType}`}>
                                        <CustomButton
                                            size="large"
                                            style={style}
                                            inverted='inverted'
                                            btnType="upload"
                                            className="add"
                                            disabled={!UP_ACCESS([Grid.getGridTypeAccess(gridType)])}
                                            onClick={() => openAddModal()}>
                                            {Grid.getBtnText(gridType)}
                                        </CustomButton>
                                    </div> :
                                    null
                            }
                            <div className={`omi-grid-pagin`}>
                                <ReactPaginate
                                    initialPage={(typeof apiResponseData?.selectedPage !== 'undefined') ? apiResponseData.selectedPage : selectedPage}
                                    previousLabel={<div className="lft-svg"></div>} // '&#129168;'
                                    nextLabel={<div className="rgt-svg"></div>} // '&#129170;'
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={((totalPageCount ? totalPageCount : gridDataCopy.length) / borderedGridDataLimit)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={activeClass !== null ? 'active' : null} />
                            </div>
                        </Fragment> :
                        null
                }
            </div>
        );
        return (
            <Fragment>
                {
                    (splitActionView && gridData && gridData.length) ?
                        <div className='wrap-data-and-actions'>
                            {SECTION_MAIN}
                            <div className={`omi-grid-container${bordered ? ' bordered' : ''} ${gridType} action${gridBodyType}`}>
                                <div className={`omi-grid-header ${gridType}`}>
                                    <div className="row"></div>
                                </div>
                                <div className={`omi-grid-body ${gridType}`}>
                                    {
                                        gridData?.map((row, rowIndex) => {
                                            return (
                                                <div key={row.id ? row.id : (rowIndex + 1)} className={`row`}>
                                                    {moreActionItems({
                                                        keyIndex: rowIndex,
                                                        row,
                                                        keyObj: { typeKey: 'more' },
                                                        rowIndex,
                                                        columnWidth: null
                                                    })}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> :
                        SECTION_MAIN
                }
                {
                    (hasPagination) ?
                        <div className={`omi-grid-pagin ${gridType}`}>
                            <ReactPaginate
                                initialPage={(typeof apiResponseData?.selectedPage !== 'undefined') ? apiResponseData.selectedPage : selectedPage}
                                previousLabel={<div className="lft-svg"></div>} // '&#129168;'
                                nextLabel={<div className="rgt-svg"></div>} // '&#129170;'
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={((totalPageCount ? totalPageCount : gridDataCopy.length) / borderedGridDataLimit)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={activeClass !== null ? 'active' : null} />
                        </div> :
                        null
                }
            </Fragment>
        );
    };
    // -------------------------------------------------------------------------------

    return OMICON_GRID();
};

const mapStateToProps = createStructuredSelector({
    appId: getAppId,
    currentUser: getCurrentUser
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val) => dispatch(setLoader(val)),
    addNewSampleAttributeModal: (modalData) => dispatch(setModalData(modalData)),
    editUserModal: (modalData) => dispatch(setModalData(modalData)),
    openFilePreview: (modalData) => dispatch(setModalData(modalData)),
    setModalSubmitData: (formData) => dispatch(setModalSubmitData(formData)),
    setRouteData: (routeData) => dispatch(setRouteData(routeData)),
    setDownloadNotifs: (data) => dispatch(setDownloadNotifs(data)),
    setIsDataset: (data) => dispatch(setIsDataset(data)),
    setSelectedAnalysisItem: (item) => dispatch(setSelectedAnalysisItem(item)),
    setNotifData: (data) => dispatch(setNotifData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OmiGrid));