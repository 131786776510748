/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import CustomButton from '../../../core/components/custom-button/custom-button.component';
import FormInput from '../../../core/components/form-input/form-input.component';
import { PreAuthCommonValidation } from '../../../core/components/pre-auth-layout/pre-auth-common-validation.component';
import PreAuthLayoutComponent from '../../../core/components/pre-auth-layout/pre-auth-layout.component';
import { preAuthBtnStyle } from '../../../core/style-config/elements.component';
import formValidityCheck from '../../../core/utils/validator';
import { setCurrentUser } from '../../../redux/user/user.actions';
import loginFormData from './forms/login.forms';
import './login.styles.scss';
import { connect } from 'react-redux';
import { setLoader } from '../../../redux/app/app.actions';
import AppService from '../../../core/services/app-service';
import { ucsfInstance, ucsfLoginInstance } from '../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import AuthService from '../../../core/services/authentication-service';
import SecurityService from '../../../core/services/security-service';
import { Link } from 'react-router-dom';
import { getAppId } from '../../../redux/app/app.selectors';
import { createStructuredSelector } from 'reselect';

const CREDS = {
    name: 'Admin User',
    email: 'admin@gmail.com',
    password: 'admin@123',
    department: 'Bio-Chemistry'
};

const LoginPage = ({ history, location, setCurrentUserData, setLoader, appId }) => {

    // DEFINE STATE HOOKS -----------------------------------
    const [userCredentials, setUserCredentials] = useState({
        loginFormElement: { ...loginFormData },
        formIsValid: false,
        loading: false
    });
    const [loginData, setLoginData] = useState({
        forgotPassword: false,
        passwordSent: false,
        loginError: false,
        loginErrorMessage: 'Invalid login. Make sure your username and password are correct.'
    });

    // DEFINE UPDATE HOOKS -----------------------------------
    /* useEffect(() => {
        setTimeout(() => {
            resetCredentials();
        }, 100);
    }, []); */

    // DEFINE/DECALRE VARIABLES ------------------------------
    const queryParams = AppService.fetchQueryParams(location.search);
    const { userType = null } = queryParams;
    const { loginFormElement } = userCredentials;
    const { forgotPassword, passwordSent, loginError, loginErrorMessage } = loginData;

    const handleSubmit_BACKUP = async event => {
        event.preventDefault();
        const pwdSent = passwordSent;
        setLoader(true);
        if (forgotPassword) {
            setLoginData({ ...loginData, passwordSent: !pwdSent });
            setLoader(false);
        } else {
            const email = loginFormElement['email']['value'];
            const password = loginFormElement['password']['value'];
            if (email.trim() === CREDS.email && password === CREDS.password) {
                setTimeout(() => {
                    setUserData();
                    setLoginData({ loginError: false });
                    history.push('/home');
                }, 750);
            } else {
                setLoginData({ loginError: true });
                setLoader(false);
            }
        }
    }

    const handleSubmit = async event => {
        event.preventDefault();
        const pwdSent = passwordSent;
        AppService.clearAllCookies();
        localStorage.clear();
        setLoader(true, 'Please wait');
        if (forgotPassword) {
            const email = loginFormElement['email']['value'];
            ucsfLoginInstance.post(COMPONENT_APIS.EMAIL_VERIFICATION_FOR_FORGOT_PWD, { email }).then(res => {
                setLoader(false);
                AppService.setNotification({
                    title: 'Reset link sent!',
                    message: `A password reset link has been sent to the email that you've provided. Please click on the link inside to create new password.`,
                    type: 'success',
                    duration: 3000
                }); // setLoginData({ ...loginData, forgotPassword: false, loginError: false });
                setLoginData({ ...loginData, passwordSent: !pwdSent });
            }).catch(err => {
                let loginErrorMessage = err?.response?.data?.error;
                setLoginData({ ...loginData, forgotPassword: true, loginError: true, loginErrorMessage: loginErrorMessage || 'Something went wrong. Please try again.' });
                setLoader(false);
            });
        } else {
            const username = loginFormElement['userName']['value']; // 'Reviewer'; // loginFormElement['email']['value'];
            const password = AppService.encryptData(loginFormElement['password']['value']); // 81oN0KuuDFJRp2Py
            // HANDLING API CALL FOR GUEST ACCOUNT USER --------------------
            ucsfLoginInstance.post(COMPONENT_APIS.USER_LOGIN, {
                username, password, appid: appId
            }).then((response) => {
                const userData = response.data;
                SecurityService.setSecurityToken(userData.token);
                AuthService._setUser(userData); // setCurrentUserData(userData);
                setLoader(true, 'Logging you in');
                ucsfInstance.get(COMPONENT_APIS.USER_PERMISSION).then(res => {
                    const { permissions, role } = res.data;
                    AuthService._setUser({ ...userData, permissions, role });
                    setCurrentUserData({ ...userData, permissions, role });
                    setLoader(false);
                    history.push('/home');
                }).catch(err => {
                    setLoader(false);
                });
            }).catch(error => {
                let loginErrorMessage = error?.response?.data?.error;
                setLoginData({ loginError: true, loginErrorMessage });
                setLoader(false);
            });
            // -------------------------------------------------------------
        }
    }

    const setUserData = () => {
        const userData = {
            name: CREDS.name,
            email: CREDS.email,
            department: CREDS.department
        };
        setCurrentUserData(userData);
        resetCredentials();
    };

    const resetCredentials = () => {
        let loginFormElementData = { ...loginFormElement };
        // loginFormElementData['email']['value'] = '';
        loginFormElementData['userName']['value'] = '';
        loginFormElementData['password']['value'] = '';
        setUserCredentials({ loginFormElement: loginFormElementData });
    }

    const inputChangedHandler = (event, identifier) => {
        let formIsValid = true;
        const updatedLoginForm = { ...loginFormElement };
        const updatedFormElement = { ...updatedLoginForm[identifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedLoginForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation.message = errorMessage;
        updatedFormElement.touched = true;
        updatedLoginForm[identifier] = updatedFormElement;
        if (forgotPassword) {
            formIsValid = updatedLoginForm[identifier].valid && formIsValid;
        } else {
            for (let identifier in updatedLoginForm) {
                formIsValid = updatedLoginForm[identifier].valid && formIsValid;
            }
        }
        setUserCredentials({ loginFormElement: updatedLoginForm, formIsValid: formIsValid });
    }

    const onForgotPassword = () => {
        const forgotPwd = forgotPassword;
        // loginFormElement['email'] = { ...loginFormElement['email'], value: '', touched: false };
        loginFormElement['userName'] = { ...loginFormElement['userName'], value: '', touched: false };
        setUserCredentials({ loginFormElement: loginFormElement });
        setLoginData((previousData) => ({ ...previousData, forgotPassword: !forgotPwd, loginError: false }));
    }

    const FORM = (
        <form className="login-form" onSubmit={handleSubmit} autoComplete="off">
            {
                !(userType && userType === 'reviewer') && !forgotPassword ?
                    <FormInput
                        elementType={loginFormElement.userName.elementType}
                        elementConfig={loginFormElement.userName.elementConfig}
                        value={loginFormElement.userName.value}
                        invalid={!loginFormElement.userName.valid}
                        shouldValidate={loginFormElement.userName.validation}
                        touched={loginFormElement.userName.touched}
                        errorMessage={loginFormElement.userName.validation.message}
                        changed={(event) => inputChangedHandler(event, 'userName')}
                        blurred={(event) => inputChangedHandler(event, 'userName')} /> :
                    null
            }
            {
                forgotPassword ?
                    <FormInput
                        elementType={loginFormElement.email.elementType}
                        elementConfig={loginFormElement.email.elementConfig}
                        value={loginFormElement.email.value}
                        invalid={!loginFormElement.email.valid}
                        shouldValidate={loginFormElement.email.validation}
                        touched={loginFormElement.email.touched}
                        errorMessage={loginFormElement.email.validation.message}
                        changed={(event) => inputChangedHandler(event, 'email')}
                        blurred={(event) => inputChangedHandler(event, 'email')} /> :
                    null
            }
            {
                forgotPassword ?
                    null :
                    <FormInput
                        elementType={loginFormElement.password.elementType}
                        elementConfig={loginFormElement.password.elementConfig}
                        value={loginFormElement.password.value}
                        invalid={!loginFormElement.password.valid}
                        shouldValidate={loginFormElement.password.validation}
                        touched={loginFormElement.password.touched}
                        errorMessage={loginFormElement.password.validation.message}
                        changed={(event) => inputChangedHandler(event, 'password')}
                        blurred={(event) => inputChangedHandler(event, 'password')} />
            }
            <CustomButton
                type="submit"
                value="Submit"
                inverted="inverted"
                disabled={!userCredentials.formIsValid}
                style={preAuthBtnStyle}>
                {forgotPassword ? 'SEND RESET LINK' : 'LOG IN'}
            </CustomButton>
            {
                (userType && userType === 'reviewer') ?
                    <CustomButton
                        type="button"
                        inverted="inverted"
                        style={preAuthBtnStyle}
                        click={(e) => history.push('/')}>
                        BACK
                    </CustomButton> :
                    null
            }
            {
                <CustomButton
                    type="button"
                    inverted="inverted"
                    style={preAuthBtnStyle}
                    click={(e) => history.push('/')}>
                    BACK
                </CustomButton>
            }
            {
                forgotPassword ?
                    null :
                    <div className="pre-auth-link" onClick={onForgotPassword}>
                        Forgot password?
                    </div>
            }
        </form>
    );

    return (
        <PreAuthLayoutComponent isNormal={false}>
            <div className='guest-login'> {(userType && userType === 'reviewer') ? 'Reviewer Login' : 'User Login'} </div>
            {
                (loginError && !forgotPassword) ?
                    <PreAuthCommonValidation validation={true}>
                        {
                            !(userType && userType === 'reviewer') ?
                                loginErrorMessage :
                                'Invalid login. Make sure you\'ve entered the right credentials.'
                        }
                    </PreAuthCommonValidation> :
                    (loginError && forgotPassword) ?
                        <PreAuthCommonValidation validation={true}>
                            {loginErrorMessage}
                        </PreAuthCommonValidation> :
                        null
            }
            {
                (!loginError && !passwordSent) ?
                    <PreAuthCommonValidation>
                        {/* You need to provide your reviewer's account password to login. */}
                        {
                            forgotPassword ?
                                'Please provide your registered email address to send the password reset link.' :
                                'Please provide your registered account credentials to login.'
                        }
                    </PreAuthCommonValidation> :
                    null
            }
            {
                passwordSent ?
                    <PreAuthCommonValidation>
                        Please check your email and follow the instructions to reset your password.
                    </PreAuthCommonValidation> :
                    FORM
            }
            {
                passwordSent && (
                    <CustomButton
                        type="button"
                        inverted="inverted"
                        style={preAuthBtnStyle}
                        click={(e) => history.push('/')}>
                        BACK
                    </CustomButton>
                )
            }
            {
                !passwordSent && (
                    <div className='link-container'>
                        <div className='link-for-signup'>
                            Not registered yet? <Link to={'/register'}>Sign Up</Link>
                        </div>
                    </div>
                )
            }
        </PreAuthLayoutComponent>
    )
};

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val, text = '') => dispatch(setLoader(val, text)),
    setCurrentUserData: (userData) => dispatch(setCurrentUser(userData))
});

const mapStateToProps = createStructuredSelector({
    appId: getAppId
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);