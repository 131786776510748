import { createSelector } from "reselect";

const selectedModal = state => state.modal;

export const getModalData = createSelector(
    [selectedModal],
    (modal) => modal?.modalData
);

export const getSubmitModalData = createSelector(
    [selectedModal],
    (modal) => modal?.modalPostData
);

export const fetchSubmitModalData = ({ modal }) => {
    return modal?.modalPostData
};

export const getUserErrorModalData = createSelector(
    [selectedModal],
    (modal) => modal?.userErrorModalData
);

export const getNewSampleAttributesModalData = createSelector(
    [selectedModal],
    (modal) => modal?.newSampleAttributesModalData
);

export const getViewSampleAttributesModalData = createSelector(
    [selectedModal],
    (modal) => modal?.viewSampleAttributesModalData
);

export const getNewCohortDimension = ({ modal }) => {
    return modal?.cohortDimension;
}

export const getSampleBlockClickedStatus = ({ modal }) => {
    return modal?.isSampleBlockClicked;
}