/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useRef } from 'react';
import useState from 'react-usestateref';
import Tree from "react-d3-tree";
import { useCenteredTree } from './data/helpers';
import { networkTreeData } from './data/network-data';
import './analysis-network.styles.scss';
import { getMainContentScrollData, getNetworkSearchVal } from '../../../../../redux/app/app.selectors';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ucsfInstance } from '../../../../../core/axios/ucsf';
import { setLoader, setNetworkModeOn, setRouteTitleObject } from '../../../../../redux/app/app.actions';
import CustomButton from '../../../../../core/components/custom-button/custom-button.component';
import { setModalData } from '../../../../../redux/modal/modal.actions';
import { setAttributeFiles, setSelectedSampleAttributeSet, triggerDataMenu } from '../../../../../redux/collections/collections.actions';
import AppService from '../../../../../core/services/app-service';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { COMPONENT_APIS } from '../../../../../core/utils/omi-api';
import NetworkNode from '../../../../../core/components/network-node/network-node';
import { ATTRIBUTE_LIST_ORDER, ATTRIBUTE_LIST_ORDER_DC_ATTRIBUTES, ATTRIBUTE_LIST_ORDER_MDC, ATTRIBUTE_LIST_ORDER_SOURCE } from '../../collection/network/data/network-data';
import { setSelectedAnalysisItem, triggerAnalysisMenu } from '../../../../../redux/analyses/analyses.actions';
import { CONST } from '../../../../../core/utils/omi-constants';
import { getNetworkDataState } from '../../../../../redux/data-saver/data-saver.selectors';
import { setNetworkDataState } from '../../../../../redux/data-saver/data-saver.actions';

const PATH = {
    STEP: 'step',
    STRAIGHT: 'straight'
};

const AnalysisNetworkView = (props) => {
    const {
        match,
        history,
        mainScrollContentData, // selectedDataItem,
        setSelectedSampleAttributeSet,
        networkSearchVal,
        location,
        setNetworkModeOn,
        triggerAnalysisMenu,
        triggerDataMenu,
        networkStateData,
        setModalData
    } = props;
    let [zoomVal, setZoomVal, zoomValRef] = useState(CONST.networkMaxScale); // CONST.networkMaxScale
    const [zoomValOnScroll, setZoomValOnScroll] = useState(1); // CONST.networkMaxScale
    const [attributeData, setAttributeData, attributeDataRef] = useState(null);
    const [translate, containerRef] = useCenteredTree();
    const [translateData, setTranslateData, translateDataRef] = useState(null); // { x: 0, y: 0 }
    const [maxScale, setMaxScale, maxScaleRef] = useState(CONST.networkMaxScale); // useState(1);
    const [networkTreeDataValues, setNetworkTreeDataValues] = useState(null);
    const [analysisData, setAnalysisData] = useState(null);
    const [treeUpdates, setTreeUpdates, treeUpdatesRef] = useState(false);
    const [treeView, setTreeView] = useState('horizontal');
    let [expansion, setExpansion] = useState(false);
    let [isHorizontal, setIsHorizontal] = useState(true);
    let [containerStyle, setContainerStyle] = useState({
        width: "80vw", // "100vw",
        height: "100%" // "80vh" // marginTop: "150px"
    });
    const networkTree = { ...networkTreeData };
    const nodeSize = { x: 140, y: 52 }; // { x: 200, y: 200 };
    const foreignObjectProps = { height: nodeSize.y, x: -4, y: -28 };
    let tabIndex = 0;
    let api = (match.params.type === 'data-sets') ? COMPONENT_APIS.DATA_SETS : COMPONENT_APIS.SEARCH_ANALYSES;
    const dispatch = useDispatch();
    const treeRef = useRef();
    const queryParams = AppService.fetchQueryParams(location.search);
    const iD = match.params.type === 'data-sets' ? queryParams.dsId : queryParams.analysisId;
    const mainContentWindow = document.getElementById('main_content');
    const { name } = AppService.getBrowserInfo();
    const browserName = name;

    useEffect(() => {
        if (mainScrollContentData) { setAttributeData(null); }
    }, [mainScrollContentData]);

    useEffect(() => {
        setAttributeData(null); // setSelectedSampleAttributeSet();
        fetchAnalysisData();
        return () => {
            setNetworkTreeDataValues(null);
            triggerAnalysisMenu(false);
            triggerDataMenu(false);
            AppService.removeStorageVal('translateData');
            AppService.removeStorageVal('zoomVal');
        } // }, [analysisData]);
    }, [history?.location?.pathname]);

    useEffect(() => {
        if (networkSearchVal !== '' && networkSearchVal !== null) {
            dispatch(setLoader(true));
            triggerAnalysisMenu(false);
            ucsfInstance.get(COMPONENT_APIS.NETWORK(iD)).then(response => {
                if (networkSearchVal !== 'reset') {
                    response.data = buildSubTree(networkSearchVal, response.data) || response.data;
                }
                setAttributeData(null);
                setNetworkTreeDataValues(response.data);
                dispatch(setLoader(false));
                setNetworkCoordinates();
                setTimeout(() => {
                    triggerAnalysisMenu(true);
                    AppService.renderTreeElem(browserName);
                }, CONST.leftPanelOpenTimeForNetworkRender);
            }).catch(error => {
                console.log('ERROR : ', error);
                dispatch(setLoader(false));
            });
        }
    }, [networkSearchVal]);

    const networkDataProcessing = (data) => {
        let { state, response } = data;
        setZoomVal(CONST.networkMaxScale);
        setNetworkTreeDataValues(response.data);
        dispatch(setLoader(false));
        setNetworkCoordinates(state);
        setTimeout(() => {
            triggerAnalysisMenu(true);
            AppService.renderTreeElem(browserName);
        }, CONST.leftPanelOpenTimeForNetworkRender);
        if (!mainContentWindow) { return; }
        setTimeout(() => {
            mainContentWindow.scroll({ top: mainContentWindow.scrollHeight, behavior: 'smooth' });
        }, 25);
    }

    const fetchAnalysisData = async (state = null) => {
        let networkResponseData = {};
        await triggerAnalysisMenu(false);
        if (state) { switchTreeView(state); }
        if (networkStateData && networkStateData['iD'] && networkStateData['iD'] === iD) {
            let { apiRes, networkResponse } = networkStateData;
            setAnalysisData(apiRes.data);
            setLinkRouteData(apiRes);
            networkDataProcessing({ response: networkResponse, state });
        } else {
            await dispatch(setLoader(true));
            await ucsfInstance.get(`${api}/${iD}/`).then(res => {
                networkResponseData['iD'] = iD;
                networkResponseData['apiRes'] = res;
                setAnalysisData(res.data);
                setLinkRouteData(res);
                ucsfInstance.get(COMPONENT_APIS.NETWORK(iD)).then(response => {
                    networkResponseData['networkResponse'] = response;
                    dispatch(setNetworkDataState(networkResponseData));
                    networkDataProcessing({ response, state });
                }).catch(error => {
                    console.log('ERROR : ', error);
                    dispatch(setLoader(false));
                });
            }).catch(error => {
                console.log('COLLECTION-DATA-ERROR:NETWORK -> ', error);
                dispatch(setLoader(false));
            });
        }
    };

    const setLinkRouteData = (response) => {
        let type = match.params.type;
        let attributes = response.data['attributes'];
        let analysisTitleVal = (type !== 'data-sets') ? attributes['Title'] : '';
        let dsTitleVal = (type === 'data-sets') ? `${attributes['Title']} ${attributes['Version']}` : '';
        dispatch(setAttributeFiles(response.data['files']));
        if (type === "data-sets") {
            ucsfInstance.get(COMPONENT_APIS.ANALYSES(iD)).then(res => {
                let analysisAttributes = res.data['attributes'];
                dispatch(setRouteTitleObject({ analysisTitle: analysisAttributes['Title'], dsTitle: dsTitleVal }));
            });
        } else {
            dispatch(setRouteTitleObject({ analysisTitle: analysisTitleVal, dsTitle: dsTitleVal }));
        }
    }

    const setNetworkCoordinates = (state = null) => {
        if (treeRef.current && state) {
            treeRef.current ? treeRef.current.render() : setTranslateData(treeRef.current.state.d3.translate);
            let networkSpaceElem = document.getElementById('d3-tree-container-analysis');
            if (networkSpaceElem) {
                const { width, height } = networkSpaceElem.getBoundingClientRect();
                if (state === 'vertical') {
                    setTranslateData({ x: width / 2, y: height / 2.75 });
                } else {
                    setTranslateData({ x: width / 7.75, y: height / 2 });
                }
            }
        } else {
            if (treeRef.current) {
                treeRef.current ? treeRef.current.render() : setTranslateData(treeRef.current.state.d3.translate);
                setTranslateData(treeRef.current.state.d3.translate);
            }
        }
        if (!mainContentWindow) { return; }
        setTimeout(() => {
            mainContentWindow.scroll({ top: mainContentWindow.scrollHeight, behavior: 'smooth' });
        }, 50);
    }

    const buildSubTree = (networkSearchVal, root) => {
        let newChildren = [];
        if (root) {
            for (let i = 0; i < root.children.length; i++) {
                let child = buildSubTree(networkSearchVal, root.children[i]);
                if (child) {
                    newChildren.push(child);
                }
            }
            if (newChildren.length > 0) {
                root.children = newChildren;
            }
            if (newChildren.length > 0 || root.name.toLowerCase().indexOf(networkSearchVal.toLowerCase()) !== -1) {
                return root;
            }
        }
        return null;
    };

    const processNodeElementData = (rd3tProps) => {
        let { nodeDatum, foreignObjectProps } = rd3tProps;
        const foreignProps = { ...foreignObjectProps };
        nodeDatum['hasArrow'] = (
            nodeDatum['parentType'] &&
            nodeDatum['parentType'] === 'transformation' &&
            nodeDatum['name'] !== 'Source' &&
            isHorizontal
        ) ? true : false;
        tabIndex += 1;
        nodeDatum['tabIndex'] = tabIndex;
        if (nodeDatum && nodeDatum.type && nodeDatum.type === 'transformation') {
            nodeDatum['nodeType'] = nodeDatum.name.toLowerCase();
            nodeDatum['size'] = 'circle';
            if (nodeDatum.children && nodeDatum.children.length) {
                nodeDatum.children.map(child => {
                    child['hasArrow'] = isHorizontal ? true : false;
                    child['totalLen'] = nodeDatum.children.length;
                    child['parentType'] = 'transformation';
                    return child;
                });
                switch (nodeDatum.children.length) {
                    case 2:
                        nodeDatum.children[0]['arrowTransform'] = '-35deg';
                        nodeDatum.children[1]['arrowTransform'] = '35deg';
                        break;
                    case 3:
                        nodeDatum.children[0]['arrowTransform'] = '-49deg';
                        nodeDatum.children[2]['arrowTransform'] = '49deg';
                        break;
                    default:
                        nodeDatum.children[0]['arrowTransform'] = '0deg';
                        break;
                }
            }
        } else {
            nodeDatum['size'] = getSizeType(nodeDatum.name);
        }
        if (nodeDatum.children && nodeDatum.children.length > 1) {
            nodeDatum.children.forEach(child => {
                if (child.type === 'transformation') {
                    child['hasSiblings'] = true;
                    child['siblingCount'] = nodeDatum.children.length;
                }
            });
        } else if (nodeDatum.children && nodeDatum.children.length === 1) {
            if (
                nodeDatum.children[0]['type'] === 'transformation' &&
                nodeDatum.children[0]['name'].toLowerCase() !== 'rdp' &&
                nodeDatum.children[0]['name'].toLowerCase() !== 'mdc'
            ) {
                nodeDatum.children[0]['siblingCount'] = 1;
            }
        }
        rd3tProps['foreignObjectProps'] = getForeignObjectProps(nodeDatum, foreignProps, 'name');
        rd3tProps['foreignObjectProps'] = getForeignObjectProps(nodeDatum, foreignProps, 'type');
        return rd3tProps;
    };

    const getForeignObjectProps = (nodeDatum, foreignProps, key) => {
        foreignProps['width'] = getObjectWidth(nodeDatum.size);
        if (key === 'name') {
            switch (nodeDatum[key]) {
                case 'Source':
                    foreignProps['x'] = isHorizontal ? -100 : -52;
                    foreignProps['y'] = isHorizontal ? foreignProps['y'] : -42;
                    return foreignProps;
                case 'Raw Data':
                    foreignProps['x'] = isHorizontal ? foreignProps['x'] : -25;
                    if (nodeDatum.children && nodeDatum.children.length > 1) {
                        foreignProps['x'] = -25;
                    }
                    return foreignProps;
                case 'Data Collection Attributes':
                    foreignProps['x'] = isHorizontal ? foreignProps['x'] : -72;
                    return foreignProps;
                default:
                    foreignProps['x'] = isHorizontal ? foreignProps['x'] : -15;
                    if (nodeDatum.type === 'transformation') {
                        if (!nodeDatum['hasSiblings']) { foreignProps['x'] = isHorizontal ? -10 : -15; }
                        if (nodeDatum[key] === 'RDP') { foreignProps['x'] = -15; }
                    } else {
                        foreignProps['x'] = -48;
                    }
                    return foreignProps;
            }
        } else if (key === 'type') {
            switch (nodeDatum[key]) {
                case 'Dataset':
                    if (nodeDatum.children && nodeDatum.children.length > 1) {
                        foreignProps['x'] = isHorizontal ? -35 : -52;
                    }
                    if (nodeDatum.children && nodeDatum.children.length <= 1) {
                        foreignProps['x'] = -7;
                    }
                    if (nodeDatum.children && nodeDatum.children.length <= 1 && nodeDatum['parentType'] !== 'transformation') {
                        foreignProps['x'] = isHorizontal ? -15 : -52;
                    }
                    if (nodeDatum.children && nodeDatum.children.length <= 1 && nodeDatum['parentType'] === 'transformation') {
                        foreignProps['x'] = -48;
                    }
                    return foreignProps;
                default:
                    return foreignProps;
            }
        }
    };

    const getObjectWidth = (size) => {
        switch (size) {
            case 'xsmall':
                return 52;
            case 'xlarge':
                return 140;
            case 'large':
                return 104;
            case 'medium':
                return 84;
            case 'small':
                return 70;
            case 'circle':
                return 34;
            default:
                return 140;
        }
    };

    const getSizeType = (value) => {
        switch (value) {
            case 'Raw Data':
                return 'xsmall';
            case 'Preprocessed Data':
            case 'Data Collection Attributes':
                return 'xlarge';
            case 'Covariation Networks':
                return 'large';
            case 'Data Collection':
            case 'Processed Data':
            case 'Dataset':
                return 'medium'; // return 'small';
            default:
                return 'large';
        }
    };

    const setZoomValue = (type) => { // setMaxScale(1);
        const fixedVal = 0.02525; // 0.2525; // 0.0525; // 0.2525;
        // let zoomValue = (zoomValRef.current) ? zoomValRef.current : zoomVal;
        let zoomValFromLocalStorage = Number(AppService.getStorageValue('zoomVal'));
        let translateDataFromLocalStorage = JSON.parse(AppService.getStorageValue('translateData'));
        if (type === 'in') {
            zoomValFromLocalStorage = zoomValFromLocalStorage + 0.052; // zoomValOnScroll
        } else {
            zoomValFromLocalStorage = zoomValFromLocalStorage - 0.052; // zoomValOnScroll
        }
        setTranslateData(translateDataFromLocalStorage);
        if (zoomValFromLocalStorage < fixedVal) {
            setZoomVal(fixedVal);
        } else {
            if (zoomValFromLocalStorage >= CONST.networkMaxScale) {
                setZoomVal(CONST.networkMaxScale);
            } else {
                setZoomVal(zoomValFromLocalStorage);
            }
        }
        setAttributeData(null);
    };

    const getMouseOverData = (event) => {
        if (event && !attributeDataRef.current && !treeUpdatesRef.current) {
            setAttributeData(event);
        } else if (
            event &&
            attributeDataRef.current &&
            attributeDataRef.current.__rd3t.id !== event.__rd3t.id
        ) {
            setAttributeData(event);
        } else if (
            event &&
            attributeDataRef.current &&
            attributeDataRef.current.__rd3t.id === event.__rd3t.id &&
            !treeUpdatesRef.current
        ) {
            return;
        } else {
            setAttributeData(null);
        }
    };

    const onTranslate = (direction = '') => {
        // setMaxScale(1);
        let translateObj = null;
        let translateDataFromLocalStorage = JSON.parse(AppService.getStorageValue('translateData'));
        let zoomValFromLocalStorage = Number(AppService.getStorageValue('zoomVal'));
        if (translateDataFromLocalStorage) {
            translateObj = translateDataFromLocalStorage; // translateDataRef.current;
        } else {
            translateObj = translate;
        }
        setZoomVal(zoomValFromLocalStorage);
        if (direction === 'left') {
            translateObj.x += 60;
            setTranslateData({ ...translateObj });
        } else if (direction === 'right') {
            translateObj.x -= 60;
            setTranslateData({ ...translateObj });
        } else {
            translateObj.x -= 0.5;
            translateObj.y -= 0.5;
            setTranslateData({ ...translateObj });
        }
        setAttributeData(null);
    };

    const openSampleAttributesModal = (modalData) => {
        if (modalData['_id']) {
            let apiString = api;
            dispatch(setLoader(true));
            setSelectedSampleAttributeSet(); // selectedDataItem.id
            modalData['isDataSet'] = false;
            if (modalData.name.toLowerCase().includes('data collection')) {
                apiString = COMPONENT_APIS.DATA_COLLECTIONS;
            } else if (
                modalData.name.toLowerCase().includes('analyses') ||
                modalData.name.toLowerCase().includes('analysis')
            ) {
                apiString = COMPONENT_APIS.SEARCH_ANALYSES;
            } else if (
                modalData.name.toLowerCase().includes('.') ||
                modalData.name.toLowerCase().includes('dataset')
            ) {
                modalData['isDataSet'] = true;
                apiString = COMPONENT_APIS.DATA_SETS;
            }
            ucsfInstance.post(`${apiString}/sample-attributes/`, { id: modalData['_id'] }).then(sampleAttributeResponse => {
                if (sampleAttributeResponse.data && sampleAttributeResponse.data.sample_attributes) {
                    const responseData = sampleAttributeResponse.data;
                    setModalData({
                        type: 'sample-attribute-modal',
                        modalData: {
                            ...modalData,
                            sampleAttributes: responseData.sample_attributes,
                            optionalAttributes: responseData.optional_attributes
                        },
                        data: {
                            title: 'Sample Attributes',
                            btnText: 'Reset Sample Attributes'
                        }
                    }); // setSampleAttributeData({ sampleAttributes: responseData.sample_attributes }); // responseData.sample_attributes;
                }
                dispatch(setLoader(false));
            }).catch(error => {
                setModalData({
                    type: 'sample-attribute-modal',
                    modalData: { ...modalData, sampleAttributes: [], optionalAttributes: [] },
                    data: {
                        title: 'Sample Attributes',
                        btnText: 'Reset Sample Attributes'
                    }
                });
                dispatch(setLoader(false));
            });
        }
    };

    const linkAction = (attribute, val, attributeData = null) => {
        const { key, href, value } = attribute || {};
        let baseUrl = val;
        if (key === 'Accession ID') {
            baseUrl = `https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${val}`;
            if (val.toLowerCase().includes('e-mtab')) {
                baseUrl = `https://www.ebi.ac.uk/arrayexpress/experiments/${val}`;
            }
            window.open(baseUrl);
        } else if (key === 'BioProject ID') {
            baseUrl = `https://www.ncbi.nlm.nih.gov/bioproject/${val}`;
            window.open(baseUrl);
        } else if (key === 'PubMed ID') {
            baseUrl = `https://pubmed.ncbi.nlm.nih.gov/${val}`;
            window.open(baseUrl);
        } else if (key === 'Omicon ID' || key === 'Analysis Omicon ID') {
            let rowObject = attributeData['attributes'];
            if (rowObject) {
                let baseUrl = `/home/collections/data-collections`;
                let searchParam = `dcId=${rowObject['_id']}`;
                let urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                if ((match.params.type && match.params.type.toLowerCase().trim() === 'data-sets') || (attributeData['type'] && attributeData['type'] === 'Dataset')) {
                    baseUrl = `/home/collections/data-sets`;
                    urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                    dispatch(setLoader(true));
                    ucsfInstance.get(COMPONENT_APIS.GET_DC_DETAILS(rowObject['_id'])).then(async dcData => {
                        await dispatch(setLoader(false));
                        searchParam = await `dcId=${dcData.data['_id']}&dsId=${rowObject['_id']}`;
                        await window.open(`${urlAddress}/attributes?${searchParam}&contentType=collections&from=advancedSearch`);
                    }).catch(err => dispatch(setLoader(false)));
                } else if ((match.params.type && match.params.type.toLowerCase().trim() === 'data-collections') || (attributeData['name'] && attributeData['name'] === 'Data Collection Attributes')) {
                    window.open(`${urlAddress}/attributes?${searchParam}&contentType=collections&from=advancedSearch`);
                } else if (match.params.type === 'analysis-data') {
                    let searchUrl = `analysisId=${rowObject['_id']}`;
                    let searchParams = `${searchUrl}&mainAnalysisId=${rowObject['_id']}`;
                    baseUrl = `/home/analyses/analysis-data`;
                    urlAddress = `${window.location.protocol}//${window.location.host}${baseUrl}`;
                    if (attributeData['name'] && attributeData['name'] === 'Covariation network') {
                        searchUrl = `analysisId=${rowObject['analysisId']}`;
                        if (key === 'Omicon ID') {
                            searchParams = `${searchUrl}&mainAnalysisId=${rowObject['analysisId']}&analysisFileFolderId=${rowObject['_id']}`;
                            window.open(`${urlAddress}/files?${searchParams}&contentType=analyses&from=advancedSearch`);
                        } else if (key === 'Analysis Omicon ID') {
                            searchParams = `${searchUrl}&mainAnalysisId=${rowObject['analysisId']}`;
                            window.open(`${urlAddress}/attributes?${searchParams}&contentType=analyses&from=advancedSearch`);
                        }
                    } else if (attributeData['name'] && attributeData['name'] === 'Enrichment results') {
                        searchUrl = `analysisId=${rowObject['analysisId']}`;
                        searchParams = `${searchUrl}&mainAnalysisId=${rowObject['analysisId']}`;
                        window.open(`${urlAddress}/attributes?${searchParams}&contentType=analyses&from=advancedSearch`);
                    } else {
                        window.open(`${urlAddress}/attributes?${searchParams}&contentType=analyses&from=advancedSearch`);
                    }
                }
            }
        } else if (href || (value.includes('http') && typeof value === 'string')) {
            window.open(value);
        }
    };

    const splitValues = (values, attribute, attributeData = null) => {
        if (!Array.isArray(values)) {
            if (typeof values === 'string' && values.trim() === '') { return 'NA'; }
            let valArray = [];
            if (values.includes('|')) {
                valArray = values.split('|');
            } else if (values.includes(',')) {
                valArray = values.split(',');
            } else {
                valArray = values.split(' ');
            }
            return valArray.map(val =>
                <label
                    className={(attribute['key'] === 'Source' || val.toLowerCase() === 'na') ? 'normal' : ''}
                    key={val.trim()}
                    onClick={() => (val.toLowerCase() !== 'na') ? linkAction(attribute, val.trim(), attributeData) : {}}>
                    {val.trim()}
                </label>
            );
        } else if (Array.isArray(values)) {
            return values.map(val =>
                <label
                    className={(attribute['key'] === 'Source' || val.toLowerCase() === 'na') ? 'normal' : ''}
                    key={val.trim()}
                    onClick={() => (val.toLowerCase() !== 'na') ? linkAction(attribute, val.trim(), attributeData) : {}}>
                    {val.trim()}
                </label>
            );
        }
        return '';
    };

    const attributeComponent = (attributeData) => {
        const attributeRef = createRef();
        let attributeList = [];
        const { attributes, files, name, type } = attributeData;
        let innerHeight = window.innerHeight - 120; // - 120; // 120; // 86
        let leftPos = attributeData.clientX - 616;
        let scrolltopVal = (mainScrollContentData?.target?.scrollTop ? mainScrollContentData?.target?.scrollTop : 0);
        let topPos = (attributeData.clientY - 170) + 10 + scrolltopVal; // 170 // 180
        if (expansion) {
            topPos = attributeData.clientY - 40;
            innerHeight = window.innerHeight - 50;
            leftPos += 316;
        }
        // const drContainer = document.querySelector('.d3-tree-container');
        let drContainer = document.getElementById('d3-tree-container-analysis');
        if (Array.isArray(attributes)) {
            attributes.forEach(attribute => {
                Object.keys(attribute).forEach(key => {
                    if (['Source', 'Raw Data'].indexOf(attributeData.name) >= 0) {
                        if (!Array.isArray(attribute[key])) {
                            if (attribute[key].toLowerCase() !== 'na') {
                                attributeList.push({ key: key, value: attribute[key], href: true });
                            } else {
                                attributeList.push({ key: key, value: attribute[key] });
                            }
                        } else {
                            const len = attribute[key].length - 1;
                            attributeList.push({
                                key: key,
                                value: attribute[key].map((val, i) => (i < len) ? `${val}, ` : val),
                                href: (
                                    ['Accession ID', 'BioProject ID', 'PubMed ID'].indexOf(key) >= 0
                                ) ? true : false
                            });
                        }
                    } else {
                        attributeList.push({ key: key, value: attribute[key] });
                    }
                });
            });
        } else {
            Object.keys(attributes).forEach(attribute => {
                if (['Raw Data'].indexOf(attributeData.name) >= 0) {
                    if (attributes[attribute].toLowerCase() !== 'na') {
                        attributeList.push({ key: attribute, value: attributes[attribute], href: true });
                    } else {
                        attributeList.push({ key: attribute, value: attributes[attribute] });
                    }
                } else {
                    if (['OR', 'QN', 'BC'].indexOf(attributeData.name) >= 0) {
                        if (['Owner'].indexOf(attribute) < 0) {
                            attributeList.push({ key: attribute, value: attributes[attribute] });
                        }
                    } else if ((['MDC'].indexOf(attributeData.name) >= 0)) {
                        if (['Version', 'Working Directory'].indexOf(attribute) < 0) {
                            attributeList.push({ key: attribute, value: attributes[attribute] });
                        }
                    } else if ((['RDP'].indexOf(attributeData.name) >= 0)) {
                        if (['Raw Data Processing Code'].indexOf(attribute) < 0) {
                            attributeList.push({ key: attribute, value: attributes[attribute] });
                        }
                    } else {
                        attributeList.push({
                            key: attribute, value: attributes[attribute],
                            href: (['Omicon ID', 'Analysis Omicon ID'].indexOf(attribute) >= 0) ? true : false
                        });
                    }
                }
            });
        }
        attributeList = AppService.orderRecords(attributeList, ATTRIBUTE_LIST_ORDER, 'key');
        if (attributeData.name === 'Source') {
            attributeList = AppService.orderRecords(attributeList, ATTRIBUTE_LIST_ORDER_SOURCE, 'key');
        } else if (attributeData.name === 'Data Collection Attributes') {
            attributeList = AppService.orderRecords(attributeList, ATTRIBUTE_LIST_ORDER_DC_ATTRIBUTES, 'key');
        } else if (attributeData.name === 'MDC') {
            attributeList = AppService.orderRecords(attributeList, ATTRIBUTE_LIST_ORDER_MDC, 'key');
        }
        if (!expansion) {
            if (attributeData.clientX < 616) {
                leftPos = 12;
            } else if ((drContainer.clientWidth - attributeData.clientX) < 0) {
                const diff = drContainer.clientWidth - attributeData.clientX;
                leftPos = leftPos + diff;
            }
        } else {
            if (attributeData.clientX < 316) {
                leftPos = 12;
            } else if ((drContainer.clientWidth - (attributeData.clientX + 316)) < 0) {
                const diff = drContainer.clientWidth - (attributeData.clientX + 316);
                leftPos = leftPos + diff;
            }
        }
        if (topPos > (innerHeight - attributeData.clientY)) { // 250 // 333
            // topPos = (attributes['_id']) ? (topPos - 333) - 42 : (topPos - 200) - 122; // - 72;
            topPos = (attributes['_id']) ? (topPos - 333) : (topPos - 200) - 67; // - 72;
        } else {
            topPos += 10;
        }
        return (
            <div
                className={`analysis-node-attribute-container${attributes['_id'] ? ' sample-attribute' : ''}`}
                style={{ top: `${topPos}px`, left: `${leftPos}px` }}
                ref={attributeRef}
                onMouseLeave={() => { setTimeout(() => { setAttributeData(null); }, 500) }}>
                <div className="content-1">
                    <div className="close-btn-container">
                        <div style={{ padding: '20px' }}></div>
                        <div className='container-title'> {type && type.toLowerCase().includes('dataset') ? `${type} ` : ''} {name} </div>
                        <div className="close-btn" onClick={() => {
                            setTreeUpdates(true);
                            setAttributeData(null);
                            setTimeout(() => { setTreeUpdates(false); }, 500);
                        }}></div>
                    </div>
                    <div className={`detail-container${(!attributes['_id']) ? ' lg' : ''}`}>
                        <div className="attributes">
                            <div className="title">Attributes</div>
                            <div className="attribute-body">
                                {
                                    attributeList.map((attribute, index) => {
                                        let decimalPlace = 3;
                                        if (attribute?.key?.toLowerCase() === 'relsignum') { decimalPlace = 4; }
                                        return (
                                            (attribute.key !== '_id' && attribute.key !== 'analysisId') ?
                                                <div className="item line" key={index + 1}>
                                                    <span className="key">{attribute.key}:</span>&nbsp;
                                                    {
                                                        attribute.href ?
                                                            splitValues(attribute.value, attribute, attributeData) :
                                                            AppService.decimalValCorrection(attribute.value, decimalPlace)
                                                    }
                                                </div> :
                                                null
                                        );
                                    })
                                }
                            </div>
                        </div>
                        {
                            <div className="attributes">
                                <div className="title">Files</div>
                                <div className="attribute-body">
                                    {
                                        (['Source', 'Raw Data'].indexOf(attributeData.name) < 0) && files && files.length ?
                                            files.map((file, index) => {
                                                return (
                                                    <div className="item" key={index + 1}>
                                                        <div
                                                            className="value file"
                                                            onClick={() => {
                                                                if (file.name.toLowerCase().includes('.r')) {
                                                                    // return filePreviewClick(file, 'download');
                                                                    return filePreviewClick(file, '');
                                                                } else {
                                                                    if (file.name.toLowerCase().includes('.csv')) {
                                                                        file['sizeDisplayName'] = AppService.formatBytes(file?.size);
                                                                        const limitVal = AppService.fileSizeChecker('preview', file);
                                                                        if (limitVal === '') {
                                                                            return filePreviewClick(file, '');
                                                                        } else {
                                                                            return filePreviewClick(file, 'download');
                                                                        }
                                                                    }
                                                                    return filePreviewClick(file, '');
                                                                }
                                                            }}>
                                                            {file.name}
                                                        </div>
                                                    </div>
                                                );
                                            }) :
                                            <div className="item">
                                                <div className="value inh-attr">NA</div>
                                            </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="content-2">
                    {
                        attributes['_id'] && name?.toLowerCase() !== 'covariation network' ? (
                            <div className="action-container">
                                {/* <div className="view-btn"></div> */}
                                <CustomButton
                                    size="large"
                                    style={{ padding: '0 20px', height: '28px' }}
                                    onClick={() => openSampleAttributesModal({ // ...selectedDataItem,
                                        ...analysisData,
                                        ...attributes,
                                        ...attributeData
                                    })}>
                                    View Sample Attributes
                                </CustomButton>
                            </div>
                        ) : null
                    }
                    {/* <div className={`upload-files ${!attributes['_id'] ? 'disabled' : ''}`} onClick={() => openFileUploadModal({ ...attributeData, ...attributes })}>
                        <div className="upload-img"></div>
                        <div className="title">Upload files</div>
                    </div> */}
                </div>
            </div>
        );
    };

    const filePreviewClick = (rowData, type) => {
        dispatch(setLoader(true));
        const isCSV = rowData.name.toLowerCase().includes('.csv');
        if (!isCSV) {
            if (rowData['key']) {
                AppService.getAwsCredentials().then(async res => {
                    const s3Configuration = await {
                        credentials: {
                            accessKeyId: res['AccessKeyId'],
                            secretAccessKey: res['SecretAccessKey'],
                            sessionToken: res['SessionToken']
                        },
                        region: res['region']
                    };
                    const s3 = await new S3Client(s3Configuration);
                    const url = await getSignedUrl(s3, new GetObjectCommand({
                        Bucket: res['bucket'],
                        Key: rowData['key']
                    }), {
                        expiresIn: 15 * 60
                    });
                    rowData['fileType'] = AppService.getFileType(rowData['name']);
                    const driveUrl = AppService.extractURL(url, rowData['fileType']);
                    if (type === 'download') {
                        dispatch(setLoader(false));
                        AppService.downloadURL(url);
                        // window.open(url, '_blank');
                        // window.open(`https://drive.google.com/viewerng/viewer?url=${driveUrl}`, '_blank');
                    } else {
                        if (url.toLowerCase().includes('.r') || url.includes('.txt')) {
                            fetch(url).then(res => res.text()).then(rcode => {
                                openAddModal({ ...rowData, url, fileType: AppService.getFileType(url), textData: rcode.replace(/\n/g, "<br />") });
                            });
                        } else {
                            openAddModal({ ...rowData, url, fileType: AppService.getFileType(url) });
                            /* setTimeout(() => {
                                dispatch(setLoader(false));
                                window.open(`https://drive.google.com/viewerng/viewer?url=${driveUrl}`, rowData['name']); // '_blank'
                            }, 500); */
                        }
                    }
                });
            } else {
                AppService.downloadURL(rowData['name']);
                // window.open(rowData['name'], '_blank');
            }
        } else {
            const key = { key: rowData['key'] };
            ucsfInstance.post(COMPONENT_APIS.CSV_FILE_PREVIEW, key).then(res => {
                const result = res.data; // dispatch(setLoader(false));
                setModalData({
                    type: 'file-preview',
                    modalData: {
                        ...rowData,
                        fileType: 'csv',
                        csvGridData: JSON.parse(result['results']),
                        columns: result['columns']
                    },
                    data: {
                        title: rowData.name
                    },
                });
            }).catch(err => {
                dispatch(setLoader(false));
            })
        }
    };

    const openAddModal = (modalData) => {
        setModalData({
            type: 'file-preview',
            modalData,
            data: {
                title: modalData.name
            }
        });
    };

    const openFileUploadModal = (modalData) => {
        setModalData({
            type: 'dc-upload',
            modalData,
            data: {
                title: modalData.name,
                btnText: 'Upload'
            }
        });
    };

    const onDoubleClick = (rd3tProps) => {
        setMaxScale(CONST.networkMaxScale);
        setAttributeData(null);
        rd3tProps.toggleNode();
        // setTimeout(() => { setMaxScale(zoomVal); }, 100);
    };

    const resetNetwork = (state = null) => {
        dispatch(setLoader(true));
        setAttributeData(null);
        setNetworkTreeDataValues(null);
        AppService.removeStorageVal('translateData');
        AppService.removeStorageVal('zoomVal');
        fetchAnalysisData(state);
    };

    const expandNetworkView = (state) => {
        setExpansion(!expansion);
        setAttributeData(null);
        const containerCSS = {
            width: "80vw",
            height: containerStyle.height
        };
        if (state === 'expand') {
            containerCSS.width = "100vw";
        }
        setContainerStyle(containerCSS);
        setNetworkModeOn(state);
    };

    const switchTreeView = (state) => {
        triggerAnalysisMenu(false);
        if (browserName.toLowerCase() !== 'safari') {
            setAttributeData(null);
            setIsHorizontal(!isHorizontal);
            setTreeView(state);
        } else {
            setAttributeData(null);
            setIsHorizontal(!isHorizontal);
            setTreeView(state);
        }
        setNetworkCoordinates(state);
        setTimeout(() => {
            triggerAnalysisMenu(true);
            AppService.renderTreeElem(browserName);
        }, CONST.leftPanelOpenTimeForNetworkRender);
    };

    // <div className="d3-tree-container"></div>
    return (
        <div
            id='d3-tree-container-analysis'
            // className={`${containerStyle.width === '100vw' ? 'scale-up-br' : 'scale-down-br'}`}
            style={containerStyle}
            ref={containerRef}>
            <div className={`control-bar${expansion ? ' expand' : ''}`}>
                <div className="zoom">
                    <div className="in" onClick={() => setZoomValue('in')}></div>
                    <div className="out" onClick={() => setZoomValue('out')}></div>
                    <div className="reset-icon" title="Reset Network" onClick={() => resetNetwork()}></div>
                </div>
                <div className="time-line">
                    <div
                        onClick={() => !expansion ? expandNetworkView('expand') : expandNetworkView('')}
                        className={expansion ? 'collapse-area' : 'expand-area'}
                        title={expansion ? 'Collapse' : 'Expand'}>
                    </div>
                    {/*
                            browserName.toLowerCase() === 'safari' ?
                                null :
                                <div className="rotation" title="Change Orientation" onClick={() => isHorizontal ? switchTreeView('vertical') : switchTreeView('horizontal')}></div>
                        */}
                    <div className="rotation" title="Change Orientation" onClick={() => isHorizontal ? switchTreeView('vertical') : switchTreeView('horizontal')}></div>
                </div>
            </div>
            <div className="translation-bar left" onMouseDown={() => onTranslate('left')}>
                <div className="lt"></div>
            </div>
            <div className="translation-bar right" onClick={() => onTranslate('right')}>
                <div className="rt"></div>
            </div>
            {
                networkTreeDataValues ?
                    <Tree
                        ref={treeRef}
                        data={networkTreeDataValues} // networkTree
                        orientation={treeView}
                        translate={translateData ? translateData : translate} //  ? translateData : translate
                        nodeSize={isHorizontal ? nodeSize : { x: 75, y: 100 }}
                        renderCustomNodeElement={(rd3tProps) => { // renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                            const props = { ...processNodeElementData({ ...rd3tProps, foreignObjectProps }) };
                            return (
                                <NetworkNode
                                    {...props}
                                    attributeData={attributeData}
                                    isHorizontal={isHorizontal}
                                    onForeignObject={(event) => getMouseOverData(event)}
                                    doubleClick={() => onDoubleClick(rd3tProps)} />
                            )
                        }}
                        transitionDuration={250}
                        enableLegacyTransitions={true}
                        pathFunc={PATH.STRAIGHT}
                        scaleExtent={{
                            min: 0.02525, // min: 0.35
                            max: maxScaleRef?.current ? maxScaleRef.current : maxScale
                        }}
                        zoom={zoomValRef.current}
                        collapsible={true}
                        zoomable={true}
                        onUpdate={(event) => {
                            const translation = event.translate;
                            AppService.setStorageValue('translateData', translation);
                            AppService.setStorageValue('zoomVal', event.zoom);
                            const translateObj = translateDataRef?.current ? translateDataRef?.current : translate;
                            if (attributeDataRef.current && translateObj?.x !== translation?.x && translateObj?.y !== translation?.y) {
                                setTreeUpdates(true);
                                setAttributeData(null); // setTranslateData(event.translate); // onTreeUpdate(event, treeRef);
                                setTimeout(() => {
                                    setTreeUpdates(false);
                                }, 500);
                            }
                        }}
                        separation={{
                            nonSiblings: isHorizontal ? 2 : 2,
                            siblings: isHorizontal ? 2.5 : 2
                        }}
                    /> : null
            }
            <div className="legend">
                <div className="processing-types">
                    <div className="type-content">
                        <div className="img rdp">RDP</div>
                        <div className="item-name">Raw Data Processing</div>
                    </div>
                    <div className="type-content">
                        <div className="img mdc">MDC</div>
                        <div className="item-name">Make Data Collection</div>
                    </div>
                    <div className="type-content">
                        <div className="img sn">OR</div>
                        <div className="item-name">Outlier Removal</div>
                    </div>
                    <div className="type-content">
                        <div className="img sn">QN</div>
                        <div className="item-name">Quantile Normalization</div>
                    </div>
                    <div className="type-content">
                        <div className="img sn">BC</div>
                        <div className="item-name">Batch Correction</div>
                    </div>
                    <div className="type-content">
                        <div className="img fm">FM</div>
                        <div className="item-name">Find Modules</div>
                    </div>
                    <div className="type-content">
                        <div className="img ea">EA</div>
                        <div className="item-name">Enrichment Analysis</div>
                    </div>
                </div>
            </div>
            {attributeData ? attributeComponent(attributeData) : null}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    mainScrollContentData: getMainContentScrollData,
    networkSearchVal: getNetworkSearchVal,
    networkStateData: getNetworkDataState
});

const mapDispatchToProps = dispatch => ({
    setModalData: (modalData) => dispatch(setModalData(modalData)),
    setSelectedSampleAttributeSet: () => dispatch(setSelectedSampleAttributeSet(null)),
    setNetworkModeOn: (data) => dispatch(setNetworkModeOn(data)),
    triggerAnalysisMenu: (data) => dispatch(triggerAnalysisMenu(data)),
    triggerDataMenu: (data) => dispatch(triggerDataMenu(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AnalysisNetworkView));