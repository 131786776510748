import patterns from "./patterns";

const formValidityCheck = (updatedFormElement, updatedFormData) => {
    const { value, validation, description } = { ...updatedFormElement };
    const rules = { ...validation };
    let isValid = true, errorMessage = null;
    if (!rules) {
        return { validity: true, errorMessage };
    }
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
        if (!isValid && errorMessage === null) {
            errorMessage = description ? `Enter ${description}` : null; // rules.message;
            if (rules.isGlobalSearch) {
                errorMessage = null;
            }
        }
    }
    if (value && value.length > 0) {
        if (rules.isEmail) {
            const pattern = patterns.email;
            isValid = pattern.test(value) && isValid;
            if (!isValid && errorMessage === null) {
                errorMessage = 'You\'ve entered an incorrect email address.';
            }
        }
        if (rules.isNumeric) {
            const pattern = patterns.numeric;
            isValid = pattern.test(value) && isValid;
            if (!isValid && errorMessage === null) {
                errorMessage = 'Please enter a valid number';
            }
        }
        if (rules.isConfirmPassword) {
            const passwordElement = { ...updatedFormData['password'] };
            const confirmPasswordVal = value;
            const passwordVal = passwordElement.value;
            isValid = (passwordVal && passwordVal.length && (passwordVal === confirmPasswordVal)) && isValid;
            if (!isValid && errorMessage === null) {
                errorMessage = 'Passwords don\'t match';
            }
        }
        if (rules.isCommaSeparated) {
            isValid = !value.includes(';') && isValid;
            if (!isValid && errorMessage === null) {
                errorMessage = 'Please enter comma separated values';
            }
        }
        if (rules.minLength) {
            isValid = (value.length >= rules.minLength) && isValid;
            if (!isValid && errorMessage === null) {
                errorMessage = `Minimum length is ${rules.minLength}`;
            }
        }
        if (rules.maxLength) {
            isValid = (value.length <= rules.maxLength) && isValid;
            if (!isValid && errorMessage === null) {
                errorMessage = `Maximum length is ${rules.maxLength}`;
            }
        }
    }
    return { validity: isValid, errorMessage };
};

export default formValidityCheck;