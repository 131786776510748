import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import appReducer from "./app/app.reducer";
import collectionsReducer from "./collections/collections.reducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import modalReducer from "./modal/modal.reducer";
import analysesReducer from "./analyses/analyses.reducer";
import advancedSearchReducer from "./advanced-search/advanced-search.reducer";
import dataSaverReducer from "./data-saver/data-saver.reducer";
import notificationsReducer from "./notifications/notification.reducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'dataSaver']
    // whitelist: ['user', 'collections', 'analyses', 'dataSaver']
};

const rootReducer = combineReducers({
    user: userReducer,
    app: appReducer,
    collections: collectionsReducer,
    modal: modalReducer,
    analyses: analysesReducer,
    advancedSearch: advancedSearchReducer,
    dataSaver: dataSaverReducer,
    notifications: notificationsReducer
});

export default persistReducer(persistConfig, rootReducer);