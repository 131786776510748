/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ucsfInstance } from '../../../core/axios/ucsf';
import Aux from '../../../core/components/auxiliary/auxiliary';
import Content from '../../../core/components/content/content.component';
import Header from '../../../core/components/header/header.component';
import AppService from '../../../core/services/app-service';
import { getAppId } from '../../../redux/app/app.selectors';
import { setCollectionListDataValue, setPossibleValues } from '../../../redux/collections/collections.actions';
import { setAwsCredentials } from '../../../redux/user/user.actions';
import AnalysisNetworkView from './analyses/network/analysis-network.component';
import './home.styles.scss';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import { Fragment } from 'react';
import { setAnalystsList, setOwnersList, setUsersList } from '../../../redux/data-saver/data-saver.actions';
import { setLoader } from '../../../redux/app/app.actions';
import SecurityService from '../../../core/services/security-service';

const HomePage = (props) => {
    const {
        setDataCollectionList,
        setPossibleValues,
        setOwnersList,
        setAnalystsList,
        setUsersList,
        setLoader
    } = props;

    useEffect(() => {
        let possibleValues = null;
        setLoader(true);
        // DS RESPONSE --------------------
        SecurityService.getSecurityToken() && ucsfInstance.get(COMPONENT_APIS.POSSIBLE_VALUES_DS).then(dsRes => {
            // setLoader(true);
            possibleValues = { dsAttributes: dsRes.data };
            // DC RESPONSE --------------------
            ucsfInstance.get(COMPONENT_APIS.POSSIBLE_VALUES_DC).then(dcRes => {
                // setLoader(true);
                possibleValues = { ...possibleValues, dcAttributes: dcRes.data };
                // ANALYSIS RESPONSE --------------------
                ucsfInstance.get(COMPONENT_APIS.POSSIBLE_VALUES_PJCT).then(analysisRes => {
                    possibleValues = { ...possibleValues, analysisAttributes: analysisRes.data };
                    setPossibleValues(possibleValues);
                    // setLoader(false);
                }).catch(analysisErr => {
                    possibleValues = { ...possibleValues, analysisAttributes: analysisErr };
                    // setLoader(false);
                });
            }).catch(dcErr => {
                possibleValues = { ...possibleValues, dcAttributes: dcErr };
                // setLoader(false);
            });
        }).catch(dsErr => {
            possibleValues = { dsAttributes: dsErr };
            // setLoader(false);
        });
        // OWNERS LIST -----------------------
        SecurityService.getSecurityToken() && ucsfInstance.get(COMPONENT_APIS.OWNERS).then(async owners => {
            setOwnersList(JSON.parse(JSON.stringify(owners.data)));
            // ANALYSTS LIST ----------------------
            ucsfInstance.get(COMPONENT_APIS.ANALYSTS).then(async analysts => {
                setAnalystsList(JSON.parse(JSON.stringify(analysts.data)));
                // USERS LIST -------------------------
                ucsfInstance.get(COMPONENT_APIS.USERS).then(async users => {
                    setUsersList(JSON.parse(JSON.stringify(users.data)));
                }).catch(error => error);
            }).catch(error => error);
        }).catch(error => error);
        // ------------------------------------
        return () => {
            setPossibleValues();
            setDataCollectionList();
        }
    }, []);

    return (
        <Fragment>
            <Header {...props} />
            <Content {...props} />
        </Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    appId: getAppId
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val) => dispatch(setLoader(val)),
    setAwsCredentials: (creds) => dispatch(setAwsCredentials(creds)),
    setDataCollectionList: () => dispatch(setCollectionListDataValue([])),
    setPossibleValues: (data = null) => dispatch(setPossibleValues(data)),
    setOwnersList: (data) => dispatch(setOwnersList(data)),
    setAnalystsList: (data) => dispatch(setAnalystsList(data)),
    setUsersList: (data) => dispatch(setUsersList(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
