import React from 'react';
import { useState } from 'react';
import FormInputComponent from '../../../../../../core/components/form-input/form-input.component';
import AppService from '../../../../../../core/services/app-service';
import formValidityCheck from '../../../../../../core/utils/validator';
import './save-query.styles.scss';

const SaveQueryModal = ({
    changeTrigger
}) => {

    let descriptionForm = AppService.formContent(
        'Query Description',
        'description',
        'Enter query description to be saved',
        'textArea',
        'text',
        'description',
        '', '', '', '', true
    );
    const [saveSearchQueryForm, setSaveSearchQueryForm] = useState({
        formData: { description: { ...descriptionForm } },
        isFormValid: false
    });
    const { formData } = saveSearchQueryForm;


    const inputChangedHandler = (event, identifier) => {
        // FORM-DATA-UPDATE-HANDLER
        let formIsValid = true;
        const updatedSearchQueryForm = { ...formData };
        const updatedFormElement = { ...updatedSearchQueryForm[identifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedSearchQueryForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation['message'] = errorMessage;
        updatedFormElement.touched = true;
        updatedSearchQueryForm[identifier] = updatedFormElement;
        for (let identifier in updatedSearchQueryForm) {
            if (updatedSearchQueryForm[identifier].validation?.required) {
                formIsValid = updatedSearchQueryForm[identifier].valid && formIsValid;
            }
        }
        setSaveSearchQueryForm({ formData: updatedSearchQueryForm, isFormValid: formIsValid });
        changeTrigger({ formData: updatedSearchQueryForm, isFormValid: formIsValid });
    };


    return (
        <div className='query-description'>
            <FormInputComponent
                {...formData['description']}
                changed={(e) => inputChangedHandler(e, 'description')} />
        </div>
    );
}

export default SaveQueryModal;