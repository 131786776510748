export const appActionTypes = {
    SET_LOADER_ON: 'SET_LOADER_ON',
    SET_LOADER_OFF: 'SET_LOADER_OFF',
    SET_APP_ID: 'SET_AP_ID',
    SET_WINDOW: 'SET_WINDOW',
    SET_COLLECTION_UPLOAD_SUCCESS_VAL: 'SET_COLLECTION_UPLOAD_SUCCESS_VAL',
    SET_LOADER_STATE: 'SET_LOADER_STATE',
    SET_IN_LOADER_STATE: 'SET_IN_LOADER_STATE',
    SET_USER_ERROR: 'SET_USER_ERROR',
    SET_MAIN_CONTENT_SCROLL: 'SET_MAIN_CONTENT_SCROLL',
    SET_NETWORK_SEARCH_VAL: 'SET_NETWORK_SEARCH_VAL',
    SET_FILE_UPLOAD_RESET: 'SET_FILE_UPLOAD_RESET',
    SET_ROUTE_TITLES: 'SET_ROUTE_TITLES',
    SET_NETWORK_MODE: 'SET_NETWORK_MODE',
    SET_HOME_PAGE_CLICK: 'SET_HOME_PAGE_CLICK',
    SET_COMMENTS_COUNT: 'SET_COMMENTS_COUNT',
    SET_NOTIF_DATA: 'SET_NOTIF_DATA',
    SET_LANDING_PAGE_SAMPLES: 'SET_LANDING_PAGE_SAMPLES',
    SET_SYSTEM_TABLES_DATA: 'SET_SYSTEM_TABLES_DATA',
    SET_REFRESH_APP_ID: 'SET_REFRESH_APP_ID',
    SET_REFRESH_WINDOW: 'SET_REFRESH_WINDOW'
};
