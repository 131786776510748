import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const GuardedRoute = ({ component: WrappedComponent, currentUser, ...otherProps }) => {
    const isAuthenticated = (currentUser && currentUser.permissions) ? true : false;
    const { path } = otherProps;
    const pathsForGlobalAccess = [
        '/user-verification',
        '/reset-password'
    ];

    return (
        <Route
            {...otherProps} render={(props) => {
                return (
                    (isAuthenticated && pathsForGlobalAccess.indexOf(path?.trim()) < 0) ? (
                        <Redirect to='/home' />
                    ) : (
                        <WrappedComponent {...props} />
                    )
                );
            }} />
    );
}

export default GuardedRoute;