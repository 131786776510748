/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './loading.styles.scss';
import Loader from '../../../assets/loaders/spinner-blue-full.gif';
import PreAuthLayoutComponent from '../../../core/components/pre-auth-layout/pre-auth-layout.component';
import { getAppId, getUcsfWindow } from '../../../redux/app/app.selectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../../redux/user/user.actions';
import AuthService from '../../../core/services/authentication-service';
import SecurityService from '../../../core/services/security-service';
import { setLoader, setUserErrorData } from '../../../redux/app/app.actions';
import { ucsfInstance } from '../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { CONST } from '../../../core/utils/omi-constants';

const LoadingPage = ({
    history,
    appId,
    setCurrentUser,
    setErrorData,
    ucsfWindow,
    setLoader
}) => {
    const [pageState, setPageState] = useState({
        // endPoint: `ws://localhost:8000/ws/login/omi_connect/`,
        endPoint: `${CONST.WS_POINT}/ws/login/${appId}/`,
        userData: null,
        messages: []
    });

    // DECLARATIONS : VARIABLES
    let loadingText = 'We\'re almost there...';
    let WS_EXT = { 'Sec-WebSocket-Extensions': `permessage-deflate; client_max_window_bits` };

    // DECLARATIONS : HOOKS
    useEffect(() => {
        const ws = new W3CWebSocket(pageState.endPoint, '', '', WS_EXT);
        ws.onmessage = (evt) => {
            setPageState({ ...pageState, userData: evt });
            const userData = evt?.data ? JSON.parse(evt?.data) : null;
            console.log('ON-DATA -> ', userData);
            if (
                userData &&
                userData.token &&
                Number(userData?.status_code) === 200 &&
                userData?.appid === appId
                // userData?.appid === 'omi_connect'
            ) {
                SecurityService.setSecurityToken(userData.token);
                AuthService._setUser(userData);
                setCurrentUser(userData);
                setLoader(true);
                setTimeout(() => {
                    if (ucsfWindow) {
                        ucsfWindow.close();
                        ws.close();
                    }
                    setLoader(false);
                }, 250);
                ucsfInstance.get(COMPONENT_APIS.USER_PERMISSION).then(response => {
                    const { permissions, role } = response.data;
                    AuthService._setUser({ ...userData, permissions, role });
                    setCurrentUser({ ...userData, permissions, role });
                    setLoader(false);
                    history.push('/home');
                }).catch(err => {
                    history.push('/home');
                });
            } else if (
                userData &&
                Number(userData?.status_code) !== 200
            ) {
                setErrorData(userData);
                setTimeout(() => {
                    if (ucsfWindow) { ucsfWindow.close(); }
                    history.push('/');
                }, 500);
            }
        }
    }, [appId, ucsfWindow]);

    return (
        <PreAuthLayoutComponent isNormal={true}>
            <div className="loading-wheel">
                <img src={Loader} alt="loader" />
                <div className="text">{loadingText}</div>
            </div>
        </PreAuthLayoutComponent>
    );
}

const mapStateToProps = createStructuredSelector({
    appId: getAppId,
    ucsfWindow: getUcsfWindow
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    setLoader: (val) => dispatch(setLoader(val)),
    setErrorData: (errorData) => dispatch(setUserErrorData(errorData))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);