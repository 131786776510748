import { notificationActionTypes } from "./notification.types";

export const setSearchNotificationData = (item) => ({
    type: notificationActionTypes.SET_SEARCH_NOTIFICATION_DATA,
    payload: item
});

export const setDownloadNotificationData = (item) => ({
    type: notificationActionTypes.SET_DOWNLOAD_NOTIFICATION_DATA,
    payload: item
});

export const setCommentNotificationData = (item) => ({
    type: notificationActionTypes.SET_COMMENT_NOTIFICATION_DATA,
    payload: item
});