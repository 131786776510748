import styled from 'styled-components';

export const PreAuthCommonValidation = styled.div`
    color: ${props => props.validation ? "#ff5454 !important" : "#FFFFFF"};
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    width: 250px;
    margin-bottom: 15px;
`;

export const RegisterAuthCommonValidation = styled.div`
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;