/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from 'react';
import './pop-over.styles.scss';
import { POP_OVER_ITEM_LIST } from './data/pop-over.data';
import AppService from '../../services/app-service';
import { createStructuredSelector } from 'reselect';
import { getSearchDataState } from '../../../redux/data-saver/data-saver.selectors';
import { connect } from 'react-redux';
import Loader from '../../../assets/loaders/spinner-blue.gif';
import { UP_ACCESS } from '../../utils/permissions';

const PopOver = (props) => {
    let {
        offSetOn = true,
        rowData,
        showToolTip = false,
        dataList = [],
        elementType,
        gridType = '',
        gridDataType = null,
        userData = null,
        listType,
        showLink = false,
        loader = false
    } = props;
    let popOverListArray = AppService.filterDetailViewWithPermissions(POP_OVER_ITEM_LIST[listType], elementType) || [];

    if (
        rowData &&
        rowData.fileType &&
        rowData.fileType === 'folder' &&
        rowData.type &&
        rowData.type.toLowerCase() === 'covariation network' &&
        UP_ACCESS(['FILE_DOWNLOAD', 'FILE_DOWNLOADS'])
    ) {
        popOverListArray = [...popOverListArray, { img: 'download', name: 'Create Local KME Table' }];
        popOverListArray = AppService.removeDuplicateObjectFromArray(popOverListArray, 'name');
    } else if (gridDataType?.toLowerCase() === 'covariation networks' && UP_ACCESS(['FILE_DOWNLOAD', 'FILE_DOWNLOADS'])) {
        popOverListArray = [...popOverListArray, { img: 'download', name: 'Download KME Table' }];
        popOverListArray = AppService.removeDuplicateObjectFromArray(popOverListArray, 'name');
    }

    const setKeyValueBasedOnPermissions = () => {
        let availableListItems = POP_OVER_ITEM_LIST[listType];
        availableListItems?.forEach(item => {
            if (!(popOverListArray.length && (popOverListArray.findIndex(obj => (obj.name === item.name)) >= 0))) {
                item['disabled'] = 'grey';
            }
        });
        return availableListItems;
    }

    let popOverListData = (
        (dataList && dataList.length) ?
            dataList :
            (elementType || popOverListArray.length) ?
                popOverListArray :
                setKeyValueBasedOnPermissions()
    );

    if (userData) {
        let name = userData?.user?.name || ((userData?.user?.first_name + userData?.user?.last_name) || (userData?.user?.username || ''));
        popOverListData = [{ img: 'username', name: name.split(/(?=[A-Z])/).join(' ') }, ...popOverListData];
    }

    const popOverRef = createRef();
    const [listData, setListData] = useState([]);

    const offSetCal = () => {
        let style = {};
        let offsetList = ['geneSet', 'sampleAttributes', 'fileTypeResults'];
        if (offsetList.indexOf(listType) < 0) {
            if (offSetOn) {
                style.top = `${(props?.divRef?.current?.offsetTop - props?.scrollObject?.top) + 25}px`;
            } else {
                const rowElem = document.getSelection();
                style.top = `${(rowElem?.focusNode?.offsetTop - props?.scrollObject?.top)}px`;
            }
        }
        return style;
    }

    useEffect(() => {
        let popOverList = [], newNotifList = [], advancedSearchDataSet = [];
        // SETTING NOTIFCATIONS WITH DUPLICATION ENTRY REMOVAL ---------------------------------
        if (dataList.length) {
            popOverListData[popOverListData.length] = { img: 'clear', name: 'clear all' };
            popOverListData = AppService.removeDuplicateObjectFromArray(popOverListData, 'name');
            popOverListData.forEach(notif => {
                if (notif.name !== 'clear all') { newNotifList.push(notif); }
            });
            popOverListData.forEach(notif => {
                if (notif.name === 'clear all') { newNotifList.push(notif); }
            });
            popOverListData = [...newNotifList];
        }
        if (rowData && rowData['checked'] && popOverListData.length && (popOverListData.indexOf(item => (item.name.toLowerCase() === 'download') >= 0))) {
            popOverListData = popOverListData.filter(item => (item.name.toLowerCase() !== 'download'));
        }
        // --------------------------------------------------------------------------------------
        setListData(popOverListData);
        if (listType === 'sampleAttributes') { // && !rowData?.deletable
            popOverList = [{ ...popOverListData[0] }];
            setListData(popOverList);
        } else {
            if (rowData && rowData?.fileType && ['pdf', 'xls', 'xlsx', 'csv', 'txt', 'r-code'].indexOf(rowData?.fileType) < 0) {
                popOverList = popOverListData.filter(item => (item.name.toLowerCase() !== 'preview'));
                setListData(popOverList);
            }
        }
    }, [listType, rowData, dataList]);

    const fileSizeChecker = (item) => {
        let exceedsLimit = '';
        const modalData = props.rowData;
        if (
            modalData && modalData.sizeDisplayName && (item.name.toLowerCase() === 'preview') &&
            (
                modalData.sizeDisplayName.includes('MB') ||
                modalData.sizeDisplayName.includes('GB') ||
                modalData.sizeDisplayName.includes('TB') ||
                modalData.sizeDisplayName.includes('PB')
            )
        ) {
            const valArray = modalData.sizeDisplayName.split(' ');
            const val = Number(valArray[0]);
            if (val > 1) {
                return ' grey';
            }
        }
        return exceedsLimit;
    };

    const imageType = (image = '') => {
        if (image.toLowerCase().includes('.zip')) {
            return 'zip';
        }
        return image;
    }

    const popOverListItem = (propData) => {
        let { index, item } = propData;
        return (
            <div
                key={index}
                title={showToolTip ? item.name : ''}
                className={`pop-over-container-list-item${(item?.img === 'username') ? ' user-name' : ''}${` ${item?.disabled || ''}`}`}
                onClick={() => {
                    if (listType !== 'recentFiles') {
                        props.click(item);
                    }
                }}>
                {
                    (item?.img !== 'username') ?
                        <div className={`pop-over-container-list-item-img ${imageType(item.img)}`}></div> :
                        null
                }
                <div className="pop-over-container-list-item-label" title={item.name}>
                    {
                        (item?.img === 'username') ?
                            <div className='user-info'>
                                <div className='user-info-name'>{item.name}</div>
                                {
                                    userData?.user?.email &&
                                    <div className='user-info-email'>{userData?.user?.email.toLowerCase()}</div>
                                }
                            </div> :
                            item.name
                    }
                </div>
            </div>
        );
    }

    return (
        <div
            className={(
                'pop-over-container' +
                `${(` ${props?.className}` || '')}` +
                `${((gridType ? (' ' + gridType) : '') || '')}` +
                `${(gridType !== 'sampleAttributes' && props?.gridLen > 8) ? ' flow' : ''}` +
                `${((gridDataType ? (' ' + gridDataType) : '') || '')}`
            )}
            ref={popOverRef}
            style={{ ...offSetCal() }}>
            <div className="pop-over-container-list">
            <div className='notification-body-wrapper'>
            {loader ?  <div className='loader-wrapper'>
                    <img src={Loader} />
                     </div>
                     :listData?.map((item, index) => {
                        return popOverListItem({ item, index });
                    })}
                </div>
                {showLink && <div className='view-all-notification-link' onClick={() => props.viewNotificationLink()}>
                    View All Notifications
                </div>}
            </div>
            
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    advancedSearchData: getSearchDataState
});

export default connect(mapStateToProps)(React.memo(PopOver));