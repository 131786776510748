/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ucsfInstance } from '../../../../core/axios/ucsf';
import { setLoader } from '../../../../redux/app/app.actions';
import SampleAttributesCohort from '../../../../core/components/sa-cohort/sa-cohort.component';
import CustomButton from '../../custom-button/custom-button.component';
// import SampleAttributesCohort from '../../attributes/sa-cohort/sa-cohort.component';

const SaCohortModal = (props) => {
    const {
        modalData
    } = props
    const dispatch = useDispatch();
    const [sampleAttributeDataCopy, setSampleAttributeDataCopy] = useState({});
    const [sampleAttributeDataReset, setSampleAttributeDataReset] = useState(false);
    const [sampleAttributeData, setSampleAttributeData] = useState({
        optionalAttributes: {},
        sampleAttributes: {}
    });
    let api = `/api/${modalData.isDataSet ? 'data-sets' : 'data-collections'}`;

    useEffect(() => {
        if (modalData) {
            const attributeData = {
                sampleAttributes: modalData.sampleAttributes,
                optionalAttributes: modalData.optionalAttributes ? modalData.optionalAttributes : {}
            };
            setSampleAttributeData(attributeData);
            setSampleAttributeDataCopy({ ...attributeData });
        }
        return () => { setSampleAttributeData(null); }
    }, [modalData]);

    /* const processSampleAttributeData = () => {
        dispatch(setLoader(true));
        ucsfInstance.post(`${api}/sample-attributes`, { id: modalData.id }).then(sampleAttributeResponse => {
            if (sampleAttributeResponse.data && sampleAttributeResponse.data.sample_attributes) {
                const responseData = sampleAttributeResponse.data;
                setSampleAttributeData({ sampleAttributes: responseData.sample_attributes }); // responseData.sample_attributes;
            }
            dispatch(setLoader(false));
        }).catch(error => {
            dispatch(setLoader(false));
        });
    }; */

    const renderSampleAttribute = (filterData) => {
        if (sampleAttributeData) {
            dispatch(setLoader(true));
            ucsfInstance.post(`${api}/sample-attributes/`, filterData).then(sampleAttributeResponse => {
                if (sampleAttributeResponse.data && sampleAttributeResponse.data.sample_attributes) {
                    const responseData = sampleAttributeResponse.data;
                    setSampleAttributeData({
                        sampleAttributes: responseData.sample_attributes,
                        optionalAttributes: responseData.optional_attributes
                    }); // responseData.sample_attributes;
                    setSampleAttributeDataReset(false);
                }
                dispatch(setLoader(false));
            }).catch(error => {
                setSampleAttributeDataReset(false);
                dispatch(setLoader(false));
            });
        }
    }

    return (
        // <Fragment>
        <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {
                Object.keys(sampleAttributeData.sampleAttributes).length ?
                    <SampleAttributesCohort
                        dataId={modalData._id ? modalData._id : modalData.id}
                        newSampleAttribute={null}
                        sampleAttributeDataReset={sampleAttributeDataReset}
                        sampleAttributeData={sampleAttributeData}
                        onItemClick={(filterData) => !modalData.isAdvancedSearch ? renderSampleAttribute(filterData) : null}
                    /> :
                    `No sample attributes are available!`
            }
            {
                Object.keys(sampleAttributeData.sampleAttributes).length ?
                    <CustomButton
                        inverted='inverted'
                        size='large'
                        style={{ display: 'unset', marginTop: '20px', width: '255px' }}
                        click={() => {
                            dispatch(setLoader(true));
                            setSampleAttributeDataReset(true);
                            setTimeout(() => {
                                dispatch(setLoader(false));
                                setSampleAttributeData({ ...sampleAttributeDataCopy });
                            }, 750);
                        }}>
                        RESET SAMPLE ATTRIBUTES
                    </CustomButton> :
                    null
            }
        </div>
        /* <CustomButton inverted='inverted' size='large' click={() => {
            dispatch(setLoader(true));
            setTimeout(() => {
                dispatch(setLoader(false));
                setSampleAttributeData({ ...sampleAttributeDataCopy });
            }, 750);
        }}> RESET SAMPLE ATTRIBUTES
        </CustomButton> */
        // </Fragment>
    );
};

export default SaCohortModal;