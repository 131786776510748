/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Aux from '../../../core/components/auxiliary/auxiliary';
import CustomButton from '../../../core/components/custom-button/custom-button.component';
import PreAuthLayoutComponent from '../../../core/components/pre-auth-layout/pre-auth-layout.component';
import { setLoader, setUCSFWindow } from '../../../redux/app/app.actions';
import { getAppId, getUcsfWindow, getUserErrorData } from '../../../redux/app/app.selectors';
import { setUserErrorData } from '../../../redux/app/app.actions';
import './welcome.styles.scss';
import { CONST } from '../../../core/utils/omi-constants';
import { useEffect } from 'react';
import Loader from '../../../assets/loaders/spinner-blue-full.gif';
import { useState } from 'react';
import SecurityService from '../../../core/services/security-service';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import { Fragment } from 'react';
import AuthService from '../../../core/services/authentication-service';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { ucsfInstance, ucsfLoginInstance } from '../../../core/axios/ucsf';
import { setCurrentUser } from '../../../redux/user/user.actions';
import AppService from '../../../core/services/app-service';
import { Link } from 'react-router-dom';

const WelcomePage = ({
    match,
    location,
    history,
    appId,
    setUcsfWindowRef,
    loginErrorData,
    setErrorData,
    setLoader,
    setCurrentUser,
    ucsfWindow
}) => {
    // DECLARATION : STATE VARS & HOOKS
    const d = useDispatch();
    const [logout, setLogout] = useState('');
    const [login, setLogin] = useState(false);
    const [loadingText, setLoadingText] = useState('We\'re almost there...');

    // DECLARATION : VARIABLES
    const { path } = match;
    const pageState = {
        endPoint: `${CONST.WS_POINT}/ws/login/`,
        userData: null,
        messages: []
    };
    let btnStyle = { minWidth: '200px', lineHeight: '0px', width: 'auto', padding: '0 20px' };
    let UCSF_LOGIN_LINK = `${CONST.END_POINT}/secure/appid=${appId}`;
    let WS_EXT = { 'Sec-WebSocket-Extensions': `permessage-deflate; client_max_window_bits` };

    // RENDER - HOOKS
    useEffect(() => {
        // CLEAR COOKIES-----------------------------
        AppService.clearAllCookies();
        // ------------------------------------------
        let logoutID = SecurityService.getLogoutId();
        let { name } = AppService.getBrowserInfo();
        let openLogoutUrl = () => {
            if (CONST.END_POINT.includes('staging') || CONST.END_POINT.includes('theomicon')) {
                window.open(COMPONENT_APIS.APP_SHIBBOLETH_REDIRECT_LOGOUT, '_blank').focus();
            } else {
                window.open(`${CONST.END_POINT}${COMPONENT_APIS.APP_SHIBBOLETH_LOGOUT}`, '_blank').focus();
            }
        };
        setLogout(logoutID);
        setLoader(false);
        if (logoutID) {
            setLoadingText('Logging you out, Please wait.');
            SecurityService.setLogoutId('');
            SecurityService.removeLogoutId();
            if (name.toLowerCase() === 'safari') {
                setTimeout(() => {
                    setLogout('');
                    setLogin(false);
                    setTimeout(() => {
                        openLogoutUrl();
                    }, 50);
                }, 1500);
            } else {
                setTimeout(() => {
                    openLogoutUrl();
                    setTimeout(() => {
                        setLogout('');
                        setLogin(false);
                    }, 250);
                }, 1500);
            }
        } else {
            setLogout('');
        }
    }, []);

    useEffect(() => {
        let logoutID = SecurityService.getLogoutId();
        if (appId && !logoutID) {
            let endPoint = `${pageState.endPoint}${appId}/`;
            const ws = new W3CWebSocket(endPoint, '', '', WS_EXT);
            ws.onmessage = (evt) => {
                const userData = (evt?.data) ? JSON.parse(evt?.data) : null;
                if (
                    userData &&
                    userData.token &&
                    Number(userData?.status_code) === 200 &&
                    userData?.appid === appId
                ) {
                    setLoadingText('We\'re almost there...');
                    setLogin(true);
                    SecurityService.setSecurityToken(userData.token);
                    AuthService._setUser(userData); // setCurrentUser(userData);
                    setTimeout(() => {
                        if (ucsfWindow) { ucsfWindow.close(); ws.close(); setUcsfWindowRef(null); }
                        setTimeout(() => {
                            ucsfInstance.get(COMPONENT_APIS.USER_PERMISSION).then(response => {
                                const { permissions, role } = response.data;
                                AuthService._setUser({ ...userData, permissions, role });
                                setCurrentUser({ ...userData, permissions, role });
                                setLogin(false); // setLoader(false);
                                history.push('/home');
                            }).catch(err => {
                                setLogin(false);
                                history.push('/home');
                            });
                        }, 500);
                    }, 250);
                } else if (userData && Number(userData?.status_code) !== 200) {
                    setErrorData(userData);
                    setTimeout(() => {
                        if (ucsfWindow) { ucsfWindow.close(); }
                        history.push('/');
                    }, 500);
                }
            }
        }
    }, [appId, ucsfWindow]);

    // DEFINITION : METHODS
    const loginWithUcsfUser = () => {
        setErrorData(null);
        AppService.clearAllCookies();
        localStorage.clear();
        if (process.env.NODE_ENV === 'staging') { UCSF_LOGIN_LINK = `${CONST.END_POINT}/secure/appid=${appId}`; }
        const ucsf = window.open(`${UCSF_LOGIN_LINK}`, `ucsf_${appId}`, "toolbar=yes, top=200, left=500, width=650, height=700");
        setUcsfWindowRef(ucsf);
        // LOADING COMPONENT REDIRECTION CODE --------------------------
        /* setTimeout(() => {
            setTimeout(() => { history.push('/loading'); }, 625);
        }, 125); */
        // -------------------------------------------------------------
    }

    const loginAsGuestUser = () => {
        setLoader(true, 'Logging in as Guest User');
        const username = 'guestuser'; // 'Reviewer'; // loginFormElement['email']['value'];
        const password = 'omicon@123'; // `81oN0KuuDFJRp2Py`;
        // HANDLING API CALL FOR GUEST ACCOUNT USER --------------------
        ucsfLoginInstance.post(COMPONENT_APIS.USER_LOGIN, { username, password }).then((response) => {
            const userData = response.data;
            SecurityService.setSecurityToken(userData.token);
            AuthService._setUser(userData);
            ucsfInstance.get(COMPONENT_APIS.USER_PERMISSION).then(res => {
                const { permissions, role } = res.data;
                AuthService._setUser({ ...userData, permissions, role });
                setCurrentUser({ ...userData, permissions, role });
                setLoader(false);
                history.push('/home');
            }).catch(err => {
                setLoader(false);
            });
        }).catch(error => {
            setLoader(false);
            AppService.setNotification({
                title: 'Error',
                message: 'Something went wrong. Please try again.',
                type: 'danger'
            });
        });
    }

    const versionCheckOnActions = (actionType) => {
        setLoader(true);
        ucsfInstance.get(COMPONENT_APIS.VERSION_CHECK).then(res => {
            setLoader(false);
            switch (actionType) {
                case 'login':
                    history.push(`/login`);
                    break;
                case 'register':
                    history.push(`/register`);
                    break;
                case 'ucsfLogin':
                    loginWithUcsfUser();
                    break;
                case 'guestLogin':
                    loginAsGuestUser();
                    break;
                default:
                    return;
            }
        }).catch(err => {
            setLoader(false);
        })
    }

    return (
        <PreAuthLayoutComponent isNormal={true}>
            <div className="welcome-note">
                {
                    loginErrorData ?
                        <span>
                            {
                                (loginErrorData.type && loginErrorData.type === 'permission') ?
                                    (loginErrorData?.message || 'Access denied') + ', please contact the admin.' :
                                    (loginErrorData?.message || 'Something went wrong') + ', please try again.'
                            }
                        </span> :
                        (!logout && !login) ?
                            <span>
                                OMICON is a portal for the collaborative study of biological systems using omics data.
                                It was created by The Oldham Lab, which is part of the Department of Neurological Surgery at UCSF.
                            </span> :
                            <Fragment>
                                <div className="loading-wheel">
                                    <img src={Loader} alt="loader" />
                                    <div className="text">{loadingText}</div>
                                </div>
                            </Fragment>
                }
            </div>
            <div className="btn-group">
                {
                    path.includes('external') ? (
                        <Aux>
                            <CustomButton size="large" style={btnStyle} onClick={() => history.push(`/login`)}>LOGIN</CustomButton>
                            <CustomButton size="large" style={btnStyle} onClick={() => history.push(`/register`)}>REGISTER</CustomButton>
                            <CustomButton size="large" style={btnStyle}>BROWSE</CustomButton>
                        </Aux>
                    ) : (
                        (!logout && !login) ?
                            <Aux>
                                {/* <CustomButton size="large" style={btnStyle} onClick={loginWithUcsfUser}>OLDHAM LAB MEMBERS</CustomButton> */}
                                {/* <CustomButton size="large" style={btnStyle} onClick={loginAsGuestUser}>GUEST</CustomButton> */}
                                <CustomButton size="large" style={btnStyle} onClick={() => versionCheckOnActions('login')}>REGISTERED USERS</CustomButton>
                                <div className='link-container'>
                                    <div className='link-container-for-signup'>
                                        Are you a new user? <span onClick={() => versionCheckOnActions('register')}>Sign Up</span>
                                    </div>
                                    <div className='link-container-for-signup'>
                                        Oldham Lab Member? <span onClick={() => versionCheckOnActions('ucsfLogin')}>Login</span>
                                    </div>
                                    <div className='link-container-for-signup'>
                                        Skip registration? <span onClick={() => versionCheckOnActions('guestLogin')}>Login as Guest User</span>
                                    </div>
                                    {/* <div className='link-container-for-login'>
                                        Registered already? <Link to={'/login'}>Login</Link>
                                    </div> */}
                                </div>
                                {/* <CustomButton size="large" style={btnStyle} onClick={() => history.push(`/login?userType=reviewer`)}>Reviewer</CustomButton> */}
                                {/* <CustomButton size="large" style={btnStyle} onClick={() => history.push(`${match.url}external`)}>External User</CustomButton> */}
                            </Aux> :
                            null
                    )
                }
            </div>
        </PreAuthLayoutComponent>
    );
};

const mapStateToProps = createStructuredSelector({
    appId: getAppId,
    loginErrorData: getUserErrorData,
    ucsfWindow: getUcsfWindow
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    setUcsfWindowRef: (ucsf) => dispatch(setUCSFWindow(ucsf)),
    setErrorData: (errorData) => dispatch(setUserErrorData(errorData)),
    setLoader: (val, text = '') => dispatch(setLoader(val, text))
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);