/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import AppService from '../../../../../../core/services/app-service';
import { setLoader } from '../../../../../../redux/app/app.actions';
import './dc-upload.styles.scss';
import AWS from 'aws-sdk';
import { ucsfInstance } from '../../../../../../core/axios/ucsf';
import { setModalData } from '../../../../../../redux/modal/modal.actions';
import { Progress } from 'react-sweet-progress';
import { COMPONENT_APIS } from '../../../../../../core/utils/omi-api';

const DCUpload = (props) => {
    const {
        uploadFile = null
    } = props;
    const dropzoneRef = createRef();
    const [acceptedFileData, setAcceptedFileData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('active');
    const d = useDispatch();

    useEffect(() => {
        if (uploadFile && acceptedFileData && acceptedFileData.length) {
            uploadFileData();
        }
        return () => {
            setAcceptedFileData([]);
        }
    }, [uploadFile]);

    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const uploadFileData = (success = true) => {
        d(setLoader(true));
        let percentage = 0;
        let requestUrl = '';
        AppService.getAwsCredentials(true).then(async res => {
            await AWS.config.update({
                accessKeyId: res['AccessKeyId'],
                secretAccessKey: res['SecretAccessKey'],
                sessionToken: res['SessionToken']
            });
            const myBucket = await new AWS.S3({
                params: { Bucket: res['bucket'] },
                region: res['region']
            });
            const params = await {
                ACL: '',
                Body: acceptedFileData[0],
                Bucket: res['bucket'],
                Key: acceptedFileData[0].name
            };
            await d(setLoader(false));
            requestUrl = await `${myBucket.endpoint.protocol}//${res['bucket']}.${myBucket.endpoint.hostname}/${params.Key}`;
            await myBucket.putObject(params).on('httpUploadProgress', (evt, response) => {
                percentage = Math.round((evt.loaded / evt.total) * 100);
                setProgress(percentage);
            }).send((err, data) => {
                if (err) {
                    setUploadStatus('error');
                } else if (data) {
                    if (requestUrl) {
                        const uploadParams = {
                            s3_url: requestUrl,
                            type: 2,
                            ds_id: props.modalData['_id']
                        };
                        ucsfInstance.post(COMPONENT_APIS.SAVE_UPLOAD, uploadParams).then(res => {
                            setUploadStatus('success');
                        }).catch(err => {
                            setUploadStatus('error');
                            d(setModalData({
                                type: 'file-upload',
                                data: {
                                    title: 'Zip file upload failed',
                                    btnText: 'UPLOAD NEW FILE'
                                }
                            }));
                        });
                    }
                }
            });
        });
    };

    return (
        <div className="dc-wrapper">
            <div className="dc-upload-container-block-drop">
                {/* <div className="block-drop-label">Please upload the new gene set file</div> */}
                <div className="dc-upload-container-block-drop-container">
                    <div className="dc-upload-container-block-drop-container-img"></div>
                    <div className="dc-upload-container-block-drop-container-desc">
                        <Dropzone
                            ref={dropzoneRef}
                            noClick
                            noKeyboard
                            multiple={false}
                            onDrop={(acceptedFiles) => {
                                props.filesAccepted(acceptedFiles);
                                setAcceptedFileData(acceptedFiles);
                            }}>
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <div className="container">
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>
                                                Drag and drop or&nbsp;
                                                <span onClick={openDialog}>browse</span>
                                            </p>
                                        </div>
                                    </div>
                                );
                            }}
                        </Dropzone>
                    </div>
                </div>
                {
                    acceptedFileData && acceptedFileData.length ? (
                        <div className="dc-upload-container-block-drop-container files">
                            <div className="selected-file">Selected File(s)</div>
                            <ul>
                                {acceptedFileData.map(file => (
                                    <li key={file.path}>
                                        <span>{file.path} - {AppService.formatBytes(file.size)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null
                }
            </div>
            {
                progress ? (
                    <div className="upload-process">
                        <Progress percent={progress} status={uploadStatus} />
                    </div>
                ) : null
            }
        </div>
    );
};

export default DCUpload;