import { CONST } from "../../../../../core/utils/omi-constants";

export const ADV_SEARCH = (id, type = null) => {
    let endPoint = CONST.END_POINT;
    if (CONST.URL_TYPE !== 'staging') {
        endPoint = `${window.location.protocol}//${window.location.host}`;
    }
    if (type === 'recent') {
        return `${endPoint}/home/advanced-search?queryType=${type}&queryId=${id}`;
    }
    return `${endPoint}/home/advanced-search?queryId=${id}`;
};

export const TabMenu = [{
    title: 'Select result type',
    configKey: 'result_type',
    isSelected: true,
    show: true,
    searchData: null
}, {
    title: 'Filter data',
    configKey: 'data',
    isSelected: false,
    show: true,
    searchData: null
},
{
    title: 'Filter analyses',
    configKey: 'analyses',
    isSelected: false,
    show: true,
    searchData: null
}, {
    title: 'Search Results',
    configKey: 'search_results',
    isSelected: false,
    show: false,
    resultData: null,
    searchData: null
}];

export const TAB_KEYS = {
    // Enrichment_Results: 'Enrichment Results',
    // enrichment_results: 'enrichment results',
    enrichment_results: 'gene sets',
    Enrichment_Results: 'Gene Sets'
};

export const dataTabOrder = {
    'data': [
        'Data Collection Attributes',
        'Data Collection',
        'Dataset Attributes',
        'Dataset',
        'Sample Attributes',
        'Samples'
    ],
    'analyses': [
        'Covariation Networks',
        'Covariation Modules',
        TAB_KEYS['Enrichment_Results'],
        'Analyses Instances',
        'Analysis'
    ]
};

export const resultTypeData = [{
    type: 'Select a result type',
    typeItems: null
}, {
    type: 'Data',
    typeItems: [
        'Data Collections',
        'Datasets',
        'Samples',
        'Features'
    ]
}, {
    type: 'Analyses',
    typeItems: [
        'Analyses',
        'Covariation Networks',
        'Covariation Modules',
        TAB_KEYS['Enrichment_Results']
    ]
}, {
    type: 'File Types',
    typeItems: [
        'Analyte Data (Dataset)',
        'Data Collection Attributes',
        'Dataset Attributes',
        'Dataset Feature Metadata',
        'Gene Set Enrichment (. csv)',
        'Gene Set Enrichment (.pdf)',
        'kME Table (metadata)',
        'Module Eigengene Correlogram',
        'Module Eigengene Network',
        'Module Eigengenes',
        'Module Snapshots',
        'Module Statistics (.csv)',
        'Module Statistics (.pdf)',
        'Network Statistics',
        'Analysis Attributes',
        'Sample Attributes (Data Collection)',
        'Sample Attributes (Dataset)',
        'Sample Metrics (Dataset)',
        'Sample Metrics (Summary; Dataset)',
        'Sample Networks'
    ]
}];

export const resultTypeData_BACKUP = [{
    name: 'Data',
    typeItems: [
        { name: 'Data Collections' },
        { name: 'Datasets' },
        { name: 'Samples' },
        { name: 'Features' }
    ]
}, {
    name: 'Analyses',
    typeItems: [
        { name: 'Analyses' },
        { name: 'Covariation Networks' },
        { name: 'Covariation Modules' },
        { name: 'Enrichment Results' }
    ]
}, {
    name: 'File Types',
    typeItems: [
        { name: 'Analyte Data (Dataset)' },
        { name: 'Data Collection Attributes' },
        { name: 'Dataset Attributes' },
        { name: 'Dataset Feature Metadata' },
        { name: 'Gene Set Enrichment (. csv)' },
        { name: 'Gene Set Enrichment (.pdf)' },
        { name: 'kME Table (metadata)' },
        { name: 'Module Eigengene Correlogram' },
        { name: 'Module Eigengene Network' },
        { name: 'Module Eigengenes' },
        { name: 'Module Snapshots' },
        { name: 'Module Statistics (.csv)' },
        { name: 'Module Statistics (.pdf)' },
        { name: 'Network Statistics' },
        { name: 'Analysis Attributes' },
        { name: 'Sample Attributes (Data Collection)' },
        { name: 'Sample Attributes (Dataset)' },
        { name: 'Sample Metrics (Dataset)' },
        { name: 'Sample Metrics (Summary; Dataset)' },
        { name: 'Sample Networks' }
    ]
}];

export const greyedDataItemList = [
    'Sample Match Count',
    'Sample Match Percent',
    'Dataset Sample Overlap Count'
];

export const greyedAnalysesItemList = [
   // 'Enrichment P-value'
];

export const covariationModuleItemList = [
    'Set ID',
    'Set Name',
    'Geneset Omicon ID'
];

export const geneSetsItemList = [
    'Covariation Module',
    'Covariation Module Omicon ID',
    'Covariation Network Omicon ID',
    'Input Dataset Omicon ID'
];

export const featureMatchOptionsList = [
    'Feature Match Count',
    'Feature Match Percent'
];
export const featureMatchCountList = [
    'Feature Match Count'
]

export const featureSearchModuleDefinitionList = [
    'Feature Search Module Definition'
];
export const enrichMentAnalysisModuleDefinitionList = [
    'Enrichment Analysis Module Definition',
    'Enrichment P-value'
]

export const resultOutputTypes = [
    'Show records',
    'Show count'
];

const dsOrderList = [
    'Title',
    'Omicon ID',
    'Date Created',
    'Owner',
    'Analyst',
    'Availability',
    'Source',
    'Accession ID',
    'BioProject ID',
    'PubMed ID',
    'Macromolecule',
    'Analyte',
    'Technology',
    'Platform',
    'Transformations',
    'Quantification Scale',
    'Number of Features',
    'Number of Samples',
    'Number of Individuals',
    'Raw Data Location',
    'Unique Identifier',
    'Mapping Tables',
    'Version',
    'Library Prep',
    'Selection Method',
    'ERCC Controls',
    'Fragmentation Method',
    'Median Insert Size',
    'UMIs',
    'Stranded',
    'End Capture Method',
    'Amplification Method',
    'PCR Cycles',
    'Endedness',
    'Read 1 Length',
    'Read 2 Length',
    'Multiplexed',
    'Samples per Lane',
    'Alignment Tool',
    'Alignment Tool Version',
    'Annotation File',
    'Read Quantification Tool',
    'Read Quantification Tool Version',
    'Reference Genome',
    'Reference Transcriptome',
    'Sample Match Count',
    'Sample Match Percent'
];

const dcOrderList = [
    'Title',
    'Omicon ID',
    'Date Created',
    'Owner',
    'Analyst',
    'Availability',
    'Macromolecule',
    'Analyte',
    'Technology',
    'Platform',
    'Number of Samples',
    'Number of Individuals',
    'Dataset Sample Overlap Count',
];

const analysesOrderList = [
    'Title',
    'Omicon ID',
    'Type',
    'Date Created',
    'Input Dataset(s)',
    'PubMed ID(s)',
    'Owner',
    'Analyst',
    'Availability',
];

const covariationNetworksOrderList = [
    'Omicon ID',
    'Similarity Measure',
    'Initial Similarity Threshold',
    'Initial Similarity Threshold (Quantile)',
    'Minimum Module Size',
    'Maximum Module Eigengene Correlation',
    'Initial Number of Modules',
    'Final Number of Modules',
    'Mean Module Specificity',
    'Mean Module Eigengene % Variance Explained',
];

const covariationModulesOrderList = [
    'Omicon ID',
    'Module',
    'Initial Size',
    'Expanded Size (BC)',
    'Unique Size (BC)',
    'Specificity',
    'Module Eigengene % Variance Explained',
    'Module Definition',
];

const enrichmentOrderList = [
    'Omicon ID',
    // 'Gene Set Source',
    'Set Source',
    'Module Definition',
    'Enrichment P-value',
    'Alias mapping',
];

export const orderedLists = {
    'Data Collections': dcOrderList,
    'Datasets': dsOrderList,
    'Analyses': analysesOrderList,
    'Covariation Networks': covariationNetworksOrderList,
    'Covariation Modules': covariationModulesOrderList,
    [TAB_KEYS['Enrichment_Results']]: enrichmentOrderList
};

export const featureMatchOptions = [
    {
        "Attribute": "Feature Match Count",
        "Attribute Type": "Numeric",
        "Operators": [
            "Equals",
            "Greater Than",
            "Less Than",
            "Greater Than or Equal To",
            "Less Than or Equal To"
        ]
    },
    {
        "Attribute": "Feature Match Percent",
        "Attribute Type": "Numeric",
        "Operators": [
            "Equals",
            "Greater Than",
            "Less Than",
            "Greater Than or Equal To",
            "Less Than or Equal To"
        ]
    }
];

export const sampleSearchQueries = [
    { createdBy: 'OMICON', queryId: '1b', description: 'Find all RNA-seq datasets with at least 100 samples from adult human gliomas that contain the gene MALAT1' },
    { createdBy: 'OMICON', queryId: '3', description: 'Find all human brain samples with any kind of disease' },
    { createdBy: 'OMICON', queryId: '4b', description: 'Find all adult human malignant glioma samples with deleterious mutations in IDH1' },
    { createdBy: 'OMICON', queryId: '5', description: 'Find all adult human oligodendroglioma samples with deletion of chr1p OR chr19q AND no mutation in IDH1' },
    { createdBy: 'OMICON', queryId: '6', description: 'Find all Analyses involving FM by Rebecca that have been created since 4/1/21' },
    { createdBy: 'OMICON', queryId: '7', description: 'Find all covariation networks from human gliomas on Affymetrix U133A microarrays where min module size ≥ 10 and # modules ≥ 50' },
    { createdBy: 'OMICON', queryId: '8b', description: 'Find all covariation modules in datasets consisting solely of adult human glioma samples that contain the following genes: BUB1, MKI67, PBK, and WEE1' },
    { createdBy: 'OMICON', queryId: '9', description: 'Find all covariation modules in datasets consisting solely of adult human oligodendroglioma samples that are significantly enriched with microglial markers' },
    { createdBy: 'OMICON', queryId: '10b', description: 'Find all Analyte Data files generated exclusively from adult human glioma samples by RNA-seq that were used as input for covariation analysis by FindModules' },
    { createdBy: 'OMICON', queryId: '11b', description: 'Find all Gene Set Enrichment Results (.pdf) for Covariation Networks produced from adult human glioma samples using the top 1% or higher of biweight midcorrelations and a minimum module size ≥ 20' },
    { createdBy: 'OMICON', queryId: '12', description: 'Export a list of unique gene symbols for covariation modules identified in bulk RNA-seq datasets that include adult human male gliomas and are maximally enriched with markers of radial glia (cell type)' },
    { createdBy: 'OMICON', queryId: '13', description: 'Download complete kME table for a covariation network produced from GSE15824 using the top .01% of biweight midcorrelations and a minimum module size of 8' },
    { createdBy: 'OMICON', queryId: '14', description: 'Find all unique human Entrez IDs for covariation modules generated exclusively from adult human gliomas that were maximally enriched with markers of OPCs (cell type)' },
    { createdBy: 'OMICON', queryId: '15b', description: 'Return a list of all unique enzymes encoded by genes from covariation modules identified in bulk gene expression datasets generated exclusively from adult human gliomas that were maximally enriched with markers of T cells' },
    { createdBy: 'OMICON', queryId: '16b', description: 'Return a list of unique OMIM IDs associated with seed genes for covariation modules identified in bulk gene expression datasets generated exclusively from adult human glioma samples that include GATA1, GATA3, and AIRE as seed genes' },
    { createdBy: 'OMICON', queryId: '18', description: 'Find all covariation modules for which >= 1% of seed genes belong to the Gene Ontology Biological Process \'oxidative phosphorylation\' (GO:0006119)' },
    { createdBy: 'OMICON', queryId: '19b', description: 'Find all unique gene sets associated with human neuronal cell types that were significantly enriched (P < 1e-10) in covariation modules derived from adult human glioma samples analyzed by RNA-seq, ranked by P-value' },
    { createdBy: 'OMICON', queryId: '20b', description: 'Find all gene sets that were significantly enriched (P < 1e-10) in covariation modules derived from the top 1% of pairwise cors with a minimum module size >= 12 in RNA-seq datasets produced with at least 10% grade 2 oligodendrogliomas' }
];

export const omiconUnlinkSet = [
    // 'analysis instances',
    // 'covariation networks',
    // 'covariation modules',
    // TAB_KEYS['enrichment_results'],
    'samplesearchqueries'
];

export const defaultFieldObjects = {
    moduleDefinition: {
        field: "Module_Definition",
        label: "Module Definition",
        operator: "equal",
        type: "string",
        value: "Any"
    },
    enrichmentPValue: {
        field: "Enrichment_P-value",
        label: "Enrichment P-value",
        operator: "lessthan",
        type: "string",
        value: "0.05"
    }
};

/* export const sampleSearchQueries = {
    http://localhost:3001/home/analyses/analysis-data/files?
    analysisId=951cc0ca8fcb40f6a66fdf05019398fc&
    analysisFileFolderId=a9ee43df4c3b4b47b376087db94ee9e6&
    mainAnalysisId=951cc0ca8fcb40f6a66fdf05019398fc&
    contentType=analyses
    queryList: sampleSearchQueryArray.forEach(query => (query['searchLink'] = ADV_SEARCH(query.queryId)))
}; */
