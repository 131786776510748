/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import MainContent from './main-content/main-content.component';
import SideContent from './side-content/side-content.component';
import './content.styles.scss';
import { connect } from 'react-redux';
import { setHomePageClick, setNetworkModeOn } from '../../../redux/app/app.actions';
import { createStructuredSelector } from 'reselect';
import { getNetworkMode } from '../../../redux/app/app.selectors';

const Content = (props) => {
    const {
        setHomePageClick,
        setNetworkModeOn,
        networkModeOn
    } = props;
    const { history: { location: { pathname } } } = props;
    const [hideSideContent, setHideSideContent] = useState(false);

    useEffect(() => {
        setHideSideContent(false);
        setNetworkModeOn(null);
        if (
            pathname.includes('sample-attributes') ||
            pathname.includes('gene-set') ||
            pathname.includes('data-collection-attributes') ||
            pathname.includes('data-set-attributes') ||
            pathname.includes('analysis-attributes') // pathname.includes('network')
        ) {
            setHideSideContent(true);
        }
    }, [pathname]);

    useEffect(() => {
        if (networkModeOn && networkModeOn === 'expand') {
            setHideSideContent(true);
        } else if (networkModeOn !== null && networkModeOn === '') {
            setHideSideContent(false);
        }
    }, [networkModeOn]);

    return (
        <div className="omi-content"> {/* onClick={() => setHomePageClick()} */}
            <SideContent {...props} hideSideContent={hideSideContent} />
            <MainContent {...props} fullScreen={hideSideContent} />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    networkModeOn: getNetworkMode
});

const mapDispatchToProps = (dispatch) => ({
    setHomePageClick: () => dispatch(setHomePageClick(true)),
    setNetworkModeOn: () => dispatch(setNetworkModeOn(null))
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);

{/* <div className="omi-content">
    <Container>
        <Section
            className="side-width"
            maxSize={`${leftPanelSize}%`}
            style={{
                overflowX: 'hidden',
                overflowY: 'auto',
            }}>
            {
                !hideSideContent ?
                    <SideContent {...props} /> :
                    null
            }
        </Section>
        <Bar size={2} style={{ background: 'transparent', cursor: 'col-resize' }} />
        <Section
            className={`${hideSideContent ? 'full-screen' : 'full-width'}`}
            style={{
                overflowX: 'hidden',
                overflowY: 'auto',
                width: '85vw'
            }}>
            <MainContent {...props} fullScreen={hideSideContent} />
        </Section>
    </Container>
</div> */}