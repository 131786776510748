/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import AppService from '../../services/app-service';
import './network-node.styles.scss';

const NetworkNode = (props) => {
    const {
        nodeDatum,
        toggleNode,
        foreignObjectProps,
        onForeignObject,
        doubleClick,
        attributeData,
        isHorizontal
    } = props;
    const divRef = useRef();
    const [onHover, setOnHover] = useState(0);
    const { name } = AppService.getBrowserInfo();
    const browserName = name;
    let timeOut;

    useEffect(() => {
        if (attributeData === null) {
            setOnHover(0);
            clearTimeout(timeOut);
        } else {
            setOnHover(1);
        }
    }, [attributeData]);

    const showAttributesPopOver = (event, eventType) => {
        if (onHover < 1) {
            setOnHover(0);
            clearTimeout(timeOut);
        }
        const attributeObject = {
            ...event,
            ...foreignObjectProps,
            ...nodeDatum,
            divRef: divRef
        };
        if (eventType === 'click') {
            onForeignObject(attributeObject);
        } else {
            timeOut = setTimeout(() => {
                setOnHover(1);
                onForeignObject(attributeObject);
            }, 500);
        }
    };

    const getColor = (type) => {
        switch (type) {
            case 'rdp': return '#f25e3b';
            case 'mdc': return '#2390bb';
            case 'sn': return '#ffbb43';
            case 'fm': return '#6da06f';
            case 'ea': return '#9151bf';
            default: return '#ffbb43';
        }
    };

    const positionText = (nodeName = '', type = '', index) => {
        if (type !== 'Dataset' && type !== 'transformation') {
            if (index === 0) {
                switch (nodeName.toLowerCase()) {
                    case 'source': return { x: 25 + foreignObjectProps.x, y: isHorizontal ? 2 : -10 };
                    case 'data collection': return { x: 15 + foreignObjectProps.x, y: -5 };
                    case 'raw': return { x: 12 + foreignObjectProps.x, y: -5 };
                    case 'covariation': return { x: 12 + foreignObjectProps.x, y: -5 };
                    case 'enrichment': return { x: 12 + foreignObjectProps.x, y: -5 };
                    case 'dataset': return { x: 20 + foreignObjectProps.x, y: -5 };
                    default: return { x: 25 + foreignObjectProps.x, y: 5 };
                }
            } else if (index > 0) {
                switch (nodeName.toLowerCase()) {
                    case 'attributes': return { x: 35 + foreignObjectProps.x, y: 15 };
                    case 'data': return { x: 10 + foreignObjectProps.x, y: 15 };
                    case 'network': return { x: 22 + foreignObjectProps.x, y: 15 };
                    case 'results': return { x: 26 + foreignObjectProps.x, y: 15 };
                    default: return { x: 12 + foreignObjectProps.x, y: 15 };
                }
            }
        } else if (type === 'Dataset') {
            if (nodeName === 'Dataset') {
                return { x: 25 + foreignObjectProps.x, y: -5 };
            } else {
                if (nodeName.includes('.')) {
                    const nodeNameArr = nodeName.split('.');
                    switch (nodeNameArr.length) {
                        case 1: return { x: 45 + foreignObjectProps.x, y: 15 };
                        case 2: return { x: 40 + foreignObjectProps.x, y: 15 };
                        case 3: return { x: 35 + foreignObjectProps.x, y: 15 };
                        case 4: return { x: 30 + foreignObjectProps.x, y: 15 };
                        case 5: return { x: 24 + foreignObjectProps.x, y: 15 };
                        default: return { x: 20 + foreignObjectProps.x, y: 15 };
                    }
                }
                return { x: 15 + foreignObjectProps.x, y: 15 };
            }
        } else if (type === 'transformation') {
            switch (nodeName.toLowerCase()) {
                case 'rdp': return { x: -15, y: 5 };
                case 'mdc': return { x: -18, y: 5 };
                case 'sn': return { x: -10, y: 5 };
                case 'fm': return { x: -10, y: 5 };
                case 'ea': return { x: -10, y: 5 };
                default: return { x: -10, y: 5 };
            }
        }
    }

    const textShape = (name = '', type = '') => {
        if (type !== 'Dataset') {
            switch (name.toLowerCase()) {
                case 'data collection attributes': return ['Data Collection', 'Attributes'];
                case 'raw data': return ['Raw', 'Data'];
                case 'covariation network': return ['Covariation', 'network'];
                case 'enrichment results': return ['Enrichment', 'results'];
                default: return name.split(' ');
            }
        } else if (type === 'Dataset') {
            return [type, name];
        }
    }

    const nodeElementForSafari = () => {
        const text = (
            textShape(nodeDatum.name, nodeDatum.type).map((stringVal, index) => {
                return (
                    <text
                        key={`${AppService.getRandomId(5)}_${index}`}
                        fill="white"
                        strokeWidth="0"
                        {...positionText(stringVal, nodeDatum.type, index)}
                        style={{ textAnchor: "inherit" }}
                        onMouseOverCapture={(event) => showAttributesPopOver(event, 'mouse-over')}
                        onMouseLeave={() => {
                            if (onHover < 1) {
                                clearTimeout(timeOut);
                                onForeignObject(null);
                            }
                        }}>
                        {stringVal}
                    </text>
                );
            })
        );
        const circle = (
            <Fragment>
                <circle {...foreignObjectProps} key={AppService.getRandomId(7)} strokeWidth="0" fill={getColor(nodeDatum.nodeType)} r={20}
                    ref={divRef}
                    tabIndex={nodeDatum?.tabIndex}
                    onDoubleClickCapture={doubleClick}
                    onMouseOverCapture={(event) => showAttributesPopOver(event, 'mouse-over')}
                    onMouseLeave={() => {
                        if (onHover < 1) {
                            clearTimeout(timeOut);
                            onForeignObject(null);
                        }
                    }} />
                <g className="rd3t-label" >
                    {text}
                </g>
            </Fragment>
        );
        const rect = (
            <Fragment key={AppService.getRandomId(7)}>
                <rect {...foreignObjectProps} key={AppService.getRandomId(7)} fill="#444444" rx="5" ry="5"
                    ref={divRef}
                    tabIndex={nodeDatum?.tabIndex}
                    onDoubleClickCapture={doubleClick}
                    onMouseOverCapture={(event) => showAttributesPopOver(event, 'mouse-over')}
                    onMouseLeave={() => {
                        if (onHover < 1) {
                            clearTimeout(timeOut);
                            onForeignObject(null);
                        }
                    }} />
                <g className="rd3t-label">
                    {text}
                </g>
            </Fragment>
        );
        return nodeDatum.type === 'transformation' ? circle : rect;
    }

    const nodeElementForOthers = () => {
        return (
            <g className="collection-node others" key={AppService.getRandomId(7)}>
                <foreignObject {...foreignObjectProps} alignmentBaseline="auto">
                    <div
                        ref={divRef}
                        tabIndex={nodeDatum?.tabIndex}
                        onDoubleClickCapture={doubleClick} // onClick={(event) => showAttributesPopOver(event, 'click')}
                        onMouseOverCapture={(event) => showAttributesPopOver(event, 'mouse-over')}
                        onMouseLeave={() => {
                            if (onHover < 1) {
                                clearTimeout(timeOut);
                                onForeignObject(null);
                            }
                        }}
                        className={`node-wrapper ${(nodeDatum?.size === 'circle') ? 'cl' : ''}`}>
                        {
                            nodeDatum?.hasArrow ? (
                                <div className="node-arrow">
                                    <div className="head">&#10148;</div>
                                </div>
                            ) : null
                        }
                        <div className={`node ${nodeDatum?.size} ${nodeDatum.nodeType ? nodeDatum.nodeType : ''}`}>
                            {
                                nodeDatum.type === 'Dataset' ?
                                    <div className="ds-title">
                                        <div className="title">{nodeDatum.type}</div>
                                        <div className="node-name version">{nodeDatum.name}</div>
                                    </div> :
                                    <div className="node-name">{nodeDatum?.name}</div>
                            }
                        </div>
                    </div>
                </foreignObject>
            </g>
        );
    }

    // return nodeElementForSafari();
    return (
        nodeDatum?.hideNodeLabel ? null :
            browserName.toLowerCase() === 'safari' ?
                nodeElementForSafari() :
                nodeElementForOthers()
    );
};

export default React.memo(NetworkNode);