export const POP_OVER_ITEM_LIST = {
    'folderOptions': [{
        img: 'download',
        name: 'Download',
        dc_permission: ['COLLECTION_DOWNLOAD'],
        permission: ['PROJECT_DOWNLOAD']
    }, {
        img: 'delete',
        name: 'Delete',
        dc_permission: ['COLLECTION_DELETE'],
        permission: ['PROJECT_DELETE']
    }],
    'recentFiles': [{
        img: 'open-file',
        name: 'Preview'
    }, {
        img: 'download',
        name: 'Download'
    }],
    'folderOptions_BACKUP': [{
        img: 'starred',
        name: 'Add to Starred'
    }, {
        img: 'collaborator',
        name: 'Add Collaborator'
    }, {
        img: 'download',
        name: 'Download'
    }, {
        img: 'delete',
        name: 'Delete'
    }],
    'userOptions': [
        /* {
            img: 'profile',
            name: 'My Profile'
        }, */
        {
            img: 'logout',
            name: 'Log Out'
        }
    ],
    'gridOptions': [{
        img: 'open-file',
        name: 'Preview',
        dc_permission: ['COLLECTION_PREVIEW', 'FILE_PREVIEW'],
        ds_permission: ['DATA_SET_VIEW', 'FILE_PREVIEW'],
        permission: ['PROJECT_PREVIEW', 'FILE_PREVIEW']
    }, {
        img: 'download',
        name: 'Download',
        dc_permission: ['COLLECTION_DOWNLOAD', 'FILE_DOWNLOAD'],
        permission: ['PROJECT_DOWNLOAD', 'FILE_DOWNLOAD']
    }],
    'geneSet': [{
        img: 'open-file',
        name: 'Preview',
        permission: ['RESOURCE_GENESET_PREVIEW']
    }, {
        img: 'download',
        name: 'Download',
        permission: ['RESOURCE_GENESET_DOWNLOAD']
    }],
    'gridOptions_BACKUP': [{
        img: 'open-file',
        name: 'Open File'
    }, {
        img: 'network',
        name: 'View Network'
    }, {
        img: 'download',
        name: 'Download'
    }, {
        img: 'delete',
        name: 'Delete'
    }, {
        img: 'starred',
        name: 'Add to Starred'
    }, {
        img: 'collaborator',
        name: 'Add Collaborator'
    }, {
        img: 'comments',
        name: 'Comment'
    }],
    'sampleAttributes': [{
        img: 'open-file',
        name: 'Edit',
        permission: ['SYSTEM_TABLES_SAMPLE_ATTRIBUTES_CREATE', 'SYSTEM_TABLES_SAMPLE_ATTRIBUTES_MODIFY']
    }, {
        img: 'delete',
        name: 'Delete',
        permission: ['SYSTEM_TABLES_SAMPLE_ATTRIBUTES_DELETE']
    }],
    'fileTypeResults': [{
        img: 'open-file',
        name: 'Preview'
    }, {
        img: 'download',
        name: 'Download'
    }],
    'files': [{
        img: 'open-file',
        name: 'Preview',
        ds_permission: ['DATA_SET_VIEW', 'FILE_PREVIEW'],
        dc_permission: ['DS_FOLDER_FILE_PREVIEW', 'FILE_PREVIEW'],
        permission: ['PROJECT_FOLDER_FILE_PREVIEW', 'FILE_PREVIEW']
    }, {
        img: 'download',
        name: 'Download',
        dc_permission: ['DS_FOLDER_FILE_DOWNLOAD', 'FILE_DOWNLOAD'],
        permission: ['PROJECT_FOLDER_FILE_DOWNLOAD', 'FILE_DOWNLOAD']
    }],
    'globalSearchFileTypeResults': [{
        img: 'open-file',
        name: 'Preview',
        ds_permission: ['DATA_SET_VIEW', 'FILE_PREVIEW'],
        dc_permission: ['DS_FOLDER_FILE_PREVIEW', 'FILE_PREVIEW'],
        permission: ['PROJECT_FOLDER_FILE_PREVIEW', 'FILE_PREVIEW']
    }, {
        img: 'download',
        name: 'Download',
        dc_permission: ['DS_FOLDER_FILE_DOWNLOAD', 'FILE_DOWNLOAD'],
        permission: ['PROJECT_FOLDER_FILE_DOWNLOAD', 'FILE_DOWNLOAD']
    }],
    'userManagement': [
        {
            img: 'delete',
            action: 'delete',
            name: 'Remove user'
        }
    ],
    'userManagementForLab': [
        {
            img: 'delete',
            action: 'delete',
            name: 'Remove user'
        }
    ],
    'savedQueries': [{
        img: 'delete',
        name: 'Delete',
        action: 'delete_query'
    }],
};