export const networkTreeData_BACKUP = {
    name: 'Raw Data',
    size: 'xsmall',
    hasArrow: false,
    nodeSvgShape: {
        shape: 'none'
    },
    children: [{
        name: 'RDP',
        type: 'rdp',
        size: 'circle',
        hasArrow: false,
        hideNodeLabel: false,
        nodeSvgShape: {
            shape: 'none', // 'circle',
            shapeProps: {
                r: 20,
                fill: '#F25E3B',
            }
        },
        x: 180,
        children: [{
            name: 'Preprocessed Data',
            size: 'large',
            hasArrow: true,
            nodeSvgShape: {
                shape: 'none'
            },
            x: 250,
            children: [{
                name: 'MDC',
                type: 'mdc',
                size: 'circle',
                hasArrow: false,
                hideNodeLabel: false,
                nodeSvgShape: {
                    shape: 'none', // 'circle',
                    shapeProps: {
                        r: 20,
                        fill: '#2390BB',
                    }
                },
                children: [{
                    name: 'Data Collection',
                    size: 'medium',
                    hasArrow: true,
                    nodeSvgShape: {
                        shape: 'none'
                    },
                    children: [
                        {
                            name: 'Dataset 1',
                            size: 'small',
                            hasArrow: true,
                            nodeSvgShape: {
                                shape: 'none'
                            },
                            children: [{
                                name: 'SN',
                                type: 'sn',
                                size: 'circle',
                                hasArrow: false,
                                hideNodeLabel: false,
                                nodeSvgShape: {
                                    shape: 'none'
                                },
                                children: [{
                                    name: 'Processed Data',
                                    size: 'medium',
                                    hasArrow: true,
                                    nodeSvgShape: {
                                        shape: 'none'
                                    },
                                    position: {
                                        x: -95
                                    },
                                    children: [{
                                        name: 'FM',
                                        type: 'fm',
                                        size: 'circle',
                                        hasArrow: false,
                                        hideNodeLabel: false,
                                        nodeSvgShape: {
                                            shape: 'none'
                                        },
                                        children: [{
                                            name: 'Covariation Networks',
                                            size: 'large',
                                            hasArrow: true,
                                            nodeSvgShape: {
                                                shape: 'none'
                                            },
                                            position: {
                                                x: -95
                                            },
                                        }]
                                    }]
                                }]
                            }]
                        },
                        {
                            name: 'Dataset 2',
                            size: 'small',
                            hasArrow: true,
                            nodeSvgShape: {
                                shape: 'none'
                            },
                            children: [{
                                name: 'SN',
                                type: 'sn',
                                size: 'circle',
                                hasArrow: false,
                                hideNodeLabel: false,
                                nodeSvgShape: {
                                    shape: 'none'
                                },
                                children: [{
                                    name: 'Processed Data',
                                    size: 'medium',
                                    hasArrow: true,
                                    nodeSvgShape: {
                                        shape: 'none'
                                    },
                                    position: {
                                        x: -95
                                    },
                                    children: [{
                                        name: 'FM',
                                        type: 'fm',
                                        size: 'circle',
                                        hasArrow: false,
                                        hideNodeLabel: false,
                                        nodeSvgShape: {
                                            shape: 'none'
                                        },
                                        children: [{
                                            name: 'Covariation Networks',
                                            size: 'large',
                                            hasArrow: true,
                                            nodeSvgShape: {
                                                shape: 'none'
                                            },
                                            position: {
                                                x: -95
                                            },
                                        }]
                                    }]
                                }]
                            }]
                        },
                        {
                            name: 'Dataset 3',
                            size: 'small',
                            hasArrow: true,
                            nodeSvgShape: {
                                shape: 'none'
                            },
                            children: [{
                                name: 'SN',
                                type: 'sn',
                                size: 'circle',
                                hasArrow: false,
                                hideNodeLabel: false,
                                nodeSvgShape: {
                                    shape: 'none'
                                },
                                children: [{
                                    name: 'Processed Data',
                                    size: 'medium',
                                    hasArrow: true,
                                    nodeSvgShape: {
                                        shape: 'none'
                                    },
                                    position: {
                                        x: -95
                                    },
                                    children: [{
                                        name: 'FM',
                                        type: 'fm',
                                        size: 'circle',
                                        hasArrow: false,
                                        hideNodeLabel: false,
                                        nodeSvgShape: {
                                            shape: 'none'
                                        },
                                        children: [{
                                            name: 'Covariation Networks',
                                            size: 'large',
                                            hasArrow: true,
                                            nodeSvgShape: {
                                                shape: 'none'
                                            },
                                            position: {
                                                x: -95
                                            },
                                        }]
                                    }]
                                }]
                            }]
                        }
                    ],
                }]
            }]
        }]
    }]
};

export const treeDataBackup = [
    {
        name: 'Data Collection',
        size: 'medium',
        /* attributes: {
            keyA: 'val A',
            keyB: 'val B',
            keyC: 'val C'
        }, */
        nodeSvgShape: {
            shape: 'none', // 'circle',
            /* shapeProps: {
                r: 20,
                fill: 'blue',
            }, */
        },
        children: [
            {
                name: 'Dataset 1',
                size: 'small',
                nodeSvgShape: {
                    shape: 'none', // 'rect',
                    /* shapeProps: {
                        width: 150,
                        height: 50,
                        x: -25,
                        y: -25,
                        rx: 12,
                        ry: 12,
                        fill: 'red'
                    }, */
                }
            },
            {
                name: 'Dataset 2',
                size: 'small',
                nodeSvgShape: {
                    shape: 'none', // 'circle',
                    /* shapeProps: {
                        r: 20,
                        fill: 'white',
                    }, */
                },
            },
            {
                name: 'Dataset 3',
                size: 'small',
                nodeSvgShape: {
                    shape: 'none'
                },
            }
        ],
    },
];

export const networkTreeData_BACKUP2 = {
    "_id": "b532af6ae35a485e9f1867306f38f94e",
    "name": "Source",
    "attributes": [],
    "files": [],
    "children": [
        {
            "name": "MDC",
            "type": "transformation",
            "attributes": {
                "Analyst": "",
                "Version": "",
                "Date Analyzed": "",
                "Working Directory": ""
            },
            "files": [
                {
                    "key": "Oldham_DC_16-59-32/Oldham_makeDC_sessionInfo_16-59-32.txt",
                    "name": "Oldham_makeDC_sessionInfo_16-59-32.txt"
                },
                {
                    "key": "Oldham_DC_16-59-32/Oldham_QA_log_16-59-32.csv",
                    "name": "Oldham_QA_log_16-59-32.csv"
                },
                {
                    "key": "Oldham_DC_16-59-32/E-MTAB-2768_makeDC_driver_16-59-32.R",
                    "name": "E-MTAB-2768_makeDC_driver_16-59-32.R"
                }
            ],
            "children": [
                {
                    "name": "Data Collection Attributes",
                    "attributes": [
                        {
                            "Title": "Labreche|E-MTAB-2768|2015",
                            "Owner": "Michael Oldham",
                            "Availability": "Lab",
                            "Number of Individuals": "71",
                            "Number of Samples": "71"
                        }
                    ],
                    "files": [
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DC_attributes_16-59-32.csv",
                            "name": "Oldham_DC_attributes_16-59-32.csv"
                        },
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DC_sample_attributes_16-59-32.csv",
                            "name": "Oldham_DC_sample_attributes_16-59-32.csv"
                        }
                    ],
                    "children": []
                }
            ]
        },
        {
            "_index": "network",
            "_id": "c84612b646cc44348de93856307b32af",
            "name": "Raw Data",
            "attributes": {
                "Raw Data Location": "NA"
            },
            "files": [
                "NA"
            ],
            "children": [{
                "name": "RDP",
                "type": "transformation",
                "attributes": {
                    "Analyst": "mRNA",
                    "Raw Data Processing Code": "E-MTAB-2768_log.R"
                },
                "files": [
                    {
                        "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/RDP/E-MTAB-2768_log_16-59-32.R",
                        "name": "E-MTAB-2768_log_16-59-32.R"
                    }
                ],
                "children": [{
                    "name": "Dataset",
                    "attributes": {
                        "Title": "Labreche|E-MTAB-2768|2015",
                        "Owner": "Michael Oldham",
                        "Analyst": "Original authors",
                        "Availability": "Lab",
                        "Analyte": "mRNA",
                        "Technology": "Microarray",
                        "Platform": "Affymetrix HGU133 Plus 2.0",
                        "Quantification Scale": "Continuous (log2)",
                        "Number of Features": "42548",
                        "Unique Identifier": "PROBEID"
                    },
                    "files": [
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/Oldham_DS1_analyte_metadata_16-59-32.csv",
                            "name": "Oldham_DS1_analyte_metadata_16-59-32.csv"
                        },
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/Oldham_DS1_analyte_data_16-59-32.csv",
                            "name": "Oldham_DS1_analyte_data_16-59-32.csv"
                        },
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/Oldham_DS1_attributes_16-59-32.csv",
                            "name": "Oldham_DS1_attributes_16-59-32.csv"
                        }
                    ],
                    "children": []
                }]
            }]
        }
    ]
}

export const networkTreeData_BACKUP7 = {
    'name': 'Source',
    'type': null,
    'attributes': [{
        'Source': 'GEO',
        'Accession ID': 'GSE4290',
        'BioProject ID': 'PRJNA94787',
        'PubMed ID': '16616334'
    }],
    'files': [{
        'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_attributes_20-53-46.csv',
        'name': 'GSE4290_DS1_attributes_20-53-46.csv'
    }],
    'children': [{
        'name': 'MDC',
        'type': 'transformation',
        'attributes': {
            'Analyst': '',
            'Version': '',
            'Date Analyzed': '',
            'Working Directory': ''
        },
        'files': [{
            'key': 'GSE4290_makeDC_20-53-46/GSE4290_QA_log_20-53-46.csv',
            'name': 'GSE4290_QA_log_20-53-46.csv'
        },
        {
            'key': 'GSE4290_makeDC_20-53-46/GSE4290_makeDC_sessionInfo_20-53-46.txt',
            'name': 'GSE4290_makeDC_sessionInfo_20-53-46.txt'
        },
        {
            'key': 'GSE4290_makeDC_20-53-46/GSE4290_makeDC.driver_20-53-46.R',
            'name': 'GSE4290_makeDC.driver_20-53-46.R'
        }],
        'children': [{
            'name': 'Data Collection Attributes',
            'type': null,
            'attributes': {
                'Title': 'Sun 2006 GSE4290',
                'Owner': 'Michael Oldham',
                'Availability': 'Lab',
                'Number of Individuals': '180',
                'Number of Samples': '180',
                '_id': '20e6c3904a7c421f9d5a4e603fd56a23'
            },
            'files': [{
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DC_sample_attributes_20-53-46.csv',
                'name': 'GSE4290_DC_sample_attributes_20-53-46.csv'
            },
            {
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DC_attributes_20-53-46.csv',
                'name': 'GSE4290_DC_attributes_20-53-46.csv'
            }],
            'children': []
        }]
    },
    {
        'name': 'Raw Data',
        'type': null,
        'attributes': { 'Raw Data Location': 'https://www.ncbi.nlm.nih.gov/geo/download/?acc=GSE4290&format=file' },
        'files': [{
            'name': 'https://www.ncbi.nlm.nih.gov/geo/download/?acc=GSE4290&format=file',
            'key': null
        }],
        'children': [{
            'name': 'RDP',
            'type': 'transformation',
            'attributes': { 'Analyst': 'mRNA', 'Raw Data Processing Code': null },
            'files': [{
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/RDP/GSE4290_Rcode_MCO_v3_RDP_20-53-46.R',
                'name': 'GSE4290_Rcode_MCO_v3_RDP_20-53-46.R'
            },
            {
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/RDP/GSE4290_DS1_RDP_workflow_20-53-46.csv',
                'name': 'GSE4290_DS1_RDP_workflow_20-53-46.csv'
            }],
            'children': [{
                'name': 'Dataset',
                'type': null,
                'attributes': {
                    'Title': 'Sun 2006 GSE4290',
                    'Owner': 'Michael Oldham',
                    'Analyst': 'Patrick Schupp',
                    'Availability': 'Lab',
                    'Analyte': 'mRNA',
                    'Technology': 'Microarray',
                    'Platform': 'Affymetrix HGU133 Plus 2.0',
                    'Quantification Scale': 'Continuous (linear)',
                    'Number of Features': '42548',
                    'Unique Identifier': 'PROBEID',
                    '_id': 'dd04c7e702414e1a9cd84619ec202af0'
                },
                'files': [{
                    'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_feature_metadata_20-53-46.csv',
                    'name': 'GSE4290_DS1_feature_metadata_20-53-46.csv'
                },
                {
                    'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_analyte_data_20-53-46.csv',
                    'name': 'GSE4290_DS1_analyte_data_20-53-46.csv'
                },
                {
                    'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_attributes_20-53-46.csv',
                    'name': 'GSE4290_DS1_attributes_20-53-46.csv'
                }],
                'children': [{
                    'name': 'OR',
                    'type': 'transformation',
                    'attributes': {
                        'Analyst': 'Patrick Schupp',
                        'Owner': 'Michael Oldham'
                    },
                    'files': [{
                        'name': 'GSE43289_All_38_final_sample_metrics.csv',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_final_sample_metrics.csv'
                    },
                    {
                        'name': 'All_rd_Qnorm_pc1.pdf',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_Qnorm_pc1.pdf'
                    },
                    {
                        'name': 'All_change_by_round.pdf',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_change_by_round.pdf'
                    },
                    {
                        'name': 'All_rd_ComBat_pc1.pdf',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_ComBat_pc1.pdf'
                    },
                    {
                        'name': 'All_rd_1_pc1.pdf',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_1_pc1.pdf'
                    },
                    {
                        'name': 'GSE43289_All_38_ComBat_sampleinfo.txt',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_ComBat_sampleinfo.txt'
                    },
                    {
                        'name': 'All_rd_2_pc1.pdf',
                        'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_2_pc1.pdf'
                    },
                    {
                        'name': 'GSE43289_makeSN_sessionInfo_13-14-11.txt',
                        'key': 'GSE43289_makeSN_13-14-11/GSE43289_makeSN_sessionInfo_13-14-11.txt'
                    },
                    {
                        'name': 'GSE43289_AllGroup_outliers_05-09-04.csv',
                        'key': 'GSE43289_makeSN_13-14-11/GSE43289_AllGroup_outliers_05-09-04.csv'
                    },
                    {
                        'name': 'GSE43289_AllGroup_dataset_metrics_05-09-04.csv',
                        'key': 'GSE43289_makeSN_13-14-11/GSE43289_AllGroup_dataset_metrics_05-09-04.csv'
                    },
                    {
                        'name': 'GSE43289_makeSN_QA_log_13-14-11.csv',
                        'key': 'GSE43289_makeSN_13-14-11/GSE43289_makeSN_QA_log_13-14-11.csv'
                    },
                    {
                        'name': 'GSE43289_SN_driver_05-09-04.R',
                        'key': 'GSE43289_makeSN_13-14-11/GSE43289_SN_driver_05-09-04.R'
                    },
                    {
                        'name': 'GSE43289_makeSN.driver_13-14-11.R',
                        'key': 'GSE43289_makeSN_13-14-11/GSE43289_makeSN.driver_13-14-11.R'
                    }],
                    'children': [{
                        'name': 'A.1.1',
                        'type': null,
                        'attributes': {
                            'Title': 'Vital 2010 GSE43289',
                            'Owner': 'Michael Oldham',
                            'Analyst': 'Patrick Schupp',
                            'Availability': 'Lab',
                            'Analyte': 'mRNA',
                            'Technology': 'Microarray',
                            'Platform': 'Affymetrix HGU133 Plus 2.0',
                            'Quantification Scale': 'Continuous (linear)',
                            'Number of Features': '42548',
                            'Unique Identifier': 'PROBEID',
                            '_id': '9e2d3f7d3f9b4542afc33ec5f56423f0'
                        },
                        'files': [{
                            'name': 'GSE43289_All_38_outliers_removed.csv',
                            'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_outliers_removed.csv'
                        },
                        {
                            'name': 'GSE43289_All_38_outliers_removed_DS_attributes.csv',
                            'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_outliers_removed_DS_attributes.csv'
                        }],
                        'children': [{
                            'name': 'QN',
                            'type': 'transformation',
                            'attributes': {
                                'Analyst': 'Patrick Schupp',
                                'Owner': 'Michael Oldham'
                            },
                            'files': [{
                                'name': 'GSE43289_All_38_final_sample_metrics.csv',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_final_sample_metrics.csv'
                            },
                            {
                                'name': 'All_rd_Qnorm_pc1.pdf',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_Qnorm_pc1.pdf'
                            },
                            {
                                'name': 'All_change_by_round.pdf',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_change_by_round.pdf'
                            },
                            {
                                'name': 'All_rd_ComBat_pc1.pdf',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_ComBat_pc1.pdf'
                            },
                            {
                                'name': 'All_rd_1_pc1.pdf',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_1_pc1.pdf'
                            },
                            {
                                'name': 'GSE43289_All_38_ComBat_sampleinfo.txt',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_ComBat_sampleinfo.txt'
                            },
                            {
                                'name': 'All_rd_2_pc1.pdf',
                                'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/All_rd_2_pc1.pdf'
                            },
                            {
                                'name': 'GSE43289_makeSN_sessionInfo_13-14-11.txt',
                                'key': 'GSE43289_makeSN_13-14-11/GSE43289_makeSN_sessionInfo_13-14-11.txt'
                            },
                            {
                                'name': 'GSE43289_AllGroup_outliers_05-09-04.csv',
                                'key': 'GSE43289_makeSN_13-14-11/GSE43289_AllGroup_outliers_05-09-04.csv'
                            },
                            {
                                'name': 'GSE43289_AllGroup_dataset_metrics_05-09-04.csv',
                                'key': 'GSE43289_makeSN_13-14-11/GSE43289_AllGroup_dataset_metrics_05-09-04.csv'
                            },
                            {
                                'name': 'GSE43289_makeSN_QA_log_13-14-11.csv',
                                'key': 'GSE43289_makeSN_13-14-11/GSE43289_makeSN_QA_log_13-14-11.csv'
                            },
                            {
                                'name': 'GSE43289_SN_driver_05-09-04.R',
                                'key': 'GSE43289_makeSN_13-14-11/GSE43289_SN_driver_05-09-04.R'
                            },
                            {
                                'name': 'GSE43289_makeSN.driver_13-14-11.R',
                                'key': 'GSE43289_makeSN_13-14-11/GSE43289_makeSN.driver_13-14-11.R'
                            }],
                            'children': [{
                                'name': 'A.1.1.1',
                                'type': null,
                                'attributes': {
                                    'Title': 'Vital 2010 GSE43289',
                                    'Owner': 'Michael Oldham',
                                    'Analyst': 'Patrick Schupp',
                                    'Availability': 'Lab',
                                    'Analyte': 'mRNA',
                                    'Technology': 'Microarray',
                                    'Platform': 'Affymetrix HGU133 Plus 2.0',
                                    'Quantification Scale': 'Continuous (linear)',
                                    'Number of Features': '42548',
                                    'Unique Identifier': 'PROBEID',
                                    '_id': '46654a1dae9f414dbb29653477ba8a92'
                                },
                                'files': [{
                                    'name': 'GSE43289_All_38_Qnorm.csv',
                                    'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_Qnorm.csv'
                                },
                                {
                                    'name': 'GSE43289_All_38_Qnorm_DS_attributes.csv',
                                    'key': 'GSE43289_makeSN_13-14-11/All_05-09-04/GSE43289_All_38_Qnorm_DS_attributes.csv'
                                }],
                                'children': []
                            }]
                        }]
                    }]
                }]
            }]
        }]
    }],
    '_id': '46654a1dae9f414dbb29653477ba8a92',
    'index': 'network'
}

export const networkTreeData = {
    'name': 'Source',
    'type': null,
    'attributes': [{
        'Source': 'GEO',
        'Accession ID': 'GSE4290',
        'BioProject ID': 'PRJNA94787',
        'PubMed ID': '16616334'
    }],
    'files': [{
        'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_attributes_20-53-46.csv',
        'name': 'GSE4290_DS1_attributes_20-53-46.csv'
    }],
    'children': [{
        'name': 'MDC',
        'type': 'transformation',
        'attributes': {
            'Analyst': '',
            'Version': '',
            'Date Analyzed': '',
            'Working Directory': ''
        },
        'files': [{
            'key': 'GSE4290_makeDC_20-53-46/GSE4290_QA_log_20-53-46.csv',
            'name': 'GSE4290_QA_log_20-53-46.csv'
        },
        {
            'key': 'GSE4290_makeDC_20-53-46/GSE4290_makeDC_sessionInfo_20-53-46.txt',
            'name': 'GSE4290_makeDC_sessionInfo_20-53-46.txt'
        },
        {
            'key': 'GSE4290_makeDC_20-53-46/GSE4290_makeDC.driver_20-53-46.R',
            'name': 'GSE4290_makeDC.driver_20-53-46.R'
        }],
        'children': [{
            'name': 'Data Collection Attributes',
            'type': null,
            'attributes': {
                'Title': 'Sun 2006 GSE4290',
                'Owner': 'Michael Oldham',
                'Availability': 'Lab',
                'Number of Individuals': '180',
                'Number of Samples': '180',
                '_id': '20e6c3904a7c421f9d5a4e603fd56a23'
            },
            'files': [{
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DC_sample_attributes_20-53-46.csv',
                'name': 'GSE4290_DC_sample_attributes_20-53-46.csv'
            },
            {
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DC_attributes_20-53-46.csv',
                'name': 'GSE4290_DC_attributes_20-53-46.csv'
            }],
            'children': []
        }]
    },
    {
        'name': 'Raw Data',
        'type': null,
        'attributes': { 'Raw Data Location': 'https://www.ncbi.nlm.nih.gov/geo/download/?acc=GSE4290&format=file' },
        'files': [{
            'name': 'https://www.ncbi.nlm.nih.gov/geo/download/?acc=GSE4290&format=file',
            'key': null
        }],
        'children': [{
            'name': 'RDP',
            'type': 'transformation',
            'attributes': { 'Analyst': 'mRNA', 'Raw Data Processing Code': null },
            'files': [{
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/RDP/GSE4290_Rcode_MCO_v3_RDP_20-53-46.R',
                'name': 'GSE4290_Rcode_MCO_v3_RDP_20-53-46.R'
            },
            {
                'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/RDP/GSE4290_DS1_RDP_workflow_20-53-46.csv',
                'name': 'GSE4290_DS1_RDP_workflow_20-53-46.csv'
            }],
            'children': [{
                'name': 'Dataset',
                'type': null,
                'attributes': {
                    'Title': 'Sun 2006 GSE4290',
                    'Owner': 'Michael Oldham',
                    'Analyst': 'Patrick Schupp',
                    'Availability': 'Lab',
                    'Analyte': 'mRNA',
                    'Technology': 'Microarray',
                    'Platform': 'Affymetrix HGU133 Plus 2.0',
                    'Quantification Scale': 'Continuous (linear)',
                    'Number of Features': '42548',
                    'Unique Identifier': 'PROBEID',
                    '_id': 'dd04c7e702414e1a9cd84619ec202af0'
                },
                'files': [{
                    'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_feature_metadata_20-53-46.csv',
                    'name': 'GSE4290_DS1_feature_metadata_20-53-46.csv'
                },
                {
                    'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_analyte_data_20-53-46.csv',
                    'name': 'GSE4290_DS1_analyte_data_20-53-46.csv'
                },
                {
                    'key': 'GSE4290_makeDC_20-53-46/GSE4290_DS1_20-53-46/GSE4290_DS1_attributes_20-53-46.csv',
                    'name': 'GSE4290_DS1_attributes_20-53-46.csv'
                }],
                'children': [{
                    'name': 'OR',
                    'type': 'transformation',
                    'attributes': {
                        'Analyst': 'Patrick Schupp',
                        'Owner': 'Michael Oldham'
                    },
                    'files': [{
                        'name': 'Normal_rd_1_pc1.pdf',
                        'key': 'GSE9385_makeSN_13-02-45/Normal_12-16-57/Normal_rd_1_pc1.pdf'
                    },
                    {
                        'name': 'GSE9385_Normal_6_final_sample_metrics.csv',
                        'key': 'GSE9385_makeSN_13-02-45/Normal_12-16-57/GSE9385_Normal_6_final_sample_metrics.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN_QA_log_13-02-45.csv',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN_QA_log_13-02-45.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN_sessionInfo_13-02-45.txt',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN_sessionInfo_13-02-45.txt'
                    },
                    {
                        'name': 'GSE9385_SN_driver_12-21-28.R',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_SN_driver_12-21-28.R'
                    },
                    {
                        'name': 'SampleNetwork_1.08.r',
                        'key': 'GSE9385_makeSN_13-02-45/SampleNetwork_1.08.r'
                    },
                    {
                        'name': 'GSE9385_histology_dataset_metrics_12-21-28.csv',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_histology_dataset_metrics_12-21-28.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN.driver_13-02-45.R',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN.driver_13-02-45.R'
                    }],
                    'children': [{
                        'name': 'A.1.2',
                        'type': null,
                        'attributes': {
                            'Title': 'French 2007 GSE9385',
                            'Owner': 'Michael Oldham',
                            'Analyst': 'Patrick Schupp',
                            'Availability': 'Lab',
                            'Analyte': 'mRNA',
                            'Technology': 'Microarray',
                            'Platform': 'Affymetrix Human Exon 1.0 ST',
                            'Quantification Scale': 'Continuous (linear)',
                            'Number of Features': '20785',
                            'Unique Identifier': 'PROBEID',
                            '_id': '2324a9265490429487ef89bdfd8d5eb1'
                        },
                        'files': [{
                            'name': 'GSE9385_Normal_6_outliers_removed.csv',
                            'key': 'GSE9385_makeSN_13-02-45/Normal_12-16-57/GSE9385_Normal_6_outliers_removed.csv'
                        },
                        {
                            'name': 'GSE9385_Normal_6_outliers_removed_DS_attributes.csv',
                            'key': 'GSE9385_makeSN_13-02-45/Normal_12-16-57/GSE9385_Normal_6_outliers_removed_DS_attributes.csv'
                        }],
                        'children': []
                    }]
                },
                {
                    'name': 'OR',
                    'type': 'transformation',
                    'attributes': {
                        'Analyst': 'Patrick Schupp',
                        'Owner': 'Michael Oldham'
                    },
                    'files': [{
                        'name': 'GSE9385_oligodendroglioma_23_final_sample_metrics.csv',
                        'key': 'GSE9385_makeSN_13-02-45/oligodendroglioma_12-21-28/GSE9385_oligodendroglioma_23_final_sample_metrics.csv'
                    },
                    {
                        'name': 'oligodendroglioma_rd_1_pc1.pdf',
                        'key': 'GSE9385_makeSN_13-02-45/oligodendroglioma_12-21-28/oligodendroglioma_rd_1_pc1.pdf'
                    },
                    {
                        'name': 'GSE9385_makeSN_QA_log_13-02-45.csv',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN_QA_log_13-02-45.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN_sessionInfo_13-02-45.txt',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN_sessionInfo_13-02-45.txt'
                    },
                    {
                        'name': 'GSE9385_SN_driver_12-21-28.R',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_SN_driver_12-21-28.R'
                    },
                    {
                        'name': 'SampleNetwork_1.08.r',
                        'key': 'GSE9385_makeSN_13-02-45/SampleNetwork_1.08.r'
                    },
                    {
                        'name': 'GSE9385_histology_dataset_metrics_12-21-28.csv',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_histology_dataset_metrics_12-21-28.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN.driver_13-02-45.R',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN.driver_13-02-45.R'
                    }],
                    'children': [{
                        'name': 'A.1.3',
                        'type': null,
                        'attributes': {
                            'Title': 'French 2007 GSE9385',
                            'Owner': 'Michael Oldham',
                            'Analyst': 'Patrick Schupp',
                            'Availability': 'Lab',
                            'Analyte': 'mRNA',
                            'Technology': 'Microarray',
                            'Platform': 'Affymetrix Human Exon 1.0 ST',
                            'Quantification Scale': 'Continuous (linear)',
                            'Number of Features': '20785',
                            'Unique Identifier': 'PROBEID',
                            '_id': 'd425eec42ce74b44bd1763d506960a52'
                        },
                        'files': [{
                            'name': 'GSE9385_oligodendroglioma_23_outliers_removed.csv',
                            'key': 'GSE9385_makeSN_13-02-45/oligodendroglioma_12-21-28/GSE9385_oligodendroglioma_23_outliers_removed.csv'
                        },
                        {
                            'name': 'GSE9385_oligodendroglioma_23_outliers_removed_DS_attributes.csv',
                            'key': 'GSE9385_makeSN_13-02-45/oligodendroglioma_12-21-28/GSE9385_oligodendroglioma_23_outliers_removed_DS_attributes.csv'
                        }],
                        'children': []
                    }]
                },
                {
                    'name': 'OR',
                    'type': 'transformation',
                    'attributes': {
                        'Analyst': 'Patrick Schupp',
                        'Owner': 'Michael Oldham'
                    },
                    'files': [{
                        'name': 'glioblastoma_rd_1_pc1.pdf',
                        'key': 'GSE9385_makeSN_13-02-45/glioblastoma_12-21-14/glioblastoma_rd_1_pc1.pdf'
                    },
                    {
                        'name': 'GSE9385_glioblastoma_26_final_sample_metrics.csv',
                        'key': 'GSE9385_makeSN_13-02-45/glioblastoma_12-21-14/GSE9385_glioblastoma_26_final_sample_metrics.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN_QA_log_13-02-45.csv',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN_QA_log_13-02-45.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN_sessionInfo_13-02-45.txt',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN_sessionInfo_13-02-45.txt'
                    },
                    {
                        'name': 'GSE9385_SN_driver_12-21-28.R',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_SN_driver_12-21-28.R'
                    },
                    {
                        'name': 'SampleNetwork_1.08.r',
                        'key': 'GSE9385_makeSN_13-02-45/SampleNetwork_1.08.r'
                    },
                    {
                        'name': 'GSE9385_histology_dataset_metrics_12-21-28.csv',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_histology_dataset_metrics_12-21-28.csv'
                    },
                    {
                        'name': 'GSE9385_makeSN.driver_13-02-45.R',
                        'key': 'GSE9385_makeSN_13-02-45/GSE9385_makeSN.driver_13-02-45.R'
                    }],
                    'children': [{
                        'name': 'A.1.1',
                        'type': null,
                        'attributes': {
                            'Title': 'French 2007 GSE9385',
                            'Owner': 'Michael Oldham',
                            'Analyst': 'Patrick Schupp',
                            'Availability': 'Lab',
                            'Analyte': 'mRNA',
                            'Technology': 'Microarray',
                            'Platform': 'Affymetrix Human Exon 1.0 ST',
                            'Quantification Scale': 'Continuous (linear)',
                            'Number of Features': '20785',
                            'Unique Identifier': 'PROBEID',
                            '_id': 'a033ef7b53f649519d11d60367324c6b'
                        },
                        'files': [{
                            'name': 'GSE9385_glioblastoma_26_outliers_removed.csv',
                            'key': 'GSE9385_makeSN_13-02-45/glioblastoma_12-21-14/GSE9385_glioblastoma_26_outliers_removed.csv'
                        },
                        {
                            'name': 'GSE9385_glioblastoma_26_outliers_removed_DS_attributes.csv',
                            'key': 'GSE9385_makeSN_13-02-45/glioblastoma_12-21-14/GSE9385_glioblastoma_26_outliers_removed_DS_attributes.csv'
                        }],
                        'children': []
                    }]
                }]
            }]
        }]
    }]
}

export const ATTRIBUTE_LIST_ORDER = [
    'Title',
    'Owner',
    'Analyst',
    'Availability',
    'Analyte',
    'Technology',
    'Platform',
    'Unique Identifier',
    'Number of Features',
    'Transformations',
    'Quantification Scale',
    'Number of Individuals',
    'Number of Samples'
];

export const ATTRIBUTE_LIST_ORDER_SOURCE = [
    'Source',
    'Accession ID',
    'BioProject ID',
    'PubMed ID'
];

export const ATTRIBUTE_LIST_ORDER_DC_ATTRIBUTES = [
    'Title',
    'Owner',
    'Availability',
    'Number of Individuals',
    'Number of Samples'
];

export const ATTRIBUTE_LIST_ORDER_MDC = [
    'Analyst',
    'Date Analyzed'
];