import AppService from "../../core/services/app-service";
import _ from 'lodash';
import AuthService from '../../core/services/authentication-service';

export const setDownloadNotifList = (notifList = [], payload = null) => {
    const { data, type } = payload;
    if (type === 'clear all') {
        notifList = [];
    } else if (type === 'clear-one') {
        notifList = notifList
            .filter((notif) => ((notif.name !== data.name) && (notif.name !== 'clear all')));
    } else if (type === 'all') {
            notifList = [];
            data.searchNotifications?.forEach((item) => {//advance search notifications
               const notifData = JSON.parse(item.advancedSearchNotifData.notification_data);
                const dataObj = {
                    listType: 'advancedSearch',
                    img: 'search-in-list',
                    notifId: item.notifId,
                    name: `Search Results__${notifData?.formData?.description?.value ? notifData?.formData?.description?.value : item.advancedSearchNotifData.type}__${item.advancedSearchNotifData.timestamp}`,
                    notifData: notifData,
                    ...item.advancedSearchNotifData
                };
                notifList = [...notifList, _.cloneDeep(dataObj)];
            });
            notifList = AppService.removeDuplicateObjectFromArray(notifList, 'notifId');
            data.downloadNotifications?.forEach((downLog) => {//download notifications
                const downloadObj = {
                    listType: 'download',
                    img: 'zip',
                    name: downLog.key.split('/')[1],
                    key: downLog.key
                };
                notifList = [...notifList, _.cloneDeep(downloadObj)];
            });
            data.mention_notifications?.forEach((mention) => {
                const mentionObject = {
                    listType: 'mention',
                    img: 'comments',
                    notifId: mention.notification_id,
                    name: `${mention.mentioned_by} mentioned you in a comment on ${mention.created_at}`,
                    url: mention.comment_page_url,
                    parent_id: mention.parent_id || mention.comment
                };
                notifList = [...notifList, mentionObject];
            });

    }
    return notifList;
}