const sampleAttributeAddForm = {
    id: {
        value: null,
        valid: true
    },
    sampleAttributeSource: {
        label: 'Attribute',
        description: 'attribute',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    type: {
        label: 'Attribute Type',
        description: 'attribute type',
        elementType: 'select',
        elementConfig: {
            type: 'select',
            placeholder: '',
            disabled: false
        },
        value: 'Text',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    examples: {
        label: 'Example',
        description: 'example',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    required: {
        label: 'Required',
        description: 'required',
        elementType: 'select',
        elementConfig: {
            type: 'select',
            placeholder: '',
            disabled: false
        },
        value: 'Yes',
        validation: {
            required: true
        },
        valid: true,
        touched: false
    },
    indexed: {
        label: 'Indexed',
        description: 'indexed',
        elementType: 'select',
        elementConfig: {
            type: 'select',
            placeholder: '',
            disabled: false
        },
        value: 'No',
        validation: {
            required: true
        },
        valid: true,
        touched: false
    },
    possibleValues: {
        label: 'Enumerated Values',
        description: 'enumerated values',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            hint: "Enter comma separated values. Eg: x, y, z, ...",
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: false,
            isCommaSeparated: true
        },
        valid: true,
        touched: false
    },
    category: {
        label: 'Category',
        description: 'category',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: false
        },
        valid: false,
        touched: false
    },
    dataSource: {
        label: 'Data Source',
        description: 'data source',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    description: {
        label: 'Description',
        description: 'description',
        elementType: 'textArea',
        elementConfig: {
            type: 'text',
            placeholder: null,
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
};

export default sampleAttributeAddForm;