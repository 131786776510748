/* eslint-disable no-unused-vars */
import React, { createRef, useEffect, useState } from 'react';
import './list-element.styles.scss';
import PopOver from '../pop-over/pop-over.component';
import AppService from '../../services/app-service';
import { POP_OVER_ITEM_LIST } from '../pop-over/data/pop-over.data';

const ListElement = (props) => {
    const { draggable = false, listType, elementType } = props;
    const [showPopOver, setShowPopOver] = useState(false);
    const [divReference, setDivReference] = useState(null);
    const listItems = AppService.filterDetailViewWithPermissions(POP_OVER_ITEM_LIST[listType], elementType);
    const divRef = createRef();

    useEffect(() => {
        if (props.scrolled) {
            setShowPopOver(false);
            setDivReference(null);
        }
    }, [props.scrolled]);

    const clickMethod = (event, clickType = null) => {
        if (props.isDataSet) {
            return props.arrowClick('isDataSet', props, event);
        }
        return props.arrowClick(props, event, clickType);
    }

    const togglePopOver = (type = null) => {
        props.itemClicked();
        setDivReference(divRef);
        if (type) {
            setShowPopOver(false);
        } else {
            setShowPopOver(!showPopOver);
        }
    }

    const onDragEvent = (event) => {
        let dt = event.dataTransfer;
        const stringifiedObj = JSON.stringify(props); // `${{ ...props }}`;
        dt.setData('props', stringifiedObj);
    }

    return (
        <div
            className={`list-element-container${(props.focused) ? ' selected' : ''}${props.isDataSet ? ' data-set' : ''}`}
            draggable={draggable}
            onDragStart={(event) => onDragEvent(event)}>
            <div className="part-l">
                { /*
                    elementType !== 'collections' ?
                        <div
                            className="arrow-content"
                            onClick={(event) => clickMethod(event, 'arrowClick')}>
                            <i className={`arrow ${props.selected ? 'down' : 'right'}`}></i>
                        </div> : null
                */ }
                <div className="label-content" onClick={(event) => clickMethod(event)}>
                    <div className="folder-icon"></div>
                    <div className="list-name" title={props.title}>{`${props.title}`}</div>
                </div>
            </div>
            {
                listItems.length ?
                    <div className="part-r">
                        <div
                            ref={divRef}
                            className="more-icon"
                            onClick={() => togglePopOver()}
                            onBlur={() => togglePopOver('blur')}
                            tabIndex={props.tabIndex}>
                            {
                                showPopOver ? (
                                    <PopOver
                                        listType={props.listType}
                                        tabIndex={props.tabIndex}
                                        elementType={props.elementType}
                                        scrollObject={props.scrollObject}
                                        divRef={divReference}
                                        click={(event) => props.moreItemsClick(event, props)} />
                                ) : null
                            }
                        </div>
                    </div> :
                    null
            }
        </div>
    );
};

export default React.memo(ListElement);