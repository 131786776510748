import { createSelector } from "reselect";

const selectedUser = state => state.user;

export const getCurrentUser = createSelector(
    [selectedUser],
    (user) => user?.currentUser
);

export const fetchCurrentUser = ({ user }) => {
    return user.currentUser;
};

export const getCurrentRouteInfo = createSelector(
    [selectedUser],
    (user) => user?.routeData
);

export const getAwsCredentials = createSelector(
    [selectedUser],
    (user) => user?.awsCredentials
);

export const getDownloadNotifs = createSelector(
    [selectedUser],
    (user) => user?.downloadNotifs
);

export const getGlobalSearchData = createSelector(
    [selectedUser],
    (user) => user.globalSearchData
);

export const getExactMatchGlobalSearchData = ({ user }) => {
    return user?.exactMatchGlobalSearchData;
}