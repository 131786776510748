import React, { createRef, useEffect } from 'react';
import useState from 'react-usestateref';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setModalData, setModalSubmitData } from '../../../../redux/modal/modal.actions';
import { getModalData } from '../../../../redux/modal/modal.selectors';
import Aux from '../../auxiliary/auxiliary';
import CustomButton from '../../custom-button/custom-button.component';
import './omi-modal.styles.scss';
import '../modals.styles.scss';
import SampleAttributesModalBody from '../../../../pages/post-auth/home/admin/system-tables/attribute-tables/sample-attributes/sa-modal-body/sa-modal-body.component';
import FilePreviewContent from '../file-content/file-content.component';
import DCUpload from '../../../../pages/post-auth/home/collection/network/dc-upload/dc-upload.component';
import { setFileUploadStateReset, setLoader, setNotifData, setRefreshAppId, setRefreshWindow } from '../../../../redux/app/app.actions';
import AppService from '../../../services/app-service';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import GeneSetModalBody from '../../../../pages/post-auth/home/resources/gene-set/gs-modal-body/gs-modal-body.component';
import SaCohortModal from '../sa-cohort-modal/sa-cohort-modal.component';
import { Fragment } from 'react';
import { getAppId } from '../../../../redux/app/app.selectors';
import { setCurrentUser, setDownloadNotifs } from '../../../../redux/user/user.actions';
import SingleSampleBlock from '../single-block-modal/single-block-modal';
import NewUserModal from '../../../../pages/post-auth/home/admin/user-management/new-user-modal/new-user-modal.component';
import SaveQueryModal from '../../../../pages/post-auth/home/advanced-search/search-tabs/save-query-modal/save-query.modal';
import PermissionModal from '../permission-modal/permission.modal';
import SecurityService from '../../../services/security-service';
import { UP_ACCESS } from '../../../utils/permissions';
import ReportBugModal from '../../report-bug/report-bug-modal/report-bug-modal.component';
import MessageModal from '../message-modal/message.modal';
import { getCurrentUser } from '../../../../redux/user/user.selectors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import TOSModal from '../tos-modal/tos.modal';
import { ucsfInstance } from '../../../../core/axios/ucsf';
import { COMPONENT_APIS } from '../../../../core/utils/omi-api';

const OmiModal = ({
    dataModel,
    setModalData,
    setModalSubmitData,
    setLoader,
    setFileUploadReset,
    location,
    setNotifData,
    currentUser
}) => {
    const [animate, setAnimate] = useState(false);
    const [formEvent, setFormEvent, formEventRef] = useState(null);
    const [formIsValid, setFormValidity] = useState(false);
    const [disableClose, setDisableClose] = useState(false);
    const [modalContainerStyle, setModalContainerStyle] = useState(null);
    const [csvClass, setCsvClass] = useState('');
    const [uploadFiles, setUploadFiles] = useState(null);
    const [files, setFiles] = useState(null);
    const [isTosAccepted, setIsTosAccepted] = useState(false);
    const containerRef = createRef(); // const wsEndPoint = AppService.wsDowloadZip(appId);
    const d = useDispatch();
    const history = useHistory();
    let queryParams = null;
    if (location) { queryParams = AppService.fetchQueryParams(location.search); }

    useEffect(() => {
        setCsvClass('');
        if (
            dataModel &&
            dataModel.modalData &&
            dataModel.modalData.name &&
            dataModel.modalData.name.includes('.csv')
        ) {
            setCsvClass('csv');
        } else if (dataModel?.modalData?.fileType?.toLowerCase() === 'pdf') {
            setCsvClass('pdf');
        }
        if (formIsValid) { setFormValidity(false); }
        if (dataModel && dataModel.type === 'file-upload') { setFormValidity(true); }
        if (dataModel && dataModel.type === 'permission') { setFormValidity(true); }
        if (dataModel && dataModel.type === 'version-update') {
            setDisableClose(true);
            setFormValidity(true);
        }
        if (dataModel && dataModel.type === 'tos') { setFormValidity(true); }
        setModalContainerStyle(null);
        return () => {
            setFormValidity(false);
            setFileUploadReset(false);
            setAnimate(false);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataModel]);

    const modalBodyContent = () => {
        switch (dataModel.type) {
            case 'new-gene-set':
                return (
                    <GeneSetModalBody
                        {...dataModel}
                        changeTrigger={(event) => getTriggeredEvent(event)}
                        filesTrigger={(event) => getFilesTriggerEvent(event)}
                        filesAccepted={(files) => setFileUploads(files)} />
                );
            case 'file-upload':
                return (
                    <Aux>
                        <div className="error-modal-container-body-desc">
                            {
                                dataModel.data.errorList && dataModel.data.errorList.length ?
                                    `${dataModel.data.message} following are the missing files:` :
                                    'Some error has occured while uploading. Please try again'
                            }
                        </div>
                        <div className="error-modal-container-body-points">
                            {
                                dataModel.data.errorList && dataModel.data.errorList.length ?
                                    dataModel.data.errorList.map((listItem, i) => {
                                        return (<div key={i + 1} className="point">• <span>{listItem}</span></div>);
                                    }) :
                                    dataModel.data.message
                            }
                        </div>
                        <div className="error-modal-container-body-desc">
                            {/* Your file could not be uploaded for the following reasons: */}
                        </div>
                    </Aux>
                );
            case 'new-sample-attribute':
                return <SampleAttributesModalBody {...dataModel} changeTrigger={(event) => getTriggeredEvent(event)} />;
            case 'sample-attribute-modal':
                return <SaCohortModal {...dataModel} />;
            case 'file-preview':
                return <FilePreviewContent {...dataModel} />;
            case 'dc-upload':
                return <DCUpload {...dataModel} filesAccepted={(files) => setFileUploads(files)} uploadFile={uploadFiles} />;
            case 'single-sample-block':
                return <SingleSampleBlock {...dataModel} />;
            case 'new-user':
                return <NewUserModal {...dataModel} changeTrigger={(event) => getTriggeredEvent(event)} />;
            case 'save-search-query':
                return <SaveQueryModal {...dataModel} changeTrigger={(event) => getTriggeredEvent(event)} />;
            case 'permission':
                return <PermissionModal {...dataModel} changeTrigger={(event) => getTriggeredEvent(event)} />;
            case 'report-bug':
                return <ReportBugModal {...dataModel} changeTrigger={(event) => getTriggeredEvent(event)} />;
            case 'version-update':
                return <MessageModal {...dataModel} />;
            case 'tos':
                return <TOSModal isTosAccepted={isTosAccepted} />;
            default:
                break;
        }
    };

    const onMessageModalClick = (e) => {
        if (e.toLowerCase() === 'refresh') {
            window.location.reload();
        } else if (e.toLowerCase() === 'logout') {
            closeModal();
            setLoader(true, 'Logging you out');
            SecurityService.removeSecurityToken();
            SecurityService.setLogoutId(AppService.getRandomId(6));
            if ((currentUser && (currentUser?.role === 'RU' || currentUser?.role === 'GU')) || !currentUser) {
                SecurityService.setLogoutId('');
            }
            setTimeout(() => {
                d(setRefreshAppId(true));
                setTimeout(() => {
                    setLoader(false);
                    d(setCurrentUser());
                    history.push('/');
                    setTimeout(() => {
                        d(setRefreshWindow(true));
                    }, 275);
                }, 1500);
            }, 250);
        }
    }

    const setFileUploads = (files) => {
        setFormValidity(false);
        setFiles(files);
        if (files && files.length) {
            setFormValidity(true);
        }
    }

    const getFilesTriggerEvent = (event) => {
        if (event && event.length) {
            setModalContainerStyle({ marginTop: `${14 + (event.length + 0.5)}%`, marginBottom: '35px' });
        }
    }

    const getTriggeredEvent = (event) => {
        setFormEvent(event);
        if (formEventRef.current.filesAccepted) {
            if ('isFilesRequired' in formEventRef.current && !formEventRef.current['isFilesRequired']) {
                setFormValidity(formEventRef.current?.isFormValid);
            } else {
                setFormValidity(formEventRef.current?.isFormValid && formEventRef.current.filesAccepted.length);
            }
        } else {
            setFormValidity(formEventRef.current?.isFormValid);
        }
        getModalContainerStyle();
    }

    const closeModal = () => {
        setAnimate(true);
        setTimeout(() => {
            setFormValidity(false);
            setModalData();
            setAnimate(false);
        }, 500);
    }

    const modalButtonAction = (type = 'close') => {
        switch (type) {
            case 'submit':
                if (dataModel.modalData) { formEvent['reqType'] = 'UPDATE'; }
                if (formEvent && files && files.length) { formEvent['files'] = files; }
                setModalSubmitData(formEvent); // setFormValidity(false);
                closeModal();
                break;
            case 'permission':
                const clearCacheData = () => {
                    caches?.keys()?.then((names) => {
                        names?.forEach((name) => {
                            caches?.delete(name);
                        });
                    });
                };
                setLoader(true);
                clearCacheData();
                SecurityService.removeSecurityToken();
                SecurityService.setLogoutId('');
                setTimeout(() => {
                    d(setRefreshAppId(true));
                    d(setCurrentUser());
                    setTimeout(() => {
                        setLoader(false);
                        closeModal();
                        dataModel.history.push('/register');
                    }, 750);
                }, 250); // setFormValidity(false);
                break;
            case 'delete':
                if (dataModel.modalData) { formEvent['reqType'] = 'DELETE'; }
                setModalSubmitData(formEvent); // setFormValidity(false);
                closeModal();
                break;
            case 'file-upload':
                setUploadFiles(files);
                break;
            case 'closeAndUpload':
                setAnimate(true);
                setTimeout(() => {
                    setFileUploadReset(true);
                    setModalData();
                    setAnimate(false);
                }, 500);
                break;
            case 'close':
                setAnimate(true);
                setTimeout(() => {
                    setModalData();
                    setAnimate(false);
                }, 275);
                break;
            case 'tos':
                setIsTosAccepted(true);
                closeModal();
                break;
            default:
                if (dataModel.type === 'tos') {
                    setLoader(true, 'Logging you out');
                    const clearCacheData = () => {
                        caches?.keys()?.then((names) => {
                            names?.forEach((name) => {
                                caches?.delete(name);
                            });
                        });
                    };
                    clearCacheData();
                    SecurityService.removeSecurityToken();
                    SecurityService.setLogoutId(AppService.getRandomId(6));
                    if (currentUser?.role === 'RU') {
                        SecurityService.setLogoutId('');
                    }
                    setTimeout(() => {
                        setRefreshAppId(true);
                        setTimeout(() => {
                            setLoader(false);
                            setCurrentUser();
                            window.location.href = '/';
                        }, 1500);
                    }, 250);

                }
                else closeModal();
                break;
        }
    }

    const getModalContainerStyle = () => {
        const doc = containerRef.current; // document.getElementsByClassName('modal-container')[0];
        if (doc && ((window.outerHeight - doc.clientHeight) <= 120)) {
            if (!modalContainerStyle) {
                setModalContainerStyle({ marginTop: '10%', marginBottom: '35px' });
            }
        }
    }
    const getDownloadType = (rowData) => {
        let type = '';
        if (rowData['key'].includes('mapping_tables')) {
            type = 'Mapping Tables';
        }
        if (rowData['type'] === 'filePreview') {
            type = 'Gene Sets';
        }
        if (rowData['type_']?.includes('(')) {
            type = rowData['type_'].split('(')[0];
        }
        else
            return type || rowData['downloadType'] || rowData['type'] || rowData['type_'];
    }
    const awsSocketFolderDownload = (rowData, type = null) => {
        AppService.getAwsCredentials().then(async res => {
            const notifData = {
                title: 'Download Initiated!',
                message: `${rowData.name}`
            };
            setLoader(false);
            AppService.setNotification(notifData);
            if (type === 'docker') {
                setNotifData({
                    type,
                    covariation_file_id: rowData['_id'],
                    project_id: queryParams['analysisId']
                });
            } else {
                setNotifData({ key: rowData['key'], type, downloadType: getDownloadType(rowData), name: rowData['name'] });
            }
        });
    };

    const fileDownload = (rowData) => {
        if (rowData.isHelpAboutModal) {
            AppService.getS3URLAndDownloadFile(rowData);
        } else {
            let fileTypeList = ['csv', 'r', 'r-code', 'txt', 'pdf'];
            if (rowData['fileType'] && (fileTypeList.indexOf(rowData['fileType'].toLowerCase()) >= 0)) {
                awsSocketFolderDownload(rowData);
            } else if (rowData['sizeDisplayName'] && AppService.sizeChecker(rowData['sizeDisplayName'])) {
                awsSocketFolderDownload(rowData);
            } else if (rowData.size) {
                let fileSizeDisplayName = AppService.formatBytes(rowData.size);
                if (AppService.sizeChecker(fileSizeDisplayName)) {
                    awsSocketFolderDownload(rowData);
                } else {
                    urlDownload(rowData);
                }
            } else {
                urlDownload(rowData);
            }
        }
    };

    const urlDownload = (rowData) => {
        setLoader(true);
        AppService.getAwsCredentials().then(async res => {
            const s3Configuration = await {
                credentials: {
                    accessKeyId: res['AccessKeyId'],
                    secretAccessKey: res['SecretAccessKey'],
                    sessionToken: res['SessionToken']
                },
                region: res['region']
            };
            const s3 = await new S3Client(s3Configuration);
            const url = await getSignedUrl(s3, new GetObjectCommand({
                Bucket: res['bucket'],
                Key: rowData['key']
            }), {
                expiresIn: 15 * 60
            });
            AppService.downloadURL(url);
            // window.open(url, '_blank');
            setLoader(false);
        });
    }

    return (
        <Aux>
            {
                dataModel ? (
                    <Aux>
                        <div className={`overlay-container ${dataModel?.type}`}></div>
                        <div className="modal-content-container">
                            {
                                (dataModel?.type === 'file-preview') ? (
                                    <div className="file-header">
                                        <div className="redirection">
                                            <div className="left-arrow" onClick={() => modalButtonAction()}></div>
                                            <div className={`${dataModel.modalData.fileType}-icon`}></div>
                                            <div style={{ marginLeft: '10px' }}>
                                                {dataModel.modalData.name}
                                            </div>
                                        </div>
                                        <div className="comments">
                                            {
                                                UP_ACCESS(['FILE_DOWNLOAD']) &&
                                                <CustomButton
                                                    inverted='inverted'
                                                    style={{ height: '30px', width: '100px' }}
                                                    click={() => fileDownload(dataModel.modalData)}>
                                                    Download{dataModel.modalData.size ? ` (${AppService.formatBytes(dataModel.modalData.size)})` : ''}
                                                </CustomButton>
                                            }
                                        </div>
                                    </div>
                                ) : null
                            }
                            <div className={`modal-container ${dataModel.type}${csvClass ? ` ${csvClass}` : ''}${!animate ? ' scale-in-center' : ' scale-out-center'}`} ref={containerRef}>
                                {
                                    (dataModel?.type !== 'file-preview') ? (
                                        <Fragment>
                                            {dataModel?.type !== 'tos' && <div className="modal-container-close">
                                                {
                                                    (!disableClose) &&
                                                    <span onClick={() => {
                                                        if (dataModel.action === 'closeAndUpload') {
                                                            return modalButtonAction(dataModel.action);
                                                        }
                                                        return modalButtonAction();
                                                    }}></span>
                                                }
                                            </div>}
                                            <div className="modal-container-header">
                                                {dataModel.data.title}
                                            </div>
                                            <div className="modal-container-body">
                                                {modalBodyContent()}
                                            </div>
                                            <div className="modal-container-footer">
                                                <CustomButton
                                                    inverted='inverted'
                                                    style={{ minHeight: '28px', minWidth: '140px' }}
                                                    disabled={!formIsValid}
                                                    click={() => {
                                                        if (files && dataModel?.type !== 'new-gene-set') {
                                                            return modalButtonAction('file-upload');
                                                        } else if (dataModel.action === 'closeAndUpload') {
                                                            return modalButtonAction(dataModel.action);
                                                        } else if (dataModel.action === 'close') {
                                                            return modalButtonAction(dataModel.action);
                                                        } else if (dataModel.type === 'permission') {
                                                            return modalButtonAction('permission');
                                                        } else if (dataModel.type === 'version-update') {
                                                            return onMessageModalClick(dataModel.data.btnText);
                                                        } else if (dataModel.type === 'tos') {
                                                            return modalButtonAction('tos');
                                                        }
                                                        return modalButtonAction('submit');
                                                    }}>
                                                    {dataModel.data.btnText}
                                                </CustomButton>
                                                {
                                                    (dataModel?.data?.cancelText) ?
                                                        <CustomButton
                                                            inverted=''
                                                            style={{ minHeight: '28px', minWidth: '140px', marginLeft: '10px' }}
                                                            click={modalButtonAction}
                                                        >
                                                            {dataModel.data.cancelText}
                                                        </CustomButton> :
                                                        null
                                                }
                                            </div>
                                        </Fragment>
                                    ) : modalBodyContent()
                                }
                            </div>
                        </div>
                    </Aux>
                ) : null
            }
        </Aux>
    );
}

const mapStateToProps = createStructuredSelector({
    dataModel: getModalData,
    appId: getAppId,
    currentUser: getCurrentUser
});

const mapDispatchToProps = (dispatch) => ({
    setModalData: () => dispatch(setModalData(null)),
    setModalSubmitData: (formData) => dispatch(setModalSubmitData(formData)),
    setLoader: (val, message) => dispatch(setLoader(val, message)),
    setFileUploadReset: (val) => dispatch(setFileUploadStateReset(val)),
    setDownloadNotifs: (val) => dispatch(setDownloadNotifs(val)),
    setNotifData: (val) => dispatch(setNotifData(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(OmiModal);