import React, { Fragment } from 'react';
import './page-loader.styles.scss';
import Loader from '../../../../assets/loaders/spinner-blue.gif';
import { createStructuredSelector } from 'reselect';
import { isLoading } from '../../../../redux/app/app.selectors';
import { connect } from 'react-redux';

const PageLoader = ({ loading }) => {
    const { val, text } = loading;

    return (
        val && (
            <Fragment>
                <div className="overlay-container loading"></div>
                <div className="loader-container">
                    <div className="loader">
                        <img src={Loader} alt="loader" />
                    </div>
                    {
                        text ?
                            <div className='loader-text'>
                                {text}...
                            </div> :
                            null
                    }
                </div>
            </Fragment>
        )
    );
}

const mapStateToProps = createStructuredSelector({
    loading: isLoading
});

export default connect(mapStateToProps)(PageLoader);