/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import FormInput from '../../../../../../../../core/components/form-input/form-input.component';
import formValidityCheck from '../../../../../../../../core/utils/validator';
import newSampleAttributeData from './data/sa-modal-body.data';
import sampleAttributeAddForm from './forms/sa-modal-body.forms';
import './sa-modal-body.styles.scss';

const style = {
    width: '260px',
    height: '36px'
};

const SampleAttributesModalBody = ({ changeTrigger, ...otherProps }) => {
    const { modalData } = otherProps;
    const [sampleAttributesForm, setSampleAttributesForm] = useState({
        formData: { ...sampleAttributeAddForm },
        isFormValid: false
    });
    const { formData } = sampleAttributesForm;
    const [sampleAttributeData, setSampleAttributeData] = useState({
        ...newSampleAttributeData
    });
    const enumerationHint = "Enter comma separated values. Eg: x, y, z, ...";

    useEffect(() => {
        resetFormValues();
        if (modalData) {
            populateEditValues(modalData);
        }
    }, [modalData]);

    const resetFormValues = () => {
        const updatedSampleAttributesForm = { ...formData };
        for (let identifier in updatedSampleAttributesForm) {
            updatedSampleAttributesForm[identifier].value = '';
            updatedSampleAttributesForm[identifier].valid = false;
            if (identifier === 'required') {
                updatedSampleAttributesForm[identifier].value = 'Yes';
                updatedSampleAttributesForm[identifier].valid = true;
            }
            if (identifier === 'indexed') {
                updatedSampleAttributesForm[identifier].value = 'No';
                updatedSampleAttributesForm[identifier].valid = true;
            }
            if (identifier === 'type') {
                updatedSampleAttributesForm[identifier].value = 'String';
                updatedSampleAttributesForm[identifier].valid = true;
                updatedSampleAttributesForm['possibleValues'].validation.required = false;
                updatedSampleAttributesForm['possibleValues'].valid = true;
                updatedSampleAttributesForm['possibleValues'].touched = false;
                updatedSampleAttributesForm['possibleValues'].elementConfig.hint = null;
                updatedSampleAttributesForm['possibleValues'].elementConfig.disabled = true;
            }
        }
        setSampleAttributesForm({ formData: updatedSampleAttributesForm });
        changeTrigger({ formData: updatedSampleAttributesForm, isFormValid: false });
    };

    const populateEditValues = (modalData) => {
        let formIsValid = true;
        const editData = {
            id: modalData['id'],
            sampleAttributeSource: modalData['name'],
            type: modalData['type'],
            examples: modalData['examples'],
            required: modalData['is_required'] ? 'Yes' : 'No',
            indexed: modalData['is_indexed'] ? 'Yes' : 'No',
            dataSource: modalData['data_source'],
            possibleValues: convertPossibleValues(modalData['possible_values']),
            category: modalData['category'],
            description: modalData['description']
        };
        const updatedSampleAttributesForm = { ...formData };
        updatedSampleAttributesForm['id'].value = null;
        for (let identifier in updatedSampleAttributesForm) {
            updatedSampleAttributesForm[identifier].value = editData[identifier];
            updatedSampleAttributesForm[identifier].valid = editData[identifier] ? true : false;
            if (updatedSampleAttributesForm[identifier].validation?.required) {
                formIsValid = updatedSampleAttributesForm[identifier].valid && formIsValid;
            }
        }
        updatedSampleAttributesForm['possibleValues'].elementConfig.type = 'text';
        if (editData.type === 'Date') {
            updatedSampleAttributesForm['possibleValues'].elementConfig.type = 'date';
        } else if (editData.type === 'Integer' || editData.type === 'Numeric') {
            updatedSampleAttributesForm['possibleValues'].elementConfig.type = 'number';
        } else if (editData.type === 'Enumeration') {
            updatedSampleAttributesForm['possibleValues'].elementConfig.disabled = false;
        }
        setSampleAttributesForm({ formData: updatedSampleAttributesForm });
        changeTrigger({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
    };

    const convertPossibleValues = (possibleValues = []) => {
        let valueArray = '';
        if (possibleValues && possibleValues.length) {
            possibleValues.forEach((value, index) => {
                if (index !== (possibleValues.length - 1)) {
                    valueArray += value + ', ';
                } else {
                    valueArray += value;
                }
            });
        }
        return valueArray;
    };

    const inputChangedHandler = (event, identifier) => {
        // FORM-DATA-UPDATE-HANDLER
        let formIsValid = true;
        const updatedSampleAttributesForm = { ...formData };
        const updatedFormElement = { ...updatedSampleAttributesForm[identifier] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedSampleAttributesForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation['message'] = errorMessage;
        updatedFormElement.touched = true;
        updatedSampleAttributesForm[identifier] = updatedFormElement;
        for (let identifier in updatedSampleAttributesForm) {
            if (updatedSampleAttributesForm[identifier].validation?.required) {
                formIsValid = updatedSampleAttributesForm[identifier].valid && formIsValid;
            }
        }
        setSampleAttributesForm({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
        changeTrigger({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
    };

    const selectValue = (event, identifier) => {
        if (event) {
            let formIsValid = true;
            const updatedSampleAttributesForm = { ...formData };
            if (event === 'Enumeration' && modalData && modalData['possible_values']) {
                updatedSampleAttributesForm['possibleValues'].value = convertPossibleValues(modalData['possible_values']);
            }
            const updatedFormElement = { ...updatedSampleAttributesForm[identifier] };
            updatedFormElement.value = (event && event?.id ? event.id : (event?.name) ? event.name : event ? event : updatedFormElement?.value ? updatedFormElement.value : '');
            const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedSampleAttributesForm);
            updatedFormElement.valid = validity;
            updatedFormElement.validation['message'] = errorMessage;
            updatedFormElement.touched = true;
            updatedSampleAttributesForm[identifier] = updatedFormElement;
            conditionalValTrigger(identifier, updatedSampleAttributesForm, updatedFormElement);
            setSampleAttributesForm({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
            changeTrigger({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
            for (let identifier in updatedSampleAttributesForm) {
                if (event !== 'Enumeration') {
                    updatedSampleAttributesForm['possibleValues'].value = '';
                }
                if (updatedSampleAttributesForm[identifier].validation?.required) {
                    formIsValid = updatedSampleAttributesForm[identifier].valid && formIsValid;
                }
            }
            setSampleAttributesForm({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
            changeTrigger({ formData: updatedSampleAttributesForm, isFormValid: formIsValid });
        }
    };

    const conditionalValTrigger = (identifier, updatedSampleAttributesForm, updatedFormElement) => {
        if (identifier === 'type') {
            const enumeratedValues = updatedSampleAttributesForm['possibleValues'];
            enumeratedValues.elementConfig.type = 'text';
            enumeratedValues.validation.required = false;
            enumeratedValues.valid = true;
            enumeratedValues.touched = false;
            enumeratedValues.elementConfig.hint = null;
            enumeratedValues.elementConfig.disabled = false;
            if (updatedFormElement.value === 'Enumeration') {
                enumeratedValues.validation.required = true;
                enumeratedValues.touched = true;
                enumeratedValues.elementConfig.hint = enumerationHint;
                if (enumeratedValues.value === '') {
                    enumeratedValues.valid = false;
                    enumeratedValues.validation.message = `Enter ${enumeratedValues.description}`;
                }
            } else if (updatedFormElement.value === 'Integer') {
                enumeratedValues.elementConfig.type = 'number';
                enumeratedValues.elementConfig.step = '1';
                enumeratedValues.elementConfig.disabled = true;
            } else if (updatedFormElement.value === 'Numeric') {
                enumeratedValues.elementConfig.type = 'number';
                enumeratedValues.elementConfig.disabled = true;
            } /* else if (updatedFormElement.value === 'Date') {
                enumeratedValues.elementConfig.type = 'date';
            } */
            else {
                enumeratedValues.elementConfig.disabled = true;
            }
        }
    }

    return (
        <Fragment>
            <div className="sa-form-elements req">
                <span className="req">*</span> Required fields
            </div>
            <div className="sa-form-elements">
                <div>
                    <FormInput
                        tabIndex={1}
                        style={style}
                        label={formData.sampleAttributeSource.label}
                        elementType={formData.sampleAttributeSource.elementType}
                        elementConfig={formData.sampleAttributeSource.elementConfig}
                        invalid={!formData.sampleAttributeSource.valid}
                        shouldValidate={formData.sampleAttributeSource.validation}
                        touched={formData.sampleAttributeSource.touched}
                        errorMessage={formData.sampleAttributeSource.validation.message}
                        value={formData.sampleAttributeSource.value}
                        changed={(event) => inputChangedHandler(event, 'sampleAttributeSource')}
                        blurred={(event) => inputChangedHandler(event, 'sampleAttributeSource')} />
                </div>
                <div>
                    {/* <FormInput
                        tabIndex={2}
                        style={style}
                        label={formData.type.label}
                        elementType={formData.type.elementType}
                        elementConfig={formData.type.elementConfig}
                        invalid={!formData.type.valid}
                        shouldValidate={formData.type.validation}
                        touched={formData.type.touched}
                        errorMessage={formData.type.validation.message}
                        value={formData.type.value}
                        changed={(event) => inputChangedHandler(event, 'type')}
                        blurred={(event) => inputChangedHandler(event, 'type')} /> */}
                    <FormInput
                        tabIndex={2}
                        style={style}
                        idSelected={formData.type.value}
                        label={formData.type.label}
                        elementType={formData.type.elementType}
                        elementConfig={formData.type.elementConfig}
                        isToggled={formData.type.toggle}
                        invalid={!formData.type.valid}
                        shouldValidate={formData.type.validation}
                        touched={formData.type.touched}
                        errorMessage={formData.type.validation.message}
                        list={sampleAttributeData['attributeType']}
                        selectVal={(event) => selectValue(event, 'type')}
                        blurred={(event) => selectValue(event, 'type')} />
                </div>
            </div>
            <div className="sa-form-elements">
                <div>
                    <FormInput
                        tabIndex={3}
                        style={style}
                        label={formData.examples.label}
                        elementType={formData.examples.elementType}
                        elementConfig={formData.examples.elementConfig}
                        invalid={!formData.examples.valid}
                        shouldValidate={formData.examples.validation}
                        touched={formData.examples.touched}
                        errorMessage={formData.examples.validation.message}
                        value={formData.examples.value}
                        changed={(event) => inputChangedHandler(event, 'examples')}
                        blurred={(event) => inputChangedHandler(event, 'examples')} />
                </div>
                <div>
                    <FormInput
                        tabIndex={4}
                        style={style}
                        idSelected={formData.required.value}
                        label={formData.required.label}
                        elementType={formData.required.elementType}
                        elementConfig={formData.required.elementConfig}
                        isToggled={formData.required.toggle}
                        invalid={!formData.required.valid}
                        shouldValidate={formData.required.validation}
                        touched={formData.required.touched}
                        errorMessage={formData.required.validation.message}
                        list={sampleAttributeData['required']}
                        selectVal={(event) => selectValue(event, 'required')}
                        blurred={(event) => selectValue(event, 'required')} />
                </div>
            </div>
            <div className="sa-form-elements">
                <div>
                    <FormInput
                        tabIndex={5}
                        style={style}
                        idSelected={formData.indexed.value}
                        label={formData.indexed.label}
                        elementType={formData.indexed.elementType}
                        elementConfig={formData.indexed.elementConfig}
                        isToggled={formData.indexed.toggle}
                        invalid={!formData.indexed.valid}
                        shouldValidate={formData.indexed.validation}
                        touched={formData.indexed.touched}
                        errorMessage={formData.indexed.validation.message}
                        list={sampleAttributeData['indexed']}
                        selectVal={(event) => selectValue(event, 'indexed')}
                        blurred={(event) => selectValue(event, 'indexed')} />
                </div>
                <div>
                    <FormInput
                        tabIndex={6}
                        style={style}
                        label={formData.possibleValues.label}
                        elementType={formData.possibleValues.elementType}
                        elementConfig={formData.possibleValues.elementConfig}
                        invalid={!formData.possibleValues.valid}
                        shouldValidate={formData.possibleValues.validation}
                        touched={formData.possibleValues.touched}
                        errorMessage={formData.possibleValues.validation.message}
                        value={formData.possibleValues.value}
                        changed={(event) => inputChangedHandler(event, 'possibleValues')}
                        blurred={(event) => inputChangedHandler(event, 'possibleValues')} />
                </div>
            </div>
            <div className="sa-form-elements">
                {/* <div>
                    <FormInput
                        tabIndex={7}
                        style={style}
                        label={formData.category.label}
                        elementType={formData.category.elementType}
                        elementConfig={formData.category.elementConfig}
                        value={formData.category.value}
                        changed={(event) => inputChangedHandler(event, 'category')}
                        blurred={(event) => inputChangedHandler(event, 'category')} />
                </div> */}
                <div>
                    <FormInput
                        tabIndex={7}
                        style={style}
                        label={formData.dataSource.label}
                        elementType={formData.dataSource.elementType}
                        elementConfig={formData.dataSource.elementConfig}
                        invalid={!formData.dataSource.valid}
                        shouldValidate={formData.dataSource.validation}
                        touched={formData.dataSource.touched}
                        errorMessage={formData.dataSource.validation.message}
                        value={formData.dataSource.value}
                        changed={(event) => inputChangedHandler(event, 'dataSource')}
                        blurred={(event) => inputChangedHandler(event, 'dataSource')} />
                </div>
            </div>
            <div className="sa-form-elements">
                <div style={{ width: '100%', height: 'auto' }}>
                    <FormInput
                        tabIndex={8}
                        label={formData.description.label}
                        elementType={formData.description.elementType}
                        elementConfig={formData.description.elementConfig}
                        invalid={!formData.description.valid}
                        shouldValidate={formData.description.validation}
                        touched={formData.description.touched}
                        errorMessage={formData.description.validation.message}
                        value={formData.description.value}
                        changed={(event) => inputChangedHandler(event, 'description')}
                        blurred={(event) => inputChangedHandler(event, 'description')} />
                </div>
            </div>
        </Fragment>
    );
}

export default SampleAttributesModalBody;