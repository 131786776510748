const newUserForm = {
    id: { value: null },
    userName: {
        label: 'Name',
        description: 'name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    email: {
        label: 'Email',
        description: 'email',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: '',
            disabled: false
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    },
    availability: {
        label: 'User role',
        description: 'user role',
        elementType: 'select',
        elementConfig: {
            type: 'select',
            placeholder: '',
            disabled: false
        },
        value: 'LAB',
        validation: {
            required: true
        },
        valid: false,
        touched: false
    }
};

export default newUserForm;